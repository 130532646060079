@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.main {
  background-color: white;
  font-family: "Inter", sans-serif;
}
.message {
  color: #333a40 !important;
}

.modal-header-section {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0;
  align-items: center;

  .close {
    line-height: 0.8;
    font-size: 1.375rem;
    opacity: 1;
  }

  .modal-heading {
    display: flex;
    align-items: center;

    i {
      font-size: 1.25rem;
    }

    h4 {
      font-weight: 600;
      font-size: 1rem;
    }
  }
}
.modal-footer-section {
  display: flex;
  justify-content: flex-start;
  padding: 0 1rem 1rem;
  flex-direction: row-reverse;

  .btn {
    margin-left: 0.5rem;
    padding: 5px 20px;
    border: none;
  }
}

.modal {
  font-family: 'Inter', sans-serif !important;

  .modal-content {
    background-color: #ffffff !important;
    label {
      font-family: 'Inter', sans-serif !important;
    }

    .modal-body {
      h5 {
        font-size: 0.8125rem;
      }
    }
  }

  //text editor warning popup

  .text-editor-warning, .delete-popup {
    max-width: 25rem;

    .modal-heading {
      i {
        color: #ff565e;
        margin-right: 0.625rem;
      }

      h4 {
        color: #ff565e;
      }
    }

    .btn-primary {
      background-color: #ff565e !important;
      border-color: #ff565e !important;

      &:focus,
      &:active {
        background-color: #ff565e !important;
        border-color: #ff565e !important;
      }
    }
  }
}
