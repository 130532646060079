.trace-formula-wrap {
    padding: 0.625rem 1rem 0 1rem;
    height: 100%;
}

.trace-formula-header {
    margin: 0 -1rem;
    padding: 0 1rem 0.625rem 1rem;
}

.trace-formula-body {
    height: calc(100% - 2.75rem);
    display: grid;
    grid-template-columns: 19.25rem auto;
}

.trace-formula-list {
    height: calc(100vh - 11.125rem);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.formula-item {
    cursor: pointer;
    padding: .8rem 1rem;
    border-radius: .5rem;
    background: #fff;
    border-left: solid 0.25rem transparent;
    display: flex;
    flex-direction: column;
    gap: .4rem;
    transition: .3s;

    .formula-delete-btn {
        transform: scale(0);
        transition: .3s;
    }

    &:hover {
        background: #F9F9F9;

        .formula-delete-btn {
            transform: scale(1);
            transition: .3s;
        }
    }
    &.active {
        background: #F9F9F9;
        border-left: solid 0.25rem #2c83c4;
        transition: .3s;
    }
}

.formula-col-pill {
    background: #DEE2E6;
    border-radius: .2rem;
    padding: .25rem .3rem;
    max-width: 5rem;
    width: fit-content !important;
}

.formula-meta-info-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
}

.formula-select .ant-select-selection-search {
    margin-inline-start: 0 !important;
}
