@import "../../../../../assets/scss/custom/common/variables";
@import "../../../../../assets/scss/custom/common/fonts";
@import "../../../../../assets/scss/custom/common/colors";
@import "../../../../../assets/scss/common/colors";

.user-card {
  .list-section {
    max-width: 46.25rem;
    margin: 0.375rem auto;
    display: flex;
    align-items: center;

    // display: grid;
    // align-items: center;
    // grid-template-columns: 20px 64px auto;
  }

  .list-column {
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 0;
    width: 6.25rem;
    height: 1.375rem;
    line-height: 1.5rem;
    .paragraph {
      & span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        width: calc(100% - 0.3125rem);
      }
    }
  }

  .list-column-first {
    width: 1.25rem;
  }

  .list-column-second {
    width: 4.125rem;
  }
}

.user-table .icon-box-desc {
  margin-top: 3px;
}

.user-gridview {
  display: grid;
  gap: $padding10;
  grid-template-columns: repeat(auto-fill, minmax(18.125rem, 1fr));
  margin: 0;
  padding: $padding10;
  position: $p-relative;
  // height: calc(100vh - 73px);
  // overflow-y: scroll;

  .card-tags {
    bottom: auto;
    top: 2.5rem;
    right: 1.875rem;
  }

  .card {
    border-radius: 0.1875rem;
    border: none;
    cursor: pointer;
    height: 8.4375rem;
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow:
        0 1px 2px -2px rgba(0, 0, 0, 0.16),
        0 3px 6px 0 rgba(0, 0, 0, 0.12),
        0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
  }

  .card-body {
    padding: $padding10;
  }

  .landingCard-circle {
    display: flex;
    justify-content: space-between;
    position: $p-relative;

    &::after {
      content: "";
      position: $p-absolute;
      height: 1px;
      background-color: $light-background;
      left: -10px;
      right: -10px;
      top: 50%;
      transform: translate(0, -50%);
    }

    .icon-box {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 50%;
      border: 1px solid $light-background;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      z-index: 1;

      :is(img, svg) {
        height: 1.875rem;
        width: auto;
      }
    }

    .landingCard-profile-list {
      display: flex;
    }
  }

  .landingCard-actions {
    display: flex;
  }

  .landingCard-header {
    display: flex;
    align-items: center;
    margin-bottom: $margin10;
    margin-top: $margin10;
    color: $primary;
    font-weight: 800;

    p {
      margin: 0;
    }
  }

  .landingCard-description {
    // margin-bottom: $margin10;
  }

  .landingCard-profile-list {
    .activeIcon {
      margin-top: 13px;
    }

    .landingCard-profile-listitem {
      display: flex;
      align-items: center;
      margin-bottom: $margin10;

      .label {
        margin: 0 $margin10;
      }

      span {
        &:last-child {
          width: calc(100% - 106px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .landingCard-actions .edit {
    margin-right: 5px;
  }
}

.add-user-modal {
  width: 500px;

  .add-user-form {
    .add-user-form-dual-fields {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .add-user-form-checkbox {
      display: flex;
      gap: 5px;

      .label {
        font-weight: 600;
      }
    }
  }
}
.Oauth-main-container {
  .Oauth-topheading {
    margin-top: 2%;
    font-family: "InterMedium";
    font-size: 13px;
    font-weight: 400;
  }
  .heading-title {
    font-size: 14px;
    font-weight: bold;
  }
  .small-text {
    font-size: 10px;
    font-weight: 200;
    color: #d3d0d0;
  }
  .Oauth-btn {
    background-color: #ffffff;
    outline: none !important;
    border: none !important;
    padding: 21px;
    border-radius: 0  ;
    font-size: 14px;
    box-shadow: 1px 1px 1px 1px #d9d9d9;
  }
}
.SAML-main-container {
  background-color: #ffffff;

  .SAML-topheading {
    padding: 10px;
    height: 40px;
    border-top: 1px solid #d3d0d0;
    border-bottom: 1px solid #d3d0d0;
  }
  .heading-title {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 600;
  }
  .heading-2 {
    font-size: 13px;
    color: #9b9b9b !important;
  }
  .SAML-details {
    height: calc(100vh - 120px);
    overflow: auto;
    margin-top: 10px;
    padding-left: 78px;
    padding-right: 78px;
  }
  .label {
    color: #6c757d;
  }
  .checkbox-label {
    margin: 8px;
    color: #6c757d;
    font-size: 12px;
    font-family: "interMedium";
  }
}

.user-details-page {
  .sub-header-right{  //added for user details 'page not aligned buttons
    display: inline-flex;
  }
  .user-details-content {
    background-color: #f9f9f9;
    padding: 0.625rem;
    height: calc(100vh - 4.4375rem);

    .user-general-details {
      background: #ffffff;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0.1875rem;
      margin-bottom: 0.625rem;

      .user-general-details-header {
        display: flex;
        padding: 0.625rem;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f1f1f1;

        .sup-user-ic {
          svg {
            height: 1.25rem;
            width: 1.25rem;
          }
        }

        & > p {
          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
        }

        p {
          margin: 0;
          font-weight: 600;
          font-size: $fontSizeHeading;
          span {
            margin: 0.3125rem;
          }
        }
      }

      .user-general-details-content {
        padding: 0.625rem;
        width: 78%;
      }
    }
  }
}

.user-tile-supusr {
  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
}