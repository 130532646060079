.info-item {
  display: flex;
  align-items: center;

  .icon-box {
    background-color: #f1f1f1;
    width: 2.1875rem;
    height: 2.1875rem;
    border-radius: 50%;
    text-align: center;
    line-height: 2.1875rem;

    svg {
      max-height: 1.875rem;
      max-width: 1.875rem;
    }
  }

  .info-item-content {
    margin-left: 0.625rem;
    font-size: 0.6875rem;
    .label {
      margin: 0;
      text-transform: capitalize;
    }
    p {
      margin: 0;
      color: #828282;
    }
  }

  &:not(:last-child) {
    margin-right: 1.25rem;
  }
}
