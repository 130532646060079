.access-mgmt-table {
    height: calc(100vh - 290px);

    table {
        .ant-table-thead {
            th {
                background-color: $tableColGrey;
            }
        }

        th,
        td {
            &.description {
                width: 30%;

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    white-space: inherit;
                    word-break: break-all;
                }
            }
        }
    }
}

body {
    .sources_circle {
        height: 1.5rem;
        width: 1.5rem;
        background-color: $altoGray;
        border-radius: 50%;
        display: inline-block;
    }

    .data-domain-caps {
        table {
            tr {
                td {
                    &:nth-child(2) {
                        text-transform: $tt-uppercase;
                    }
                }
            }
        }

        ul {
            li {
                span {
                    text-transform: $tt-uppercase;
                }
            }
        }
    }

    table {
        border-collapse: collapse;

        td {
            .avatar-group {
                padding-left: $padding10 + 2;
                display: flex;
                flex-wrap: wrap;
            }

            .avatar-group .avatar-group-item {
                margin-left: -6px;
                border-radius: 50%;

                .avatar-xs {
                    position: relative;

                    .bxs-crown {
                        position: absolute;
                        top: -5px;
                        left: -5px;
                        padding: $padding5 - 2;
                        color: $yellow;
                        border: 1px solid $yellow;
                        border-radius: 50%;
                        background-color: $white;
                        font-size: $font12 - 2;
                    }
                }
            }
        }
    }

    .ant-table {
        // table {
        //     table-layout: auto !important;
        // }
        .ant-table-thead {
            tr {
                th {
                    border: none;
                    font-family: $inter-semibold;
                    color: $color-content;
                    background-color: $header-back;

                    .ant-table-column-sorters {
                        justify-content: space-between;
                        display: flex;
                    }

                    &::before {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 1px;
                        height: 1.6em;
                        background-color: rgba(0, 0, 0, 0.06);
                        transform: translateY(-50%);
                        transition: background-color 0.3s;
                        content: "";
                    }

                    span {
                        font-family: $inter-semibold;

                        div {
                            font-family: $inter-semibold;
                        }
                    }
                }
            }
        }

        .ant-table-column-title {
            z-index: 0;
        }

        

        .ant-table-tbody {
            tr {
                cursor: pointer;

                // &.table-selected-row {
                //     td {
                //         background-color: $header-back !important;
                //     }
                // }

                &.ant-table-row-selected {
                    td {
                        background-color: $header-back !important;
                    }
                }
            }

            td {
                color: $color-content;
                font-weight: 500;
                vertical-align: middle;

                &.ant-table-column-sort {
                    background-color: $white;
                }
            }
        }

        &.ant-table-empty {
            .ant-table-tbody {
                tr {
                    cursor: auto;

                    &:hover {
                        td {
                            background-color: inherit;
                        }
                    }
                }
            }
        }

        tr {
            th,
            td {
                padding: $padding5 $padding10 !important;

                &.single-line-ellipsis {
                    width: $width-full - 85;
                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: inherit;
                        word-break: break-all;
                    }
                    div {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: inherit;
                        word-break: break-all;
                    }
                }
            }

            td {
                font-weight: 500;
                border-bottom: 1px solid $galleryGray;
            }

            .ant-table-column-sorters {
                padding: 0 !important;
            }

            &.ant-table-measure-row {
                td {
                    padding: 0 !important;
                }
            }
        }
    }

    .ant-dropdown {
        box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        min-width: $width50 * 3 !important;
        
        ul {
            box-shadow: none;
            border-radius: 4px;

            li {
                .bx-trash-alt {
                    color: $lightred;
                }

                .bx-edit-alt {
                    color: $primary;
                }
            }
        }
    }

    .ant-dropdown,
    .ant-dropdown-menu-submenu {
        .ant-dropdown-menu {
            padding: 0;

            li {
                line-height: 20px;

                span {
                    font-size: $font12;
                    // color: $color-content;
                }

                i {
                    padding-right: $padding5;
                    font-size: $font12;
                    vertical-align: middle;
                    margin: 0 $margin10 0 0;
                }

                &.ant-dropdown-menu-item-active {
                    background-color: #F8F8FF;
                }

                &.ant-dropdown-menu-submenu-active {
                    .ant-dropdown-menu-submenu-title {
                        background-color: #F8F8FF;
                    }
                }
            }
        }
    }

    .ant-dropdown-menu-submenu {
        .ant-dropdown-menu {
            li {
                line-height: 16px;

                .bx-plus-circle,
                .bx-trash-alt {
                    float: right;
                    padding: 0;
                    margin: $margin5 0 0 0;
                }
            }
        }
    }

    .landingPage_listView_data {
        .ant-table {
            td,
            th {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(5),
                &:nth-child(7) {
                    width: 200px;
                }
            }

            td {
                &:nth-child(5),
                &:nth-child(7) {
                    span {
                        width: 180px;
                    }
                }
            }

            .ant-table-tbody {
                td {
                    .landingPage_listView_sources {
                        margin-left: $margin10;
                    }

                    &:nth-child(1) {
                        > div {
                            p {
                                max-width: 160px;
                            }
                        }
                    }

                    > span {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        margin: 0;
                        display: block;
                    }

                    > div {
                        display: flex;
                        flex-wrap: wrap;

                        p {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            margin: 0;
                        }
                    }

                    &:last-child {
                        > div {
                            flex-wrap: nowrap;
                        }
                    }

                    &.description {
                        > div {
                            p {
                                text-overflow: inherit;
                                white-space: inherit;
                                overflow: inherit;

                                span:first-child {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: grid !important;
                                    grid-template-columns: repeat(500, auto);
                                    // display: -webkit-box !important;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    white-space: inherit;
                                    word-break: break-all;
                                    span{
                                        display: -webkit-box  !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-accordion {
        .ant-table {
            .ant-table-thead {
                tr {
                    th {
                        background-color: $tableColGrey;
                    }
                }
            }
        }
    }

    .section-header {
        & ~ .ant-table-wrapper {
            .ant-table {
                .ant-table-thead {
                    tr {
                        th {
                            background-color: $tableColGrey;
                        }
                    }
                }
            }
        }
    }

    .data-catalog-list-view {
        & > div {
            // height: 300px;
        }
        .custom-simple-landing-dgt {
            height: 100%;
            overflow-x: hidden;
        }
    }
}
