@import "../../../../../assets/scss/common/colors";
@import "../../../../../assets/scss/common/font";
@import "../../../../../assets/scss/common/variables";
@import "../../../../../assets/scss/custom/common/variables";

.icon-box-desc {
  float: left;
  margin-right: 0.375rem;
  margin-top: -0.1875rem;
}

.user-groups {
  .user-groups-container {
    padding: 0.625rem;
    display: flex;
    gap: 0.625rem;
    height: calc(100vh - 4.375rem);
    background-color: #f9f9f9;

    .user-groups-left-side {
      width: 30%;
      border-right: 0.0625rem solid $light-background;
      background-color: $white;
      box-shadow: 0.0625rem 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.25);
      background-color: $white;

      .user-group-details {
        .user-group-details-fields {
          padding: 0.5rem 0.625rem;
        }

        .user-group-details-header {
          padding: 0.625rem;
          border-bottom: 0.0625rem solid #ededed;
          font-size: 0.8125rem;
          font-weight: 600;
        }
      }
    }

    .user-groups-right-side {
      width: 70%;
      position: relative;
      background-color: $white;
      overflow-y: scroll;
      background-color: #f9f9f9;

      .user-groups-table {
        height: 100%;
        background: #ffffff;
        box-shadow: 0.0625rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
        border-radius: 0.1875rem;

        display: flex;
        flex-direction: column;

        .user-groups-table-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.625rem;
          border-bottom: 0.0625rem solid #f1f1f1;
        }

        .user-groups-table-title {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          h4 {
            font-weight: 600;
            font-size: $fontSizeHeading;
            margin: 0;
          }
        }
        .user-groups-table-options {
        }

        .user-groups-table-container {
          flex: 1;

          //State

          .double-toggle-btn {
            width: $width-full;

            .ant-radio-group {
              display: flex;
            }

            .ant-radio-button-wrapper {
              width: 50%;

              &:nth-child(2) {
                border-radius: 0;
                border-left: 0.0625rem solid $grey-three;
              }

              .ant-radio-button {
                ~ span {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    margin-right: $margin5;
                    width: 0.9375rem;
                    height: 0.9375rem;
                  }
                }
              }
            }
          }

          ///END
        }
      }
    }
  }
  .no-data-container .no-data-content {
    svg {
      height: 8.75rem;
      width: 10.75rem;
    }
  }
}
.user-group-modal {
  @media (max-width: 992px) {
    max-width: 90% !important;
  }
}
