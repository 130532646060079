@import "../../../assets/scss/custom/common/variables";
@import "../../../assets/scss/common/colors";
.pro-common-modal {
    .modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }
    .modal-header {
        height: 2.5rem;
    }
    .modal-footer {
        min-height: 2.5rem;
    }
    :is(.modal-header, .modal-footer) {
        padding: 0.25rem 1rem !important;
    }
    &.confirm-popup {
        max-width: 25rem;
        min-width: 21.875rem; 
        .modal-title h2 {
            color: $primary;
        }

        .pro-common-modal-heading {
            svg {
                height: 1.25rem;
                width: 1.25rem;
            }
        }
    }
    &.modal-custom-sm {
        min-width: 300px;
        max-width: 400px;
    }
}
.pro-common-modal-heading {
    font-size: $fontSizeHeading;
    margin-bottom: 0;
    font-weight: 700;
}