#main-lpg-wrap {
    position: relative;
    height: calc(100vh - 2.25rem);
    overflow-y: auto !important;
    &.stop-scroll {
        overflow: hidden !important;
    }
    #landingContentTab {
        width: 24rem;
    }
}
.rel-dom-tb-wrap h4 {
    transition: .3s;
}
.rel-dom-tb-wrap {
    &.stay {
        position: sticky;
        top: 3.875rem;
        z-index: 2;
        background: #fff;
        border-bottom: solid 1px #d6d6d6;
        h4 {
            // height: 0;
            // margin: 0;
            // opacity: 0;
            padding-top: 1rem;
            transition: .3s;
        }
    }
}
.leg-title-cont {
    position: relative;
    transition: .3s;
    &.fade {
        margin-top: 0;
        transition: .3s;
    }
}
.lpg-gif {
    transition: .3s;
}
.leg-content-wrap, .lpg-gif, .landing-search-lg {
    transition: .3s;
}
.landing-search-lg, .leg-content-wrap {
    position: relative;
}
.clsta-land-legend {
    height: auto;
    transition: .3s;
}
.leg-shrink {
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: #FFF;
    background-image: none !important;
    transition: .3s;

    &.leg-shrink-dict {
        // height: 124px;
        top: -50px;
        #landing-search-lg {
            bottom: -52px;
            transition: .3s;
        }
    }

    .lpg-gif {
        display: none;
        visibility: hidden;
        z-index: -10;
    }

    .leg-content-wrap {
        // Domain Slider Removed so that css is updated here
        top: -4.8125rem;
        margin-top: -4rem;

        // With Domain Slider use this css
        // top: -7.8125rem;
        // margin-top: -1rem;
        transition: .3s;
    }
}
.ex-comp-wrap {
    display: grid;
    grid-template-columns: minmax(48.25rem, 34.375rem) auto;
    align-items: flex-start;

    .catalog-extra-btns {
        position: relative;
        top: 0.375rem;
    }
}
.custom-content-dgt {
    border: none !important;
}
.new-dict-lpg {
    .custom-content-dgt {
        height: calc(100vh - 174px) !important;
        margin-bottom: 15px;
        &::-webkit-scrollbar {
            display: none;
        }
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }
    .custom-simple-landing-dgt {
        &::-webkit-scrollbar {
            display: none;
        }
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }
}
.data-dict-count {
    color: #ffffff;
    font-size: 0.75rem;
    position: absolute;
    top: -2.5rem;
    background: #2c83c4;
    right: 0.625rem;
    z-index: 2;
    margin: 0;
    line-height: 1;
    padding: 0.25rem 0.625rem;
    border-radius: 0.625rem;
    font-family: 'InterSemibold';
}

.main-lpg-content {
    margin-inline: 10.4rem;

    @media (max-width: 1400px) {
        margin-inline: 7.5rem;
    }
    @media (max-width: 1100px) {
        margin-inline: 5.5rem;
    }
    @media (max-width: 900px) {
        margin-inline: 3.5rem;
    }
}
.leg-content-wrap{
    margin-inline: 9.2rem;

    @media (max-width: 1400px) {
        margin-inline: 6.25rem;
    }
    @media (max-width: 1100px) {
        margin-inline: 4.25rem;
    }
    @media (max-width: 900px) {
        margin-inline: 2.25rem;
    }
}