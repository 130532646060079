// @import "../../../../../assets/scss/custom/common/variables";
// @import "../../../../../assets/scss/custom/common/fonts";
// @import "../../../../../assets/scss/common/colors";

@import "../../../../../assets/scss/custom/common/variables";
@import "../../../../../assets/scss/custom/common/fonts";
@import "../../../../../assets/scss/custom/common/colors";
@import "../../../../../assets/scss/common/colors";
.createPOD-newconn {
    font-size: 0.875rem;
    background-color: #fff;
    .card {
        margin: 0;
        border-radius: 0 !important;
        box-shadow: none !important;
        border: 0;
    }

    .new-conn-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.25rem 1.125rem;

        p {
            margin: 0 0.625rem 0 0;
            cursor: auto;
            color: $color-content;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 20rem);
        }

        .btn {
            margin: 0 0 0 $margin10;
            padding: 0 $padding10;

            &:disabled {
                background-color: $primary;
                color: $white !important;
            }
        }
    }

    .createPOD-heading {
        background-color: #eff2f7;
        padding: $padding10 1.25rem;
        font-size: $font12;
        font-weight: 600;
        color: $color-content;
    }

    .podHeading {
        font-size:0.75rem;
        color: $grey-three;
    }

    .createPOD-newconn-carousel {
        display: flex;
        align-items: center;
        padding: 0.625rem;

        i {
            color: #5f5de9;
            font-size: 1.25rem;
        }

        .carousel-inner {
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
                padding: 0.3125rem;
                border: 1px solid #fff;

                &.selected {
                    border: 1px solid #5f5de9;
                    border-radius: 2.1875rem;
                }
            }

            img {
                cursor: pointer;
                height: 3.125rem;
            }
        }
    }

    .createPOD-newconn-form {
        padding: 0.9375rem 1.25rem;

        label {
            font-size: 0.75rem !important;
            font-family: $inter-regular !important;
            font-weight: 400 !important;
            margin: $margin10 0 $margin5 0;
            color: $grey-three;
            i {
                color: $primary;
            }
        }

        .ant-select-selector {
            min-height: 2.1875rem;
            border: 1px solid $galleryGray !important;
            border-radius: 0 !important;
            padding: 0 0.25rem !important;
            font-family: $inter-regular;
            box-shadow: none !important;
            font-size: $font12;
        }
    }
}
.createPOD-newconn {
    display: grid;
    grid-template-columns: 45% 55%;
}

.connection-srcs-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    text-align: center;
    justify-items: center;
    gap: 0.625rem;
    align-items: start;
}
.connection-srcs-grid {
    & > .connection-srcs-grid-template {
        display: flex;
        align-items: center;
        justify-content:center;
        flex-wrap: wrap;
        &.disable-tile {
            opacity: .6;
        }
        &:not(.disable-tile):hover {
            > div {
                outline: solid 3px #2c83c4;
                border-color: transparent;
            }
          .con-src-name {
                color: #2c83c4;
                font-weight: 700;
            }  
        } 
        & > .con-src-name {
            cursor: pointer;
            :is(&.selected) {
                color: #2c83c4;
                font-weight: 700;
            }
            width: 100%;
            display: block;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 0.75rem;
        }
        & > div {
            position: relative;
            :is(&.selected) {
                outline: solid 0.1875rem #2c83c4;
                border-color: transparent;
            }
            height: 3.75rem;
            width: 3.75rem;
            border: solid 0.0625rem #00000040;
            border-radius: 100%;
            padding: 0.125rem;
            display: flex;
            align-items: center;
            justify-content: center;
            & img {
                border-radius: 100%;
            }
        }
    }
}
.cm-conn-modal {
    & > .modal-content {
        // height: 756px;
    }
}
.selected-connection-cm {
    border: 1px solid #00CC92 !important;
}
#connCrtModal {
    &.custom-select-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
        height: auto !important;
    }
    .ant-select-selection-item-content {
        display: flex !important;
        align-items: center !important;
    }
    .ant-select-selection-item {
        background: #2c83c4;
        color: #fff !important;
    }
    .ant-select-selection-item-remove {
        color: #fff;
    }
    .ant-select-selection-overflow {
        max-width: calc(100% - 18px) !important;
    }
}

.create-dom-sel {
    .ant-select-selection-search {
        margin-left: 0 !important;
    }
}

.cm-conn-modal  {
    .modal-title {
        .d-flex.align-items-center {
            svg {
                height: 1.125rem;
                width: 1.125rem;
            }
        }
    }
}