@import "../../../assets/scss/common/colors";
@import "../../../assets/scss/common/variables";
@import "../../../assets/scss/common/font";

body {
    .flow-landing-content {
        .common-page-table {
            .ant-table-body {
                tbody {
                    tr {
                        td {
                            &:nth-child(3),
                            &:nth-child(5),
                            &:nth-child(7) {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }

    .section-with-drag {
        .sources_circle {
            margin: 0 $margin10 0 0;
        }

        .label {
            color: $grey-three;
        }

        .custom-flex-width {
            width: calc(100% - 2.1875rem);
        }

        .flows-filter-wrapper,
        .flow-query-builder {
            max-height: calc(100vh - 14.0625rem);
            overflow: auto;
        }

        .height-60 {
            height: 3.75rem;
        }

        .w-70 {
            width: $width-full - 30;
        }

        .w-30 {
            width: $width-full - 70;
        }

        .w-60 {
            width: $width-full - 40;
        }

        .w-40 {
            width: $width-full - 60;
        }

        .ace_editor {
            .ace_gutter {
                background-color: $light-background;
                color: $black;
            }
        }

        .landingPage_listView_sources {
            img {
                width: 30px;
                height: 30px;
            }
        }
    }

    .flow-filter-height {
        height: calc(100vh - 102px);
        // height: calc(100% - 32px);
        // overflow: auto;
    }

    .flow-card-view {
        padding: 0.9375rem;
        display: grid;
        align-items: flex-start;
        grid-template-columns: repeat(auto-fill, minmax(17.375rem, 1fr));
        gap: 1.75rem;
        overflow: auto;
        .flows-landing-cards-link{
            position: relative;
        }
        & > .flows-landing-cards-link:not(:last-child)::after {
            content: "";
            display: block;
            width: 2px;
            height: 75%;
            background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
            position: absolute;
            top: 12%;
            right: -0.9375rem;
        }

        & > .flows-landing-cards-link:not(:last-child)::before {
            content: "";
            display: block;
            width: 90%;
            height: 2px;
            background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
            position: absolute;
            bottom: -0.9375rem;
            left: 50%;
            transform: translateX(-50%);
        }

        & .paragraph {
            font-size: 0.625rem !important;
        }

        .ant-card-cover {
            position: relative;
            transition: 0.4s;
            width: 100%;
            // &:hover {
            //     transition: .4s;
            //     width: 150%;
            //     position: relative;
            //     z-index: 2;
            //     border: solid 1px #cdcdcd;
            //     border-radius: 3px 0 0 3px;
            //     & + .ant-card-body::after {
            //         border-radius: 3px;
            //         content: '';
            //         position: absolute;
            //         display: block;
            //         width: calc(100% - 15px);
            //         height: 100%;
            //         background: #00000036;
            //         top: 0;
            //         right: 0;
            //         transition: .4s;
            //         z-index: 1;
            //     }
            // }
        }

        .dbox-opts {
            position: absolute;
            top: 0.625rem;
            right: 0.625rem;
            z-index: 1;
        }

        .flow-meta-det {
            position: relative;
            bottom: 0;
            left: 0;
            width: 100%;

            .landingCard-profile-listitem {
                svg {
                    height: 0.9375rem;
                    width: 0.9375rem;
                }
            }
        }

        .fl-desc {
            min-height: auto;
            -webkit-line-clamp: 1;
            font-size: 0.8125rem;
            color: #444;
            margin-bottom: 0.5rem;
        }

        .action-icons {
            width: 25%;
            justify-content: flex-start;
            min-width: 5rem;
        }

        .table-icon {
            svg {
                path {
                    transition: fill 0.2s ease-in-out;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }

        .flow-details-container {
            min-width: 50%;
            width: 100%;

            h2 {
                margin-bottom: 0.375rem;
                text-transform: uppercase;
            }
        }

        .flow-grid {
            height: 12.8125rem; //270px;
            // height: 120px;

            .filled-star-icon,
            .star-icon {
                & svg {
                    height: 0.8125rem;
                }

                position: absolute;
                top: 0.625rem;
                right: 2.25rem;
                z-index: 1;
                width: 1.625rem;
                height: 1.625rem;
                // border: solid 1px #00000029;
                border-radius: 50%;
                // background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .ant-card {
            // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            border: none !important;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            // display: grid;
            // grid-template-columns: 100%;
            // grid-template-columns: 40% calc(60% - 35px);
            // grid-template-columns: 35% calc(65% - 35px);
            // border: 1px solid #d9d9d9;

            &:hover {
                box-shadow:
                    0 1px 2px -2px rgba(0, 0, 0, 0.16),
                    0 3px 6px 0 rgba(0, 0, 0, 0.12),
                    0 5px 12px 4px rgba(0, 0, 0, 0.09);
            }

            .ant-card-cover {
                height: 5rem; //150px;
                margin: 0px;

                img {
                    height: 100%;
                    object-fit: cover;
                    border-radius: 3px !important;
                    // border: solid 1px #ebebeb;
                }
            }

            .ant-card-body {
                padding: 1rem 0.625rem;
            }

            .ant-card-meta-title {
                font-size: $font16 + 2;
                color: $black;
                font-family: $inter-semibold;
                line-height: 1.2;
            }

            .paragraph {
                color: $grey-three;
                margin: 0;
            }

            .ant-card-meta-description {
                .paragraph {
                    width: $width-full - 40;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:nth-child(2) {
                        width: 100%;
                    }
                }

                .description-data {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }

    .flow-card-view-gridtable {
        // padding: 15px;
        // display: grid;
        // align-items: flex-start;
        // grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
        // gap: 28px;
        // overflow: auto;

        & > div:not(:last-child)::after {
            content: "";
            display: block;
            width: 2px;
            height: 80%;
            background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
            position: absolute;
            top: 20%;
            right: -15px;
        }

        & > div:not(:last-child)::before {
            content: "";
            display: block;
            width: 80%;
            height: 2px;
            background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }

        & .paragraph {
            font-size: 10px !important;
        }

        .ant-card-cover {
            position: relative;
            transition: 0.4s;
            width: 100%;
            // &:hover {
            //     transition: .4s;
            //     width: 150%;
            //     position: relative;
            //     z-index: 2;
            //     border: solid 1px #cdcdcd;
            //     border-radius: 3px 0 0 3px;
            //     & + .ant-card-body::after {
            //         border-radius: 3px;
            //         content: '';
            //         position: absolute;
            //         display: block;
            //         width: calc(100% - 15px);
            //         height: 100%;
            //         background: #00000036;
            //         top: 0;
            //         right: 0;
            //         transition: .4s;
            //         z-index: 1;
            //     }
            // }
        }

        .dbox-opts {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
        }

        .flow-meta-det {
            position: relative;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .fl-desc {
            min-height: auto;
            -webkit-line-clamp: 1;
            font-size: 13px;
            color: #444;
        }

        .action-icons {
            width: 25%;
            justify-content: flex-start;
            min-width: 80px;
        }

        .table-icon {
            svg {
                path {
                    transition: fill 0.2s ease-in-out;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }

        .flow-details-container {
            min-width: 50%;
            width: 100%;

            h2 {
                margin-bottom: 6px;
                text-transform: uppercase;
            }
        }

        .flow-grid {
            height: 310px;
            // height: 120px;

            .filled-star-icon,
            .star-icon {
                & svg {
                    height: 13px;
                }

                position: absolute;
                top: 10px;
                right: 36px;
                z-index: 1;
                width: 26px;
                height: 26px;
                // border: solid 1px #00000029;
                border-radius: 50%;
                // background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .ant-card {
            // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: grid;
            grid-template-columns: 100%;
            // grid-template-columns: 40% calc(60% - 35px);
            // grid-template-columns: 35% calc(65% - 35px);
            border: 1px solid #d9d9d9;

            &:hover {
                box-shadow:
                    0 1px 2px -2px rgba(0, 0, 0, 0.16),
                    0 3px 6px 0 rgba(0, 0, 0, 0.12),
                    0 5px 12px 4px rgba(0, 0, 0, 0.09);
            }

            .ant-card-cover {
                height: 200px;
                margin: 0px;

                img {
                    height: 100%;
                    object-fit: cover;
                    border-radius: 3px !important;
                    border: solid 1px #ebebeb;
                }
            }

            .ant-card-body {
                padding: 16px 10px;
            }

            .ant-card-meta-title {
                font-size: $font16 + 2;
                color: $black;
                font-family: $inter-semibold;
                line-height: 1.2;
            }

            .paragraph {
                color: $grey-three;
                margin: 0;
            }

            .ant-card-meta-description {
                .paragraph {
                    width: $width-full - 40;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:nth-child(2) {
                        width: 100%;
                    }
                }

                .description-data {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }
    .landing-page-accordion {
        padding: 0 $padding5 * 3;
    }

    .flow-landing-content {
        height: calc(100vh - 72px);
        background-color: #ffffff;
        overflow-y: auto;
    }

    .flow-property-heading {
        & > svg {
            height: 18px;
        }
    }

    .ant-tabs-nav-list .ant-tabs-tab-btn svg {
        height: 18px;
    }
}

.empty-flow {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    padding: 10px;
    font-size: 13px !important;
    font-weight: bolder;
    font-family: $inter-semibold;
}
