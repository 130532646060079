.prv-new-filter-pop {
    // min-width: 22.5rem;

    // .ant-popover-inner-content {
    //     padding: 0.75rem 1rem !important;
    // }
    .filter-resize-wrap {
        padding: 0.75rem 1rem !important;

        .custom-handle {
            &::after {
                content: '';
                display: block;
                width: .4rem;
                height: .4rem;
                background: none;
                opacity: .5;
                border-right: solid 2px #828282;
                border-bottom: solid 2px #828282;
                position: absolute;
                bottom: 4px;
                right: 4px;
                transition: .2s;
            }
            &:hover::after {
                opacity: 1;
                transition: .2s;
            }
        }
    }

    .new-dgt-flt-con-comp {
        height: calc(100% - 0.5rem);
    }
}

.prv-flt-itm {
    cursor: pointer;
    background: transparent;
    border-radius: 0.1875rem;
    padding: 0.3125rem 0.625rem !important;
    transition: 0.3s;

    &:hover {
        background: #ececec;
        transition: 0.3s;
    }

    &.disabled {
        opacity: 0.8;
        cursor: not-allowed;
    }

    &.active {
        background: #d6edff;
    }

    & > span {
        font-size: 0.6875rem;
    }

    & > svg {
        height: 0.6875rem;
        width: 0.6875rem;
    }
}
.new-dgt-flt-comb-comp {
    .new-dgt-flt-con-comp {
        .action-part {
            position: relative;
            margin-top: -1.375rem;
            z-index: 0;
        }
    }
    .new-dgt-flt-con-comp {
        .flt-criteria-itm-li {
            margin: 0 -1rem;
            padding: 0.4375rem 1.5rem 0.4375rem 1rem;
            overflow-y: auto;
            max-height: 100%;
            // max-height: 14.625rem;
        }
    }
    .flt-val-tbl {
        margin: 0 -1.5rem;

        .rdg-cell {
            padding: 0 1.5rem !important;
        }
    }
}
.prv-and-or-tg {
    .ant-radio-button-wrapper {
        height: 1.25rem !important;
        font-size: 0.625rem !important;
        line-height: 1.1875rem !important;
        padding-inline: 0.3125rem !important;
        font-family: "InterRegular" !important;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: #2c83c4;
        border-color: #2c83c4;
    }
    .ant-radio-button-wrapper:first-child {
        border-start-start-radius: 0.1875rem !important;
        border-end-start-radius: 0.1875rem !important;
    }
    .ant-radio-button-wrapper:last-child {
        border-start-end-radius: 0.1875rem !important;
        border-end-end-radius: 0.1875rem !important;
    }
    .ant-radio-button-wrapper:hover {
        position: relative;
        color: #2c83c4;
    }
}
.flt-criteria-wrap {
    :is(.ant-select-selection-item, .ant-select-selection-placeholder) {
        font-size: 0.75rem;
    }

    :is(.ant-select-selector, .ant-input) {
        border-radius: 0;
        font-size: 0.75rem !important;
    }

    .ant-picker {
        height: 1.875rem !important;
        width: 100% !important;
        border-radius: 0 !important;
    }

    .ant-select-selection-placeholder {
        color: #444444ba;
    }

    .ant-input::placeholder {
        color: #444444ba;
    }
}
.criteria-close-btn {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 100%;
    background: #cecece;
    border: solid 1px #cecece;
    top: -0.3125rem;
    right: -0.3125rem;
    transition: 0.3s;

    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    &:hover:not(.disabled) {
        background: #ffb1b1;
        border-color: #dc3545;
        transition: 0.3s;

        svg path {
            fill: #dc3545;
        }
    }

    svg {
        height: 0.625rem;
        width: 0.625rem;
    }

    svg path {
        fill: #626262;
    }
}
.flt-criteria-itm-li {
    & > div:not(:last-child) {
        margin-bottom: 0.5rem;
    }
}
.criteria-flt-ddl {
    z-index: 2023 !important;
    min-height: 6.25rem;
    max-height: 10rem;
    overflow-y: auto;
    width: 18rem !important;

    &.new-filter-criteria-box-width {
        width: 27% !important;
    }
    &.old-filter-criteria-box-width {
        // width: 186px !important;
        width: 11.25rem !important;
        padding: 0rem;
        margin-left: -1.5rem;
    }
    // &.alert-criteria-box-width {
    //     width: 19% !important;
    // }

    .ant-dropdown-menu {
        height: 100% !important;
    }

    .ant-dropdown-menu li.ant-dropdown-menu-item-active {
        background-color: #65bdff36 !important;
    }
}
.criteria-ddl-close {
    .ant-input-prefix {
        margin-right: 0 !important;
    }
}
.criteria-prv-ddl {
    cursor: pointer;

    input {
        cursor: pointer !important;
    }
}
.flt-val-tbl {
    min-height: 4rem;
    height: calc(100% - 3.5rem) !important;
    &.error {
        overflow-y: auto;
    }
    .reloadble-err-comp {
        margin: 0.75rem 0 !important;

        .dead-ic {
            display: none;
        }

        code {
            width: fit-content !important;
            max-width: 90% !important;
        }
    }

    .custom-content-dgt {
        grid-template-columns: 1fr !important;
    }
}
// .filter-by-value-prewrap {
//     max-height: 16rem;
// }
.new-dgt-flt-val-comp {
    position: relative;
    height: calc(100% - 0rem);

    .action-part {
        position: relative;
        margin-top: -2.8125rem;
    }
}
.new-dgt-flt-val-comp {
    .ant-checkbox-inner {
        outline: none !important;
    }
}
// .dgt-filter-action-wrap {
//     margin-top: -33px;
// }

.custom-dgt-celldata {
    max-width: 50vw;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
}

.new-custom-data-grid .rdg-row-even {
    background: #2c83c40a !important;

    &:hover {
        background: #f5f5f5 !important;
    }
}