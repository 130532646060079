// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
@import "../../../../assets//scss//common/colors";

.bgNocolor {
  background-color: transparent !important;
}

input[type="password"] {
  -webkit-appearance: none !important; /* Safari, Chrome, Edge, Opera */
  -moz-appearance: none !important; /* Firefox */
  appearance: none !important; /* Standard */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.active-a {
  font-weight: bold;
  color: #00aeed;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.persona-choice-wrap {
  & > div {
    text-align: center;
  }
}

.persona-selected {
  height: 11.875rem;
  border: 0.125rem solid #2c83c4;
  box-shadow: 0.375rem 0.375rem 0rem -0.125rem #2c83c4;
  border-radius: 1.25rem;
  background: linear-gradient(130deg, #2c83c40f 50%, transparent 50%);

  img {
    object-fit: cover;
    padding: 0.625rem;
    height: 9.375rem;
    width: 9.375rem;
  }
  .persona-title {
    color: #2c83c4 !important;
    font-size: 0.75rem !important;
  }
}
.persona-container {
  height: 11.875rem;
  border: 0.125rem solid transparent;
  img {
    object-fit: cover;
    padding: 0.625rem;
    height: 9.375rem;
    width: 9.375rem;
  }
  .persona-title {
    font-size: 0.75rem !important;
  }
}

.add-user-form {
  .modal-title {
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

.form-wrapper {
  min-width: 28.25rem;
  min-height: 25.3125rem !important;
  margin: auto;
  border-radius: 0.625rem;
}
.form-wrapper .my-head {
  border-radius: 0.625rem 0.625rem 0 0;
  padding: 1.25rem !important;
}
.form-wrapper.form-wrapper-forgot {
  min-height: 100% !important;
}
.wrapper {
  min-height: 100vh;
  position: relative;
  display: flex;
}
.my_primary {
  color: #556ee6;
}
.mybg_primary {
  background: #556ee6;
}
.my-head {
  align-items: center;
  padding: 2rem 2rem 2rem 2rem;
  height: 115px;
  width: 100%;
  background-size: 18% !important;
  background-position: 86% center !important;
  background-repeat: no-repeat !important;
  display: flex;
}
.my-head > h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
}
/* .backImg{
    background: url(../../../assets/images/data-science.png), #D4DAF9;

  } */

.my-head.backImg {
  padding: 20px !important;
  border-radius: 10px 10px 0 0;
}

.my-auto .form-wrapper {
  width: 500px;
  margin: auto;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.2rem 1rem !important;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modalBody {
  font-family: "Poppins" !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  padding: 2%;
}

.modalHeader {
  border-bottom: 2px solid gray !important ;
}
.modalFooter {
  border-top: 2px solid gray !important ;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f4f6f9 !important;
  opacity: 0.5;
}

/* login */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .login-signup-container {
    flex-direction: column;
  }
}
.logout-div {
  .btn-primary {
    background-color: #2c83c4 !important;
    padding: 5px;
  }
  .btn-primary:hover {
    background-color: #2c83c4 !important;
    padding: 5px;
  }
}

.login-signup-container {
  // display: flex;
  display: grid;
  grid-template-columns: 35% auto;
  height: 100%;
  overflow: hidden;
  .route-decoration {
    font-size:0.8rem;
    text-decoration: none;
    &:hover {
      color: $grey-three;
    }
  }
  .label-color {
    color: $grey-three;
  }
  .right-side {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .image_container {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    .carousel-indicators > li {
      border-radius: 83%;
      height: 7px;
      width: 7px;
    }
    .text_container {
      position: relative;
      text-align: center;
      bottom: 20%;
    }
    .carousel-indicators {
      bottom: -35px;
    }
    .carousel-inner {
      width: 90%;
      margin-left: 5%;
    }
    .carousel-item {
      background: #fff;
      min-height: 50px;
      border-radius: 5px;
      margin-bottom: 2%;
    }
  }
  .left-side {
    height: calc(100vh - 10px);
    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10%;
      img {
        width: 65%;
      }
    }
  }
  // .custom-check-box {
  //   height: 20px;
  //   width: 20px;
  //   border-radius: 50%;
  //   display: flex;
  //   margin-right: 5px;
  //   align-items: center;
  //   justify-content: center;
  // }
  // .unchecked {
  //   background: $grey-two;
  // }
  // .checked {
  //   background: $success-green;
  // }
}

:is(.pass-policy-container, .login-signup-container) {
  .custom-check-box {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
  }
  .unchecked {
    background: $grey-two;
  }
  .checked {
    background: $success-green;
  }
}

.auth-action-button {
  height: 48px !important;
}
.auth-page-link {
  color: #828282;
  font-weight: 400;
}
.auth-title-phrase {
  font-size: 1.87rem; // 36px;
}
.auth-subtitle-phrase {
  font-size: 0.875rem;//14px;
  max-width: 452px;
}
.left-side {
  max-width: 640px;
}
.font-w-600 {
  font-weight: 600;
}
.auth-active-link {
  color: #2c83c4;
  text-decoration: none;
}
.highlight-text {
  font-weight: 700;
  color: #000;
}
.eye-indicator-wrap {
  position: relative;
  display: block;
  & input {
    padding-right: 36px !important;
  }
}
.pass-indicator {
  position: absolute;
  cursor: pointer;
  right: 10px;
  display: flex;
  top: 0;
  width: 28px;
  height: 28px;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
}
.label-color {
  color: $grey-three;
}
.initials-icon {
  width: 30%;
  height: 84px;
  width: 84px;
  background: $primary;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
}
.grey-color {
  color: #828282 !important;
}
.userauth-cl-ic-box {
  background-color: rgb(241, 241, 241);
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0 0 5px;
}
.userauth-cl-cn-box {
  padding: 20px 15px;
  height: 100%;
  h4 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width of the container */
  height: auto; /* Height adjusts automatically */
  max-width: 200px; /* Maximum width to constrain the size */
  margin: auto; /* Center the QR code container */
}

.qr-code-image {
  width: 100%;
  height: auto;
}
