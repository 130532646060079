@import "../../../../../assets/scss/common/colors";
@import "../../../../../assets/scss/common/colors";
@import "../../../../../assets/scss/common/variables";
@import "../../../../../assets/scss/common/font";

.connectiongridview-responsive {
    display: grid;
    gap: $padding10;
    grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
    margin: 0;
    padding: $padding10;
    position: $p-relative;

    .custom-badge {
        font-size: 0.5625rem;
        padding: 0.125rem 0.375rem;
        text-transform: uppercase;
    }

    .card-tags {
        bottom: auto;
        top: 40px;
        right: 30px;
    }

    .card {
        border-radius: 3px;
        border: none;
        // border: 1px solid #82828236;
        cursor: pointer;
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
        }
        height: 100%;
    }

    .card-body {
        padding: $padding10;
        position: relative;

    }

    .connectionsCard-circle {
        display: flex;
        justify-content: space-between;
        position: $p-relative;

        &::after {
            content: "";
            position: $p-absolute;
            height: 1px;
            background-color: $light-background;
            left: -10px;
            right: -10px;
            top: 50%;
            transform: translate(0, -50%);
        }

        .icon-box {
            width: $width70 - 10;
            height: $height70 - 10;
            border-radius: 50%;
            border: 1px solid $light-background;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            z-index: 1;

            img {
                height: $height50 - 6;
                width: auto;
            }
        }

        .connectionsCard-profile-list {
            display: flex;
        }
    }

    .connectionsCard-actions {
        display: flex;
    }

    .connectionsCard-header {
        display: flex;
        align-items: center;
        margin-bottom: $margin10;

        p {
            margin: 0;
            color: $primary;
        }
    }

    .connectionsCard-description {
        .connection-heading {
            font-family: $inter-bold;
        }
        
        .grey-text {
            color: $grey-three;
        }

        .connectionsCard-status {
            position: absolute;
            right: 15px;
            top: 55px;
        }
    }

    .connectionsCard-profile-list {
        .connectionsCard-profile-listitem {
            display: flex;
            align-items: center;
            margin-bottom: $margin10;

            .label {
                margin: 0 $margin10;
            }

            span {
                &:last-child {
                    width: calc(100% - 106px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .connectionsCard-tag-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        .landing-grid-tag {
            height: $height50 - 15;
            padding: 0 $padding10 * 2;
            border-radius: 30px;
            line-height: 35px;
            margin: 0 $margin5;

            &.last-tag-number {
                background-color: $light-primary;
                color: $primary;
            }

            &.red {
                background: rgba($color: $error-red, $alpha: 0.20);

                .label {
                    color: $error-red;
                }

                svg {
                    path {
                        fill: $error-red;
                    }
                }
            }

            &.orange {
                background: rgba($color: $accent-two, $alpha: 0.20);

                .label {
                    color: $accent-two;
                }

                svg {
                    path {
                        fill: $accent-two;
                    }
                }
            }

            &.green {
                background: rgba($color: $accent-four, $alpha: 0.20);

                .label {
                    color: $accent-four;
                }

                svg {
                    path {
                        fill: $accent-four;
                    }
                }
            }
            
            &.yellow {
                background: rgba($color: $accent-six, $alpha: 0.20);

                .label {
                    color: $accent-six;
                }

                svg {
                    path {
                        fill: $accent-six;
                    }
                }
            }
            
            &.grey {
                background: rgba($color: $grey-three, $alpha: 0.20);

                .label {
                    color: $grey-three;
                }

                svg {
                    path {
                        fill: $grey-three;
                    }
                }
            }

            &.purple {
                background: rgba($color: $accent-nine, $alpha: 0.20);

                .label {
                    color: $accent-nine;
                }

                svg {
                    path {
                        fill: $accent-nine;
                    }
                }
            }

            &.blue {
                background: rgba($color: $accent-eleven, $alpha: 0.20);

                .label {
                    color: $accent-eleven;
                }

                svg {
                    path {
                        fill: $accent-eleven;
                    }
                }
            }

            &.magenta {
                background: rgba($color: $accent-seven, $alpha: 0.20);

                .label {
                    color: $accent-seven;
                }

                svg {
                    path {
                        fill: $accent-seven;
                    }
                }
            }

            &.pink {
                background: rgba($color: $accent-eight, $alpha: 0.20);

                .label {
                    color: $accent-eight;
                }

                svg {
                    path {
                        fill: $accent-eight;
                    }
                }
            }
        }

        .label {
            margin-left: $margin10;
        }
    }
    .red-border {
        border: 1px solid $error-red;
    }
}
.connectiongridview-responsive .label {
    font-size: 0.625rem !important;
}
.connectiongridview-responsive .paragraph{
    font-size: 0.6875rem;
}
.connectiongridview-responsive .connection-heading {
    font-size: 0.75rem;
}
.conn-dmn-ic {
    display: flex;
    & svg {
        height: 0.875rem;
        width: 0.875rem;
        margin: 0.125rem .30rem 0.125rem 0px;
    }
    & i {
        font-size: 0.875rem;
        margin: 2px 6px 0px 0px;
    }
}
.disabled-opt-conn {
    cursor: not-allowed;
    opacity: 0.6;
    box-shadow: none !important;
}
.connection-desc-para {
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9.375rem;
    line-height: 0.75rem;
}

.av-conn-card {
    .landingCard-profile-listitem {
        svg {
            height: 0.875rem;
            width: 0.875rem;
        }
    }
}