.pod-creation-header {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.375rem 0.8125rem;
    border-bottom: solid 0.0625rem #d6d6d6;
    height: 2.5625rem;
    .pod-creation-header-title {
        font-family: 'InterSemibold';
        color: #333333;
    }
}
.pod-crt-step-container {
    display: flex;
    gap: 1.875rem;
}
.pod-crt-step-container {
    & > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #33333360;
        font-family: 'InterSemibold';
        &.active {
            color: #333333;
            .indic {
                border-color: #2C83C4;
            }
        }
        &.visited {
            color: #333333;
            .indic {
                color: #FFF;
                background-color: #2C83C4;
                border-color: #2C83C4;
            }
        }
    }
    .indic {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.75rem;
        height: 1.75rem;
        border: solid 2px #D6D6D6;
        border-radius: 100px;
    }
}
.pod-creation-source-grid {
    & > div:first-child {
        border-right: solid 1px #d6d6d6;
    }
    display: grid;
    grid-template-columns: minmax(12.5rem, 21.875rem) auto;

    .react-split__sash {
        z-index: 0 !important;
    }
}

.pod-crt-parent {
    .react-split__sash {
        z-index: 0 !important;
    }
}

.pod-crt-src-conn-detail {
    position: relative;
    padding: 0.625rem 0.75rem 0.875rem 0.5rem;
    border-bottom: solid 1px #d6d6d6;
    & > div {
        width: calc(100% - 3.25rem);
        margin-left: auto;
    }
    .cn-img {
        position: absolute;
        display: block;
        height: 2.75rem;
        width: 2.75rem;
        top: 0.75rem;
        left: 0.5rem;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .cn-txt {
        font-family: 'InterSemibold';
        color: #333333;
        width: calc(100% - 1.875rem);
    }
    .cn-edit-btn {
        position: absolute;
        right: 0.5rem;
        top: 0.9375rem;
    }
}
.pod-crt-either-exp-sch {
    height: calc(100% - 68px);

    &.cpilot-highlight {
        position: relative;
        z-index: 3;

        &::before {
            content: '';
            display: block;
            position: fixed;
            height: 100%;
            width: 100%;
            background: #0f0f0fc4;
            top: 0;
            left: 0;
            z-index: 0;
        }
    }
}
.exp-dir-wrap {
    &.src-view {
        .ant-tree.ant-tree-directory {
            height: calc(100vh - 14.625rem) !important;
        }
    }

    .ant-tree.ant-tree-directory {
        height: calc(100vh - 11.875rem);
        overflow-y: hidden;
    }

    .ant-tree-treenode {
        padding-top: 4px;
    }

    .ant-tree.ant-tree-directory .ant-tree-treenode:before {
        bottom: 0px;
    }

    .ant-tree-treenode .ant-tree-switcher {
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-tree-treenode .ant-tree-checkbox {
        order: 2;
        // margin-right:0;
        // margin-left: 10px;
        margin-top: 0.25rem;
        margin-inline-end: 0.5rem;

        @media (max-width: 1280px) {
            margin-top: .5rem;
        }
    }

    .ant-tree-treenode .ant-tree-node-content-wrapper {
        order: 3;
        width: 60%;
        // max-width: 270px;
        &.ant-tree-node-selected svg path {
            fill: #FFF !important;
        }
    }

    .ant-tree-iconEle.ant-tree-icon__customize {
        display: flex !important;
        align-items: center;
        position: absolute;
    }

    .ant-tree-title {
        width: 100%;
        // width: 200px;
        // width: calc(100% - 24px);
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // margin-left: auto;
        font-size: 0.8125rem;
        color: #333;
        margin-right: auto;
        padding-left: 1.5rem
    }

    :is(.ant-tree-treenode-leaf-last, .ant-tree-treenode-disabled){ 
        .ant-tree-switcher {
            // display: none !important;
        }
        .ant-tree-checkbox{
            // display: none !important;
        }
        .ant-tree-node-content-wrapper {
            padding-left: 5px !important;
            // padding-left: 30px !important;
        }

        /* .ant-tree-checkbox-disabled {
            display: none !important;
        } */

        .ant-tree-title {
            // width: 200px;
        }
    }
    
    .ant-tree-treenode-selected {
        .ant-tree-title {
            color: #fff;
        }
    }

    .ant-tree.ant-tree-directory .ant-tree-treenode-selected.ant-tree-treenode:before {
        background: #2C83C4 ;
    }

    .ant-tree-treenode.ant-tree-treenode-disabled{
        opacity: .8;
        &.ant-tree-treenode-selected {
            svg path {
                fill: #FFF !important;
            }
        }
    }
}
.tree-header-pane {
    background: #F8F8F8;
    padding: 0.3125rem 0.5rem;
    border-bottom: solid 1px #d6d6d6;
    min-height: 2.625rem;
}
.site-tree-search-value {
    background: #155e94;
    color: #fff !important;
}
.file-opts-head {
    border-bottom: solid 1px #d6d6d6;
}
.format-disp-wrap {
    display: flex;
    gap: 16px;
    &.disabled {
        .format-rd-box {
            opacity: .6;
            cursor: not-allowed;
        }
    }
}
.format-rd-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    .frmt-rd-ic {
        display: flex;
        width: 1.875rem;
        height: 1.875rem;
        align-items: center;
        border: solid 1px #d6d6d6;
        justify-content: center;
        border-radius: 3px;

        svg {
            height: 1.25rem;
            width: 1.25rem;
        }
    }
    .frmt-rd-label {
        font-size: 0.8125rem;
        font-family: 'InterSemibold';
        color: #5C5C5C;
    }

    &.frmt-selected {
        .frmt-rd-ic {
            border: solid 1px #2C83C4;
            background: #F8FCFF;

            svg path {
                fill: #2C83C4;
            }
        }
        .frmt-rd-label {
            color: #2C83C4;
        }
    }
}
.file-opts-close-btn {
    position: absolute;
    top: 8px;
    right: 0px;
}
.fopts-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}
.file-opts-head {
    padding: 14px;
    height: 2.5625rem;
}
.fopts-cus-cont {
    padding: 10px 14px;
    height: calc(100% - 43px);
    overflow-y: auto;
}
.pod-crt-prev {
    .handle-tab-height {
        .ant-tabs-content.ant-tabs-content-top {
            height: auto !important;
        }
    }

    :is(
    .ant-tabs-tabpane.ant-tabs-tabpane-active,
    .ant-tabs-content.ant-tabs-content-top,
    .ant-tabs.ant-tabs-top,
    .data-grid-shrink,
    .custom-simple-landing-dgt
    ) {
        height: 100% !important;
    }
    &:not(.file-sys) :is(
    .ant-tabs-tabpane.ant-tabs-tabpane-active,
    .ant-tabs-content.ant-tabs-content-top,
    .ant-tabs.ant-tabs-top,
    .data-grid-shrink,
    .custom-simple-landing-dgt
    ) {
        height: calc(100% - .5rem) !important;
        // height: 100% !important;
    }
}
.pod-crt-save-details {
    .label-color {
        color: #333333 !important;
    }
    .custom-select-dropdown > .ant-select-disabled .ant-select-selection-item {
        padding: 1px 2px 2px 1px !important;
    }
}
.fopts-grid {
    .custom-select-dropdown .ant-select.ant-select-disabled .ant-select-selector .ant-select-selection-placeholder {
        margin-left: 0px !important;
        font-family: "InterRegular";
    }
}
.pod-crt-sql-cont {
    .pod-crt-sql-edit.COMMON_MONACO_CODEEDITOR {
        height: calc(100% - 26px);
    }
    &.read-only {
        .COMMON_MONACO_CODEEDITOR {
            height: calc(100% - 36px);
        }
    }
    &.draw-op {
        width: calc(100% - 375px);
        transition: .3s;
    }
    transition: .3s;
}
.eng-btn-clarista-active {
    svg path {
        fill: #2C83C4;
    }
    &.draw-up {
        background: #589acf1c !important;
        border: solid 1px #2C83C4 !important;
    }
}
.eng-btn-db-active {
    svg path {
        fill: #941BCD;
    }
    &.draw-up {
        background: #941bcd0f !important;
        border: solid 1px #941BCD !important;
    }
}
.eng-dpd {
    width: 250px;
    padding: 8px 0 !important;
    & label {
        font-family: 'InterSemibold';
    }
    & .ant-dropdown-menu-item {
        padding: 8px 12px !important;
        border-radius: 0 !important;
    }

    & > li:first-child.ant-dropdown-menu-item-selected {
        background: #589acf1c !important;
    }

    & .ant-dropdown-menu-item-selected {
        background: #941bcd0f !important;
    }

    & > li:first-child.ant-dropdown-menu-item-selected label {
        color: #2C83C4;
    }

    & > li.ant-dropdown-menu-item-selected label {
        color: #941BCD;
    }

    .ant-dropdown-menu-item-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
.crt-pod-par-draw {
    position: fixed;
    top: 7.85rem;
    height: calc(100% - 7.9rem);

    &.src-view {
        top: 9.6875rem;
        height: calc(100% - 9.8125rem);
    }

    .ant-drawer-mask {
        display: none;
    }
    .ant-drawer-content-wrapper {
        box-shadow: 12px 17px 20px 0px #00000073;
        border-left: solid 1px #d6d6d6;
    }
    :is(.ant-drawer-header, .ant-drawer-body) {
        padding: 0.75rem 1rem;
    }
}
.param-draw-done {
    position: absolute;
    bottom: 0;
    background: #FFF;
    left: 0;
}
.param-cont-wrap {
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -1rem;
    padding-right: 1rem;
}
.pod-crt-save-btn {
    height: 1.875rem !important;
    width: 9.25rem;

    svg {
        width: 1.25rem;
    }
}
.warn-btn {
    background-color: #e18a18 !important;
}
.param-intro {
    h2 {
        color: #60b5f4;
    }
    &.cont-hidden {
        position: absolute;
        bottom: 43px;
        left: 0;
        border-top: solid 1px #d6d6d6;
        padding: 3px 15px;
    }
}
.esc_exp_res_table {
    .custom-simple-landing-dgt {
        grid-template-columns: auto 100px auto auto auto auto !important;
    }
}

.published-content {
    position: fixed;
    top: 4.75rem;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #fff;
}

.publish-res {
    .ant-result-title, .ant-result-subtitle {
        font-family: "InterSemiBold" !important;
    }
    .ant-result-extra {
        display: flex;
        justify-content: center;
    }
}

.publishing-overlay {
    position: fixed;
    top: 35px;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    background: #00000060;
}

.sch-based-tab-wrap {
    position: relative;
    height: 100%;

    .new-custom-tabs > .ant-tabs-nav {
        margin-bottom: .3rem;
    }

    .new-custom-tabs {
        .ant-tabs-tab + .ant-tabs-tab {
            margin: 0 0 0 1.5rem !important;
        }
    }

    .ant-tabs-nav {
        width: calc(100% - 120px);

        &::before {
            border: none !important;
        }

        .ant-tabs-nav-more {
            display: flex;
            align-items: center;
        }
    }
    .sql-tgl-btn {
        position: absolute;
        top: 5px;
        right: 0;
    }
    // .new-custom-tabs.non-custom-qry {
    //     .ant-tabs-tab[data-node-key = "result"] {
    //         display: none;
    //     }
    // }
}
.pod-crt-parent {
    .connectiongridview-responsive.new-box-style > div:not(:last-child) .card:not(.no-separator)::before {
        display: none;
    }
}
.pod-crt-types {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
    gap: 0.625rem;
    padding: 0.625rem;

    .pod-crt-type-card {
        cursor: pointer;
        border: solid 1px transparent;

        &:hover {
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 
            0 3px 6px 0 rgba(0, 0, 0, 0.12), 
            0 5px 12px 4px rgba(0, 0, 0, 0.09);
        }

        &.active {
            border-color: #00CC92;
        }

        &.disabled:not(.active) {
            opacity: 0.6;
            box-shadow: none !important;
            cursor: not-allowed !important;
        }
    }
}

.pod-crt-type-ic {
    img {
        width: 2.1875rem !important;
        height: 2.1875rem !important;
    }

    .ic-wrap {
        display: flex;
        width: 2.1875rem;
        height: 2.1875rem;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border: solid 1px #DEE2E6;
        border-radius: 100%;
    }
}

.no-connection-view {
    & > div {
        height: 12.5rem !important
    };
}

.pod-crt-tree-comp {
    .ant-tree-list, .ant-tree-list-holder {
        height: 100%;
    }
}

.uns-table-col-hd {
    display: grid;
    grid-template-columns: 100%;

    .act-btn {
        display: none;
    }
    &:hover, &.active {
        grid-template-columns: auto 56px;
        .act-btn {
            display: flex;
        }
    }
}

.pod-crt-head-icon {
    svg {
        height: 1.25rem;
        width: 1.25rem;
    }
}

.root-wrap {
    svg {
        height: 1rem;
        width: 1rem;
    }
}

.pod-crt-tree-comp {
    .ant-tree-iconEle {
        svg {
            height: 0.875rem;
            width: 0.875rem;
        }
    }
}

.pod-crt-info-icon {
    svg {
        min-height: 3.125rem;
        min-width: 3.125rem;
        max-height: 5rem;
        max-width: 5rem;
    }
}

.fopts-cus-cont {
    .alert.alert-warning {
        svg {
            height: 0.875rem;
            width: 0.875rem;
        }
    }
}

.param-intro {
    font-size: 1rem;

    code {
        font-size: 0.875rem !important;
    }
}