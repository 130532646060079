.landingPage {
    .rotate-button-backdrop {
        display: none;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &.data-dict .add-options-list {
        right: 22px;
    }

    .add-options-list {
        position: fixed;
        right: 22px;
        z-index: 1;
        opacity: 0;
        transition: all .3s ease;
        bottom: 60px;
        pointer-events: none;


        li {
            padding: 0.75rem;
            background-color: white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;


            svg, path {
                fill: #2C83C4;
            }

            svg {
                height: 1.5625rem;
                width: 1.5625rem;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }

        }
    }

    .active-list {
        opacity: 1;
        bottom: 80px;
        pointer-events: all;


    }

   
    .custom-btn-grp {
        .custom-btn-primary {
            svg {
                transition: all .3s ease;
            } 
        } 

        .rotate-icon {   
            svg {
                transform: rotate(135deg);
            } 
        }
    }
}
