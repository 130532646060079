.landing-search-lg .custom-search input {
    font-size: 0.875rem;
    height: 3rem;
    border-radius: 3.125rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-color: #9B9B9B;
}

.landing-search-lg .custom-search-icon {
    left: 1rem !important;
}

#landing-search-lg .custom-close-icon svg {
    height: 0.75rem !important;
    width: 1.5rem !important;
}