.conf-head {
    font-size: 1.25rem;
    color: #333333;
}
.conf-head-desc {
    color: #455a64;
}
.user-config-container {
    max-width: 71.875rem; //62.5rem;
    margin: 0 auto;
    padding-top: 2.375rem;
    padding-inline: 1rem;
    .ant-select-multiple {
        height: 2.1875rem !important;
        border-radius: 0rem !important;
        margin: 0rem;
    }
}
.config-item {
    display: grid;
    grid-template-columns: 3rem auto 12.5rem;
    column-gap: 0.9375rem;
    align-items: center;
    padding: 0.625rem 0;
}
.disable-provider-item {
    cursor: not-allowed !important;
    display: grid;
    grid-template-columns: 3rem auto;
    column-gap: 0.9375rem;
    align-items: center;
    padding: 0.625rem 0.5rem;
    border-radius: 0.375rem;
    color: #343a40;
    transition: 0.3s;

    &:hover {
        background: #f1f1f1;
        transition: 0.3s;
    }

    .text-disable {
        color: darkgray !important;
    }
    &.active {
        color: #2c83c4;
        background: #bde3ff24;
        font-family: "InterSemibold";
        box-shadow: inset 0rem 0rem 0.4375rem -0.1875rem #2c83c4;
        transition: 0.3s;
    }
    .config-itm-ic {
        svg {
            filter: grayscale(1);
        }
    }
}
.config-item-short {
    cursor: pointer;
    display: grid;
    grid-template-columns: 3rem auto;
    column-gap: 0.9375rem;
    align-items: center;
    padding: 0.625rem 0.5rem;
    border-radius: 0.375rem;
    color: #343a40;
    transition: 0.3s;

    &:hover {
        background: #f1f1f1;
        transition: 0.3s;
    }

    &.active {
        color: #2c83c4;
        background: #bde3ff24;
        font-family: "InterSemibold";
        box-shadow: inset 0rem 0rem 0.4375rem -0.1875rem #2c83c4;
        transition: 0.3s;
    }
}
.config-itm-ic {
    display: flex;
    height: 2.875rem;
    width: 2.875rem;
    align-items: center;
    justify-content: center;
    border: solid 0.0625rem #d6d6d6;
    border-radius: 100%;
    padding: 0.625rem;
    color: #868484;
}
.custom-btn-large {
    font-size: 0.8125rem;
    padding: 0.375rem 0.9375rem;
    height: 2.1875rem;
    display: flex;
    align-items: center;
    background: #2c83c4;
    border: solid 0.0625rem transparent;
    border-radius: 0.25rem;
    color: #fff;

    &:hover {
        background: #155e94;
    }
}

.custom-btn-large > svg {
    height: 0.8125rem;
    width: 0.8125rem;
}
.conf-add-grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.25rem;

    .ant-select-multiple {
        height: 2.1875rem !important;
        border-radius: 0rem !important;
        margin: 0rem;
    }
    .ant-select-selector {
        height: 1.75rem !important;
        border-radius:0.1875rem !important;
        margin: 0rem;
    }
    &::before {
        content: "";
        display: block;
        width: 0.125rem;
        height: 100%;
        background: radial-gradient(#bfbdbd8f 10%, transparent 77%);
        position: absolute;
        top: 0;
        left: 50%;
    }
}
.conf-add-item-container {
    padding: 0.625rem 1.125rem;
    border: solid 0.0625rem #cecbcb;
    border-radius: 0.375rem;
}
.conf-sel {
    .ant-select-selector {
        border-radius: 0.1875rem;
        border-color: #ececec !important;
        font-size: 0.6875rem !important;
        height: 1.75rem !important;
    }
}
