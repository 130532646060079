body,
body * {

    ::-webkit-scrollbar {
        height: 13px;
        width: 13px;
    }
    
    ::-webkit-scrollbar:disabled {
        display: none;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: #75727295;
        border: 4px solid transparent;
        border-radius: 9px;
        background-clip: content-box;
        min-height: 32px;
        min-width: 32px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        border-top-width: 2px;
        border-right-width: 2px;
        border-bottom-width: 2px;
        border-left-width: 2px;
    }
    
    :hover::-webkit-scrollbar-thumb {
        background-color: #757272;
    }
    
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    


    // /* width */
    // &::-webkit-scrollbar {
    //     width: 5px;
    //     height: 5px;
    // }

    // /* Track */
    // &::-webkit-scrollbar-track {
    //     background-color: $light-background;
    // }

    // /* Handle */
    // &::-webkit-scrollbar-thumb {
    //     background-color: $grey-three;
    //     border-radius: 0.125rem;
    // }

    // /* Handle on hover */
    // &::-webkit-scrollbar-thumb:hover {
    //     background-color: #555;
    // }

    // ::-webkit-scrollbar-corner {
    //     background-color: transparent;
    // }
}

// custom scrollbar will not work in mozilla and internet explorer browser.
// this is limited to specific browser like chrome and microsoft edge.

// typography

.page-heading,
.section-heading,
.label,
.subtitle,
.paragraph,
.caption {
    color: $black;
}

.page-heading {
    font-size: $font20 + 4;
    font-family: $inter-bold;
}

.label,
.section-heading {
    font-family: $inter-semibold;
}

.section-heading {
    font-size: 0.8125rem;
    margin-bottom: 0;
    // font-size: $font16 + 2;
}

.subtitle {
    font-size: 0.8125rem;
    font-family: $inter-medium;
    & svg {
        height: 1rem;
        width: 1rem;
    }
}

.subtitle-datacatlog-icon {
    font-size: 0.8125rem;
    font-family: $inter-medium;
    & svg {
        height: 1.125rem;
        width: 1.125rem;
    }
}
.caption,
.paragraph {
    font-family: $inter-regular;
}

.paragraph {
    font-size: $font14;
}

.caption {
    font-size: 0.8125rem;
}

.label {
    font-size: $font12 !important;
}
