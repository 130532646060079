@media screen and (min-width: 1920px) {
    .transformer-configure-modal .landingPage .landing-list-main .landinggridview-responsive {
        grid-template-columns: repeat(auto-fill, minmax(385px, 1fr)) !important;
    }
    .start-pg-domain-wrap .dm-search-li-wrap .ant-tabs-tab-btn {
        font-size: 16px;
    }
    .start-pg-domain-wrap .dm-search-li-wrap {
        max-width: 912px;
        grid-template-columns: 46px auto;

        .lpg-domains-tab .ant-tabs-nav-more {
            width: 46px;
            height: 46px;

            .anticon-ellipsis {
                svg {
                    height: 22px;
                    width: 22px;
                }
            }
        }

        .custom-search input {
            height: 44px !important;
            font-size: 14px;
            padding-left: 36px !important;
        }

        .dm-search-close {
            height: 24px;
            width: 15px;
        }

        .custom-search-icon {
            svg {
                height: 18px !important;
                width: 18px !important;
            }
        }

        .dm-toggle-search {
            height: 46px;
            width: 46px;
            background: #FFF;

            svg {
                height: 22px;
                width: 22px;
            }
        }

        .ant-tabs-tab-btn {
            font-size: 16px;

            svg {
                height: 22px;
                width: 22px;
            }
        }

        .ant-tabs-tab {
            height: 46px;
        }
    }
}
@media screen and (min-width: 1280px) and (min-height: 768px) {
    .start-page-content-wrap {
        padding-top: 100px !important;
    }
}
@media screen and (min-width: 1920px) and (min-height: 900px) {
    .start-page-content-wrap {
        padding-top: 130px !important;
    }
}
@media screen and (min-width: 1920px) and (min-height: 950px) {
    .start-page-content-wrap {
        padding-top: 200px !important;
    }
}
// @media screen and (min-width: 1440px) {
    // .transformer-configure-modal .landingPage .landing-list-main .landinggridview-responsive {
    //     grid-template-columns: repeat(auto-fill, minmax(356px, 1fr)) !important;
    // }
    // .start-page-content-wrap {
    //     padding-top: 64px;
    // }
// }
@media screen and (max-width: 1440px) {
    /* .transformer-configure-modal .landingPage .landing-list-main .landinggridview-responsive {
        grid-template-columns: repeat(auto-fill, minmax(21.25rem, 1fr)) !important;
    } */
    
    #catalogCharts.catalog-visualization .custom-simple-landing-dgt {
        grid-template-columns: minmax(9.375rem, auto) 24% minmax(6.25rem,auto) 7.5rem minmax(9.375rem, auto) 8.75rem !important
    }
}
@media screen and (max-width: 1580px) {
    .transformer-configure-modal .landinggridview-responsive .round-tags-container .round-tags-item .label {
        max-width: 48px !important;
    }
}
@media screen and (max-width: 1366px) {
    .pulse-logimg-wrap {
        width: fit-content !important;
    }
}
@media screen and (max-width: 1280px) {
    // .pulse-legend { 
    //     h2 {
    //         font-size: 20px;
    //     }
    //     p {
    //         font-size: 14px;
    //     }
    // }
    // .chat-landing {
    //     height: calc(100vh - 17.625rem);
    // }
    .pulse-landing-wrap {
        padding-bottom: 0;
    }
    .pulse-search-comp {
        padding: 0.3125rem 0.9375rem 0.3125rem 0.375rem;
    }
    .lpg-content-tab {
        .ant-tabs-tab-btn {
            font-size: 0.75rem !important;
            svg {
                height: 1rem !important;
                width: 1rem !important;
            }
        }
        .ant-tabs-tab {
            padding: 0.5rem 0rem !important
        }
    }
    .data-view-dbox {
        ._src_img {
            svg {
                height: 1.5rem !important;
                width: 1.5rem !important;
            }
        }
        /* .desc-box {
            width: calc(100% - 2.375rem);
        } */
    }
    // .paragraph {
    //     font-size: 0.8125rem !important;
    // }
    // .label {
    //     font-size: 0.625rem !important;
    // }
    .scheduler-gridview-responsive {
        // & > div :is(.paragraph) {
        //     font-size: 11px !important;
        // }
        // & .landingCard-profile-list {
        //     margin-top: 8px !important;
        // }
        // & .dashboard-grid {
        //     min-height: 128px;
        // }
    }
    .chat-gridview-responsive {
        // .data-view-dbox .desc-box .desc-txt {
        //     margin-bottom: 8px;
        //     font-size: 11px !important;
        //     min-height: 33px !important;
        // }
        // & > div span.paragraph span {
        //     font-size: 11px !important;
        // }
    }
    .relevant-list-head {
        font-size: 0.875rem !important;
    }
    .pulse-land-tabs .custom-search-input {
        height: 1.75rem;
    }
    // .pulse-hero-sec {
    //     grid-template-columns: 68% auto;
    // }

    /*
    ** TalkData Chat Window all Media Css
    */
    .normal-talkdata-main-wrapper, .tracker-data-wrap {
        .btn-large{
            height: 24px !important;
            width: 24px !important;
            svg {
                height: 16px !important;
                width: 16px !important;
            }
            &.btn-with-text {
                width: auto !important;
                font-size: 11px !important;
                svg {
                    height: 12px !important;
                    width: 12px !important;
                }
            }
        }
        .ques-bar { 
            h2 {
                font-size: 16px !important;
            }
            input {
                font-size: 14px !important;
                height: 24px !important;
            }
        }
        .talk-data-ques-header {
            padding: 0.25rem 1rem;
        }
        .talk-conv-wrap {
            height: calc(100vh - 10rem);
        }
        .chat-user-ask, .talk-chat-ai-quote-reply, .question-suggestion-stack > div {
            /* h1, h2, h3 {
                font-size: 0.875rem !important;
            }
            font-size: 0.75rem !important; */
        }
        .question-suggestion-stack > div {
            font-size: 0.75rem !important;
            padding: 0.375rem 0.75rem
        }
    }
    // .normal-talkdata-main-wrapper .talk-conv-wrap {
    //     height: calc(100vh - 10rem);
    // }
    .tracker-dgt-wrap {
        .talk-chat-cont-head {
            .chat-name-title {
                word-break: break-all;
                white-space: normal;
                font-size: 0.875rem;
            }
        }
    }
    // .dm-toggle-search {
    //     width: 33px;
    //     height: 33px;
    // }
    // .dm-toggle-search svg {
    //     height: 16px;
    // }
    // .lpg-domains-tab {
    //     .ant-tabs-tab-btn {
    //         font-size: 12px;
    //     }
    //     & .ant-tabs-tab + .ant-tabs-tab {
    //         font-size: 12px;
    //         height: 33px;
    //     }
    //     .ant-tabs-nav-list .ant-tabs-tab-btn svg {
    //         height: 16px;
    //         width: 16px;
    //         margin-right: 4px;
    //         margin-left: 6px;
    //     }
    // }
    .leg-title-cont h2 {
        font-size: 1.75rem;
    }
    .leg-title-cont h4 {
        font-size: 1.25rem;
    }
    .leg-title-cont p {
        font-size: 0.875rem;
    }
    .landing-search-lg .custom-search input {
        height: 2.75rem;
        font-size: 0.8125rem;
    }
    .flow-card-view .fl-desc {
        font-size: 0.6875rem;
    }
    .main-lpg-id-wrap {
        &.lab {
            .ex-comp-wrap {
                grid-template-columns: 80% auto;
            }
        }
    }
    .leg-shrink .leg-content-wrap {
        top: -4.25rem;
        // top: -7.25rem;
    }
    // .flow-card-view .flow-details-container h2 {
    //     font-size: 11px !important;
    // }
    // .flow-card-view {
        // .landingCard-profile-list {
        //     & > .landingCard-profile-listitem:last-child {
        //         span.label.label-gray {
        //             margin-left: .5rem !important;
        //         }
        //     }
        // }
        // .landingCard-profile-listitem {
        //     span.label.label-gray {
        //         flex-basis: 4.5rem !important;
        //     }
        // }
    // }
    .transformer-configure-modal .selected-pod-details .dsrc-lf-icon svg {
        width: 1.375rem !important;
        margin-right: 0 !important;
    }
    .transformer-configure-modal {
        .selec-pod-meta-info > .col-2 {
            flex: 0 0 20% !important;
            max-width: 20% !important;
        }
    }
    // .feedback-custom-domain {
    //     font-size: 0.75rem !important;
    // }
    // .feed-ques-title-wrap {
    //     max-width: 20.625rem !important;
    // }
    .chat-feed-title {
        .vertical-separator {
            height: 1.25rem !important;
            position: relative;
            top: 2px;
        }
    }
    .new-custom-tabs .ant-tabs-tab-btn {
        font-size: 0.6875rem !important;
    }
    .trace-talk-content-wrap .trace-li-sect {
        min-width: auto !important;
        // width: 280px !important;
    }
    .catalog-dict-analysis-table {
        & > div:first-child {
            & > div:first-child {
                // flex-basis: 74% !important;
                // flex-basis: 66% !important;
            }
        }
    }
    .pod-creation-source-grid {
        grid-template-columns: minmax(12.5rem, 18.75rem) auto;

        .ant-tree-treenode .ant-tree-node-content-wrapper {
            width: 50%;
        }
    }
    /* .exp-dir-wrap .ant-tree-treenode .ant-tree-node-content-wrapper {
        max-width: 248px;
    } */
    .business-term-selection-modal {
        max-width: 90% !important;
    }
    .start-pg-trend-wrap {
        .slick-slider.slick-vertical.slick-initialized {
            height: 240px !important;
        }
    }
    .start-pg-discover {
        padding-top: 112px;
    }
    .pg-discover-heading {
        margin-bottom: 54px;
    }
    .dp-conn {
        width: 106px;
        margin-left: auto;
        .dp-con1, .dp-con2, .dp-con3 {
            height: 45px;
            width: 45px;
        }
    }
    .start-pg-trend-wrap .asked-quest {
        min-height: 36px;
    }
    .discover-pipeline {
        grid-template-columns: 112px 66px 240px 80px 240px 80px 240px;
    }
    
    .start-pg-greeting {
        margin-bottom: 12px;
    }
    .start-pg-domain-wrap {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1210px) {
    .discover-pipeline {
        grid-template-columns: max-content 66px 216px 80px 216px 80px 216px;
    }
}

@media screen and (max-width: 1130px) {
    .dp-pipline-pipe1 {
        left: -50px;
        width: 50px;
    }
    .dp-pipline-pipe2, .dp-pipline-pipe3 {
        left: -75px;
        width: 75px;
    }
    .discover-pipeline {
        grid-template-columns: max-content 54px 216px 66px 216px 66px 216px;
    }
}

@media screen and (max-width: 1085px) {
    .discover-pipeline {
        grid-template-columns: max-content 54px 184px 66px 184px 66px 184px;
    }
}

@media screen and (max-width: 1024px) {
    .userauth-cl-cn-box {
        padding: 0 15px;
    }
    .userauth-cl-ic-box {
        width: 70px;
        height: 70px;
        padding: 20px;
    }
    .normal-talkdata-main-wrapper, .tracker-data-wrap {
        .talk-conv-wrap .talkdata-conv-array-wrap > div {
            width: 95%;
        }
        .talk-chat-content.talk-data {
            max-width: 100% !important;
            .cht-act-bt {
                display: none !important;
            }
        }
    }
    .transformer-configure-modal .selected-pod-details .domain-tabs {
        grid-template-columns: 70% auto !important;
    }
    .section-with-drag.merge-drag.flow-with-drag {
        & > div {
            width: 50% !important;
        }
    }
    .target-transformer-main-div .target-md-left-sd .create-pod-table-container .custom-simple-landing-dgt {
        overflow-x: auto;
    }
    .main-lpg-id-wrap {
        &.navigator {
            .ex-comp-wrap {
                grid-template-columns: 70% auto !important;
            }
        }
    }
    .health-card-internal {
        h2 {
            font-size: 1.125rem;
        }
        .ant-progress-inner {
            height: 3.75rem !important;
            width: 3.75rem !important;
            font-size: 1rem !important;
        }
    }
    .catalog-dict-analysis-table {
        & > div:first-child {
            & > div:first-child {
                // flex-basis: 74% !important;
                // flex-basis: 56% !important;
            }
        }
        p.small {
            font-size: 0.6875rem !important;
        }
    }
    #podDictSearch {
        .custom-search-input {
            width: 9.375rem;
        }
    }
    #dictLastRefresh {
        font-size: 0.6875rem !important;
    }
    /* .file-det {
        & > label {
            font-size: 11px;
        }
        & > small {
            font-size: 9px;
        }
    }
    .file-ic {
        height: 36px;
        width: 36px;
    } */
}
@media screen and (max-width: 990px) {
    #podDictSearch .custom-search-input {
        width: 6.9375rem;
    }
    .health-stats-row {
        display: grid !important;
        grid-template-columns: 200px auto 330px;
        .col-4 {
            flex: unset !important;
            max-width: 100% !important;
        }
        #podHealthCard, #podStatsCard, #podUsageCard {
            padding-left: .25rem !important;
            padding-right: .25rem !important;
        }
    }
    .talkdata-content.talkdata-exp {
        max-width: 96% !important;
        width: 96% !important;
    }
    .health-card-internal {
        width: 100% !important;
        .h4 {
            font-size: 1.25rem !important;
        }
    }
    .classification-select-modal, .business-term-selection-modal {
        max-width: 90% !important;
    }
    .dp-pipline-pipe1 {
        left: -40px;
        width: 40px;
    }
    .dp-pipline-pipe2, .dp-pipline-pipe3 {
        left: -54px;
        width: 54px;
    }
    .dp-conn .dp-con1, .dp-conn .dp-con2, .dp-conn .dp-con3 {
        height: 40px;
        width: 40px;
    }
    .dp-conn .dp-con1 {
        top: 8px;
    }
    .dp-conn {
        width: 92px;
    }
    .discover-pipeline {
        grid-template-columns: max-content 54px 184px 56px 184px 56px 184px;
    }
    .dp-modules .top-heading, 
    .dp-mod-card-2 .mod-card-label, 
    .dp-mod-card-3 .mod-card-label {
        font-size: 10px !important;
    }

    .dp-mod-card-2 svg,.dp-mod-card-3 svg {
        height: 14px;
        width: 14px;
    }
    .dp-mod-card-1 :not(.dp-pipline-pipe1, .dp-pipline-pipe2, .dp-pipline-pipe3) svg {
        height: 18px;
        width: 18px;
    }
}
@media screen and (max-width: 950px) {
    // .feed-ques-title-wrap {
    //     max-width: 200px !important;
    // }
    .dp-conn .dp-con1, .dp-conn .dp-con2, .dp-conn .dp-con3 {
        height: 34px;
        width: 34px;
    }
    .dp-conn {
        width: 76px;
    }
    .dp-pipline-pipe1 {
        left: -26px;
        width: 26px;
    }
    .dp-pipline-pipe2, .dp-pipline-pipe3 {
        left: -40px;
        width: 40px;
    }
    .dp-conn .dp-con1 {
        top: 18px;

        &.two-conn {
            top: 13px !important;
        }
    }
    .discover-pipeline {
        grid-template-columns: max-content 30px 164px 44px 164px 44px 164px;
    }

    .dp-pipline-pipe1, .dp-pipline-pipe2, .dp-pipline-pipe3 {
        svg path {
            stroke-width: 4;
        }
    }
}
@media screen and (max-width: 920px) {
    .auth-subtitle-phrase {
        font-size: 13px;
    }
    .auth-title-phrase {
        font-size: 28px;
    }
    .userauth-cl-ic-box {
        width: 60px !important;
        height: 60px !important;
    }
    .userauth-cl-cn-box h4 {
        font-size: 13px;
        margin-bottom: 5px !important;
    }
    .userauth-cl-cn-box p {
        font-size: 10px !important;
        line-height: 1.2;
    }
    .userauth-cl-cn-box {
        padding: 0px 8px;
    }
    .login-signup-container .right-side .carousel-item {
        min-height: 60px;
    }
    .flow-header-param-cont-wrap {
        width: 658px !important;
        overflow-x: hidden !important;
    }
    /* #dictDetailReport, #dictSampleDDL {
        label {
            font-size: 8px !important;
            width: 38px;
        }
    } */
    #catalogCharts.catalog-visualization .custom-simple-landing-dgt {
        grid-template-columns: minmax(120px, auto) 120px minmax(100px,auto) 120px minmax(150px, auto) 140px !important
    }
    .pod-creation-source-grid {
        grid-template-columns: minmax(180px, 230px) auto;

        .ant-tree-treenode .ant-tree-node-content-wrapper {
            width: 36%;
        }
    }
    // .exp-dir-wrap .ant-tree-treenode .ant-tree-node-content-wrapper {
    //     max-width: 185px;
    // }
    .pod-crt-either-exp-sch .custom-search-input:not(:disabled) {
        width: 8.125rem;
    }
    .custom-pod-crt-tree .custom-search-input:not(:disabled) {
        width: 100% !important;
    }
    #pod-crt-exp-tree {
        .tree-header-pane {
            .custom-btn {
                margin-left: 3px !important;
            }
        }
    }
    .file-opts-close-btn {
        background: #FFF;
        top: -13px;
    }
    .fopts-grid {
        label.fontSizeHeading {
            font-size: 10px !important;
        }
    }
    .crt-pod-schm-wrap.default-fit .custom-vlist-dgt {
        grid-template-columns: 30% minmax(200px, auto) minmax(180px, 200px) 130px !important;
    }
    .file-left-header {
        display: none;
    }
    /* .file-opts-head {
        .custom-btn {
            font-size: 10px !important;
        }
    } */
    .file-left-header-action-wrap {
        justify-content: space-between;
        width: 100%;
    }
    .transformer-configure-modal .transformer-preview-section .result-details .input-output-details .title {
        font-size: 10px !important;
        padding-left: 10px !important;
    }
    .script-add-op-btn {
        font-size: 11px !important;
    }
    .subtitle {
        font-size: 0.6875rem;
    }
    // .transformer-configure-modal .transformer-preview-section .result-details .input-output-details {
    //     width: 15%;
    // }
    .merge-row-dragger {
        margin-left: 0.1875rem;

        svg {
            height: 0.5rem;
        }
    }
    
    .merge-direction-ic {
        margin-left: 0.1875rem;
    
        svg {
            height: 1rem;
        }
    }

    .flows-multicol .custom-vlist-dgt {
        grid-template-columns: 5.3125rem auto auto !important;
    }
    .merge-keys-table-container .key-table .custom-simple-landing-dgt {
        grid-template-columns: calc(50% - 1.0625rem) calc(50% + 1.0625rem) !important;
    }

    .flow-header-param-cont-wrap {
        .col-6 {
            -ms-flex: 0 0 calc(50% - 0.625rem);
            flex: 0 0 calc(50% - 0.625rem);
            max-width: calc(50% - 0.625rem);
        }
    }
}
@media screen and (max-width: 820px) {
    .health-stats-row {
        grid-template-columns: 9.6875rem auto minmax(22.5rem, auto);
    }
    .health-card-internal .h4 {
        font-size: 14px !important;
    }
    .login-signup-container {
        grid-template-columns: 100%;

        .left-side {
            margin-left: auto;
            margin-right: auto;

            .form-wrapper {
                min-width: 100%;
            }

            .pr-5 {
                padding-right: 16px !important;
            }
            
            .pl-5 {
                padding-left: 16px !important;
            }
        }

        .right-side {
            display: none;
        }
        
        .logo-container[data-page='forgot-password'] {
            padding-bottom: 70px;
        }
        .form-wrapper.form-wrapper-forgot {
            min-height: 260px !important;
        }
        .auth-active-link {
            font-size: 13px;
        }
        .logo-container {
            max-width: 100% !important;
        }
    }
}
@media screen and (max-width: 768px) {
    .tracker-dgt-wrap .custom-content-dgt {
        grid-template-columns: 100% !important;

        .cht-cont-box {
            padding: 0 !important;
        }
    }
    // .feed-ques-title-wrap {
    //     max-width: 145px !important;
    // }
    .pulse-search-comp .ant-select-selection-item {
        max-width: 130px !important;
    }
    .start-pg-talkdata-search .pulse-search-container {
        max-width: calc(100% - 30px);
    }
    
    .custom-modal:not(.transformer-configure-modal), .connection-guide-modal {
        max-width: 90% !important;
    }
}
@media screen and (max-width: 1044px) {
    .leg-content-wrap.container {
        max-width: 100% !important;

    }
    .main-lpg-content.container {
        max-width: 100% !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .custom-modal:not(.transformer-configure-modal), .connection-guide-modal {
        max-width: 90% !important;
    }
}
@media screen and (max-width: 640px) {
    .clarista-start-main-wrap {
        .main-header {
            .hamburger {
                display: none;
            }
        }
    }
    .start-pg-trend-wrap .ant-carousel {
        width: 100%;
        margin: 0 auto;
    }
    .start-pg-talkdata-search {
        .pulse-search-container {
            max-width: calc(100% - 30px);
            margin: 0 auto;
        }
    }
    .start-pg-greeting h2 {
        font-size: 18px;
    }
    .talkData-wrapper .pulse-search-comp {
        width: 100% !important;
    }
    .talkData-wrapper .pulse-search-container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .ques-bar h2 {
        max-width: 200px !important;
    }
    .talk-data-ques-header {
        grid-template-columns: 216px auto 108px;
        &.shared-user-extend {
            grid-template-columns: 216px auto 180px !important;
        }
    }
    .login-signup-container p.label-color {
        font-size: 10px;
    }
    .auth-title-phrase {
        font-size: 22px;
    }
    .logo-container[data-page='verify-otp'] {
        img {
            width: 150px;
        }
    }
    
    .chat-landing.short .custom-content-dgt {
        grid-template-columns: 50% 50% !important;
    }
}
@media screen and (max-width: 480px) {
    .shared-user-extend {
        .ques-bar h2 {
            max-width: 100px !important;
        }
    }
    .talk-data-ques-header {
        &.shared-user-extend {
            grid-template-columns: 144px auto 180px !important;
        }
        gap: 5px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}
@media screen and (min-width: 948px) {
    .mod-card-label, .top-heading {
        max-width: 150px;
    }
}
@media screen and (min-width: 1084px) {
    .mod-card-label, .top-heading {
        max-width: 170px;
    }
}
@media screen and (min-width: 1210px) {
    .mod-card-label, .top-heading {
        max-width: 190px;
    }
}
@media screen and (max-width: 1280px) and (min-height: 710px) {
    .start-pg-trend-wrap {
        .slick-slider.slick-vertical.slick-initialized {
            height: 430px !important;
        }
    }
}

@media screen and (min-width: 1100px) and (max-width: 1379px){
    @media screen and (max-height: 740px) {
        .start-pg-greeting h2 {
            font-size: 20px;
        }
        .start-pg-logo-wrap {
            max-width: 220px;
        }
        .start-page-content-wrap {
            position: relative;
            padding-top: 12px;
        }
        .start-pg-greeting, .start-pg-domain-wrap {
            margin-bottom: 16px;
        }
        .start-pg-discover {
            padding-top: 112px;
        }
        .pulse-search-comp {
            padding: 2px 12px 2px 6px;
        }
        .pulse-search-comp :is(.pls-input, .ant-input).pulse-autogrow-inp {
            font-size: 0.875rem !important;
            line-height: 1.4 !important
        }
        .pg-discover-heading {
            font-size: 11px;
        }
        // .start-pg-trend-wrap .slick-slider.slick-vertical.slick-initialized {
        //     height: 240px !important;
        // }
    }
}

@media screen and (min-width: 1380px) and (max-width: 1400px) {
    @media screen and (max-height: 768px) {

        .pulse-logimg-wrap {
            height: 108px !important;
        }
        
        .start-pg-greeting h2 {
            font-size: 20px;
        }
        .start-pg-logo-wrap {
            max-width: 220px;
        }
        .start-page-content-wrap {
            position: relative;
            padding-top: 50px !important;
        }
        .start-pg-greeting, .start-pg-domain-wrap {
            margin-bottom: 20px;
        }
        .start-pg-discover {
            padding-top: 112px;
        }
        .pulse-search-comp {
            padding: 2px 12px 2px 6px;
        }
        .pulse-search-comp :is(.pls-input, .ant-input).pulse-autogrow-inp {
            font-size: 14px !important;
            line-height: 1.4 !important;
        }
        .pg-discover-heading {
            font-size: 11px;
        }

        .start-pg-domain-wrap .dm-search-li-wrap {
            max-width: 700px;
        }
        .start-pg-talkdata-search .pulse-search-container {
            max-width: 574px;
        }
        // .start-pg-trend-wrap .slick-slider.slick-vertical.slick-initialized {
        //     height: 240px !important;
        // }
    }
}

@media screen and (min-width: 1280px) {
    .relevant-list-head {
        padding: 5px 0;
    }
    .pulse-landing-wrap {
        height: calc(100vh - 40px);
    }
}