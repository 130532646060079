.clarista-copilot-starter {
    position: fixed;
    bottom: 3.2rem;
    right: 1.25rem;
    // cursor: pointer;
    transition: .3s;

    &.info-card-holder {
        bottom: 0.25rem !important;
        right: 5.625rem !important;
    }
}

.c-cpilot-float-btn {
    // position: relative;
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 0.625rem;
    position: relative;
    padding: 0.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;

    position: fixed;
    bottom: 8px;
    right: 1.25rem;

    &:hover .c-cpilot-bg-wrap {
        animation: spin 1s linear infinite;
    }
}

.clarista-cpilot-logo-anim {
    animation: spin 1s linear infinite;
}

.c-cpilot-bg-wrap {
    background: linear-gradient(90deg, #4862BD, #A1F3FF);
    height: 66px;
    width: 66px;
    border-radius: 78px;
    position: absolute;
    top: -9px;
    left: -9px;
}

.c-cpilot-sec-wrap {
    position: relative;
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        height: 1.875rem;
        width: 1.875rem;
    }
}

.cpilot-head-lg {
    color: #2F1C6A;
    font-size: 1.5rem;
}

.cpilot-head-md {
    color: #9DA3A9;
    font-size: 1.25rem;
    line-height: 1.4;
}

.cpilot-pop-head {
    border-bottom: solid 1px #dedede;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: grab;
}

.cpilot-pop {
    position: relative;
    box-shadow: 0px 0px 20px -5px #00000040;
    border-radius: 0.75rem;
    width: 25rem;
    background: #FFF;
    transition: .3s;

    &.expanded {
        width: 600px;
        transition: .3s;
    }

    &.info-card {   
        border: solid 1px #dfdfdf;

        &::before {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            background: white;
            transform: rotate(45deg);
            position: absolute;
            bottom: 16px;
            right: -8px;
            border-top: solid 1px #dfdfdf;
            border-right: solid 1px #dfdfdf;
            border-radius: 0px 3px 0px 0px;
        }
    }
}

.cpilot-pop-body {
    padding: 16px 16px;
}

.cpilot-alert {
    cursor: pointer;
    padding: 10px 16px;
    background: #FFFBFB;
    border: solid 1px #F0BFBF;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.cpilot-ask-inp {
    position: relative;

    .cpilot-mic {
        position: absolute;
        top: 1.25rem;
        right: 0.4375rem;

        .custom-btn {
            outline: none !important;
            border: none !important;
        }
    }
    .cpilot-clearbtn {
        position: absolute;
        top: 1.375rem;
        right: 0.4375rem;

        .custom-btn {
            outline: none !important;
            border: none !important;
        }
    }
}

.cpilot-ask-inp .autogrow-input-field {
    padding: 0.625rem 2.375rem 0.625rem 1rem !important;
    border-radius: 0.875rem !important;
    background: #F6F6F6;
    font-size: 0.875rem !important;
    border: solid 1px #E4E4E4;
    cursor: text;
    display: block;
    width: 100%;
    height: 4.375rem;
    min-height: 4.375rem;
    max-height: 4.375rem;
    overflow-y: hidden;
    outline: none !important;
    line-height: 1.3;
    word-break: break-word;

    &:empty:before {
        content: attr(data-placeholder);
        color: #33333360;
    }

    &:focus {
        transition: 0.3s;
        height: auto;
        overflow-y: auto;
        border-color: #1677ff;
        // background: #eef8ff;
    }
}

.cpilot-user-avt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.875rem;
    height: 1.875rem;
    background: #2C83C4;
    border-radius: 100%;
    color: #FFF;
    font-size: 0.75rem;
    position: absolute;
    right: 0;
    top: 0;
}

.cpilot-conv-sender {
    position: relative;
    padding: 0.625rem 0.625rem;
    background: #2B4ECB;
    border-radius: 0rem 0.625rem 0.625rem 0.625rem;
    color: #fff;
    font-size: 0.75rem;
    margin-right: 2.75rem;
    width: fit-content;
    max-width: 68%;
    margin-left: auto;
    white-space: pre-wrap;

    .cpilot-conv-tail {
        position: absolute;
        top: 4px;
        right: -9px;
        transform: rotateY(180deg);
        svg path {
            fill: #2B4ECB;
        }
    }
}

.cpilot-conv-wrap {
    position: relative;
    margin: 0.9375rem 0;
}

.cpilot-conv-tail {
    width: 1.125rem;
    svg {
        width: 1rem;
        height: 1rem;
    }
}

.cpilot-conv-reply {
    position: relative;
    background: #F2F2F2;
    padding: 0.625rem 0.625rem;
    border-radius: 0.625rem;
    font-size: 0.75rem;
    margin-left: 2.5rem;

    .cpilot-conv-tail {
        position: absolute;
        top: 0.25rem;
        left: -0.5625rem;

        svg path {
            fill: #F2F2F2;
        }
    }
}

.cpilot-user-clarista {
    position: absolute;
    top: 0;
    left: 0;

    svg {
        height: 1.625rem;
        width: 1.625rem;
    }
}

.cpilot-sugg-pill {
    background: #F2F2F2;
    border: solid 1px #DEDEDE;
    border-radius: 100px;
    width: fit-content;

    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}

.cpilot-simple-msg-card {
    background: #F8F8F8;
    border: solid 1px #DEDEDE;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.625rem;
    cursor: pointer;

    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}

.cpilot-content-wrap {
    max-height: calc(100vh - 20.625rem);
    overflow-y: auto;
    overflow-x: hidden;
    margin: -1rem -1rem 0.625rem -1rem;
    padding: 0rem 1rem 0rem 1rem;
}

.classi-cpilot-wrap {
    max-height: 21.25rem;
    overflow-y: auto;
    margin: -0.9375rem -0.9375rem 0 -0.9375rem;
    padding: 0.9375rem 0.9375rem;
}

.pulse-dashboard-wrapper {
    // .c-cpilot-float-btn {
    //     bottom: 16px;
    // }
    .clarista-copilot-starter {
        right: 1.25rem;
        bottom: 3.9375rem;
    }
}

.dashboard-select-table-pop {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 1;
    padding: 10px 10px;
    border-radius: 10px;
    border: solid 1px #dedede;
}

.cpilot-pop.float-talkdata-window {
    width: 100%;

    .cpilot-pop-body {
        padding: 0 !important;
    }

    .pulse-col-strip {
        display: none !important;
    }
}

.talkdata-content {
    min-width: 38.75rem;
    max-width: 55%;
    transition: .3s;

    &.talkdata-exp {
        max-width: 80%;
        width: 75%;
        transition: .3s;
    }
}

.cpilot-action-btn-top {
    position: absolute;
    top: 8px;
    right: 15px;
}

.copilot-reply-box {
    h1, h2, h3, h4 {
        font-family: 'InterSemiBold';
    }

    h1, h2, h3 {
        font-size: 12px;
    }

    ul {
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 5px;
    }

    th, td {
        border: solid 1px #828282;
        padding: 2px;
    }
    
    table {
        margin-bottom: 8px;
    }
}