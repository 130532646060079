@import "../../../assets/scss/custom/common/_colors";
@import "../../../assets/scss/common/_colors";
@import "../../../assets/scss/common/_variables";
@import "../../../assets/scss/common/_font";



.alert-timeline {
  width: 98% !important;
  border: 0 !important;
  height: 43vw !important;
  box-sizing: unset;
  box-shadow: none !important;
}

.listofpod-section {
  .alert-add-pod-btn {
    .ant-collapse-extra {
      svg {
        transform: rotate(360deg);
        border: none !important;
        padding: 0rem;
        border-radius: 0rem;
      }
    }
  }

  .alert-remove-pod-btn {

    color: $white;

    svg {
      transform: rotate(360deg) !important;
      border: none !important;
      padding: 0rem;

      border-radius: 0rem;


    }

  }
}

.alert-form-left {
  .ant-table {
    .ant-table-tbody {
      tr {

        // td{
        //   padding:0rem ​0.0625rem 0.0625rem 0.4375rem !important;
        // }
        &.table-selected-row {
          td {
            background-color: #2C83;
          }
        }
      }
    }
  }


  .form_left {
    padding: 0.4375rem;
    height: calc(100vh - 90.0356rem) !important;
    overflow: auto !important;
    width: 25% !important;

    .ant-tabs-nav {
      margin: $margin0;
    }

    .ant-tabs-nav-list {
      width: $width-full;
      margin-left: $margin5;
    }

    .ant-tabs-tab {
      border: none;
      background-color: $new-backgrey;
      width: $width-full - 50;
      justify-content: center;
      border-bottom: 0.125rem solid $new-backgrey;
      margin: 0 !important;

      .ant-tabs-tab-btn {
        color: $color-content !important;
      }

      &.ant-tabs-tab-active {
        border-color: $primary;

        .ant-tabs-tab-btn {
          color: $primary !important;
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        border-left: 0.0625rem solid $galleryGray;
      }
    }

    .ant-tabs-content-holder {
      height: calc(100vh - 10.625rem);
      // height: calc(100vh - 8.375rem);
      overflow: auto;
      padding: 0.625rem;

      .custom-accordion {
        .accordion-heading {
          h3 {
            color: black;
            margin-left: $margin10;
            font-family: $inter-medium;
            text-transform: $tt-capitalize;
            font-size: $font12;
          }
        }

        .accordion-header {
          position: $p-relative;

          .btn-danger-outline {
            background-color: $primary;
            border: none;
            color: $white !important;
            border-radius: 0.1875rem;
            height: auto !important;
            width: auto !important;
            padding: $padding5 - 1 !important;
            font-size: $font12 - 2;
            position: $p-absolute;
            right: 0.625rem;
          }

          .bx-chevrons-down {
            display: none;
          }
        }

        .collapse-div {
          padding: $padding10;
          border: none;

          label {
            font-family: $inter-regular;
            color: $color-content;
          }

          .btn {
            padding: 0 $padding10;
          }

          .ant-list-items {
            .ant-list-item {
              padding: $padding5 0;

              h4 {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              button {
                border: 0.0625rem solid $lightred;
                color: $lightred;
                width: $width50 - 30;
                height: $height50 - 30;
                padding: 0;
                line-height: 0.9375rem;
                font-size: $font12 - 2;
                text-align: center;
              }
            }
          }

          .form-control {
            color: $color-content;
            font-size: $font12;
          }

          .ant-picker {
            height: $height50 - 20 !important;
            border-radius: 0 !important;
            border: 0.0625rem solid $galleryGray !important;

            .ant-picker-input {
              input::placeholder {
                color: $color-content;
              }
            }

            .ant-picker-suffix {
              svg {
                fill: $color-content;
              }
            }

            &.ant-picker-focused {
              box-shadow: none;
            }

            .ant-picker-active-bar {
              display: none;
            }
          }
        }

        .btn-primary {
          position: initial;
          width: $width-full;
          border-radius: 0.1875rem;

          i {
            margin-right: $margin5;
          }
        }
      }

      .toggleAntD {
        .ant-radio-group {
          display: flex;
        }

        label {
          width: $width-full - 50;
          font-size: $font12 - 2 !important;
          margin: 0;
          height: $height50 - 25;
          line-height: 1.4375rem;
          border: 0.0625rem solid $primary;
          text-align: center;
          color: $primary;
          font-family: $inter-medium;
          font-weight: 600 !important;
          letter-spacing: 0.0625rem;

          &::before {
            display: none;
          }

          &.ant-radio-button-wrapper-checked {
            background-color: $primary;
            color: $white;
          }
        }
      }

      .custom-switch {
        padding-left: 0;
        padding-right: 2.25rem;

        .custom-control-label {
          margin: 0;

          &::before {
            left: auto;
            right: -2.25rem;
            cursor: pointer;
          }

          &::after {
            right: calc(-1.5rem + 0.125rem);
            left: auto;
          }
        }

        .custom-control-input:checked~.custom-control-label:after {
          transform: translateX(0.8rem);
        }

        .custom-control-input:checked~.custom-control-label::before {
          background-color: $primary;
          border-color: $primary;
        }
      }

      .accordion-chart {
        display: flex;
        justify-content: space-between;

        .chart-content {
          padding-left: $padding5;
          width: calc(100% - 5rem);
          display: flex;
          align-items: flex-end;

          input {
            border-radius: $border-radius-5 - 2;
          }
        }

        .chart-img {
          display: flex;
          align-items: center;
          border: 0.0625rem solid $primary;
          padding: $padding5 - 2;
          margin-right: $margin5;
          border-radius: 0.3125rem;
          width: $width50 + 30;
          height: $height50 + 30;

          img {
            max-width: $width-full;
            margin: $margin-auto;
          }
        }
      }

      .btn-sticky {
        position: sticky;
        bottom: 0.3125rem;
        top: auto;
        width: calc(100% - 1.25rem);
        left: 0.625rem;
        z-index: 1;
      }
    }

    .card-container {
      position: $p-relative;
    }

    .control-btn {
      position: $p-absolute;
      bottom: 0.3125rem;
      width: calc(100% - 1.25rem);
      top: auto;
      left: 0.625rem;
    }

    .control-tab {
      position: $p-relative;
      padding: $padding10 $padding10 $padding10 * 3 $padding10;

      >h3 {
        font-size: $font12;
        margin: 0;
        padding: $padding10 0;
        color: $black;
        border-bottom: 0.0625rem solid $light-background;
        font-family: $inter-medium;
        text-transform: $tt-capitalize;
      }

      .p-absolute {
        position: $p-absolute;
        top: 0.75rem;
        right: 0.625rem;
        font-size: $font20;
      }

      .custom-accordion {
        padding: 0;
      }

      input {
        color: $black;
      }


      .ant-collapse-header {

        &::before,
        &::after {
          position: $p-absolute;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .style-tab {
      padding: $padding10;

      h3 {
        font-size: $font12;
        margin: 0;
        padding: $padding10 0;
        border-bottom: 0.0625rem solid $light-background;
        color: $black;
        font-family: $inter-medium;
        text-transform: $tt-capitalize;
      }

      .style-tab-inner {
        padding: $padding10 0;

        .color-border {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .color-box {
            width: 1.875rem;
            height: 1.875rem;
            margin-right: 0.1875rem;
          }
        }
      }

      label {
        font-size: $font12 !important;
      }
    }

    .control-tab-accordion {
      .custom-accordion {
        box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.25);

        .accordion-header {
          .btn-danger-outline {
            background-color: $white;
            border: 0.0625rem solid $lightred;
            color: $lightred !important;
            padding: 0 !important;
          }
        }
      }

      .collapse-div {
        padding-top: 0;
      }
    }

    .data-control-tab {
      margin: -0.3125rem -0.3125rem -1.25rem -0.3125rem;

      .data-control-inner {
        max-height: $height50 * 4;
        overflow-y: auto;
        padding: $padding5;
      }

      .control-tab-accordion {
        padding: 0;
      }
    }

    .table-chart-column {
      .table-chart-header {
        border-bottom: 0.0625rem solid $light-background;
        padding: $padding10 0 !important;
        margin: 0 $margin10 $margin10;

        label {
          font-size: $font12;
          font-family: $inter-semibold;
          text-transform: $tt-uppercase;
          color: $black;
        }

        span {
          color: $primary;
          cursor: pointer;
        }
      }

      label {
        margin: 0;
        display: inherit;
      }

      label,
      span {
        font-size: $font12;
      }

      .ant-checkbox {
        margin: 0 $margin5 0 0;
      }

      .table-chart-header,
      ul li {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      ul {
        padding: 0 $padding10 $padding5;
        max-height: $height50 * 3 + 25;
        overflow-y: auto;

        li {
          padding: $padding5 - 2 $padding5;
          border: 0.0625rem solid $galleryGray;
          margin: $margin5 0;

          span {
            color: $primary;
            font-style: italic;
            font-size: $font12 - 2;
          }
        }
      }
    }
  }
}

.error-status {
  color: #ff565e;
  font-family: "Inter";
  font-style: "Regular";
  font-size: 0.875rem;
}

.success-status {
  color: #00cc92;
  font-family: "Inter";
  font-style: "Regular";
  font-size: 0.875rem;
}

.alert-dashboard {
  .sub-header {
    padding-bottom: 0.375rem;
  }

  .tab-look {
    color: $primary;
    display: flex;
    align-items: center;
    border-bottom: 0.0938rem solid $primary;
    padding-bottom: 0.375rem;
    margin-top: 0.3125rem;

    span {
      font-weight: 500;
      padding-left: 0.625rem;
    }
  }
}

.alert-landing-body {
  height: calc(100vh - 4.875rem);
  overflow: auto;

  .exception-modal-header {
    font-size: $font14;
    font-family: $inter-regular;
    font-weight: 600;
    font-size: $font20 - 0.125rem;
    line-height: 1.5rem;
  }

  .color-black {
    color: $black !important;
  }

  .form_left {
    .ant-tabs-tab-active {
      border-bottom: 0.0938rem solid $primary !important;
      box-shadow: none !important;
    }
  }

  .section_content_right {
    height: calc(100vh - 90.0356rem) !important;
    background: $main-background !important;
    padding: 0rem !important;
    width: 80% !important;
    padding-left: 0.4375rem !important;

    &.logs-section {
      height: calc(100vh - 5.0625rem) !important;

      & .create-result-section {
        // height: calc(100vh - 29.5625rem) !important;
        height: calc(100vh - 15.625rem) !important;

      }
    }

    &.filter-section {
      & .create-result-section {
        height: calc(100vh - 15.625rem) !important;
      }
    }

    .custom-accordion,
    .filter-section {
      background: $white;
      box-shadow: 0.0625rem 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.25);
    }

    .listofpod-section {
      .custom-accordion {
        margin-top: 0;
        max-height: 50%;
        // overflow: auto;

        table {
          background: $white;
        }
      }

      .custom-accordion .ant-table-body {
        // height: 12.5rem !important;
        max-height: 12.5rem !important;
        // overflow: auto !important;
      }
    }

    .condition-type-section {
      .custom-accordion {
        margin-top: 0.625rem;
        max-height: 25rem;
      }

      .custom-accordion .ant-collapse .ant-collapse-header {
        border-bottom: none !important;
        padding: 0.3125rem px 0.25rem 0rem 0.4375rem !important;
      }

      .sql-btn {
        svg {
          transform: rotate(360deg) !important;
          // border: none !important;
          // padding: 0rem;

          // border-radius: 0rem;


        }
      }



      .filter-section,
      .sql-editor-container,
      .no-preview-table-data {
        margin-top: 0.625rem;
        min-height: 10.625rem;
        // overflow:auto;
        box-shadow: 0.0625rem 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.25);

        .no-flow-preview-data {
          background: $white;
        }
      }

      .filter-section .flows-filter-wrapper {
        max-height: 10.625rem;
        min-height: 10rem;

        // overflow: auto;
      }

      .sql-editor-container {
        // overflow: hidden;
      }

      .ace_editor {
        // height: 12.5rem;
        max-height: auto;
        overflow: auto !important;
      }

      .no-flow-preview-data {
        text-align: center;
        background: $white;
      }
    }

    .create-result-section {
      background-color: white;
      margin-top: 0.625rem;
      // max-height: 25rem;
      // height: calc(100vh - 35.5625rem);
      position: relative;
      height: calc(100vh - 15.625rem);

      @media screen and (max-height: 44.1875rem) {
        height: 31.25rem !important;
      }

      // height: calc(70% - 0.625rem);
      overflow: hidden;

      .flow-lower-section {
        padding: 0rem !important;
        overflow-x: hidden;
        width: 100%;
        background-color: $white;
      }
    }

    .ant-collapse {
      border-bottom: none !important;

      .ant-collapse-item {
        border-bottom: none !important;
      }
    }
  }
}

.modal-heading-logs {
  padding-left: 0.4375rem;
  color: $black !important;
}

.alert-bell-count {
  position: relative;

  .bxs-alert-count {
    position: absolute;
    top: -0.125rem;
    left: 0.8125rem;

    color: #FFFFFF;
    /* border: 0.0625rem solid #FFBB01; */
    border-radius: 11%;
    height: 1.0625rem;
    width: 1.0625rem;
    background-color: $primary;
    font-size: 0.3125rem;
    text-align: center;

  }
}

.resultsection-skeleton {
  .ant-skeleton-avatar {
    width: 100% !important;
    height: 25.4375rem !important;
    border-radius: 0.1875rem;
    background-color: #FFFFFF;
  }
}

.alert-landing-body {
  .section_with_left_form {
    padding: 0.4375rem !important;
    background-color: #f9f9f9;
    grid-gap: 0.5rem !important;
    gap: 0.125rem !important;
  }
}


.ant-badge-count {
  z-index: auto;
  width: 2rem;
  height: 1.125rem;
  padding: 0rem 0.0625rem;
  color: #fff;
  font-weight: normal;
  font-size: 0.5rem;
  line-height: 1.3125rem;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 0.625rem;
  box-shadow: 0 0 0 0.0625rem #fff;
  top: 0.25rem !important
}

#alert-logs-tab.alert-logs-tab {
  // .ant-table-body {
  //   overflow: hidden !important;
  // }

  max-height: calc(100vh - 11.4375rem) !important;
  overflow: hidden !important;
}

.section_with_left_form .form_left textarea:not(:disabled) {
  height: 6.25rem !important;
}

#alert-lower-table-sec {
  height: 100%;
  // height: calc(100% - 6.25rem);

  .data-grid-shrink {
    height: 100% !important;

    .custom-data-grid-table {
      height: calc(100% - 1.5625rem) !important;
    }
  }
}

.primary-svg-color {
  svg {
    & :is(rect, circle, line) {
      stroke: #2c83c4;
    }

    & path {
      fill: #2c83c4;
    }
  }
}

.alert-ls-ic svg {
  height: 1.5rem;
  width: 1.5rem;
}

.alert-ls-ic {
  display: flex !important;
  width: 1.875rem;
  // height: 1.875rem;
}

.alert-flow-thumbnail-wrap {
  position: relative;
  display: block;
  width: 100%;
  height: 15.625rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0.125rem 0.125rem 0.625rem 0rem #e6e6e6;
  border: solid 0.0625rem #9b9b9b;
  border-radius: 0.1875rem;

  &>a {
    cursor: pointer;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.404);
    align-items: center;
    justify-content: center;
    color: #fff;
    opacity: 0;
    transition: .3s;
  }

  &:hover>a {
    opacity: 1;
    transition: .3s;
  }
}

.alert-active-li {
  position: relative;

  &::after {
    content: 'selected';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.375rem;
    height: 0.625rem;
    padding: 0.625rem 0.625rem;
    background-color: #2c83c4;
    color: #fff;
    border-radius: 0.625rem;
    font-size: 0.6875rem;
    right: 0.625rem;
  }
}

.alert-inactive-li {
  position: relative;

  &:hover::after {
    content: 'click to select';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 0.625rem;
    padding: 0;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.49);
    font-size: 0.6875rem;
    right: 0.625rem;
    font-style: italic;
  }
}

.alert-select-modal {
  .ant-table-body {
    max-height: calc(100vh - 15.25rem) !important;
  }

  .alert-landing-body {
    height: calc(100vh - 13.25rem) !important;
  }
  .alert-list-view{
    height: calc(100vh - 14rem) !important;
    .custom-simple-landing-dgt{
      grid-template-columns: 12.5rem 6.25rem 15.5rem 15.375rem 5.75rem 13.9375rem !important;
    }

  }
}

.alert-full-screen-sql {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  width: 100%;
  height: 100vh;
}

.alert-headed .sub-header-left svg {
  height: 0.9375rem;
}

.alert-headed .sub-header-left {
  font-size: 0.8125rem;
  font-weight: 600;
}

.myalert-landingpage-maindiv {
  height: calc(100% - 0.5rem);
}

#alert-lower-table-sec {
  &.custom-virtual-data-table {
    .load-more-dgt-wrapper {
      bottom: 0.9375rem !important;
    }
  }
}

.alert-time-index .custom-timeline-div {
  width: calc(100% + 1.25rem) !important;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
  max-height: calc(100vh - 9.875rem) !important;
  padding: 0;
}

.alert-time-index {
  .cust-tm-heading {
    display: none;
  }

  .custom-timeline-div .card-header {
    position: absolute;
    z-index: 1;
    top: 0.25rem;
    right: 0.5rem;
    padding: 0;
    padding-right: 0.5rem;
    border: none;
  }

  .chnglog-tmln {
    width: calc(100% - 3.25rem);
  }
}

.ant-badge-count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4375rem 0.375rem;
  background-color: #ff5722 !important;
  color: #fff;
  text-shadow: 0rem 0rem 0.0625rem black;
  height: 0.9375rem;
  top: -0.5rem;
  right: -0.3125rem;
  border-radius: 0.625rem;
  right: -0.0625rem !important;
  font-size: 0.5rem;
  font-weight: 600;
  width: auto;
}

.ant-picker-input>input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0.25rem 0.6875rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.6875rem;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.125rem;
  transition: all 0.3s;
  flex: auto;
  min-width: 0.0625rem;
  height: 1rem;
  padding: 0;
  background: transparent;
  border: 0;
}

.ant-picker-focused {
  border-color: none !important;
  box-shadow: none !important;
  border-right-width: 0.0625rem;
  outline: 0;
}

.ant-picker-panels {
  font-size: 0.75rem;
}

.ant-picker {
  height: 1.4375rem;
}

.noti-icon-alerts {
  svg {
    height: 1.125rem !important;
  }
}

.alert-varicon-in-list {
  svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
}

.alt-fls-icon svg {
  width: 1.4375rem !important;
  position: relative;
  right: -0.375rem;
}

.alert-prioricon-in-list {
  svg {
    height: 1.125rem;
    width: 1.125rem;
  }
}

.alert-prop-toggle {
  position: absolute;
  top: 0;
  right: -0.4375rem;
  display: flex;
  width: auto;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: #f2f2f2;
  border: solid 0.0625rem #c0bbbb;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  padding: 0rem 0.0625rem;
  border-right-color: transparent;
  color: #155e94;
  cursor: pointer;
  box-shadow: 0rem 0rem 0.0625rem 0.0625rem #aeaeae;
}

#alert-log-prop {
  &>.form_left {
    transition: .3s;
  }

  &>.section_content_right {
    transition: .3s;
  }
}

#alert-log-prop {
  &.hide-alert-logs {
    &>.form_left {
      width: 0 !important;
      padding: 0;
      transition: .3s;
    }

    &>.section_content_right {
      width: 100% !important;
      padding-left: 0 !important;
      transition: .3s;
    }
  }
}

.analytics-dash-table-fullwidth {
  .custom-simple-landing-dgt {
    grid-template-columns: minmax(10.8125rem, 15.625rem) minmax(16.1875rem, 18.75rem) 5rem minmax(7.3125rem, auto) minmax(9.8125rem,auto) 110.2939rem !important
  }

}

.flow-select-modal {

  .flow-landing-content {
    .custom-simple-landing-dgt {
      grid-template-columns: none !important
    }
  }
}

.alert-dashboard {
  .subtitle svg {
      height: 1rem;
      width: 1rem;
  }
}