.dict-landing-filter-tabs {
    .ant-tabs-tabpane {
        padding-left: 4px !important;
    }

    & > .ant-tabs-nav {
        margin-bottom: 0 !important;
        padding: 6px 0px;
    }

    & .ant-list-item {
        padding-left: 10px;
        padding-right: 10px;
        border: none;
        text-transform: uppercase;
        font-size: 11px;
    }

    .ant-tabs-tab {
        padding: 5px 10px !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #2c83c4 !important;
    }

    .ant-tabs-ink-bar {
        background: #2c83c4 !important;
    }

    .ant-tabs-tab:not(:first-child) {
        margin: 9px 0 0 0 !important;
    }

    .ant-tabs-nav-more {
        padding: 0px 0px 0px 0px !important;
        line-height: 0 !important;
    }
    
    .ant-tabs-nav-operations {
        position: relative;
        margin-bottom: -5px;
    }
}

.dict-lpg-chk {
    transform: scale(.8);
}