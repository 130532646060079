.policy-column-mask {
  height: 18.75rem;
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.1875rem;

  display: flex;
  flex-direction: column;

  .policy-column-mask-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
    border-bottom: 1px solid #f1f1f1;
  }

  .policy-column-mask-title h4 {
    font-weight: 600;
    font-size: 0.8125rem;
    margin: 0;
  }

  .policy-column-mask-options {
  }

  .policy-column-mask-table {
    flex: 1;

    .ant-table .ant-table-tbody tr td {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .icon-box {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 28%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(55, 54, 54, 0.15);
    z-index: 1;
    // animation: fade-right 1.5s infinite linear;

    img {
      height: 1.5625rem !important;
      width: auto;
    }

    svg {
      height: 1.25rem !important;
    }

  }
}
