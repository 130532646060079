/* Colors */
$color-midnight-black: #212121; 
$color-chocolate: #07110D;
$color-green: #225740;
$color-light-green: #569F54;
$color-gray: #b9bbc7;
$color-light-gray: #E8E8E8;
$color-silver: #6e6464;
$color-slate-gray: #525252;
$menu-inactive: #6A7183;
$menu-active:#F8F5F9;
$white: #ffffff;
$black: #000000;
$color-content: #333a40;
$header-back: #EFF2F7;
$primary: #5f5de9;
$lightprimary: rgba(95, 93, 233, 0.1);
$darkprimary:#5b59b9 ;
$secondary: #2a3042;
$darksecondary: #301ab9;
$violet: #b9b7b7;
$lightDark: #303233;
$dark: #262626;
$lightBlue: #79aebf;
$roughtwhite: #eeeeee;
$background-primary: #F7F8FA;
$softBlue: #C9E8EE;
$lightwhite: #F5F5F6;
$gray: #6A6A6A;
$toryBlue: #14549D;
$altoGray: #DDDDDD;
$disabledGray: #F2F2F2;
$lightGreen: #00CFB2;
$lightGray: #9EA7B0; //filter inventory
$darkGray:#2c2e2e;
$labelGray: #878787; // form-select labels
$galleryGray: #EAEAEA;
$breadcrum-grey:#8B8B8B;
$new-backgrey: #fbfbfd;
$red:#CC0100;
$lightred:#FF565E;
$flushMahogany: #f15959;
$marinerBlue: #3877d4;
$scooterBlue: #38B5CB;
$vikingBlue: #65c7d9;
$ripeLemon: #f5de0e;
$appleGreen: #00CC92;
$greenback: rgba(0, 204, 146, 0.1);
$bittersweetRed:#fb6b5b;
$grey: #666666;
$aliceBlue: #f6f7f9;
$solitudeGrey: #CFCFCF;
$darkGrey: #515253;
$tableColGrey: #F9F9F9;
$skyBlue: #54c7df;
$skyLight: #007bff;
$darkSky: #0192a3;
$dimGray: #717171;
$nightriderGray: #333333;
$snow: #fafafa;
$gainsboro: #D3D3D3;
$snowGray: #cccccc;
$snowGraylight:rgba(204, 204, 204, 0.07);
$breadcrumb-link: #001E3F;
$dimGrey: #989898;
$wildSandGrey: #F5F5F5;
$limeGray: #fafafb;
$orangeRed: #ff9f00;
$blue: #54c7df;
$trueBlue: #3781D8;
$green: #07701C;
$tableheadbg: #E9E9E9;
$tableborder: #d9d9d9;
$tableGray: #676767;
$placeholder: #aaaaaa;
$toastGreen: #dff0d8;
$calenderIcon: #d6d6d6;
$lightYellow:#fcf8e3;
$yellow: #FFBB01;
$darkGreen:#087D00;
$darkRed:#a94442;
$darkGreeYellow:#8a6d3b;
$lightBlue:#54c7df;
$breaklineColor:#e5e5e5;
$cyan:#17a2b8;
$suluGreen: #A9EB74;
$btnWarning: #ffc107;
$greybg:#787878;
$highlight:#05cdcd;
$btnRequest: #17a2b8;
$gold: #FFD700;
$border-color:#C6CACB;
$active:#57617A;
$disabledInput: #707070;
$gridBorderGray: #828282;
// social platforms color codes
$color-facebook: #3b5999;
$color-twitter: #55acee;
$color-youtube: #cd201f;
$color-instagram: #4f81a9;
$color-pinterest:  #bd081c;
$dict-dark-color: #0A3053 ;
$url-link-color:#3b7098;