.row-filter-container {
  height: 100%;
  padding: 10px;
  overflow-y: auto;

  .row-filter-row {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .row-filter-fields {
      display: flex;
      align-items: center;
      gap: 10px;

      .row-filter-dropdown {
        flex-basis: 35%;
        font-size: 11px;
      }

      .row-filter-input-field {
        flex: 1;
      }

      .form-group {
        margin: 0 !important;
      }

      .form-between-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .form-text {
          align-self: flex-end;
        }
      }

      .custom-btn-grp {
        align-self: flex-end;
        height: 100%;
      }
    }
  }
}
