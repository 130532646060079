.edgebutton {
    cursor: pointer;
    position: absolute;
    bottom: -4px;
    height: 15px;
    left: -12px;
    z-index: 2;
    visibility: visible;
    opacity: 1;
    transition:
        opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out;
}

.edgebutton:hover {
    height: 24px;
    width: 24px;
    bottom: -10px;
    box-shadow: 0 0 6px 2px rgb(255, 86, 94);
    border-radius: 50%;
}
