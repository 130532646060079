@import "../../../assets/scss/common/colors";
@import "../../../assets/scss/common/variables";
@import "../../../assets/scss/common/font";

.alertgridview-responsive {
    display: grid;
    grid-gap: 0.9375rem 0.625rem;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    margin: 0;
    padding: $padding10;
    position: $p-relative;

    .card-tags {
        bottom: auto;
        top: 2.5rem;
        right: 1.875rem;
    }

    .card {
        min-height: 8.3125rem;
        border-radius: 0.1875rem;
        // border: 0.0625rem solid #8282824f;
        cursor: pointer;
        transition: box-shadow 0.2s ease-in-out;
        // box-shadow: 0rem 0.125rem 0.25rem 0.125rem #e6e6e6;

        &:hover {
            box-shadow: 0 0.0625rem 0.125rem -0.125rem rgba(0, 0, 0, 0.16), 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.12), 0 0.3125rem 0.75rem 0.25rem rgba(0, 0, 0, 0.09);
        }
    }

    .card-body {
        padding: $padding10;
    }

    .alertsCard-circle {
        display: flex;
        justify-content: space-between;
        position: $p-relative;

        &::after {
            content: "";
            position: $p-absolute;
            height: 0.0625rem;
            background-color: $light-background;
            left: -0.625rem;
            right: -0.625rem;
            top: 50%;
            transform: translate(0, -50%);
        }

        .icon-box {
            width: $width70 - 10;
            height: $height70 - 10;
            border-radius: 50%;
            border: 0.0625rem solid $light-background;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            z-index: 1;

            img {
                height: $height50;
                width: auto;
            }
        }

        .alertsCard-profile-list {
            display: flex;
        }
    }

    .alertsCard-actions {
        display: flex;
    }

    .alertsCard-header {
        display: flex;
        align-items: center;
        margin-bottom: $margin10;

        p {
            margin: 0;
            color: $primary;
        }
    }

    .alertsCard-description {
        margin-bottom: $margin10;
        .section-heading {
            margin-top: 0.3125rem !important;
        }
        .alert-heading {
            font-family: $inter-bold;
            color: $primary;
        }
        
        .grey-text {
            color: $grey-three;
        }
        .alert-profile-list {
            .alert-profile-listitem {
                display: flex;
                align-items: center;
                margin-bottom: $margin10 - 0.3125rem ;

                .label {
                    margin: 0 $margin10;
                }

                span {
                    &:last-child {
                        width: calc(100% - 6.625rem);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .quality {
                    // padding: 0.3125rem 0.4375rem;
                    // background: rgba(255, 171, 0, 0.2);
                    color: $warning-yellow;
                    // border-radius: 0.9375rem;
                }
                .business {
                    // padding: 0.3125rem 0.4375rem;
                    // background: rgba(0, 204, 146, 0.2);;
                    color: $success-green;
                    // border-radius: 0.9375rem;
                }
            }
            .alert-detail {
                svg {
                    // margin-left: 0.125rem
                }
                .label {
                    // margin-left: 0.75rem;
                }
            }
        }
    }

    .alertsCard-profile-list {
        .alertsCard-profile-listitem {
            display: flex;
            align-items: center;
            margin-bottom: $margin10;

            .label {
                margin: 0 $margin10;
            }

            span {
                &:last-child {
                    width: calc(100% - 6.625rem);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .alertsCard-tag-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.3125rem;
        .landing-grid-tag {
            height: $height50 - 15;
            padding: 0 $padding10 * 2;
            border-radius: 1.875rem;
            line-height: 2.1875rem;
            margin: 0 $margin5;

            &.last-tag-number {
                background-color: $light-primary;
                color: $primary;
            }

            &.red {
                background: rgba($color: $error-red, $alpha: 0.20);

                .label {
                    color: $error-red;
                }

                svg {
                    path {
                        fill: $error-red;
                    }
                }
            }

            &.orange {
                background: rgba($color: $accent-two, $alpha: 0.20);

                .label {
                    color: $accent-two;
                }

                svg {
                    path {
                        fill: $accent-two;
                    }
                }
            }

            &.green {
                background: rgba($color: $accent-four, $alpha: 0.20);

                .label {
                    color: $accent-four;
                }

                svg {
                    path {
                        fill: $accent-four;
                    }
                }
            }
            
            &.yellow {
                background: rgba($color: $accent-six, $alpha: 0.20);

                .label {
                    color: $accent-six;
                }

                svg {
                    path {
                        fill: $accent-six;
                    }
                }
            }
            
            &.grey {
                background: rgba($color: $grey-three, $alpha: 0.20);

                .label {
                    color: $grey-three;
                }

                svg {
                    path {
                        fill: $grey-three;
                    }
                }
            }

            &.purple {
                background: rgba($color: $accent-nine, $alpha: 0.20);

                .label {
                    color: $accent-nine;
                }

                svg {
                    path {
                        fill: $accent-nine;
                    }
                }
            }

            &.blue {
                background: rgba($color: $accent-eleven, $alpha: 0.20);

                .label {
                    color: $accent-eleven;
                }

                svg {
                    path {
                        fill: $accent-eleven;
                    }
                }
            }

            &.magenta {
                background: rgba($color: $accent-seven, $alpha: 0.20);

                .label {
                    color: $accent-seven;
                }

                svg {
                    path {
                        fill: $accent-seven;
                    }
                }
            }

            &.pink {
                background: rgba($color: $accent-eight, $alpha: 0.20);

                .label {
                    color: $accent-eight;
                }

                svg {
                    path {
                        fill: $accent-eight;
                    }
                }
            }
        }

        .label {
            margin-left: $margin10;
        }
    }
    .red-border {
        border: 0.0625rem solid $error-red;
    }
}
.alert-list-view{
    .quality {
        // padding: 0.3125rem 0.4375rem;
        // background: rgba(255, 171, 0, 0.2);
        color: $warning-yellow;
        // border-radius: 0.9375rem;
        display: flex;
        width: max-content;
    }
    .business {
        // padding: 0.3125rem 0.4375rem;
        // background: rgba(0, 204, 146, 0.2);;
        color: $success-green;
        // border-radius: 0.9375rem;
        display: flex;
        width: max-content;
    }

}
.alert-selected-card {
    box-shadow: 0rem 0.0625rem 0.25rem #2C83C4 !important;
}
.dashboard-index{
    // .alertgridview-responsive .card :is(.paragraph, .label) {
    //     font-size: 0.6875rem ;
    // }

}
.alertgridview-responsive .card .custom-badge {
    font-size: 0.5625rem;
    padding: 0.125rem 0.375rem;
}
.alertgridview-responsive .card .label {
    width: 3.9375rem;
}
.alert-profile-listitem svg {
    width: 1.25rem;
}
.data-view-dbox .alert-icon-box.flow-ic svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
    right: 0 !important;
}
.data-view-dbox .alert-icon-box {
    // border: solid 0.0625rem #d3d3d3;
    // border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.875rem;
    width: 1.875rem;
    margin-top: 0.3125rem;

    & svg {
        width: 1.75rem;
    }
}
.alert-extra-identifiers {
    margin-top: 0.375rem;
}

.prior-idt {
    display: block;
    text-align: center;
    background: #f3f3f3;
    border-radius: 0.625rem;
    font-size: 0.625rem;
    padding: 0.3125rem 0;
}

.prior-sep {
    background: #e2e2e2;
    display: block;
    width: 33%;
    height: 0.125rem;
    margin: 0.1875rem auto;
}

.prior-ic > svg {
    height: 0.875rem;
    transform: rotateX(180deg);
    // height: 0.5rem;
}

.prsp-ic {
    svg {
        height: 1.25rem;
    }
}
.alert-dmn-ic {
    display: flex;
    & svg {
        height: 0.8125rem;
        margin: 0.125rem 0.3125rem 0.125rem 0.125rem;
    }
}
.prsp-idt {
    margin-bottom: 0.625rem;
}

#scaled-frame-dashboard {
    width: 100% !important;
    zoom: 1;
    -moz-transform: scale(1);
    
    -o-transform: scale(1);
  
    -webkit-transform: scale(1);
    position: relative;
    z-index: 0 !important;
    height: calc(100% - 2.1875rem);

}
