.pulse-search-comp {
    display: flex;
    align-items: center;
    border: solid 1px #CACACA;
    border-radius: 30px;
    padding: 0.4375rem 1.25rem 0.4375rem 0.375rem;
    position: absolute;
    z-index: 2;
    top: -3px;
    width: 100%;
    background: #fff;

    &.show-pulse-exp-eff{
        border-color: #e7e7e7;
        border-radius: 16px;
        box-shadow: 0px 2px 15px -7px #00000096;
    }

    .custom-btn:focus {
        border: none !important;
    }

    .ant-select-disabled {
        .ant-select-arrow {
            display: none;
        }
        &.ant-select {
            width: auto !important;
        }
        .ant-select-selector {
            padding-right: 0 !important;
        }
        .ant-select-selection-item {
            padding-right: 0 !important;
        }
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: #333 !important;
        background: transparent !important;
    }

    .ant-select {
        width: 11.875rem;
        height: 2rem;
    }

    .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
    }

    :is(.pls-input, .ant-input) {
        border: none !important;
        font-size: 1rem;
        resize: none;
        height: 1.75rem;
        transition: .3s;

        &.pulse-autogrow-inp {
            cursor: text;
            display: block;
            width: 100%;
            height: 1.75rem;
            min-height: 1.75rem;
            max-height: 5.875rem;
            font-size: 1rem !important;
            background: #fff;
            overflow-y: hidden;
            outline: none !important;
            padding: 0.25rem 0.6875rem !important;
            border-radius: 0.375rem;
            line-height: 1.3;
            word-break: break-word;

            &.disabled {
                cursor: not-allowed;
                box-shadow: none !important;
                height: 1.75rem !important;
                overflow: hidden !important;
            }

            &:empty:before{
                content:attr(data-placeholder);
                color:#b7b7b7;
            }

            &:focus {
                transition: .3s;
                height: auto;
                overflow-y: auto;
            }
        }
        
        &:focus {
            position: relative;
            z-index: 2;
            // box-shadow: 0px 0px 5px -1px grey !important;
        }

        &::placeholder {
            color: #33333370;
        }

        // &:focus {
        //     box-shadow: none;
        // }
    } 


    .vertical-separator {
        position: relative;
        // top: 5px;
        width: 0.125rem;
        height: 1.5rem;
    }

    .custom-panel-toggler {
        bottom: unset;
        box-shadow: none;
        z-index: 2;
    }
    .ant-select-selection-item {
        svg {
            height: 1.125rem;
            width: 1.125rem;
        }
    }
}
.pulse-col-draw {
    height: calc(100% - 3.125rem);
    top: 3rem;
    z-index: 1;
    outline: none !important;

    &.home-page {
        height: calc(100% - 2px);
        top: 0;
    }

    .ant-drawer-mask {
        opacity: 0 !important;
    }

    .ant-drawer-header {
        padding: 0 !important;
    }

    .ant-drawer-body {
        padding: 0 !important;
        // padding: 16px 15px;
    }

    .custom-simple-landing-dgt {
        overflow-x: hidden;
    }
    
    .pod-col-drawer-srch {
        padding: 0.375rem 0.25rem;
        .custom-search > input {
            border: none;
        }
    }
}
.col-li-panel {
    .rdg-cell {
        border: none !important;
    }
}

.pulse-search-container {
    position: relative;
}

#pulseTalkSearchInp {
    &:disabled {
        font-size: 1rem !important;
    }
}

.pulse-col-strip {
    position: fixed;
    display: flex;
    width: 8.125rem;
    justify-content: center;
    background: #fff;
    border: solid 0.0625rem #d1cdcd;
    padding: 0.1875rem 0.9375rem;
    border-radius: 0 0 0.625rem 0.625rem;
    font-size: 0.75rem;
    // font-family: "InterSemiBold";
    cursor: pointer;
    top: 13.3rem;
    // top: 10.875rem;
    left: -3.25rem;
    z-index: 1040;
    transform: rotateZ(-90deg);

    &.docs {
        width: 9.125rem;
        left: -3.8rem;
    }

    &:hover {
        background: #cacaca;
    }
}

.pulse-act-btn {
    height: 2rem;
}

.pulse-search-dm-ic {
    svg {
        height: 1.125rem;
        width: 1.125rem;
    }
}

.pulse-srch-pod-li-draw-ic {
    svg {
        height: 1rem;
        width: 1rem;
    }
}