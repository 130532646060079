@import "../../../../assets/scss/common/colors";
@import "../../../../assets/scss/common/variables";
body {
    .landingPage {
        .landing-list-main {
            background-color: transparent;
            padding: $padding10;

            .landing-accordion-list {
                margin-bottom: $margin10;
                background-color: $white;
                .custom-accordion .ant-collapse .ant-collapse-header {
                    & span > svg {
                        height: 18px;
                        width: 18px;
                    }
                    font-size: 13px !important;
                }
                .ant-collapse-item {
                    border-bottom: 1px solid #dedede !important;
                }
            }

            .card-tags {
                background-color: $white;
                box-shadow: 1px 2px 4px rgba($color: #000000, $alpha: 0.25);
                border-radius: 3px;
                width: $width100 * 3;
                position: $p-absolute;
                z-index: 2;
                right: 70px;
                top: 0;

                .card-tag-header {
                    display: flex;
                }
                .card-tags-auto-overflow {
                    max-height: 12.5rem;
                    overflow: auto;
                }
            }

            .landinggridview-responsive {
                display: grid;
                gap: 0.625rem;
                grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
                // grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
                margin: 0;
                padding: $padding10;
                position: $p-relative;

                .card-tags {
                    bottom: auto;
                    top: 40px;
                    right: 30px;
                }

                & > div:not(:last-child) .card::after {
                    content: "";
                    display: block;
                    width: 2px;
                    height: 60%;
                    background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
                    position: absolute;
                    top: 20%;
                    right: -0.375rem;
                }

                // & > div:not(:last-child) .card ::before {
                //     content: "";
                //     display: block;
                //     width: 60%;
                //     height: 2px;
                //     background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
                //     position: absolute;
                //     bottom: -15px;
                //     left: 50%;
                //     transform: translateX(-50%);
                // }

                .publish-tag-pod {
                    box-sizing: border-box;
                    margin: 0;
                    padding: 2px;
                    color: #FFFF;

                    font-size: 0.625rem !important;
                    line-height: 1.0625rem !important;
                    list-style: none;

                    display: inline-block;
                    height: auto;
                    margin-inline-end: 0.5rem;
                    padding-inline: 0.4375rem;
                    white-space: nowrap;
                    background-color: #2c83c4;
                    border: 1px solid #d9d9d9;
                    border-radius: 0.625rem !important;
                    opacity: 1;
                    transition: all 0.2s;
                    text-align: start;
                    position: relative;
                }

                .unstructured-tag {
                    position: relative;
                    border-radius: 0 !important;
                    margin-right: 1.3rem;
                    border-right: none !important;
                    padding-right: 1.1875rem;
                    background: rgb(191, 228, 255);
                    clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0 100%, 0 0);
                    // background: linear-gradient(45deg, rgb(191, 228, 255) 64px, transparent 33px), linear-gradient(135deg, rgb(191, 228, 255) 64px, transparent 30px) !important;


                    /* &::before {
                        content: '';
                        display: block;
                        width: 10px;
                        height: 12px;
                        background: linear-gradient(135deg, #bfe4ff 50%, transparent 50%);
                        position: absolute;
                        right: -11px;
                        top: -1px;
                    }
                    &::after {
                        content: '';
                        display: block;
                        width: 10px;
                        height: 12px;
                        background: linear-gradient(45deg, #bfe4ff 50%, transparent 50%);
                        position: absolute;
                        right: -11px;
                        bottom: -1px;
                    } */
                }

                .card {
                    position: relative;
                    border-radius: 3px;
                    border: none;
                    // border: 1px solid #82828236;

                    // border: 1px solid $grey-three;
                    cursor: pointer;
                    min-height: 11.0625rem;
                    // min-height: 154px;
                    transition: box-shadow 0.2s ease-in-out;

                    &:hover {
                        box-shadow:
                            0 1px 2px -2px rgba(0, 0, 0, 0.16),
                            0 3px 6px 0 rgba(0, 0, 0, 0.12),
                            0 5px 12px 4px rgba(0, 0, 0, 0.09);
                    }
                }

                .card-body {
                    padding: $padding10;
                }

                .landingCard-circle {
                    display: flex;
                    justify-content: space-between;
                    position: $p-relative;
                    margin-bottom: 10px;

                    &::after {
                        content: "";
                        position: $p-absolute;
                        height: 1px;
                        background-color: $light-background;
                        left: -10px;
                        right: -10px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    .icon-box {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        border: 1px solid $light-background;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $white;
                        z-index: 1;

                        img {
                            height: $height50;
                            width: auto;
                        }
                    }

                    .landingCard-profile-list {
                        display: flex;
                    }
                }

                .landingCard-actions {
                    display: flex;
                }

                .landingCard-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: $margin10;

                    p {
                        margin: 0;
                    }
                }

                .landingCard-description {
                    margin-bottom: $margin10;
                    & p {
                        min-height: 20px !important;
                    }
                    & p span {
                        font-size: 11px !important;
                    }
                }

                .landingCard-profile-list {
                    .landingCard-profile-listitem {
                        display: flex;
                        align-items: center;
                        margin-bottom: $margin10;

                        svg {
                            height: 0.875rem;
                            width: 0.875rem;
                        }

                        & :is(.label, .paragraph) {
                            font-size: 0.625rem !important;
                        }
                        & .label {
                            opacity: 0.8 !important;
                            width: 4.375rem;
                        }
                        .label {
                            margin: 0 0.1875rem;
                        }

                        span {
                            &:last-child {
                                width: calc(100% - 6.625rem);
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
    }
    .unstructured-coming-soon {
        .cmg-soon-content-wrap {
            margin: 0 !important;
        }
    }
    .label-gray {
        color: #828282;
    }

    .catalog-grid-index-lp {
        .no-data-component-lg {
            height: 100% !important;
        }
    }
}

.site-tree-search-value {
    color: #f50;
}

[data-theme="dark"] .site-tree-search-value {
    color: #d84a1b;
}
.catalog-landing-acc-head {
    & > span:first-child {
        margin-right: 8px;
        position: relative;
        top: -1px;
    }
}
.transformer-configure-modal .landingPage .landing-list-main .landinggridview-responsive .publish-tag-pod {
    font-size: 0.5625rem !important;
    // margin-inline-end: 0px !important;
}

.unstructured-doc-collapse, .catalog-grid-collapse {
    .ant-collapse-content-box {
        padding-inline: 0 !important;
    }
}