.user-group-modal {
  .modal-header {
    .modal-title {
      line-height: 0 !important;
    }
  }

  .podtag-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
