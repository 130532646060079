/**
 * @Files
 * Variables Style.
 */
// Border Zero
@import "./colors";
$border-none: 0;
//border radius
$border-radius-none: 0;
$border-radius-5: 0.3125rem;
$border-radius-10: 0.625rem;
$border-radius-20: 1.25rem;
$border-radius-50percent: 50%;
$border-radius-100percent: 100%;
// Cursor
$c-auto: auto;
$c-pointer: pointer;
$c-default: default;
$c-pointer-none: none;
$c-text: text;
$c-inherit: inherit;
$c-not-allowed: not-allowed;
// Display
$d-none: none;
$d-block: block;
$d-inline: inline;
$d-inline-block: inline-block;
$d-inherit: inherit;
$d-table: table;
$d-table-cell: table-cell;
// Float's
$f-left: left;
$f-right: right;
$f-none: none;
// Font Size's
$font12: 0.6875rem;
$font14: 0.8125rem;
$font16: 0.9375rem;
$font20: 1.0625rem;
$fontinherit: inherit;
$font875: 0.875rem;
$font1: 1rem;
// Font Style
$fs-italic: italic;
$fs-normal: normal;
// Font Weight
$fw-bold: bold;
$fw-normal: normal;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;
// Height
$height-vh: 100vh;
$height-full: 100%;
$height-auto: auto;
$height10: 0.625rem;
$height50: 3.125rem;
$height70: 4.375rem;
$height5-rem: 5rem;
// letter spacing Zero
$letter-spacing: 0;
// Line Height
$lh-default: 1.2;
$lh-18: 1.125rem;
$lh-normal: normal;
$lh-inherit: inherit;
$lh5-rem: 5rem;
// List Style
$list-style-none: none;
// Margin
$margin0: 0rem;
$margin5: 0.3125rem;
$margin10: 0.625rem;
$margin5-rem: 5rem;
$margin-auto: auto;
// Opacity
$opacity1: 1;
$opacity0: 0;
// Overflow
$o-auto: auto;
$o-visible: visible;
$o-hidden: hidden;
$o-scroll: scroll;
// Padding
$padding0: 0;
$padding5: 0.3125rem;
$padding10: 0.625rem;
$padding5-rem: 5rem;
$padding-percent-10: 10%;
// Pointer Events
$pointer-events-none: none;
$pointer-events-all: all;
$pointer-events-auto: auto;
// Position
$p-absolute: absolute;
$p-relative: relative;
$p-fixed: fixed;
$p-static: static;
// Text Align
$t-center: center;
$t-left: left;
$t-right: right;
$t-justify: justify;
// Text Transform
$tt-uppercase: uppercase;
$tt-none: none;
$tt-inherit: inherit;
$tt-capitalize: capitalize;
// Text Decoration
$td-none: none;
$td-underline: underline;
// Text Overflow
$to-ellipsis: ellipsis;
// Vertical Align
$v-middle: middle;
$v-top: top;
$v-baseline: baseline;
$v-bottom: bottom;
// Visiblity
$visibility-hidden: hidden;
$visibility-visible: visible;
// white space
$ws-nowrap: nowrap;
$ws-inherit: inherit;
$ws-normal: normal;
// Width
$width-full: 100%;
$width-auto: auto;
$width10: 0.625rem;
$width50: 3.125rem;
$width70: 4.375rem;
$width100: 6.25rem;
$width5-rem: 5rem;
// Word Wrap
$wb-break-all: break-all;
$ww-break-word: break-word;
body {
    .padding-2 {
        padding: $padding10;
    }

    .padding-1 {
        padding: $padding5;
    }

    .padding-top-1 {
        padding-top: $padding5;
    }

    .padding-right-1 {
        padding-right: $padding5;
    }

    .padding-bottom-1 {
        padding-bottom: $padding5;
    }

    .padding-left-1 {
        padding-left: $padding5;
    }

    .padding-top-2 {
        padding-top: $padding10;
    }

    .padding-right-2 {
        padding-right: $padding10;
    }

    .padding-bottom-2 {
        padding-bottom: $padding10;
    }

    .padding-left-2 {
        padding-left: $padding10;
    }

    .padding-right-40 {
        padding-right: $padding10 * 4;
    }

    .margin-right-2 {
        margin-right: $margin10;
    }

    .margin-top-2 {
        margin-top: $margin10;
    }

    .margin-left-2 {
        margin-left: $margin10;
    }

    .margin-bottom-2 {
        margin-bottom: $margin10;
    }

    .fs-3 {
        font-size: $font20 + 10;
    }

    .fs-25 {
        font-size: $font20 + 5;
    }

    .custom-border-top {
        border-top: 0.0625rem solid $light-background;
    }

    .custom-border-bottom {
        border-bottom: 0.0625rem solid $light-background;
    }

    .custom-border-right {
        border-right: 0.0625rem solid $light-background;
    }

    .custom-border-left {
        border-left: 0.0625rem solid $light-background;
    }

    .error-red {
        color: $error-red;

        svg {
            path  {
                fill: $error-red;
            }
        }
    }
    .red-border {
        border: 0.0625rem solid $error-red;
    }
}