.lpg-domains-tab {
    overflow: hidden;
    padding-right: 0.75rem;
    // max-width: 44.5rem;
    .ant-tabs-tab {
        padding: 0.375rem 0.9375rem 0.375rem 0.625rem !important;
        border: solid 1px #d6d6d6;
        border-radius: 2.5rem;
    }

    .ant-tabs-tab:hover {
        span {
            color: #2c83c4! important;
        }
        svg path {
            fill: #2c83c4! important;
        }
    } 

    .ant-tabs-tab.ant-tabs-tab-active span {
        color: #2c83c4! important;
    }

    .ant-tabs-tab.ant-tabs-tab-active svg path {
        fill: #2c83c4! important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        border: solid 1px #2c83c4;
    }

    .ant-tabs-nav::before {
        border: none;
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 1rem !important;
    }

    .ant-tabs-nav-more {
        display: block;
        width: 1.875rem;
        height: 1.875rem;
        padding: 0 !important;
        line-height: 0rem;
        font-size: 1rem;
        font-weight: bold;
        color: #333 !important;
        border-radius: 100%;
        transform: rotateZ(90deg);
        border: solid 1px #d6d6d6 !important;
        top: 0.25rem;
        left: 0.625rem;
    }

    .ant-tabs-nav-list .ant-tabs-tab-btn svg {
        height: 1.125rem;
        width: 1.5rem;
    }
}
.ant-tabs-dropdown-menu-item {
    svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
    }
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
    margin: 0.3125rem;
}
.dm-search-li-wrap {
    display: grid;
    grid-template-columns: 2.25rem auto;
    gap: 1rem;
    
    &.active {
        grid-template-columns: minmax(auto, 12.5rem) auto !important;
    }

    .custom-search input {
        height: 2.25rem !important;
        border-radius: 12.5rem !important;
        border-color: #d6d6d6 !important;
        padding-left: 1.875rem !important;
        padding-right: 1.5rem !important;
    }

    .custom-search-icon {
        left: 0.625rem !important;
    }

    .custom-close-icon {
        display: none !important;
        right: 0.75rem !important;
    }

    .dm-search-close {
        cursor: pointer;
        position: absolute;
        right: 0.625rem;
        top: 0.625rem;
        display: flex;
        height: 0.875rem;
        width: 0.875rem;
        align-items: center;
        justify-content: center;

        &:hover {
            svg path {
                fill: #2c83c4;
            }
        }

        svg {
            height: 0.5625rem;
            width: 0.5625rem;
        }
    }
}
.dm-toggle-search {
    cursor: pointer;
    display: flex;
    width: 2.25rem;
    height: 2.25rem;
    align-items: center;
    justify-content: center;
    border: solid 1px #d6d6d6;
    border-radius: 100px;

    &:hover {
        svg path {
            fill: #2c83c4;
        }
    }

    svg {
        height: 1.125rem;
    }
}