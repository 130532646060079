
.unst-file-opt {
    cursor: pointer;

    &.disabled {
        opacity: .8;
        cursor: not-allowed;
    }

    &:not(.disabled):hover {
        background: #ebebeb;
    }
}

.doc-file-name-type {
    svg {
        height: 1rem;
        width: 1rem;
    }
}

.unst-quick-preview-drawer {
    .ant-drawer-body {
        position: relative;
    }
}