@import "../../../../../src/assets/scss/common/colors";
@import "../../../../../src/assets/scss/common/font";
@import "../../../../../src/assets/scss/common/variables";

.role-active {
    position: relative;
    background-color: #dee2e678; // #DDEDFF;

    .ant-list-item-meta-title {
        color: #2c83c4 !important;
        font-weight: 700;
    }
}

.permission-div .custom-accordion .ant-collapse .ant-collapse-header {
    padding: 0.375rem 0rem !important;
    border-bottom: 0.0625rem solid #d3d3d3;
}

.icon-black {
    svg * {
        stroke: black !important;
    }
}

.without-background {

    background: transparent !important;

}

.role-guide {
    ul {
        li {
            padding-left: 1.25rem;
        }
    }
}

.permission-div {

    .ant-col{
        font-family:  "InterRegular" !important;

    }
    background-color: #ffff;
    .custom-accordion {
        height: 77.4vh;
    }

    .mod_ic {
        svg {
            height: 0.9375rem;
            width: 0.9375rem;
            margin-right: 0.1875rem;
        }
    }
    .ant-collapse-header {
        background: #f0f0f08c;

        // .ant-collapse-header {
        //     background: #f2f2f2;

        // }
    }

    .custom-accordion .ant-collapse .ant-collapse-item {
        border-bottom-color: #b1b1b1;
    }
}

.roles-component {
    .ant-list{
        font-family: "InterRegular" !important;

    }
    
    .ant-list.ant-list-split {
        height: 100%;

        .ant-spin-nested-loading {
            height: 93%;

            .ant-spin-container {
                height: 100%;
                overflow: auto;
            }
        }
    }
    .ant-list-item{
        padding: 0.625rem !important;
    }
    .ant-list-item-action-split {
        width: 0rem !important;
    }

    .form_left {
        .ant-list-item-action {
            margin-left: 0.1875rem !important;
        }
    }

    .ant-list-item-meta-title {
        word-break: break-all !important;
    }
}


.active-icon {
    svg {
        path {
            fill: white;
        }
    }
}

.role-active::before {
    content: "";
    position: absolute;
    width: 0.125rem;
    top: 0.625rem;
    left: -0.0625rem;
    bottom: 0.625rem;
    background-color: #2c83c4;
    border-radius: 0 0.25rem 0.25rem 0;
}

.entitlement {

    .ant-checkbox .ant-checkbox-inner {
        border: 0.125rem solid #bababa;
    }
}

.roles-component {
    height: calc(100vh - 4.3125rem);

    .ant-list-header {
        padding: 0;
    }
}

.form_left .ant-spin-container .ant-list-item:not(:last-child) {
    border-bottom: 0.0625rem solid #f0f0f0 !important;
}

.full-height-dropdown {
    .ant-select {
        .ant-select-selector {
            border: 0.0625rem solid transparent !important;
            border-radius: 0.1875rem !important;
            height: 100% !important;
            padding: 0 $padding10 !important;
            font-size: $font12 + 1;
            background-color: $primary;

            .ant-select-selection-item {
                color: $white;
                background-color: #2c83c4;

                svg {
                    color: white !important;

                    path {
                        fill: white !important;
                    }
                }
            }
        }

        &.ant-select-focused {
            .ant-select-selector {
                box-shadow: none !important;
                border-color: $primary !important;
            }
        }
    }
}
.new-ent-onboard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem
}
.onboard-usrs-li {
    border-top: solid 0.0625rem #d4d4d4;
    margin-top: 0.75rem;
    height: calc(100% - 4.875rem);
    overflow: auto;
    & .usrs-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.625rem;
        padding: 0.5rem 0;
        border-bottom: solid 0.0625rem #d4d4d4;
        border-left: solid 0.1875rem transparent;
        transition: .3s;

        svg {
            height: 1.25rem;
            width: 1.25rem;
        }

        &:hover {
            padding-left: 0.625rem;
            border-left: solid 0.1875rem #2c83c4;
            transition: .3s
        }
    }
}

.editor-viewer-ic {
    svg {
        height: 1.125rem;
        width: 1.125rem;
    }
}