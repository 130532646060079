
.custom-biz-input.searching {
    background: linear-gradient(to right, #dcdcdc, #f9f9f9, #dcdcdc);
    animation: loading-gradient 1.5s linear infinite;
    background-size: 200%;
}
.custom-biz-input input {
    font-size: 8px !important;
    font-weight: 600;
    padding: 0 !important;
    &:is(:disabled, :read-only) {
        font-size: 11px !important;
        font-weight: normal;
        color: inherit !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
.custom-biz-input i {
    font-size: 10px;
}
.custom-biz-input {
    padding: 0 10px;
    border-radius: 3px;
}
.best-match-biz-terms {
    max-height: 16.5rem;
    overflow-y: auto;
    width: 15rem
}
.best-match-biz-terms-header {
    padding: .5rem;
}
.best-match-biz-terms-header h5 {
    font-size: 0.8125rem;
    font-weight: 600;
    margin: 0;
}
li.bst-match-biz-term-li {
    &:hover {
        background: #155e9412;
    }
    &.active {
        background: #155e9412;
        border: solid 1px #155e94 !important;
        & .terms-txt {
            color: #155e94;
        }
    }
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.6875rem;
    text-transform: uppercase;
    & .catTag {
        // background: #c4f0f5;
    }
    & .clsTag {
        // background: #ffebc5;
    }
    & :is(.catTag, .clsTag) {
        font-size: 0.5rem !important;
    }
    .terms-txt {
        font-size: 0.625rem !important;
    }
    & label {
        margin-bottom: 0;
        font-size: 0.5rem;
        color: #707070;
        font-weight: 600;
        text-transform: capitalize;
    }
}
.best-match-biz-terms > li:not(:last-child) {
    border-bottom: solid 1px #ececec;
}

.bst-mtch-rd {
    .ant-radio-button-wrapper {
        font-family: 'InterSemiBold' !important;
        font-size: 0.6875rem;
    }
    .ant-radio-button-wrapper-checked {
        color: #2C83C4 !important;
        border-color: #2C83C4 !important;
    }
}

@keyframes loading-gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: -100% 50%;
    }
    100% {
        background-position: -200% 50%;
    }
}