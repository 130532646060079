.int-dictionary-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22.5rem, 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    gap: 0.625rem;
}

.notes-section {
    font-size: 0.875rem;
}
.data-dict-list-table {
    & .custom-simple-landing-dgt {
        overflow-x: auto !important;
    }
    .custom-badge {
        line-height: 1.3 !important;

        .text-elipse {
            white-space: nowrap;
            width: 5rem;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                white-space: nowrap;
                width: 5rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.data-dictionary-detail-left {
    height: 100%;
    overflow: hidden;
    // height: calc(100vh - 5.625rem);
    // overflow-y: auto;
    // overflow-x: hidden;
    padding-bottom: 0.3125rem;

    &::-webkit-scrollbar {
        display: none;
    }
}

.podsdomain-acc-list-data-dict {
    & .ant-collapse .ant-collapse-item {
        border-bottom: 0.0625rem solid #f3f3f3 !important;
    }
}

.changelog-action-ic {
    display: flex;
    width: 2rem;
    height: 2rem;
    background: #2c83c4;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0.0625rem 0.125rem 0.25rem #00000040;

    & svg {
        height: 0.75rem;
    }
    & path {
        fill: #fff;
    }
}

.chnglog-tmln {
    .ant-timeline-item-content {
        top: -0.5625rem !important;
    }
}

// .manual-fields-horizontal-title {
//     & > div:first-child {
//         position: sticky;
//         left: 0;
//         z-index: 1;
//         background: #fff;
//     }
// }
// .manual-fields-horizontal {
// & > div:first-child {
//     position: sticky;
//     left: 0;
//     z-index: 1;
//     background: #fff;
// }
//     & > div:last-child {
//         position: sticky;
//         right: 0;
//         z-index: 1;
//         background: #fff;
//     }
// }

.data-catgy-settings-ls {
    & > div {
        height: calc((100vh - 6.0625rem) / 2);
    }
}

.class-acc-ic {
    & > svg {
        border: 0.0625rem solid #17171a;
        padding: 0.125rem;
        border-radius: 0.1875rem;
        height: 1.75rem;
        width: 1.75rem;
        transition: 0.3s;
    }
}

.ant-collapse-item-active {
    .class-acc-ic {
        & > svg {
            transform: rotateX(180deg);
            transition: 0.3s;
        }
    }
}

.custom-acc-classification {
    .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-header {
        background-color: #fff !important;
    }
}

.category-ddl {
    width: calc(100% - 1.875rem);
    // flex: 0 0 calc(100% - 16.375rem);
    
    // min-width: 10rem;
    & .element-name {
        display: inline;
        padding: 0.25rem 0.75rem;
        font-weight: 700;
        font-size: 0.625rem;
        background: rgba(76, 175, 80, 0.1);
        // border: 0.0625rem solid #4caf50;
        color: #4caf50;
        text-transform: uppercase;
        border-radius: 0.1875rem;
    }
}

.dict-main-wrap {
    height: calc(100vh - 2.375rem);
    overflow-y: auto;
}

.dict-grid-card-sett {
    &:hover {
        box-shadow: 0rem 0.3125rem 1.125rem -0.375rem #00000040;
    }

    & label {
        line-height: 1.4 !important;
    }

    & .custom-badge {
        line-height: 1 !important;

        .text-elipse {
            white-space: nowrap;
            max-width: 5rem;
            min-width: 3.125rem;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (max-width: 90.625rem) {
                max-width: 3.125rem;
                min-width: 1.875rem;
            }

            span {
                white-space: nowrap;
                max-width: 5rem;
                min-width: 3.125rem;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width: 90.625rem) {
                    max-width: 3.125rem;
                    min-width: 1.875rem;
                }
            }
        }
    }
    & .ant-avatar:nth-child(3) {
        color: #787676 !important;
        font-weight: 700;
    }
    & span.ant-avatar.ant-avatar-circle {
        height: 1.25rem;
        width: 1.25rem;
        margin: 0rem 0.1875rem 0rem -0.4375rem;
        display: flex;
        align-items: center;
        line-height: 1.5;
        font-size: 0.5rem;
        // text-shadow: 0rem 0rem 0.1875rem #000;
    }
}

.custom-dict-search-box {
    & .ant-input-affix-wrapper {
        border-top-left-radius: 3.125rem !important;
        border-bottom-left-radius: 3.125rem !important;
    }

    & .ant-input-search-button {
        border-color: #2c83c4;
        background-color: #2c83c4;
        border-top-right-radius: 3.125rem !important;
        border-bottom-right-radius: 3.125rem !important;
    }

    & .ant-input-clear-icon {
        display: flex;
    }

    .ant-btn.ant-btn-lg {
        font-size: 1rem;
        height: 2.5rem;
        padding: 0.375rem 0.9375rem;
        border-radius: 0.5rem;
    }

    .ant-input-prefix {
        svg {
            height: 1.25rem;
            width: 1.25rem;
        }
    }

    .ant-input-affix-wrapper >input.ant-input {
        padding: 0;
        font-size: 1rem;
    }
}

.dict-lg-page {
    .custom-content-dgt {
        border: none !important;
    }
}

.selected-border {
    box-shadow: 0rem 0.0625rem 0.25rem #2c83c4 !important;
}

:is(.dict-grid-card-sett, .data-dict-list-table) .custom-badge {
    font-size: 0.625rem;
}

.dict-grid-card-sett .label {
    font-size: 0.625rem;
}

.dict-grid-card-sett .paragraph {
    font-size: 0.75rem !important;
}

.dict-grid-card-sett .card-header {
    padding: 0.3125rem 0.625rem;
    background: white;
}

.dict-grid-card-sett svg {
    height: 1rem;
}

.data-dictionary-detail-left label {
    font-size: 0.75rem !important;
}

.dict-detail-header {
    & svg {
        height: 0.9375rem;
    }
}

.dict-setting-pg-wrap {
    .card-header {
        font-size: 0.8125rem;
    }
}

// .category-table {
//     .ant-avatar {
//         box-sizing: border-box;
//         margin: 0.1875rem;
//         padding: 0;
//         color: rgba(0, 0, 0, 0.85);
//         font-size: 0.875rem;
//         font-variant: tabular-nums;
//         line-height: 1.5715;
//         list-style: none;
//         font-feature-settings: 'tnum', "tnum";
//         position: relative;
//         display: inline-block;
//         overflow: hidden;
//         color: #fff;
//         white-space: nowrap;
//         text-align: center;
//         vertical-align: middle;
//         background: #ccc;
//         width: 1.75rem;
//         height: 1.75rem;
//         line-height: 1.75rem;
//         border-radius: 50%;
//     }

//     .ant-avatar-group .ant-avatar {
//         border: 0.125rem solid #fff;
//     }
// }
.dict-bizterm-width {
    min-width: 10rem;
    max-width: 11.25rem;
    @media screen and (max-width: 90.625rem) {
        min-width: 4.375rem;
        max-width: 6.25rem;
    }
}
.dict-detail-header {
    font-size: 0.8125rem;
}
.custom-content-dgt {
    background: #fff !important;
}
.cat-modal-set {
    & > div {
        padding: 0 0.625rem;
    }
}
#dict-detailed-pg {
    :is(.custom-input-field, textarea, .ant-select-selector) {
        border-left: none !important;
        border-top: none !important;
        border-right: none !important;
        border-bottom: solid 0.0625rem #e6e6e6;
        border-radius: 0 !important;
        padding-left: 0.3125rem !important;
        padding-right: 0.3125rem !important;

        &:focus {
            box-shadow: none !important;
            border-color: #2C83C4 !important;
        }
    }

    .ant-picker {
        height: 1.75rem !important;

        &.ant-picker-disabled {
            border: none !important;
            padding: 0 !important;

            .ant-picker-input {
                & input {
                    color: #0000009c;
                    padding: 0 !important;
                }
            }
            .ant-picker-suffix {
                display: none !important;
            }
        }
    }

    .health-tbl-wrap .ant-select-selector {
        height: 1.875rem !important;
    }

    .custom-select-dropdown {
        .ant-select-selector {
            padding-left: 0 !important;
        }
    }

    &.hide-change-log {
        & > div {
            & > div.w-75 {
                transition: 0.3s;
                width: 100% !important;
            }
            & > div.w-25 {
                width: 0% !important;
            }
        }
        .data-dictionary-detail-right {
            transition: 0.4s;
            transform: rotateY(90deg);
        }
    }
    &.show-change-log {
        & > div {
            & > div.w-75 {
                transition: 0.3s;
                width: 75%;
            }
            & > div.w-25 {
                width: 25%;
            }
        }
        .data-dictionary-detail-right {
            transition: 0.4s;
            transform: rotateY(0deg);
        }
    }
}

.section-heading-dark {
    color: #275A89 !important;
    font-size: 0.8125rem !important;
    font-family: 'InterSemibold' !important;
}

.dict-listing-page {
    .custom-content-dgt {
        overflow-x: scroll !important;
    }
    .custom-simple-landing-dgt {
        grid-template-columns: 17.5rem 10.5rem auto 13.625rem !important;
    }
}

#dict-detailed-pg {
    .first-element-col {
        width: 5.9375rem; 
        flex: 0 0 5.9375rem
    }
    .aside-ele {
        .first-element-col {
            width: 5.625rem; 
            flex: 0 0 5.625rem
        }   
        &.edit .first-element-col {
            width: 6.75rem; 
            flex: 0 0 6.75rem
        }   
    }
}

.dict-custom-hd-ln {
    width: 100%;
    // width: 46.5625rem;
}

.data-dictionary-detail-left {
    & .notes-section {
        // width: 45.75rem;
    }
}
.add-det-card .classificationTag {
    border-color: #2c83c4;
    background: #d6ecff;
    color: #2c83c4;
}
// .dict-detail-cls-list {
//     .custom-simple-landing-dgt {
//         grid-template-columns: 16.375rem minmax(28.125rem, auto) auto !important;
//     }
// }
#data-dict-select-category {
    .custom-select-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
        height: 2rem !important;
    }
}
.dict-setting-pg-wrap {
    .setting-category-table {
        // .custom-simple-landing-dgt {
        //     grid-template-columns: 6.25rem 180.3289rem 10.0625rem 150.3922rem 6.25rem !important;
        // }
    }
    .setting-additionaldetail-table {
        // .custom-simple-landing-dgt {
        //     grid-template-columns: 6.25rem 180.3289rem 10.0625rem 150.3922rem 6.25rem !important;
        // }
    }
    .setting-classification-table {
        height: calc(100vh - 5.625rem);
        // .custom-simple-landing-dgt {
        //     grid-template-columns: auto !important;
        // }
    }
}
.dict-lg-page {
    .ant-input-affix-wrapper {
        height: 2.5rem !important;
    }
}

.dict-header-bg {
    background: #EEFAFF !important;
}

.dict-detail-table {
    .custom-simple-landing-dgt .rdg-cell {
        border: none;
    }
}

.dict-color-dark {
    color: #0A3053 !important;
    font-family: 'InterSemibold' !important;
}

#biz-desc-input {
    font-family: 'InterSemibold' !important;
}

#dict-detail-fram {
    input, textarea, .element-name {
        font-family: 'InterSemibold' !important;
    }
}

.dict-rem-class {
    position: relative;
    top: -0.0625rem;
    right: -0.1875rem;
    cursor: pointer;
    transition: .3s;
    &:hover {
        path {
            fill: #0A3053;
        }
        transition: .3s;
    }
}

.dict-table-nm {
    display: block;
    position: absolute;
    top: 0;
    left: 1.25rem;
    width: calc(100% - 2.5rem);
}

.dict-timeline-drawer {
    .ant-drawer-body {
        padding: 0rem 0;
    }
}

.dict-line-height {
    line-height: 1.4 !important;
}

.dict-new-nm {
    width: 15.625rem !important;
}

.dict-addfield-tag {
    line-height: 1.4;
    background: #eef8ff;
    padding: 0.3125rem 0.5rem;
    font-family: 'InterSemibold';
    border-radius: 0.1875rem;
    font-size: 0.625rem;
}

.dict-loading-overlay {
    position: fixed;
    top: 2.1875rem;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    background: #00000060;
}

.biz-term-primary-info {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.25rem;
    column-gap: 1.875rem;
}

.dict-detail-tab {
    font-size: 0.875rem;
    .ant-tabs-content {
        & > div:first-child {
            height: calc(100vh - 8.25rem);
            overflow-y: auto;
        }
    }
    .ant-tabs-nav-list .ant-tabs-tab-btn svg {
        height: 1rem;
        width: 0.875rem;
        margin-right: 0.3125rem;
    }
    .ant-tabs-tab {
        padding: 0rem 0rem 0.625rem 0rem !important;
        .ant-tabs-tab-btn {
            font-family: 'InterSemiBold' !important;
            color: #333333cf !important;
            font-size: 0.6875rem !important;
        }
        svg path {
            fill: #333333cf !important;
        }
    }
    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 2rem;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: #0A3053 !important;
        }
        svg path {
            fill: #0A3053 !important;
        }
    }
    .ant-tabs-ink-bar {
        height: 0.1875rem !important;
        background: #2C83C4 !important;
    }
    &.dict-settings {
        .ant-tabs-content {
            & > div {
                height: calc(100vh - 5.5rem);
                overflow-y: auto;
            }
        }
        .ant-tabs-nav {
            margin-bottom: 0;
        }
        .ant-tabs-tab {
            padding: 0rem 0rem 0.625rem 0rem;
            .ant-tabs-tab-btn {
                font-family: 'InterSemiBold' !important;
                color: #333333 !important;
                font-size: 0.6875rem !important;
            }
            svg path {
                fill: #333333 !important;
            }
        }
    }
}

.biz-stats-grid {
    display: grid;
    grid-template-columns: 18.75rem auto;
    // column-gap: 0.625rem;
}

.dict-logs-tbl-wrap {
    .custom-simple-landing-dgt {
        grid-template-columns: 5rem 5.625rem 3.75rem !important;
    }
}

.bizterm-stats-wrap {
    height: calc(100vh - 8.375rem);
}

.stats-label-color {
    color: #275A89;
}

.dict-log-ts {
    flex: 0 0 100%;
    span {
        display: block;
        line-height: 1 !important;
    }
}

.dict-stats-prg {
    .custom-prog {
        height: 0.625rem;
        // & > span:nth-child(2) {
        //     background: #F74F2A;
        // }
    }
}

.dict-prg-data {
    display: block;
    width: 100%;
    padding: 0.375rem 0.625rem;
    & > div {
        line-height: 1rem;
    }
}

.dict-health-list-wrap .rdg-cell {
    border: none !important;
}

.dict-hide-scroll {
    .custom-simple-landing-dgt {
        &::-webkit-scrollbar {
            display: none;
        }
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }
}

.dict-prev-tbl {
    .data-grid-shrink {
        height: calc(100% - 4rem) !important;
    }
    &.custom-virtual-data-table .rdg-row > .rdg-cell:first-child {
        background: #daeeff;
        // border-left: solid 0.125rem #2c83c4;
        // border-right: solid 0.125rem #2c83c4;
        border-bottom: solid 0.0625rem #fff !important;
        font-family: "InterSemibold" !important;
        color: #0A3053;
    }
}

.dict-middle-sep {
    border-left: solid 0.0625rem #f0f0f0;
    // border-right: solid 0.0625rem #f0f0f0;
}

.dict-cat-li-wrap {
    .dict-categories-items {
        height: calc(100vh - 5.5rem);
    }
}
.dict-cls-li-wrap {
    .dict-classifiers-items {
        height: calc(100vh - 5.5rem);
        
        // .custom-simple-landing-dgt {
        //     grid-template-columns: 15.625rem minmax(15.625rem, auto) 6.25rem 7.5rem 7.5rem 6.25rem !important;
        // }
    }
}
.dict-addfields-li-wrap {
    .dict-addfields-items {
        height: calc(100vh - 5.5rem);
    }
}

.ant-avatar-group .ant-avatar {
    font-family: 'InterSemiBold';
}
.ant-popover-inner-content .ant-avatar {
    font-family: 'InterSemiBold';
    line-height: 1.5625rem;
}
.dict-custom-avt {
    .ant-avatar {
        font-size: 0.5625rem !important;
    }
    .ant-avatar-group .ant-avatar:nth-child(1) {
        background: #87D068 !important;
    }
    .ant-avatar-group .ant-avatar:nth-child(2) {
        background: #F56A00 !important;
    }
    .ant-avatar-group .ant-avatar:nth-child(3) {
        background: #1677FF !important;
    }
    .ant-avatar-group .ant-avatar:nth-child(4) {
        background: #FDE3CF !important;
        color: #F56A00;
    }
}
.addfield-def-fld {
    .ant-select-selector {
        border-radius: 0.1875rem;
        height: 1.75rem !important;
        font-size: 0.6875rem;
    }
}
.pattern-test-btn {
    display: block;
    font-size: 0.625rem;
    font-family: 'InterSemibold';
    color: #3689c7;
    padding: 0.0625rem 0.1875rem;
    cursor: pointer;
    position: absolute;
    bottom: -1.25rem;
    left: 0;
}
.recognizer-sugg-ddl li.ant-dropdown-menu-item-active {
    background: #a7d9ff !important;
    border-radius: 0 !important;
    font-weight: bold !important;
}
.data-dict-metadata {
    display: grid;
    grid-template-columns: 33% auto;

    @media (max-width: 980px) {
        grid-template-columns: 40% auto;
    }

    /* @media screen and (max-width: 85.375rem) {
        grid-template-columns: 45% auto;
    } */
}
.data-dict-meta {
    &::-webkit-scrollbar {
        display: none;
    }
    & {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
.dict-pii {
    position: absolute;
    top: 0;
    right: 0;
    // left: calc(58% + 1.25rem);
}
.dict-handle-avt {

    & > div {
        flex: 0 0 10rem !important;
    }

    .ant-avatar-group {
        position: relative;
        left: -0.25rem;
    }
}
.biz-term-primary-info {
    .add-field-tbl {
        margin: 0 -0.5rem;
    }
    .add-field-header {
        padding-left: 0 !important;
        background: #fff !important;
    }
}
.health-tbl-wrap {
    .custom-simple-landing-dgt {
        grid-template-columns: repeat(4, 25%) !important;
    }
}
.domain-src-tbl {
    .custom-simple-landing-dgt {
        grid-template-columns: repeat(4, 25%) !important;
    }
}

.health-criteria-flt-ddl {
    min-height: 6.25rem;
    max-height: 10rem;
    overflow-y: auto;
    // width: 18rem !important;
    
    .ant-dropdown-menu {
        height: 100% !important;
    }

    .ant-dropdown-menu li.ant-dropdown-menu-item-active {
        background-color: #65bdff36 !important;
    }
}

.health-criteria-prv-ddl {
    cursor: pointer;
    border-bottom: solid 0.0625rem #e6e6e6 !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;

    input {
        cursor: pointer !important;
    }
}

.health-criteria-sel {
    .ant-select-selection-item {
        font-size: 0.6875rem !important;
        color: #0000009c !important;
        line-height: 1.875rem !important;
    }

    &.ant-select-disabled {
        margin-left: -0.25rem !important;
        .ant-select-selector {
            background: transparent !important;
            border: none !important;
            padding-left: 0 !important;
        }

        .ant-select-arrow {
            display: none !important;
        }
    }
}
.dict-title-strip {
    padding: 0.5rem 0.75rem;
    background: #F7F7F7;
}

.health-tbl-wrap, .domain-src-tbl {
    .lan-sim-tbl {
        padding: 0rem 0.75rem;

        .custom-simple-landing-dgt {
            &::-webkit-scrollbar-track {
                background-color: transparent !important;
            }
        }

    }
}
.domain-src-tbl {
    .lan-sim-tbl {
        height: calc(100vh - 25.6875rem) !important
    }
    .no-datasrc-found {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 25.6875rem) !important
    }
}
.dict-side-pad {
    padding: 0rem 0.75rem;
}
.dictbiz-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
.dict-bizterm-name {
    width: 100%;
    text-wrap: wrap;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0.125rem;
}
.dat-stew {
    .ant-avatar-group {
        left: 0 !important;
        & > .ant-avatar:last-child {
            margin-right: 0 !important;
        }
    }
}
.dict-f-category {
    .categoryTag {
        max-width: 12.5rem !important;
    }
}
.dict-f-classifiers {
    .classificationTag {
        max-width: 12.5rem !important;
    }
    &.edit {
        .classificationTag, .classifier-na {
            margin-left: 0.25rem;
        }
    }
}
.health-tbl-wrap {
    .no-dat-found {
        height: 11.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .lan-sim-tbl  {
        height: 11.375rem !important;
    }
}
#biz-desc-input {
    height: 3.75rem;
}
// .dict-addfield-wrap .add-field-header > .dict-custom-hd-ln > div {
//     margin-left: auto;
// }
// .dict-addfield-wrap {
//     #addFieldHead {
//         position: relative;
//         margin-top: -2.9375rem;
//         background: transparent !important;
//     }
// }
.option-select-customized {
    padding-left: .25rem;
    padding-right: .25rem;
}
.ant-select-selector {
    .option-select-customized {
        padding-left: 0 !important;
    }
}

.dict-health-ref-btn {
    position: absolute;
    top: 0.375rem;
    right: 0.375rem;
}
.total-health-bizterm {
    max-width: 8.125rem;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    bottom: -0.375rem;
}

.addfield-view-table {
    .custom-simple-landing-dgt {
        overflow-x: hidden !important;
    }
}

.dict-fil-cls-ic {
    svg {
        height: 1.375rem;
        width: 1.875rem;
    }
}

.noti-info-ic {
    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}

.biz-term-dtype {
    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}

.dict-stat-pod-item {
    svg {
        height: 1rem;
        width: 1rem;
    }
}

.settings-cls-modal-ic, .settings-addfield-modal-ic {
    svg {
        height: 1.125rem;
        width: 1.125rem;
    }
}

.delete-biz-term-btn {
    position: relative;
    margin-top: -0.75rem;
}

.pod-health-sect {
    .custom-simple-landing-dgt {
        grid-template-columns: 100% !important;
    }
}