.policy-user-groups {
  height: 18.75rem;
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.1875rem;
  margin-bottom: 0.9375rem;

  display: flex;
  flex-direction: column;

  .policy-user-groups-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
    border-bottom: 1px solid #f1f1f1;
  }

  .policy-user-groups-title h4 {
    font-weight: 600;
    font-size: 0.8125rem;
    margin: 0;
  }
  .policy-user-groups-options {
  }

  .policy-user-groups-table {
    flex: 1;

    .no-data-content > svg {
      height: 8.75rem;
      width: 10.75rem;
    }
  }
}