@import "../../../../../assets/scss/common/colors";
@import "../../../../../assets/scss/common/font";
@import "../../../../../assets/scss/common/variables";

.dictionary-wrapper {
  height: calc(100vh - 234px);
  // height: calc(100vh - 214px);
  background: $white;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;

  &.dict-full-screen {
    height: calc(100vh - 33px) !important;
    max-height: 100vh !important;
  }

  .dictionary-contentarea {
    padding: 10px;
    height: calc(100% - 50px);
    .custom-content-dgt {
      height: 100%;
    }
  }

  .analyze-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .classification-modal {
    .classificationTag span {
      max-width: 80px;
    }
  }
  .analyze-tag {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;
    width: $height50 * 20;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $white;
    border-radius: 10px;
    overflow: hidden;

    .card {
      border: none;
      .card-header {
        padding: $padding10;
        border-bottom: 1px solid $light-background;
        background-color: $white;
        border-radius: 0;

        .analyze-tag-header {
          display: flex;
          justify-content: space-between;
        }

        .analyze-tag-headings {
          display: flex;
          align-items: center;

          p {
            margin: $margin5 0 0 $margin10;
            color: $grey-three;
          }
        }

        .analyze-tag-close {
          display: flex;
          align-items: center;
        }
      }

      .card-body {
        padding: 0;

        .analyze-scroll {
          height: calc(100vh - 100px);
          overflow-y: auto;
        }
      }

      .analyze-upper-card {
        margin-bottom: $margin10;
      }

      .analyze-upper-card,
      .analyze-bottom-card {
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        margin: $margin10;
        overflow: hidden;
      }

      .analyze-common-card-header {
        border-bottom: 1px solid $light-background;
        padding: $padding10;
      }

      .analyze-upper-second-card {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;

        .left-section {
          .left-section-first {
            margin-bottom: 20px;
          }
          .left-section-first,
          .left-section-second {
            padding: $padding5;
            border: 1px solid $light-background;
            border-radius: 3px;
          }

          margin-right: $margin10;
          width: $width-full - 50;
          p {
            margin: 0;
            padding: $padding5 0;
            font-size: $font12;
            font-family: $inter-semibold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $light-background;

            &:last-child {
              border: none;
            }

            span {
              font-size: $font12 + 1;
              font-family: $inter-regular;
            }
          }
        }

        .right-section {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: $width-full - 50;
          border: 1px solid $light-background;
          border-radius: 3px;
        }
      }
    }

    .summary-background {
      background-color: #eff2f7;
      padding: 10px;

      h4 {
        font-size: 14px;
        margin: 0;
        font-weight: 600;
      }
    }

    .heat-map {
      margin-bottom: 50px;
    }

    .analyze-content {
      display: flex;
      padding: 10px;

      & > * {
        flex-basis: 100%;
      }

      .analyze-content-stats {
        margin-right: 10px;
      }

      .analyze-label {
        font-family: "Inter", sans-serif !important;
        font-weight: 400 !important;
        font-size: 13px !important;
        color: rgba(51, 58, 64, 0.8);
        margin: 0 0 5px 0;
      }
    }

    .chart-area {
      display: flex;
      padding: 10px;
      justify-content: space-between;

      .doughnut-chart,
      .progress-bar-parent {
        padding: 15px;
        border: 1px solid #eaeaea;
      }

      .doughnut-chart {
        width: 50%;
        margin-right: 5px;

        .apexcharts-legend-series {
          min-width: 105px;
        }

        .apexcharts-legend-text {
          font-family: "Inter", sans-serif !important;
        }
      }

      .progress-bar-parent {
        width: 50%;
        margin-left: 5px;

        p {
          margin: 0;
          font-size: 12px;
          line-height: 1;
          min-height: 12px;
        }
      }
      .progress-box {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .progress {
          width: 100%;
          height: 5px;
          border-radius: 0;
          margin-left: 5px;
        }

        p {
          color: rgba(51, 58, 64, 0.8);
          font-weight: 600;
          width: 70px;
          text-align: left;
        }

        &:nth-child(2) {
          .progress {
            background-color: rgba(0, 204, 146, 0.2);
          }
        }

        &:nth-child(4) {
          .progress {
            background-color: rgba(250, 180, 58, 0.2);
          }
        }

        &:nth-child(6) {
          .progress {
            background-color: rgba(255, 86, 94, 0.2);
          }
        }

        &:nth-child(8) {
          .progress {
            background-color: #fef0d8;
          }
        }
      }
    }

    .apexcharts-canvas {
      text {
        font-family: "Inter", sans-serif !important;
      }
    }

    .frequency-graph-box,
    .length-graph-box {
      padding: 10px;
      border: 1px solid #eaeaea;
      margin: 10px;
    }

    .length-graph-box {
      text-align: center;
      .legend-color {
        display: flex;
        justify-content: space-between;

        > div {
          display: flex;
          width: 100%;
        }

        .lengthGraph-block {
          width: 10px;
          margin-right: 2px;
        }

        .legend-value {
          min-width: calc(100% - 12px);
          text-align: left;

          span {
            font-size: 12px;

            &:last-child {
              width: calc(100% - 30px);
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .analyze-tag-card {
    margin-bottom: 0rem !important;
    border: 1px solid #ffffff !important;
    border-radius: unset !important;
  }

  .analyze-tag-menu {
    background-color: #eff2f7;
    padding: 0.5rem 1.2rem;
    font-size: 12px;
    font-weight: 500;
    color: #333a40;
  }
}

.dictionary-header {
  border-bottom: 1px solid #f1f1f1;
}
.header-icon {
  color: #5f5de9;
  font-size: 18px;
  cursor: pointer;
  padding: 0 8px;

  i {
    vertical-align: middle;
  }
}
.header-icon-disable {
  color: #c3c3c5;
  font-size: 20px;
  cursor: pointer;
  padding: 0 8px;
}
.header-search-input {
  height: 30px;
}
.dictionary-table-input-description {
  width: 100%;
  height: 35px;
}
.dictionary-table-input-businessTerm {
  width: 80%;
  height: 35px;
}
.text-elips {
  -webkit-line-clamp: 3 !important;
  position: relative !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  text-overflow: ellipsis !important;
  -webkit-box-orient: vertical !important;
}

.dictionary_search_container {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 0;
}
.dictionary_search_input {
  height: 28px;
  margin: 0;
  padding-left: 25px;
  width: 207px;
  background-color: #ffffff;
  border: 0.1rem solid #eaeaea;
  font-family: "Inter", sans-serif;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #5f5de9;
  }
}

.dictionary_search_icon {
  left: 5px;
  height: 16px;
}

.dictionary_search_icon,
.dictionary_close_icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.dictionary_close_icon {
  right: 9px;
  color: #7e7e7e;
  cursor: pointer;
}
.dictionary_navbar_right {
  display: flex;
}

.dictionary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $padding10;
}

.dictionary-header-title {
  display: flex;
  align-items: center;

  .header-sub-heading {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 300;
    padding-top: 0.1rem;
  }
}

.icon-column-details {
  color: #333a40;
  width: 10px !important;
  height: 10px !important;
}
.graph-loader-container {
  display: flex;
  justify-content: center;
  font-size: 1rem;
}
.default-cursor {
  cursor: auto;
}

// dictionary page
body {
  .pod-page-view {
    background-color: #FFF;
    // background-color: $grey-one;
    padding: $padding5;
    .dictionary-main {
      padding: $padding5;
      max-height: 100%;
      // max-height: calc(100vh - 110px);
      overflow-y: auto;
      .pod-meta-container {
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        background-color: $white;
        margin-bottom: $margin10;
      }

      .pod-meta-header {
        padding: $padding10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $light-background;

        .pod-meta-headings {
          display: flex;
          align-items: center;
        }
      }

      .pod-meta-description {
        padding: $padding10;
        min-height: 4.875rem;

        textarea {
          height: 6.25rem;
          padding: $padding5;
        }
      }

      .dictionary-contentarea {
        .dictionary_table {
          &.dict-full-screen {
            .ant-table-body {
              max-height: calc(100vh - 132px) !important;
            }
          }
          .ant-table-body {
            max-height: calc(100vh - 384px) !important;
          }
        }
      }
    }
  }

  .dictionary_table {
    // .ant-switch {
    // background-color: $grey-three;
    // height: $height10 * 2;
    // box-shadow: none;

    // .ant-switch-handle {
    // height: $height10 + 6;
    // width: $width10 + 6;
    // }

    // &.ant-switch-checked {
    //   background-color: $primary;

    //   .ant-switch-handle {
    //     left: calc(100% - 16px - 2px);
    //   }
    // }
    // }
  }
}
.graph-loader-container {
  display: flex;
  justify-content: center;
  font-size: 1rem;
}
.default-cursor {
  cursor: auto;
}

.dictionary-main {
  .dictionary-grid {
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: repeat(3, minmax(350px, 1fr));
    margin: 0;
    position: relative;
  }
  .dictionary-bar-card {
    height: calc(100% - 20px);
    border: 1px solid $grey-three;
    border-radius: 3px;

    .ant-progress-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .banner {
      padding: $padding10;
    }

    .content {
      border-top: 1px solid $grey-three;
      padding: $padding5;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.dictionary-toggleBtn {
  svg {
    height: 16px;
    width: 16px;
  }
}
.dict-bar-card-desc-grid {
  display: grid;
  grid-template-columns: 24px auto 32px;
  width: 100%;
  gap: 5px;
}
.word-break-all {
  word-break: break-all;
}
.graph-tile-skeleton {
  .ant-skeleton-avatar {
    width: 100% !important;
    height: 407px !important;
    border-radius: 3px;
  }
}
.sel-sample-drop {
  .ant-select-selector {
    width: 115px !important;
  }
}

#dictionary-table-wrap {
  .ant-table-tbody {
    tr {
      td {
        .dict-highlight-name {
          span {
            display: inline !important;
          }
        }
      }
    }
  }
}
.dictionary-cols-tbl-wrap {
  & > div {
    // height: 300px;
    height: calc(100vh - 310px);
  }
  .custom-simple-landing-dgt {
    height: 100%;
  }
}
.dict-full-screen {
  .dictionary-cols-tbl-wrap {
    & > div {
      // height: 300px;
      height: 100% !important;
    }
    .custom-simple-landing-dgt {
      height: 100%;
    }
  }
}
.catalog-dict-analysis-table {
  height: calc(100% - 144px);

  @media (max-width: 1280px) {
    height: calc(100% - 102px);
  }
  @media (max-width: 920px) {
    height: calc(100% - 110px);
  }

  .advanced-dictionary-tbl-wrap {
    height: calc(100% - 96px) !important;
    // margin-top: 10px;

    @media (min-width: 1280px) {
      height: calc(100% - 112px) !important;
    }
    

    /* .rdg-row, .rdg-cell {
      height: 3.5rem !important;
    } */
  };
  .advanced-dictionary-table {
    height: calc(100% - 28px) !important;
  }
}

.advanced-dictionary-tbl-wrap {

  $grid-columns: minmax(9.375rem, 20%) minmax(6.75rem, auto) minmax(auto, 31%) minmax(6.25rem, 10%) 16.25rem;

  &.default-fit {
    .custom-simple-landing-dgt {
      grid-template-columns: $grid-columns !important;
      // grid-template-columns: minmax(150px, 20%) minmax(85px, 10%) minmax(108px, auto) minmax(auto, 24%) minmax(85px, 10%)  minmax(100px, 10%) 230px !important;
      
      // Do not remove this
      // grid-template-columns: minmax(150px, 15%) minmax(135px, 10%) minmax(auto, 15%) minmax(85px, 10%) minmax(100px, 10%) 40% !important;
    }
  }

  @for $i from 6 through 100 {
    $grid-columns: #{$grid-columns} 12.5rem;
    &.cols-#{$i} {
      .custom-simple-landing-dgt {
        grid-template-columns: $grid-columns !important;
      }
    }
  }

  &:not(.default-fit) {
    .rdg-cell[aria-colindex = '1'] {
      box-shadow: 2px 0px 7px -4px #808080a3 !important;
    }
  }
}
.classification-modal.classifier-sel-mod {
  .custom-simple-landing-dgt {
    grid-template-columns: 26% 35% 120px auto !important;
  }
}

.dtype-dict-tbl-ic {
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

#dictLastRefresh {
  font-size: 0.8125rem;
}

.pod-detail-page-container {

  .pod-page-view.explore-module  {
    height: calc(100vh - 65px);

    .dictionary-main {
      height: 100% !important;
      overflow-x: hidden;
    }
  }
}

.dict-detail-body { 
  h4.subtitle {
    margin-top: 0 !important;
  }
}

.catalog-dict-analysis-table {
    transition: .3s;
    &.expanded {
      position: fixed;
      width: 100%;
      height: calc(100% - 2.4rem);
      bottom: 0;
      left: 0;
      z-index: 0;
      transition: .3s;
  }
}