// table

body {
    .custom-virtual-data-table {
        // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        // position: $p-relative;
        padding: 0 $padding10 $padding10 $padding10;
        background-color: $white;
        font-family: $inter-regular !important;
        .ant-table {
            .ant-table-thead {
                th {
                    background-color: $white !important;
                    border: 1px solid $dark-background;
                    font-family: $inter-regular;

                    .table-header-with-icon {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .ant-dropdown-trigger  {
                            font-size: $font20;
                            cursor: pointer;
                        }

                        .db-icon {
                            display: flex;
                            overflow: hidden;
                            max-width: calc(100% - 1px);
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            span {
                                max-width: calc(100% - 1px);
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }
                    }

                    p {
                        line-height: 1;
                        padding-left: $padding5 * 5;
                        width: $width-full;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    span {
                        font-size: $font14;
                        color: $black;
                        font-family: $inter-regular;
                    }

                    &::before {
                        display: none;
                    }
                }
            }

            .ant-table-container {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .virtual-data-table-cell {
            font-size: $font12;
            color: $black;
            padding: 0 $padding10;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 1px solid $dark-background;
            border-top: none;
        }
    }

    .common-page-table {
        .ant-table {
            tr {
                th,
                td {
                    border-bottom: 1px solid $light-background;
                }
            }
            .ant-table-thead {
                tr {
                    th {
                        background-color: $white;
                        font-size: $font12;
                        font-family: $inter-semibold;
                        color: $black;

                        &::before {
                            display: none;
                        }

                        .ant-table-column-sorters {
                            justify-content: flex-start;

                            &::after {
                                display: none;
                            }
                        }

                        .ant-table-column-title {
                            flex: none;
                            margin-right: $margin5;
                        }
                    }
                }
            }

            .ant-table-body {
                overflow-y: auto !important;
            }

            .ant-table-tbody {
                tr {
                    td {
                        font-size: 0.6875rem;
                        font-family: $inter-regular;
                        background-color: $white;
                        color: $black;
                        padding: $padding10 !important;

                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: inherit;
                        }
                    }

                    &.ant-table-measure-row {
                        td {
                            padding: 0 !important;
                        }
                    }

                    .table-icon {
                        svg {
                            height: $height50 - 30;
                        }

                        &:hover {
                            svg {
                                path {
                                    fill: $primary;
                                }
                            }
                        }
                    }

                    .error-red {
                        &:hover {
                            svg {
                                path {
                                    fill: $error-red;
                                }
                            }
                        }
                    }

                    .edit-icon {
                        margin: 0 $margin10;
                    }

                    &:hover {
                        td {
                            background-color: $grey-one !important;
                        }
                    }
                }
            }
        }
    }

    .no-preview-table-data {
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .custom-data-grid-row {
        font-size: 14px;
    }
}
