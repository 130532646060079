@import "../../../../assets/scss/common/colors";
@import "../../../../assets/scss/common/variables";
@import "../../../../assets/scss/common/font";

.connection-guide-modal {
    .modal-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .merge-join-content {
        display: flex;

        .join-list {
            width: $width-full - 70;
        }

        .join-list-item {
            padding: $padding10 1.25rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            position: $p-relative;

            p {
                margin-left: $margin10;
                transition: color 0.2s ease-in-out;
            }

            svg {
                height: 1.5625rem;
                width: 1.5625rem;

                path,
                ellipse {
                    transition: all 0.2s ease-in-out;
                }
            }

            &:hover {
                p {
                    color: $primary;
                }

                svg {
                    ellipse {
                        fill: $primary;
                    }

                    path {
                        stroke: $primary;
                    }
                }
            }
        }

        .selected-list-item {
            background-color: #155e94;
            border: 1px solid $primary;
            p {
                color: #FFF;
            }

            svg {
                path {
                    stroke: $primary;
                }

                ellipse {
                    fill: $primary;
                }
            }

            &::before {
                content: "";
                position: $p-absolute;
                width: $width10 - 8;
                top: 10px;
                left: -1px;
                bottom: 10px;
                background-color: $primary;
                border-radius: 0 4px 4px 0;
            }
        }

        .infographic {
            width: $width-full - 30;
            padding: 0.9375rem;
            height: 31.25rem;
            overflow: auto;

            svg {
                width: $width-full;
                height: $height-full;
            }
        }
    }
}

.out-conn-status {
    svg {
        width: 4rem;
    }
}

.out-bound-conn-create {
    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}