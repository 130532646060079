.lpg-content-tab {

    .ant-tabs-tab:hover {
        span {
            color: #2c83c4! important;
        }
        svg path {
            fill: #2c83c4! important;
        }
    } 

    .ant-tabs-nav::before {
        border: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active span {
        color: #2c83c4! important;
    }

    .ant-tabs-tab.ant-tabs-tab-active svg path {
        fill: #2c83c4! important;
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 0.75rem !important;
       
    }

    .ant-tabs-tab{
        padding: 0.75rem 0.3125rem !important;
    }
    .ant-tabs-ink-bar {
        background: #2c83c4 !important;
    }

    .ant-tabs-nav-list .ant-tabs-tab-btn svg {
        height: 1.125rem;
        width: 1.125rem;
        margin-right: 0.375rem;
    }
}