.start-pg-greeting {
    text-align: center;
    margin-bottom: 26px;
    .highlight-greet {
        color: #124575;
    }
    h2 {    
        font-size: 28px
    }
}
.start-pg-logo-wrap {
    max-width: 280px;
    margin: 0 auto 16px auto;
    .pulse-logimg-wrap {
        width: fit-content;
        margin: 0 auto;
    }
    .ant-upload.ant-upload-select {
        margin-left: auto !important;
        margin-right: auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.start-pg-domain-wrap {
    margin-bottom: 26px;

    .no-domain-info {
        justify-content: center !important;

        & > div {
            display: flex;
            align-items: center;
            height: auto !important;
            padding: 5px 0 !important;

            & > span {
                display: flex;
            }
            svg {
                height: 18px !important;
                width: 18px !important;
            }

            h2 {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                margin-left: 5px;
                font-size: 12px !important;
            }
        }
    }

    .dm-search-li-wrap  {
        position: relative;
        z-index: 1;
        max-width: 840px;
        margin: 0 auto 16px auto;
        padding: 0 15px;
        gap: 12px;

        .ant-tabs-tab {
            height: 32px;
            border-radius: 0;
            border-top: none;
            border-left: none;
            border-right: none;
            padding: 6px 5px 6px 0px;
            border-width: 2px;
            
            &:not(.ant-tabs-tab-active) {
                border-color: transparent;
            }
        }

        .ant-tabs-tab-btn {
            font-size: 13px;

            svg {
                position: relative;
                top: -1px;
                height: 15px;
                width: 15px;
                margin: 0 3px;
            }
        }

        .dm-toggle-search {
            height: 32px;
            width: 32px;
            background: #FFF;

            svg {
                height: 15px;
                width: 15px;
            }
        }

        .lpg-domains-tab .ant-tabs-nav-more {
            top: 0 !important;
        }

        .custom-search input {
            height: 32px !important;
        }

        .lpg-domains-tab .ant-tabs-nav {
            margin-bottom: 0px;
        }

        // .ant-tabs-nav-wrap {
        //     border-bottom: solid 1px #dedede;
        // }
    }
}
.start-pg-talkdata-search {
    .pulse-search-container {
        max-width: 666px;
        margin: 0 auto;
    }
}
.start-page-content-wrap {
    position: relative;
    padding-top: 54px;
    height: calc(100vh - 49px);

    .loading-dm {
        justify-content: center !important;
        margin-bottom: 16px;
    }
}

.start-pg-trend-wrap {
    position: absolute;
    padding: 16px;
    bottom: 0;
    left: 0;
    
    .asked-quest {
        min-height: 41px;
        word-break: break-all;
    }
    .ant-carousel {
        width: 400px;
    }
    .slick-slider.slick-vertical.slick-initialized {
        height: 344px !important;
    }
    .slick-list {
        height: 100% !important;
    }
    .ant-carousel .slick-list .slick-slide {
        pointer-events: auto !important;

        &.slick-active {
            opacity: .3;
        }
    }
    .trend-item {
        margin: 0 15px;
        padding: 10px 12px;

        svg {
            height: 1.875rem;
            width: 1.875rem;
        }

        &:hover {
            cursor: pointer;
            border-radius: 6px;
            background-color: #fff;
            box-shadow: 0px 0px 20px 3px #dedede;
        }
    }
}
.discover-pipeline {
    display: grid;
    grid-template-columns: 140px 66px 240px 80px 240px 80px 240px;
    margin: 0 auto;
    max-width: fit-content;
    // max-width: 1366px;
    width: 100%;
    padding: 0 16px;
}
.dp-conn {
    position: relative;
    width: 118px;
    top: 14px;

    .src-span {
        display: flex;
    }

    .sources_circle {
        height: 100%;
        width: 100%;
    }
}
.dp-con1 {
    display: flex;
    background: #fff;
    height: 50px;
    width: 50px;
    border: solid 2px #A0EDF2;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
    padding: 6px;
    box-shadow: 0px 0px 11px 2px #08c9d570;
    position: absolute;
    right: 10px;

    &.only-conn {
        top: 35px !important;
    }

    &.two-conn {
        right: 0 !important;
    }
}
.dp-con2 {
    display: flex;
    background: #fff;
    height: 50px;
    width: 50px;
    border: solid 2px #4D7FA8;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
    padding: 9px;
    box-shadow: 0px 0px 11px 2px #33679170;
    position: absolute;
    left: 0;
    top: 50px;
}
.dp-con3 {
    display: flex;
    background: #fff;
    height: 50px;
    width: 50px;
    border: solid 2px #00A1E0;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
    padding: 5px;
    box-shadow: 0px 0px 11px 2px #035BDA70;
    position: absolute;
    right: 0;
    top: 66px;
}
.start-pg-discover {
    padding-top: 126px;
}
.dp-mod-card-1 {
    cursor: pointer;
    position: relative;
    z-index: 3;
    border: solid 1px #ededed;
    padding: 8px 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 3px 6px 2px #eaeaea;
    min-height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > p {
        color: #949494;
    }
    
    & svg {
        height: 20px;
        width: 20px;
    }
}
.dp-mod-card-2 {
    position: relative;
    z-index: 2;
    padding: 8px 8px;
    border-radius: 6px;
    background: #E0F0FF;
    margin: -62px -8px 0px 8px;
    transition: .4s;

    & .mod-card-label {
        cursor: pointer;
        font-size: 11px;
    }

    & svg {
        height: 16px;
        width: 16px;
    }
}
.dp-mod-card-3 {
    position: relative;
    z-index: 1;
    padding: 8px 8px;
    border-radius: 6px;
    background: #EFF7FF;
    margin: -62px -16px 0px 16px;
    transition: .4s;

    & .mod-card-label {
        cursor: pointer;
        font-size: 11px;
    }

    & svg {
        height: 16px;
        width: 16px;
    }
}
.dp-modules {
    position: relative;

    &:hover, &.active {
        .dp-mod-card-2, .dp-mod-card-3 {
            margin-top: -40px;
            transition: .4s;
        }

        .dp-mod-indicator {
            top: -12px;
            transition: .4s;
        }

        &::after {
            opacity: 1;
            transition: .3s;
        }
    }

    & .top-heading.navigator {
        color: #16436D;

        & + svg path{
            fill: #16436D;
        }
    }
    & .top-heading.labs {
        color: #491870;

        & + svg path{
            fill: #491870;
        }
    }
    & .top-heading.pulse {
        color: #9B0B59;

        & + svg path{
            fill: #9B0B59;
        }
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: calc(100% - 10px);
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjEiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuMTgiLz4KPGNpcmNsZSBjeD0iNyIgY3k9IjIxIiByPSIxIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjE4Ii8+CjxjaXJjbGUgY3g9IjIxIiBjeT0iNyIgcj0iMSIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC4xOCIvPgo8Y2lyY2xlIGN4PSIyMSIgY3k9IjIxIiByPSIxIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjE4Ii8+Cjwvc3ZnPgo=');
        position: absolute;
        top: 37px;
        left: 30px;
        z-index: 0;
        background-repeat: repeat;
        opacity: 0;
        transition: .3s;
    }
}

.dp-mod-indicator {
    position: relative;
    margin-top: -28px;
    top: 0px;
    z-index: 3;
    transition: .4s;

    .mod-ic svg {
        height: 14px;
        width: 14px;
    }
    .mod-pri-title {
        font-size: 11px;
        margin-left: 5px;
    }
    .mod-sec-title {
        background: #F3F6FF;
        font-size: 9px;
        padding: 2px 6px;
        border-radius: 14px;
        color: #4C5484 !important;
    }
    .dp-indicator-joiner {
        display: block;
        width: 0px;
        height: 20px;
        margin: 0 auto;
        border-right: dashed 1px black;
    }

    &.navigator {
        color: #3361FF;
    }

    &.labs {
        color: #6517A2;
    }

    &.pulse {
        color: #DF1683;
    }
}

.dp-pipline-pipe1 {
    position: absolute;
    left: -65px;
    top: 50%;
    width: 65px;
    transform: translateY(-50%);

    & svg {
        width: 100%;
    }
}

.dp-pipline-pipe2 {
    position: absolute;
    left: -90px;
    top: 50%;
    width: 90px;
    transform: translateY(-50%);

    & svg {
        width: 100%;
        height: auto;
    }
}

.dp-pipline-pipe3 {
    position: absolute;
    left: -90px;
    top: 50%;
    width: 90px;
    transform: translateY(-3px) rotateY(180deg);

    & svg {
        width: 100%;
        height: auto;
    }
}

.pg-discover-heading {
    margin-top: -56px;
    color: #275A89;
    margin-bottom: 70px;
    max-width: 800px;
}

.mod-card-label, .top-heading {
    max-width: 130px;
}

.discover-toggle {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 4px 10px;
    position: fixed;
    border: solid 1px #9a9a9a;
    border-radius: 72px;
    font-size: 12px;
    bottom: 20px;
    right: 20px;
    color: #555555;
    z-index: 4;
    cursor: pointer;

    &:hover {
        background: #f4f4f4;
    }
}

.clarista-start-main-wrap {
    .main-header {
        border: none !important;
    }
}

.pipeline-anim {
    // width: 100px;
    height: 2px;
    // position: relative;
    overflow: hidden;
    background-color: #ddd;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    &::before {
        content: "";
        position: absolute;
        left: -50%;
        height: 2px;
        width: 40%;
        background-color: #3361ff;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }
    
    &.animate::before {
        -webkit-animation: lineAnim 1s linear infinite;
        -moz-animation: lineAnim 1s linear infinite;
        animation: lineAnim 1s linear infinite;
    }
}

.text-hide-gradient {
    display: block;
    width: 10px;
    background: linear-gradient(-90deg, white 35%, transparent);
    height: auto;
    position: relative;
    margin-left: -12px;
}

.see-more-button {
    color: #2c83c4;
    cursor: pointer;

    &:hover {
        color: #124575;
    }
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}