.clsta-land-legend {
    background-position: center;
    background-size: 100% 100%;
}
.leg-title-cont {
    margin-top: 40px;
    h2 {
        font-family: 'InterSemibold';
        color: #155e94;

        &.pulse {
            color: #C60C4F;
        }
        &.lab {
            color: #7a28ff;
        }
    }
    h4 {
        font-size: 22px;
        font-family: 'InterSemibold';
        color: #555;
    }
    p {
        font-size: 16px;
        color: #555;
    }
}
.lpg-gif {
    // height: 270px;
    position: absolute;
    left: -20px;
    top: 6px;

    @media (min-width: 1920px) {
        height: 17.5rem;
    }
}