body {
    .rdgrid-tbl-filter-wrap {
        position: relative;
    }
    .hide-fields-popover-wrap {
        width: 16.125rem;
}
    .rdgrid-tbl-filter-wrap {
        

        .rdg-tbl-flt-pop {
            position: absolute;
            box-shadow: 0.0625rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
            z-index: 1;
            width: 56.25rem;
            top: 2.125rem;
            left: 0;

            // background: #fff;
            // padding: 1rem;
            // border: solid 0.0625rem #eeeeee;
            // border-radius: 0.25rem;
            // box-shadow: 0rem 0.25rem 0.3125rem 0rem #ebebeb;
            .ant-card-body {
                padding: 0rem;
            }
        }

        .card-padding {
            padding: 0.625rem;
        }
    }

    .content-with-checkbox {
        height: 1.5625rem;
        border: 0.0313rem solid #17171A;
        border-radius: 0.1875rem;
        font-family: 'InterReguler';
        padding: 0.0625rem 0.1875rem 0.1875rem 0.3125rem;
        display: flex;
        align-items: center;
        background-color: #ffffff;
    }

    .simple-divider {
        border-bottom: 0.0625rem solid #f1f1f1;
    }

    .sort-filter-field-grid {
        display: grid;
        grid-template-columns: auto 3.5rem 1.875rem;
        gap: 0.625rem;
        margin-bottom: 0.625rem;
    }

    .rdg-column-style {
        // margin-left: 0.3125rem;
        font-size: 0.8125rem;
    }

    .custom-virtual-data-table {
        .rdg-cell {
            font-size: 0.6875rem;
            user-select: text !important;
            border-top: none;
            border-right: solid 0.0625rem #E8E8E8;
            border-left: none;
            outline: none !important;
            font-family: 'InterRegular';
            // background: #FFF;
            border-bottom-color: #E8E8E8 !important;
        }

        .rdg-row {
            background-color: #FFF;
            &:hover {
                background-color: #f5f5f5;
            }
        }

        .rdg-header-row {
            .rdg-cell:not(:first-child) {
                // border-left: solid 0.0625rem #e7e7e7;
            }
        }
    }

    .filter-ic-rdg-custom-ic {
        svg {
            width: 0.9375rem;
        }
    }

    .data-grid-expanded {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0.625rem;
        background: #fff;
        z-index: 1060;

        &::before {
            content: '';
            background: white;
            display: block;
            width: 100%;
            height: 100vh;
            position: absolute;
            margin: -0.625rem;
        }
    }

    .custom-virtual-data-table {
        .rdg-header-row {
            background: #FFF;
        }
        .load-more-dgt-wrapper {
            bottom: -2.625rem !important;
        }
    }

    .load-more-dgt-wrapper {
        position: absolute;
        bottom: 0.9375rem;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }

    .load-more-dgt {
        display: flex;
        width: auto;
        border-radius: 0.25rem;
        padding: 0.625rem 0.875rem;
        background: #333a40;
        box-shadow: 0rem 0rem 1.0625rem 0rem #0000005c;
        color: #ffffff;
        z-index: 1030;
        align-items: center;
    }

    .preview-page-view {
        .custom-virtual-data-table {
            .custom-data-grid-table {
                z-index: 11;
                position: relative;
            }
        }
    }

    .dgt-custom-filter-pop {
        min-width: 35.625rem;
    }

    // .category-ddl .element-name {
    //     display: inline;
    //     padding: 0.1875rem 0.1875rem;
    //     // border-radius: 0.1875rem;
    //     font-weight: 700;
    //     font-size: 0.75rem;
    //     background-color: transparent !important;
    //     color: #00BCD4;
    // }

    // .classification-ddl .element-name {
    //     display: inline;
    //     // padding: 0.1875rem 0.1875rem;
    //     // border-radius: 0.1875rem;
    //     font-weight: 700;
    //     font-size: 0.75rem;
    //     // background-color: #00BCD433;
    //     color: #FFAB00;
    // }

    // .ant-progress-inner {
    //     position: relative;
    //     padding: 0rem;
    //     margin: 0rem;
    //     display: inline-block;
    //     width: 100%;
    //     overflow: hidden;
    //     vertical-align: bottom;
    //     background-color: #f5f5f5;
    //     border-radius: 0rem;

    //     .ant-progress-bg {
    //         height: 0.3125rem !important;
    //     }

    // }
    // .ant-progress-line {
    //     position: relative;
    //     width: 100%;
    //     font-size: 0rem;
    // }
    // .ant-ribbon {
    //     box-sizing: border-box;
    //     margin: 0;
    //     padding: 0;
    //     color: rgba(0, 0, 0, 0.85);
    //     font-size: 0.5rem;
    //     font-variant: tabular-nums;
    //     line-height: 1.5715;
    //     list-style: none;
    //     font-feature-settings: 'tnum', "tnum";
    //     position: absolute;
    //     top: 0rem;
    //     height: 1.375rem;
    //     padding: 0rem 0.3125rem;
    //     color: #fff;
    //     line-height: 1.375rem;
    //     white-space: nowrap;
    //     background-color: #FFAB00;
    //     border-radius: 0.125rem;
    // }

    .dgt-hide-fl-ls {
        font-size: 0.6875rem;
    }

}
.prev-col-wrap {
    height: 100%;
    &.active {
        // box-shadow: inset -2.6875rem 0.8125rem 2.125rem 2.625rem #d2ecff;
        box-shadow: none;
        outline: solid 0.1875rem #2c83c4;
        background: #dbefff !important;
        // background: repeating-linear-gradient(319deg, #dbefff, transparent 0.375rem);
        :is(.cap-pile, .tc-tm) {
            border: solid 0.0625rem #2c83c4 !important;
        }
        .cap-pile.cat {
            background: #edf7ed !important;
        }
        .cap-pile.cls {
            background: #f1e9ff !important;
        }

        & .prev-col-nm {
            background: #bee2ff !important;
        }
        & .custom-prog {
            height: 0.5rem;
            // border: solid 0.125rem #6baadc;
        }
        & .prev-col-ac-ic {
            background: linear-gradient(90deg, transparent 0%, #acd8fb 35%) !important;
        }
    }

    &.col-fixed {
        box-shadow: none !important;
        // .prev-col-cont {
        //     outline: solid 0.1875rem #2c83c4;
        // }
        .custom-prog {
            border: none !important;
        }
    }

    .dgt-new-flt-btn:not(.rdg-filter-active, .dgt-filter-active) {
        opacity: 0.6;
        transition: .5s;
    }

    &:hover {
        .dgt-new-flt-btn {
            opacity: 1;
            transition: .5s;
        }
    }

    // .dgt-new-flt-btn:not(.rdg-filter-active, .dgt-filter-active) {
    //     transform: scale(0);
    //     transition: .5s;
    // }

    // &:hover {
    //     .dgt-new-flt-btn {
    //         transform: scale(1);
    //         transition: .5s;
    //     }
    // }
}

.prev-col-cont {
    height: inherit;

    & .custom-prog {
        height: 0.5rem;
    }
}

.prev-col-nm {
    line-height: 1.4;
    margin-bottom: 0.125rem;
    // background: #f3f3f3;
    padding: 0rem 0.3125rem 0.1875rem 0rem;
    // border-bottom: solid 0.0625rem #d6d6d6;

    & svg {
        height: 1.25rem;
    }
}

.prev-col-ex {
    line-height: 1.4;
    width: 100%;
    height: calc(100% - 2.125rem);
    padding: 0 0.1875rem;
    :is(.categoryTag, .cap-pile) {
        border-radius: 0.1875rem;
        border: none !important;
    }
    .custom-prog {
        height: 0.25rem;
        border-radius: 0.125rem;
    }
    &:hover {
        .custom-prog {
            height: 0.5rem;
            transition: .3s;
        }
    }
}

.prev-col-ex .categoryTag {
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    height: 1.125rem;
    // border-radius: 0rem;
    margin: 0 0rem;
    width: auto;
    display: inline-flex !important;
    line-height: 1;
    &.tc-tm {
        display: inline-block !important;
        max-width: 100%;
        width: auto;
        background: #cef9ff;
        color: #1497a8;
        border: solid 0.0625rem #00BCD4;
        line-height: 1;
    }
}
.prev-col-ex .classificationTag {
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    height: 1.125rem;
    // border-radius: 0rem;
    margin: 0 0rem;
    max-width: 6.25rem;
    margin-right: 0.25rem;
}
.prev-prog-wrap {
    padding-top: 0.25rem;
}
.prev-col-ac-ic {
    position: absolute;
    background: linear-gradient(90deg, transparent 0%, #fff 35%);
    // background: linear-gradient(90deg, transparent 0%, #f3f3f3 35%);
    right: 0;
    width: calc(100% - (100% - 3.625rem));
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.4375rem;
}
.prev-col-cont {
    .rdg-column-style {
        width: calc(100% - 4rem);
        display: inline-block;
        position: relative;
        bottom: -0.25rem;
        font-family: 'InterSemibold' !important;
    }
}
.prev-col-ac-ic {
    & svg path {
        fill: #2c72a7;
    }
}
.prev-stats-sphere {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: solid 0.0625rem transparent;
    transition: .3s;
    &:hover {
        border: solid 0.0625rem #bbbbbb;
        border-radius: 1.25rem;
        background: #ececec;
        padding: 0 0.125rem;
        transition: .3s;
    }
    &.active {
        border-radius: 1.25rem;
        background: #FFF;
        color: #2c83c4;
        border-color: #2c83c4;
        padding: 0 0.125rem;
        transition: .3s;
    }
}
.data-dict-identity-pane .sphere {
    width: 0.9375rem;
    border-radius: 0.0625rem;
}
.custom-data-grid-table {
    scroll-behavior: smooth;
    border-right: none !important;
    border-left: none !important;
    .cap-pile {
        border-radius: 0.1875rem !important;
    }
}
.params-li-prev {
    max-height: 16.875rem;
    overflow-y: auto;
    .ant-list-item {
        padding: 0.5rem 0.625rem !important;
    }
}
.dgt-hide-cols-item {
    svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
    }
}
.col-redirect-btn {
    cursor: pointer;
    background: #FFF;
    padding: 0.125rem;
    border-radius: 0.1875rem;
    color: #2c83c4;
    display: flex;

    svg {
        height: 0.75rem;
        width: 0.75rem;
        margin: 0;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: .6;
        color: #333333;
    }
}
.show-hide-btn-prev {
    cursor: pointer;
    // background: transparent;
    // border-radius: 0.1875rem;
    color: #333333;
    transition: .3s;

    &:hover {
        // background: #ececec;
        color: #2c83c4;
        transition: .3s;
    }
    
    &.disabled {
        opacity: .8;
        cursor: not-allowed;
    }
}
.hide-field-cs {
    .custom-search-input {
        height: 2rem;
        background: #efefef;
        border-color: #efefef;
        border-radius: 0.375rem;
    }
}
.hide-field-control-btn {
    cursor: pointer;
    color: #333333;
    transition: .3s;

    &:hover {
        color: #2c83c4;
        transition: .3s;
    }
}

.col-exp-arrow {
    svg {
        height: 0.625rem;
        width: 0.625rem;
    }
}

.col-exp-loader {
    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}