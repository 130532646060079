.connection-modal {
    // .connection-header {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    // }

    .sub-header {
        width: 100%;

        .sub-header-right {
            align-items: center !important;
        }
    }

    .connection-heading {
        display: flex;
        align-items: center;
    }

    .connection-options {
        display: flex;
        align-items: center;
    }

    .form-step {
        display: flex;
        align-items: center;
        justify-items: center;
        margin-left: 227px;

        .step-number {
            border-radius: 50%;
            height: 28px;
            width: 28px;
            // background-color: #2C83C4;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #828282;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            border: 2px solid #828282;
        }

        .active-step {
            border-radius: 50%;
            height: 28px;
            color:#FFFF;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            width: 28px;
            background-color: #2C83C4;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .line {
            width: 202px;
            height: 0px;
            border-bottom: 2px solid #828282;

        }
    }
    
    .select-conn-modal-body .ant-collapse-header {
        align-items: center !important;
    }
}