.chat-gridview-responsive {
    display: grid;
    grid-gap: 0.9375rem 0.625rem;
    grid-template-columns: repeat(auto-fill, minmax(19.5rem, 1fr));
    margin: 0;
    padding: 0.625rem;
    position: relative;
    > div span.paragraph span {
        font-size: 0.625rem;
    }
    > div span.paragraph span {
        font-size: 0.625rem;
    }

    .tile-meta-info {
        svg {
            height: 0.875rem;
            width: 0.875rem;
        }
    }

    .card {
        min-height: 8.3125rem;
        border-radius: 3px;
        cursor: pointer;
        transition: box-shadow 0.2s ease-in-out;
        &:hover {
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
        }
    }
}
.rep-det-tbl {
    .data-grid-shrink {
        height: calc(100vh - 5.875rem) !important;
    }
}
.pulse-talk-wrap {
    height: calc(100vh - 6.0625rem);
    // height: calc(100vh - 35px);
    overflow: hidden;
    // position: relative;
}
.ques-bar {
    h2 {
        position: relative;
        display: flex;
        gap: 0.375rem;
        font-size: 1.25rem;
        font-family: 'InterSemibold';
        color: #333333;
        width: fit-content !important;
        max-width: 26.25rem;
    }
}
.talk-data-ques-header {
    display: grid;
    grid-template-columns: 462px auto 108px;
    column-gap: 0.9375rem;
    padding: 0.5rem 1rem;

    &.shared-user-extend {
        grid-template-columns: 462px auto minmax(160px, 183px);
    }
}
.sugg-ques-icon {
    display: flex;
    height: 42px;
    width: 42px;
    // border-radius: 100%;
    // background: #275A89;
    align-items: center;
    justify-content: center;
}
.sugg-pill {
    color: #275A89;
    padding: 5px 20px;
    border: solid 1px #DEDEDE;
    border-radius: 50px;
}
.talk-suggest-wrap {

    position: absolute;
    z-index: 1;
    top: 47px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 50%);
    background: #FFF;
    padding: 0px 1px 0px 0px;
    border-radius: 0 0 16px 16px;
    border-top: solid 1px #dadada;
    box-shadow: 0px 5px 14px -6px #0000006e;
    // box-shadow: 0px 0px 4px -2px #000;
    transition: 0.5s;
    overflow: hidden;

    // position: absolute;
    // z-index: 1;
    // top: 66px;
    // left: 30px;
    // width: calc(100% - 58px);
    // display: grid;
    // grid-template-columns: 38px auto;
    // align-items: center;
    // background: #FFF;
    // padding: 0px 18px 0px 8px;
    // border-radius: 25px;
    // box-shadow: 0px 0px 4px -2px #000;
    // transition: .3s;
}
.sugg-pill {
    border-left: none !important;
    border-top: none !important;
    border-radius: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    border-right: none !important;
    white-space: pre-wrap;
    display: flex;
    align-items: flex-start;

    &:hover {
        background: #f7f7f7 !important;
    }
}
.sugg-hscroll {

    overflow-x: auto;
    display: grid;
    white-space: nowrap;
    gap: 0;
    padding: 0px 0;

    // overflow-x: auto;
    // display: flex;
    // white-space: nowrap;
    // gap: 10px;
    // padding: 7px 0;
    &::-webkit-scrollbar {
        display: none;
    }
    & {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
.sugg-icon-wrap {
    border-bottom: solid 1px #d9d9d9;
}
.sugg-ques-icon {
    display: flex;
    height: 24px;
    width: 24px;
    // height: 31px;
    // width: 31px;
    // border-radius: 100%;
    // background: #275A89;
    align-items: center;
    justify-content: center;
}
.ts-top {
    color: #8e8e8e;
    font-size: 0.625rem !important;
    margin-left: 40px;
}
.chat-cont-head {
    position: relative;
    display: flex;
    gap: 0.375rem;
    font-size: 1.125rem;
    font-family: "InterSemibold";
    color: #333333
}

.talk-chat-cont-head {
    display: grid;
    grid-template-columns: auto 300px;
    column-gap: 15px;

    &.with-tracker {
        display: block;
    }

    .icon-toggler.shadow-toggle {
        height: 2rem;
        background: #ffffff;
    }
}
.talk-chat-content {
    position: relative;
    padding: 0.75rem 1rem;
    border: solid 0.0625rem #f2f2f2;
    border-radius: 0.875rem;
    background: #fff;
    height: 100%;
    margin-left: 45px;
    
    &.talk-data {
        border-radius: 0.875rem;
        background: #f2f2f2;
        // box-shadow: 0 10px 20px #00000014, 0 6px 6px #0000000a;

        &.round-all {
            border-radius: 0.875rem;

            .chat-tail {
                display: none;
            }
        }

        // .chat-hover-action-btn {
        //     transform: scale(0);
        // }

        // &:hover {
        //     .chat-hover-action-btn {
        //         transform: scale(1);
        //         transition: .3s;
        //     }   
        // }
    }

    .cht-act-tg {
        margin-top: 3px;
    }

    .cht-act-bt {
        margin-top: 0.3125rem;
    }

    &.border-less {
        border: none;
    }

    &.with-divider::after {
        content: '';
        display: block;
        width: 100%;
        height: 10px;
        background: radial-gradient(#00000029 12%, transparent 30%);
        position: absolute;
        bottom: -50px;
        left: 0;
    }
}
.talk-chat-meta {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    .lg-kpi {
        h2 {
            margin-bottom: 0;
            font-size: 32px;
            font-family: 'InterSemiBold';
            // color: #0F9133;
        }
    }
    .vertical-separator {
        width: 3px;
        height: 56px;
    }
    .down {
        color: #FF0000;
    }
}

.chat-content-prev-tbl {
    &.custom-virtual-data-table {
        background: transparent !important;

        .load-more-dgt-wrapper {
            bottom: 6px !important;
        }
    }
    .dgt-extra-err-space {
        display: none;
    }
    .data-grid-shrink {
        height: 301px !important;
    }
    .custom-data-grid-table {
        height: calc(100% - 34px) !important;
    }
    // .rdg-header-row {
    //     display: contents !important;
    //     .rdg-cell {
    //         padding: 0 4px !important;
    //         overflow: hidden;
    //     }
    // }
    // .rdg-cell {
    //     line-height: 1.4 !important;
    //     border-bottom: solid 1px #f2f2f2 !important;
    // }
}

.talk-conv-wrap {
    height: calc(100vh - 10.375rem);
    position: relative;
    background: #fff;
    // background: #f9f9f9;
    display: flex;
    flex-direction: column;
    transition: 0.3s;

    &.loading-more-chat {
        height: calc(100vh - 9.5rem);
        transition: .3s;
    }

    .load-more-chat-box {
        height: 50px;
    }

    .replying-chat-box {
        height: 3.75rem;
        display: flex;
        align-items: flex-start;
        width: 95%;
        margin: 0 auto;
        margin-bottom: 2rem !important;
        gap: 0.25rem;
        font-size: 0.8125rem;
        padding-top: 0.5rem;
    }

    .replying-avt {
        display: flex;
        height: 1.75rem;
        width: 1.75rem;
        padding: 0.125rem;
        margin: 0.25rem;
        // background: #f1f1f1;
        // border: solid 1px #cecbcb;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }

    .replying-avt-chatbox {
        border-radius: 0.875rem;
        background: #d8eeff;
        // background: #e2f2ff;
        display: flex;
        padding: 0.375rem 0.75rem;
        border: solid 1px #e2f2ff;
        height: 2.5rem;
        width: 5rem;
        top: 0.125rem;
        position: relative;
        margin-left: 0.3125rem;

        .dot-typing {
            top: 0.5625rem;
        }

        .chat-tail.left svg path {
            fill: #d8eeff;
        }
    }

    .talkdata-conv-array-wrap {
        max-height: calc(100% - 0px);
        overflow-y: auto;
        margin-top: auto;
        scroll-behavior: smooth;

        & > div {
            width: 85%;
            margin-bottom: 108px;
            transition: .3s;
        }
    }

    .custom-content-dgt {
        &::-webkit-scrollbar {
            display: none;
        }
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
        & > .rdg-row > [role="gridcell"].rdg-cell {
            padding-top: 14px;

            label, p {
                line-height: 1.4 !important;
            }
        }
    }
    .custom-content-dgt > .rdg-row > .rdg-cell {
        padding: 0 !important;
    }
    .alert-danger {
        line-height: 1.4 !important;
    }
}
.chat-landing {
    height: calc(100vh - 19.5625rem);
    // height: calc(100vh - 300px);

    &.full-height {
        height: calc(100vh - 6rem);
    }

    &.short {
        .custom-content-dgt {
            grid-template-columns: repeat(3, 1fr) !important;
        }
    }
    &.long {
        .custom-content-dgt {
            grid-template-columns: repeat(4, 1fr) !important;
        }
    }

    .custom-content-dgt {
        &::-webkit-scrollbar {
            display: none;
        }
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
        & > .rdg-row > [role="gridcell"].rdg-cell {
            padding-top: 0.875rem;
            overflow: hidden !important;

            label, p {
                line-height: 1.4 !important;
            }
        }
    }
    .data-view-dbox {
        * {
            line-height: 1.4;
        }
        .tile-meta-info {
            svg {
                height: 0.875rem;
                width: 0.875rem;
            }
        }
    }
    .card {
        cursor: pointer;
        height: 100%;
        &:hover {
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)
        }
    }
    .card-body {
        padding: 1rem 0.625rem;
    }
    .no-result {
        height: 25rem;
    }
}

// .talk-chat-content {
//     &:hover {
//         box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)
//     }
// }

.chat-share-mselect {
    .ant-select-selector {
        border-radius: 3px !important;
    }
}

.talk-data-ques-header {
    .custom-panel-toggler {
        top: 124px;
    }
}

.conv-kpi-sec {
    .one-line-error-comp {
        white-space: normal;
        line-height: 1.4 !important;
    }
}

.quest-asked {
    position: relative;
    padding: 10px 15px;
    background: #F1F1F1;
    border: solid 1px #DEDEDE;
    border-radius: 6px 6px 6px 0;
    max-width: calc(100% - 314px);
    width: fit-content;
    margin-left: auto;
    font-size: 15px;
    white-space: pre-line;
    line-height: 1.4;

    &.up {
        margin-right: auto !important;
        margin-left: 42px !important;
    }

    .clst-ic {
        position: absolute;
        display: flex;
        height: 34px;
        width: 34px;
        background: #FFF;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        left: -42px;
        top: 4px;
        // box-shadow: 0px 2px 9px -4px #000;

        svg {
            height: 26px;
            width: 23px;
        }

        .ant-avatar {
            margin: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 13px !important;
            font-family: 'InterRegular';
        }
    }

    .veri-ic {
        position: absolute;
        top: 4px;
        right: -37px;
    }
}

.header-sep {
    background: #D9D9D9 !important;
    width: 0.125rem !important;
    height: 1.125rem !important;
}

.talk-index-main .main-header {
    background: #FFF;
    border-color: #D9D9D9 !important;
    min-height: 3rem;
}
.talk-index-main:not(.clarista-start-main-wrap) .main-header .left {
    position: relative;
    z-index: 2;
}

.talk-index-main .main-header .left + div {
    position: relative;
    z-index: 2;
}

.talk-index-main .main-header .hamburger { 
    svg :is(line, path) {
        stroke: #333!important;
    }
}

// .talk-index-main .main-header .user-menu p {
//     background: #256fa7 !important;
//     color: #FFF !important;
// }

:is(.kpi-highlight-blue, .highlight-blue) {
    color:#2c83c4 !important;
    font-family: 'InterSemiBold' !important;
}

:is(.kpi-highlight-red, .highlight-red) {
    color:#dc3545 !important;
    font-family: 'InterSemiBold' !important;
}

:is(.kpi-highlight-green, .highlight-green) {
    color:#28a745 !important;
    font-family: 'InterSemiBold' !important;
}

.genai-pod-tab {
    // max-width: 712px;
    .ant-tabs-tab {
        padding: 0.375rem 0.125rem 0.375rem 0.125rem !important;
        border: none;
        border-radius: 0;
        font-size: 0.6875rem !important;
        line-height: 1;
    }

    .ant-tabs-nav {
        margin-bottom: 1rem !important;
    }

    .ant-tabs-tab:hover {
        span {
            color: #2c83c4! important;
        }
        svg path {
            fill: #2c83c4! important;
        }
    } 

    .ant-tabs-tab.ant-tabs-tab-active span {
        color: #2c83c4! important;
        font-family: 'InterSemibold';
    }

    .ant-tabs-tab .ant-tabs-tab-btn span {
        font-family: 'InterSemibold';
    }

    .ant-tabs-tab.ant-tabs-tab-active svg path {
        fill: #2c83c4! important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        border-bottom: solid 2px #2c83c4;
    }

    .ant-tabs-nav::before {
        border: none;
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 10px !important;
    }

    .ant-tabs-nav-more {
        display: block;
        width: 30px;
        height: 30px;
        padding: 0 !important;
        line-height: 0px;
        font-size: 16px;
        font-weight: bold;
        color: #333 !important;
        border-radius: 100%;
        transform: rotateZ(90deg);
        border: solid 1px #d6d6d6 !important;
        top: 4px;
        left: 10px;
    }
}
.ant-tabs-dropdown-menu-item {
    svg {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
    margin: 5px;
}

.talkData-wrapper {
    .pulse-search-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        background: #FFF;
        padding-bottom: 0.9375rem;
    }
    .pulse-search-comp {
        // box-shadow: 0px -2px 13px -7px #000;
        width: 85%;
        // width: calc(100% - 50%);
        margin: 0 auto;
        border: 0;
        position: relative;
        z-index: 2;
        background: #FFF;
        // border-radius: 10px;
        border: solid 1px #d9d9d9;
        box-shadow: 0px 7px 18px -6px rgba(0, 0, 0, 0.3215686275);
        top: 0;
    }
}
.below-explanation {
    .custom-sql-editor-note {
        display: none !important;
    }
}
.chat-grph-err {
    & > div {
        width: 100%;
    }
}
.ts-top {
    &.pos-right {
        display: block;
        text-align: right;
        width: 95%;
        margin: 0 auto;
    }
}
.tracker-dgt-wrap {
    height: calc(100vh - 19.5625rem);
    padding-top: 0.625rem;
    
    .custom-content-dgt {
        grid-template-columns: 50% 50% !important;
        &::-webkit-scrollbar {
            display: none;
        }
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
        & > .rdg-row > [role="gridcell"].rdg-cell {
            padding-top: 0.875rem;

            label, p {
                line-height: 1.4 !important;
            }
        }
    }
    .custom-content-dgt > .rdg-row > .rdg-cell {
        padding: 0 !important;
    }
    .alert-danger {
        line-height: 1.4 !important;
    }
}
.tracker-box {
    .talk-chat-cont-head {
        grid-template-columns: auto 100px;
        justify-content: space-between;
    }
    .talk-chat-content {
        cursor: pointer;
        &:hover {
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)
        }
    }
    .chat-cont-head {
        font-size: 1rem;
    }
    
    .lg-kpi h2 {
        font-size: 1.5rem;
    }
    
    .conv-kpi-sec p {
        font-size: 0.8125rem;
    }
    .talk-chat-content {
        height: 27.5rem;
        margin-left: 0;
        background: #FFFFFF;
        border-color: #d9d9d9;

        .talk-chat-graph {
            background: none;
            padding: 0;
        }
    }
    .lg-kpi {
        max-width: 120px;
        white-space: break-spaces;
    }
    .talk-chat-meta .vertical-separator {
        width: 2px;
        height: 4rem;
    }
    .chat-content-prev-tbl .data-grid-shrink {
        height: 20.125rem !important;
    }
    .genai-pod-tab .ant-tabs-tab {
        padding: 0.25rem 0.125rem;
        font-size: 0.5625rem;
        border-width: 1px;
    }
    .chat-content-prev-tbl {
        height: 18.5rem !important;
    }
}
.tracker-data-wrap {
    height: calc(100vh - 3.125rem);
    overflow-y: auto;
    position: relative;
    padding-bottom: 1.25rem;

    .talk-chat-content {
        max-width: 100% !important;
    }

    .below-explanation {
        & > p {
            max-height: 31.25rem;
            height: auto !important;
        }
    }

    .cht-cont-box {
        width: 100% !important;
        padding: 1.875rem 2.5rem 0 2.5rem !important;
    }

    :is(.ts-top > .ts, .talk-chat-cont-head) {
        display: none;
    }

    .ts-top {
        height: 0.625rem;
    }

    .talk-chat-content {
        padding: 0.625rem 0.9375rem;
        border: none;

        hr {
            display: none;
        }
    }

    .reaction-el, .chat-hover-action-btn {
        display: none !important;
    }
}
.tracked-head {
    display: grid;
    grid-template-columns: auto 180px;
    align-items: center;
    column-gap: 15px;
    padding: 10px 40px;
    background: #FFF;
    position: sticky;
    z-index: 1;
    top: 0;
    // box-shadow: 0px -10px 16px 3px #00000091;
}
.tracked-head {
    .dark-color {
        color: #275A89;
    }
}

span.assump-block {
    position: relative;
    line-height: 1.4;
    white-space: initial;
    background: #E0F2FF;
    color: #275A89;
    padding: 10px 10px 10px 40px;
    border-left: solid 3px #275A89;
}

span.assm-quotes {
    position: absolute;
    top: 5px;
    left: 10px;
}

// span.assump-block::before {
//     content: '“';
//     position: absolute;
//     font-size: 46px;
//     left: 12px;
//     top: -2px;
// }

.assump-tag {
    line-height: 1.4;
    color: #333;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    // background: #d3d3d3;
    border-radius: 6px;
    font-size: 11px;
}

.assump-tag-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 16px;
}

.tracker-box {
    .assump-tag {
        padding: 5px 8px;
        line-height: 5px;
    }
    .assump-tag-wrap {
        gap: 5px;
        font-size: 10px;
        margin-bottom: 6px
    }
}

.custom-chart-li-pop {
    .ant-popover-title {
        display: none;
    }
}

.chart-li-popgrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 10px;
}

.chart-li-popgrid > div {
    cursor: pointer;
    border-radius: 3px;
    padding: 10px 5px;
    text-align: center;
    width: 94px;
    &:hover {
        background: #f3f3f3;
    }
}

.chart-li-popgrid > div svg {
    // height: 20px;
    width: 20px;
    margin-bottom: 6px;
}

.chrt-li-lb {
    max-width: 90%;
    margin: 0 auto;
    line-height: 1.4;
}

.chart-li-popgrid > div.active {
    background: #ECF9F9;
}

.chart-li-popgrid > div.disabled {
    cursor: not-allowed;
    opacity: .6;
    filter: grayscale(1);
    &:hover {
        background: transparent !important;
    }
}

.chart-li-popgrid > div.active.disabled {
    cursor: pointer;
    opacity: 1;
    filter: grayscale(0);
    &:hover {
        background: #ECF9F9 !important;
    }
}

.chart-li-popgrid > div.active .chrt-li-lb {
    color: #275A89;
    font-family: 'InterSemiBold';
}

// .chart-li-popgrid > div.active svg path {
//     fill: #275A89;
// }

.graph-type-ic {
    svg {
        height: auto !important;
        width: 18px;
    }
}

.reaction-elment-wrap {
    position: relative;
    display: flex;
    align-items: center;
    padding: .4rem .7rem;
    background: #EBEBEB;
    border-radius: 2rem;
    gap: .5rem;

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 54%;
        background: #ADB5BD;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.feed-reaction {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 1.375rem;
    width: 1.375rem;

    svg {
        height: 1.5rem;
        width: 1.5rem;
    }

    // filter: grayscale(1);

    // &.like {
    //     height: 26px;
    //     width: 26px;
    //     position: relative;
    //     bottom: -1px;    
    // }

    &.active {
        filter: grayscale(0);
        border-radius: 30px 
    }

    &.unlike {
        transform: rotate(180deg);
    }
}

// Trace Style

.trace-talk-content-wrap {
    height: calc(100vh - 3.125rem);

    :is(.react-split__sash--vertical, .react-split__sash--horizontal) .split-sash-content::before {
        content: '' !important;
    }
    
    .trace-li-sect {
        min-width: 18.75rem;
        width: 100%;
        height: 100%;
    }

    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 2rem;
    }
    
    .res-tab {
        width: 100%;
        height: auto;
        padding: 0rem 1rem 0rem 2rem;

        .ant-tabs-content-holder {
            display: none;
        }

        .ant-tabs-nav {
            margin-bottom: 0.5rem;
        }

        .ant-badge-count {
            padding: 1px 0.125rem;
            font-size: 0.625rem;
            font-family: "InterSemibold";
            top: auto !important;
            background: #5f5f5f !important;
            line-height: 0.875rem;
        }

        .ant-tabs-tab-btn {
            color: #5f5f5f !important;
        }

        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: #2c83c4 !important;
            }
            .ant-badge-count {
                background: #2c83c4 !important;
            }
        }

        .ant-scroll-number {
            height: 1rem;
            min-width: 1rem;
        }
        &.ant-tabs-top >.ant-tabs-nav::before {
            border: none !important;
        }
    }

    .chat-feed-active {
        position: absolute;
        display: block;
        width: 0.1875rem;
        height: 100%;
        background: radial-gradient(#2c83c4 40%, transparent 82%);
        top: -0.4375rem;
        left: -0.4375rem;
    }

    .chat-feed-item {
        position: relative;
        cursor: pointer;
        padding: 0.5rem 0.5rem 1.25rem 0.5rem;
        border-radius: 0.3125rem;
        min-height: 6.4375rem;
        border: solid 1px transparent;
        transition: .3s;
        // padding-bottom: 18px;

        &.active {
            border-left: solid 4px #2c83c4 !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            border-right-color: transparent !important;
            background: #F9F9F9;
        }

        &:hover {
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
            background: #F9F9F9;
            border-color: transparent;
            // border-color: #cecece;
            transition: .3s;
        }

        .lower {
            .small {
                font-size: 0.6875rem;
            }
        }

        // &:is(.active,:hover)::before {
        //     content: "";
        //     width: calc(100% + 10px);
        //     height: calc(100% - 7px);
        //     border: solid 1px #cecece;
        //     border-radius: 6px;
        //     background: transparent;
        //     position: absolute;
        //     z-index: 0;
        //     top: -3px;
        //     left: -3px;
        // }

        // &.active::before {
        //     border-color: #2c83c4;
        //     background: #11aaff14
        // }

        .upper {
            display: flex;
            align-items: flex-start;
            gap: 0.3125rem;
            margin-bottom: 0.5rem;
        }
        .lower {
            display: flex;
            gap: 0.3125rem;
            color: #838383;
            .feed-det {
                font-size: 0.75rem;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-style: italic;
                line-height: 1.4;
                width: calc(100% - 4rem);
                text-wrap: wrap;
            }
            .chat-feed-emoji {
                font-size: 1rem;
                width: 1.25rem;
                display: block;
                text-align: center;
                position: relative;
                top: -3px;
                line-height: 1.4;

                svg {
                    height: 1.25rem;
                    width: 1.25rem;
                }
            }
        }
        &.active {
            .upper {
                font-family: "InterSemibold";
                position: relative;
                z-index: 1;
                .feed-quest {
                    color: #256fa7;
                }
            }
            .lower {
                position: relative;
                z-index: 1;
            }
        }
        .feed-quest {
            flex: 0 0 calc(100% - 4.25rem);
            word-break: break-word;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.4;
            width: calc(100% - 4rem);
            text-wrap: wrap;
            color: #333333;
        }
    }

    .trace-tab-cont {
        padding: 0.25rem 0.125rem 0.25rem 2rem;
        // padding: 0.25rem 1rem;
        height: calc(100% - 3.125rem);
        overflow-y: auto;
        overflow-x: hidden;
        // & > .chat-feed-item:not(:last-child)::after {
        //     content: "";
        //     display: block;
        //     width: 65%;
        //     height: 2px;
        //     background: radial-gradient(#cecece, transparent);
        //     position: relative;
        //     bottom: -10px;
        //     margin: 0 auto;
        // }
        .custom-content-dgt {
            overflow-x: hidden !important;
            .rdg-cell {
                padding: 0rem 0.3125rem 0rem 0rem;
                overflow: hidden !important;
            }
        }
    }

    .chat-feed-title {
        display: flex;
        align-items: flex-start;
        h4 {
            // max-width: calc(100% - 260px);
            word-break: break-word;
            margin-bottom: 0;
            position: relative;
            top: 0.125rem;
        }
    }

    .chat-feed-verify-head {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #e8e8e8;
        padding: 0.625rem 0.625rem 0.625rem 0.625rem;
    }

    .chat-feed-sql {
        height: calc(100% - 3rem);
        & > div.COMMON_MONACO_CODEEDITOR  {
            height: inherit;

            & > section {
                height: calc(100% - 2rem) !important;
            }
        }
    }

    .chat-feed-graph-tgl {
        position: absolute;
        top: 0.625rem;
        right: 0.625rem;
        z-index: 1;
    }

    .feed-prv-wrap {
        & > div {
            height: 100%;
        }
        .chat-content-prev-tbl {
            height: calc(100% - 2.1875rem);
            padding-bottom: 0 !important;
        }
        .data-grid-shrink  {
            height: 100% !important;
        }
        .custom-virtual-data-table .load-more-dgt-wrapper {
            bottom: -5px !important;
        }
        .reloadble-err-comp {
            margin: 0 !important;
            padding-top: 3.125rem;
        }
    }

    #chatContGraph {
        height: 100%;
        .echarts-for-react {
            height: 100% !important;
            & > div:first-child {
                height: 100% !important;
                & > canvas {
                    height: 100% !important;
                }
            }
        }
    }

    .genai-pod-tab {
        width: calc(100% - 4.75rem);
    }

    .ant-tabs-nav-more {
        top: 0 !important;
    }

    .ant-tabs-nav {
        top: 0.125rem !important;
        margin-bottom: 0.625rem !important;
    }
}

.feed-verify-btn:not(:disabled) {
    background: #098925 !important;

    &.warning {
        background: #e18a18 !important;
    }
}

.talk-trace-float-btn {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    width: auto;
    height: 2.5rem;
    background: #2c83c4;
    align-items: center;
    justify-content: center;
    border-radius: 3rem;
    color: #fff;
    cursor: pointer;
    padding: 0 0.625rem;
    font-size: 0.9375rem;
    cursor: pointer;

    svg {
        height: 1.5rem;
        width: 1.5rem;
    }

    &:hover {
        background: #275a89;
    }
}

#chatChartExpandBtn.custom-btn svg {
    height: .8rem !important;
    width: .8rem !important;
}

.septor {
    display: block;
    width: 60%;
    height: 2px;
    background: radial-gradient(#cecece, transparent);
    margin: 0 auto;
}
.clst-anchor {
    color: #2c83c4;
    font-family: 'InterRegular';

    &:hover {
        color: #275A89;
    }
}
.feed-dm-li-itm {
    cursor: pointer;
    border-radius: 3px;
    &:hover {
        background: #f0f0f0;
    }
    &.active {
        background: #cdeaff !important;
        color: #2c83c4;
        font-family: 'InterSemibold'
    }
}
.feed-dm-li {
    .ant-popover-content {
        width: 11.25rem;
    }
}
.feed-dm-li-tg {
    display: flex;
    height: 1rem;
    min-width: 1rem;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background: #2C83C4;
    border-radius: 0.625rem;
    font-size: 0.5625rem;
    padding: 0rem 0.25rem;
}

.pulse-noti-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3125rem;
    border-radius: 0.25rem;
    width: 2rem;
    height: 1.5rem;
    color: #FFFFFF;
    cursor: pointer;

    svg {
        height: 1.5rem;
        width: 1.5rem;
    }

    &:hover {
        color: #ffffff80;
    }

    .feed-noti-count-bd {
        position: absolute;
        top: 0px;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.0625rem 0.3125rem;
        background: #CB1010;
        color: #FFF;
        font-size: 0.5625rem;
        border-radius: 20px;
    }
}

.talk-index-main {
    .pulse-noti-btn {
        width: 2rem;
        height: 2rem;
        color: #333333;
        &:hover {
            color: #2c83c4;
            background: #f0f0f0;
        }
    }
}

.feed-noti-head {
    color: rgba(51, 51, 51, 0.70);
}

.feed-noti-pop-wrap {
    .ant-popover-content {
        width: 350px;
    }
    .feed-noti-itm {
        cursor: pointer;
        &:hover {
            background: #F6F6F6;
        }
        &.active {
            background: #2c83c424;
        }
    }
}

.feed-search-inp-pop {
    position: absolute;
    bottom: -2.4375rem;
    z-index: 1;
    left: 0;
    background: #ffffff;
    padding: 0.25rem 0.25rem;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    border: solid 1px #eeeeee;
    box-shadow: 0px 4px 16px -4px #979797;

    .custom-input-field {
        background: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.feed-chat-time {
    position: absolute;
    bottom: 0.625rem;
    right: 0.5rem;
    font-size: 0.625rem;
    color: #828282bd;
}

.quest-var-wrap {
    max-height: 12.75rem;
    overflow-y: auto;
    margin: 0 -1rem 0 0;
    padding-right: 0.875rem;
}

.below-explanation {
    & > :is(span, p) {
        font-size: 0.9375rem;
    }
}

.chat-user-ask {
    position: relative;
    background: #0047BB;
    line-height: 1.4;
    color: #FFF;
    font-size: 0.9375rem;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.875rem;
    border: solid 1px #0047BB;
    width: max-content;
    margin-left: auto;
    margin-right: 2.8125rem;
    max-width: 70%;
    word-break: break-word;
    white-space: break-spaces;
    // box-shadow: 0 10px 20px #00000042, 0 6px 6px #0000000a;

    &::selection {
        background: #ffffff;
        color: #0047bb;
    }

    .chat-retry-btn {
        position: absolute;
        left: -32px;
        top: 10px;
    }
    .chat-failed-text {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: -15px;
        right: 0;
        font-size: 10px;
        color: #dc3545;
    }
}

.chat-user-sender-avt {
    position: absolute;
    right: 0;
    bottom: 0;

    .ant-avatar {
        font-size: 0.6875rem !important;
        font-family: 'InterRegular';    
    }
}

.chat-usr-sender-box {
    &.left {
        .chat-user-ask {
            margin-left: 45px;
            margin-right: auto;
            border-radius: 14px;
            background: #eeeeee;
            border-color: #eeeeee;
            color: #606060;
        }
        .chat-user-sender-avt {
            position: absolute;
            left: -3px;
            top: 0;
            width: 36px;
        }
    }
}

.chat-usr-sysreply {
    position: absolute;
    left: -2.625rem;
    top: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.125rem;
    height: 1.75rem;
    width: 1.75rem;
    // background: #f1f1f1;
    border-radius: 100%;
    margin: 0.25rem;
    // border: solid 1px #cecbcb;
}

.dot-typing {
    position: relative;
    left: -9999px;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 5px;
    background-color: #2c83c4;
    color: #2c83c4;
    box-shadow: 9984px 0 0 0 #2c83c4, 9999px 0 0 0 #2c83c4, 10014px 0 0 0 #2c83c4;
    animation: dot-typing 1.5s infinite linear;
  }
  
  @keyframes dot-typing {
    0% {
      box-shadow: 9984px 0 0 0 #2c83c4, 9999px 0 0 0 #2c83c4, 10014px 0 0 0 #2c83c4;
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 #2c83c4, 9999px 0 0 0 #2c83c4, 10014px 0 0 0 #2c83c4;
    }
    33.333% {
      box-shadow: 9984px 0 0 0 #2c83c4, 9999px 0 0 0 #2c83c4, 10014px 0 0 0 #2c83c4;
    }
    50% {
      box-shadow: 9984px 0 0 0 #2c83c4, 9999px -10px 0 0 #2c83c4, 10014px 0 0 0 #2c83c4;
    }
    66.667% {
      box-shadow: 9984px 0 0 0 #2c83c4, 9999px 0 0 0 #2c83c4, 10014px 0 0 0 #2c83c4;
    }
    83.333% {
      box-shadow: 9984px 0 0 0 #2c83c4, 9999px 0 0 0 #2c83c4, 10014px -10px 0 0 #2c83c4;
    }
    100% {
      box-shadow: 9984px 0 0 0 #2c83c4, 9999px 0 0 0 #2c83c4, 10014px 0 0 0 #2c83c4;
    }
  }

  .talk-data-chat-header {
    border-bottom: solid 1px #d9d9d9;
  }

  .chat-hover-action-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.1875rem;
    height: 2.1875rem;
    background: #FFF;
    border: solid 1px #d9d9d9;
    border-radius: 100%;
    top: 0px;
    right: 0px;
    z-index: 1;
    // top: -15px;
    // right: -15px;
    transition: .3s;
}

.chat-date-strip {
    position: relative;
    background: #FFF;
    // background: linear-gradient(90deg, #f4faff, #d6edff, #f4faff);
    color: #33333380;
    // position: sticky;
    // top: 0;
    // z-index: 3;

    & > span {
        background: #FFF;
        position: relative;
        z-index: 1;
        padding: 0px 6px;
    }

    &::before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: #D9D9D9;
        position: absolute;
        top: 50%;
    }
}

.reaction-el {
    position: absolute;
    bottom: -3rem;
    right: 0rem;
}

.waiting-reply-tim {
    position: relative;
    top: 0.1875rem;
    // background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #606060a6;
    padding: 0.375rem 0.625rem;
    // border: solid 0.0625rem #d9d9d9;
    border-radius: 0.625rem;
}

.feed-ques-title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.talk-chat-graph {
    background: #FFF;
    padding: 2px 6px;
    border-radius: 6px;
}


    
.chat-tail.right {
    position: absolute;
    bottom: 0.5rem;
    right: -0.8125rem;
    padding: 0.1875rem;
    display: flex;
    height: 1.125rem;
    width: 1.125rem;
    transform: rotateY(180deg) rotateX(-180deg);
    z-index: 0;
    align-items: center;
    justify-content: center;

    svg path {
        fill: #0047BB;
    }
}

.chat-tail.left {
    position: absolute;
    top: 0.5rem;
    left: -0.8125rem;
    padding: 0.1875rem;
    display: flex;
    height: 1.125rem;
    width: 1.125rem;
    z-index: 0;
    align-items: center;
    justify-content: center;

    svg path {
        fill: #f2f2f2;
    }
}

.talk-chat-ai-head {
    position: relative;
    margin-left: 45px;
    max-width: 70%;
    margin-bottom: 1rem;

    @media (max-width: 800px) {
        max-width: 100%;
    }

    .chat-hover-action-btn {
        transform: scale(0);
    }

    &:hover {
        .chat-hover-action-btn {
            transform: scale(1);
            transition: .3s;
        }   
    }
}

.talk-chat-ai-quote-reply {
    position: relative;
    border-left: solid 0.25rem #DEDEDE;
    padding: 0.375rem 0.9375rem;
    font-size: 0.9rem;
    color: #5c5c5c;
    font-family: 'InterMedium';

    h1, h2, h3, h4 {
        font-family: 'InterSemiBold';
    }

    h1, h2, h3, h4 {
        font-size: 1rem;
    }

    h1, h2, h3, h4, h5, h6, strong {
        color: #333;
    }

    strong {
        font-weight: normal;
        font-family: 'InterSemiBold';
    }

    ul {
        margin-bottom: 0.5rem;

        li {
            margin-bottom: .3rem;
        }
    }

    p {
        margin-bottom: 0.3125rem;
    }

    th, td {
        border: solid 1px #828282;
        padding: 0.25rem 0.375rem;
    }
    
    table {
        margin-bottom: 0.5rem;
    }
}

.ai-prg-st-lines {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #5E5F66;
    margin-bottom: 4px;
}

.chat-ai-progress-statements {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        display: block;
        height: calc(100% - 24px);
        width: 15px;
        border-left: solid 1px;
        border-top: solid 1px;
        border-bottom: solid 1px;
        border-color: #C2C2C2;
    }
}

.line-joiner {
    display: flex;
    width: 30px;
    height: 26px;
    color: #C2C2C2;
    align-items: center;
    justify-content: center;
    font-size: 5px;
}

.chat-ai-progress-statements > div > .ai-prg-st-lines:not(:is(:first-child, :last-child)) > .line-joiner {
    visibility: hidden;
}

.trace-index-tab {
    & > .ant-tabs-nav {
        padding: 0 0.625rem;
        margin-bottom: 0;
        .ant-tabs-tab {
            padding: 0.5rem 0.25rem;
    
            svg {
                width: 1.125rem !important;
                height: 1.125rem !important;
                margin-right: 0.25rem;
            }
        }
        .ant-tabs-nav-list {
            .ant-tabs-tab:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }
    
    & > .ant-tabs-content-holder {
        height: calc(100vh - 5.5rem);

        .ant-tabs-content {
            height: 100%;
        }
        .ant-tabs-tabpane {
            height: 100%;
        }
    }
}

.trace-index-wrapper {
    .talk-trace-wrapper {
        height: 100%;
    }
    .trace-talk-content-wrap {
        height: 100% !important;
    }
}

.trace-persona-wrap {
    height: 100%;
    padding: 0.625rem 1rem;
}

.trace-persona-heading {
    .persona-head {
        // display: flex;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: max-content auto;
        // grid-template-columns: max-content auto auto;
        align-items: center;
        column-gap: 0.25rem;
    }
}

.trace-persona-body {
    height: calc(100% - 1.75rem);
    overflow-y: auto;
    padding: 0.625rem 1rem;
    margin: 0 -1rem;
}

.trace-loading-more-wrap {
    position: absolute;
    bottom: 10px;
    left: 0;
    text-align: center;

    .trace-loading-more {
        display: flex;
        align-items: center;
        border-radius: 0.25rem;
        padding: 0.625rem 0.875rem;
        background: #333a40;
        box-shadow: 0px 0px 17px 0px #0000005c;
        color: #ffffff;
        width: fit-content;
        font-size: .8rem;
        margin: 0 auto;

        & > span {
            display: flex;
        }

        svg {
            height: .8rem;
            width: .8rem;
        }
    }
}

.trace-synonym-wrapper {
    padding: 0.625rem 1rem 0 1rem;
    height: 100%;
    
    .trace-synonym-body {
        height: calc(100% - 2.75rem);
        display: grid;
        grid-template-columns: 19.25rem auto;

        & > div:first-child {
            border-right: solid 1px #f0f0f0;
        }
    }

    .trace-synonym-list {
        height: calc(100vh - 11.125rem);
        overflow-y: auto;
    }

}

.trace-synonym-header {
    margin: 0 -1rem;
    padding: 0 1rem 0.625rem 1rem;
}

.trace-synonym-list-content {
    height: calc(100vh - 13rem);
    overflow-y: auto;
    margin: 0 -1rem;
    padding: 0 1rem;
}

.text-highlight-div {
    cursor: pointer;
    padding-left: 0;
    padding-right: 0.625rem;
    min-height: 2.5rem;
    transition: .3s;

    & .syn-label {
        cursor: pointer;
        width: 100%;
        word-break: break-all;
    }

    .syn-action-btns {
        display: none;
    }

    &.active {
        background: #d7e8f4 !important;
        padding-left: 10px;
        color: #2C83C4;

        .syn-action-btns {
            display: none;
        }
    }
    &:hover {
        background: #f0f0f0;
        padding-left: 10px;
        color: #2C83C4;
        
        .syn-action-btns {
            display: flex;
        }
    }
}

.float-talkdata {
    // position: fixed;
    // display: block;
    // bottom: 20px;
    // right: 20px;
    // min-width: 620px;
    // max-width: 50%;
    height: calc(100vh - 10.125rem);
    // z-index: 2;
    // padding: 10px 15px;
    // background: #FFF;

    .talk-data-chat-header {
        padding: 0 !important;
        border: none !important;
    }

    .talk-data-ques-header {
        padding: 0 !important;
        & > div:not(:nth-child(2)) {
            display: none;
        }
    }

    .talkdata-conv-array-wrap {
        padding: 0 0.9375rem;
    }

    .talk-conv-wrap {
        height: calc(100% - 4.0625rem) !important;
        // height: calc(100% - 127px) !important;
    }

    .chat-date-strip {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
    }

    .cht-cont-box {
        width: 100% !important;
        // zoom: .85;
    }

    .pulse-search-comp {
        width: 100% !important;
        box-shadow: none !important;
    }

    .pulse-search-container {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        border-radius: 0 0 0.75rem 0.75rem;
    }

    .replying-chat-box {
        width: 100% !important;
        // zoom: .85;
    }

    .chat-usr-sender-box.user-query {
        width: 100% !important;
        // zoom: .85;
    }

    .line-joiner {
        flex: 0 0 1.875rem;
        visibility: hidden;
    }

    .talk-chat-content.talk-data {
        max-width: 100% !important;
    }

    .pulse-talk-wrap {
        height: 100%;
    }

    .talk-index-main {
        height: 100%;
    }

    .chat-chart-expand-btn {
        display: none !important;
    }

    .chat-user-ask, .ai-prg-st-lines, .talk-chat-ai-quote-reply{
        h1, h2, h3 {
            font-size: 0.875rem !important;
        }
        font-size: 0.8125rem !important;
    }

    .talkdata-resp-type {
        font-size: 0.875rem !important;
    }
    .question-suggestion-stack > div {
        font-size: 0.8125rem !important;
        padding: 0.375rem 0.9375rem
    }
    .talk-chat-graph {
        & .data-grid-expanded {
            top: calc(100vh - 200vh);
        }
        & .chat-content-prev-tbl {
            .data-grid-expanded .custom-data-grid-table {
                height: calc(100% - 0.625rem) !important;
            }
        }
    }

    .ant-select-selection-item {
        & > div {
            max-width: 8.125rem;
        }
    }

    .pre-resp-head {
        font-size: 0.875rem;

        svg {
            height: .8rem;
            width: .8rem;
        }
    }

    .chat-steps-collapse .ant-collapse-header-text {
        font-size: 0.875rem;
    }
}

.text-dark-warning {
    color: #eca220;
}

.text-color-grey {
    color: #5E5F66;
}

.question-suggestion-stack {

    // display: flex;
    // flex-direction: unset;
    // gap: 10px;
    // margin-left: auto;
    // margin-right: auto;
    // margin-bottom: 24px !important;
    // flex-wrap: wrap;
    // justify-content: flex-end;
    // padding-left: 30%;

    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px !important;

    & > div {

        // display: flex;
        // margin-left: 0;
        // background: #fff;
        // border: solid 1px rgba(0, 71, 187, 0.768627451);
        // border-radius: 10px;
        // padding: 8px 12px;
        // font-size: 14px;
        // font-family: "InterSemibold";
        // color: rgba(0, 71, 187, 0.9019607843);
        // cursor: pointer;
        // width: fit-content;

        display: flex;
        margin-left: auto;
        background: #fff;
        border: solid 1px #0047bbeb;
        border-radius: 10px;
        padding: 8px 12px;
        font-size: 14px;
        font-family: 'InterSemibold';
        color: #0047bbe6;
        cursor: pointer;

        &:hover {
            color: #0047bb;
            border-color: #0047bb;
            background: #f7f7f7;
        }
    }
}

.chat-share-msg-textarea {
    textarea {
        line-height: 1.4 !important;
    }
}

.talk-data-header-usr-shared-avt {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

.talk-data-header-usr-shared {
    margin: -6px;
}

.chat-content-prev-tbl {
    .dgt-status-pane {
        grid-template-columns: 2fr 16px 1fr !important;
        padding-inline: 5px;
    }
}

.pod-crt-tab-close-layer {
    display: inline-flex;
    width: 12px;
    height: 12px;
    align-items: center;
    justify-content: center;
    color: #333;
    position: relative;
    top: 0px;
}
 
.pod-crt-tab-close-btn {
    display: none;
    width: 12px;
    height: 12px;
    align-items: center;
    justify-content: center;
    color: #333;
    position: relative;
    top: 2px;
    background: #f2f2f2;
    border-radius: 20px;
 
    svg {
        height: 12px !important;
    }
 
    &:hover {
        background: red;
        color: #FFF;
    }
}
 
.new-custom-tabs {
    .ant-tabs-tab-btn {
        &:hover {
 
            .pod-crt-tab-close-layer {
                display: none;
            }
 
            .pod-crt-tab-close-btn {
                display: inline-flex;
            }
        }
    }
}
 
.ant-tabs-dropdown-menu-item {
    .pod-crt-tab-close-btn {
        display: none !important;
    }
}

.cancel-chat-btn {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    background: #333;
    padding: 0.4375rem 0.625rem;
    color: #fff;
    font-family: 'InterSemibold';
    display: flex;
    gap: 0.375rem;
    align-items: center;
    line-height: 1;
    border: solid 0.125rem #333333;
    border-radius: 100px;
    height: 2.25rem;
    width: 2.25rem;
    transition: .3s;

    svg {
        height: 0.75rem;
        // width: 0.75rem;
        transition: .3s;
    }

    &:hover {
        background: #ffdddd;
        color: #333333;

        svg {
            transform: scale(1.1);
            transition: .3s;
        }
    }
}
#customChatStop {
    height: 2rem;
    width: 2rem;
    position: relative;
    right: -0.75rem;
    outline: none !important;
    svg {
        height: 1.5rem !important;
        width: 1.5rem !important;
    }
}

.no-chart-data-found-temp {
    font-size: 1rem;

    svg {
        height: 3.125rem;
        width: 3.125rem;
    }
}

.trace-domain-sel-itm {
    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}

.sel-domain {
    & > span:first-child {
        display: flex;
        align-items: center;
    }
    svg {
        height: 1.25rem;
        width: 1.25rem;
    }

    .bx-rotate-90  {
        svg {
            height: 0.75rem;
            width: 0.75rem;
        }
    }
}

.veri-ic {
    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}
.pre-resp-head {
    display: flex;
    align-items: center;
    color: #5b5b5b;
    font-size: 1rem;

    svg {
        height: 1rem;
        width: 1rem;
    }
}

.pre-resp-body {
    color: #858585;
}

.chat-steps-collapse {
    .ant-collapse-header-text {
        font-size: 1rem;
        font-family: 'InterSemiBold';
    }
    .ant-collapse-expand-icon {
        height: 1.75rem !important;
        padding-inline-end: 0.5rem !important
    }
    .ant-collapse-arrow {
        font-size: 0.875rem !important;
    }
    .ant-collapse-header {
        color: #333333 !important;
        padding-inline: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0.75rem !important;
        width: fit-content !important;

        .ant-collapse-header-text svg {
            position: relative;
            top: -1px;
            height: .9rem;
            width: .9rem;
            margin-right: 0.3rem;
        }
    }
    .ant-collapse-content-box {
        padding: 0 !important;
    }
}

.reply-wait-box {

    font-size: 1rem;
    font-family: "InterSemiBold";
    height: auto;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.3125rem;
    color: #333333ad;
    position: relative;
    top: -1px;

    svg {
        position: relative;
        // top: 1px;
        height: .9rem;
        width: .9rem;
        margin-right: 0.5rem;
    }
}

.pre-resp-wrap {
    position: relative;
    // padding-left: 1.6rem;
    // border-left: solid 1px #858585;

    // &::before {
    //     content: '';
    //     display: block;
    //     width: .5rem;
    //     height: 1px;
    //     background: #858585;
    //     position: absolute;
    //     left: 0px;
    //     top: 11px;
    // }

    .step-ic {
        padding-right: 0.75rem;
    }
}

// .tracker-dgt-wrap .talk-chat-cont-head .chat-name-title {
//     word-break: break-all;
//     white-space: normal;
// }

.trace-persona-search-wrap {
    .custom-search-input {
        margin-bottom: 0 !important;
    }
}

.normal-talkdata-main-wrapper {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.talkdata-query-runbtn {
    position: absolute;
    width: 3.125rem !important;
    height: 3.125rem !important;
    bottom: 0.5rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem !important;
    z-index: 1;
    border-radius: 100% !important;
    border: none;
    box-shadow: 0px 2px 12px 0px grey;

    & > svg {
        height: 100% !important;
        width: 100% !important;
    }
}

.filter-calc-dimensions-grid {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1rem;

    // & > div {
        // max-width: 16rem;
    // }

    // @media (max-width: 640px) {
    //     grid-template-columns: 1fr 1fr;
    // }

    & > div {
        position: relative;
    }
    & > div:not(:last-child)::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        right: -1.05rem;
        top: 0;
        background: #CFD4DA;
    }
}

.filter-calc-dimensions-values {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: .25rem .5rem;
        border-radius: 1.2rem;
        border: solid 1px #CFD4DA;
        color: #333;

        &.active {
            border-color: #2c83c4;
            color: #2c83c4;
        }
    }

    &.read > span {
        border: none;
        background: #f0f0f0;
        border-radius: .2rem;

        &.active {
            background: #2c83c4;
            color: #fff;
        }
    }
}

.filter-calc-metrics-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;

    @media (max-width: 640px) {
        grid-template-columns: 1fr 1fr;
    }

    & > div {
        position: relative;
        // padding: 0.5rem 0rem 0.5rem 0rem;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;

    }

    & > div:not(:last-child)::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        right: -1.05rem;
        top: 0;
        background: #CFD4DA;
    }

    .ai-magic-ic {
        position: relative;
        top: 0.25rem;
    }

    .metric-body-wrap {
        position: relative;
        height: 100%;

        .metric-body-foot {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    // .filter-calc-metric-body {
    //     padding-bottom: 2.2rem;
    // }

    // .filter-calc-metric-header {
    //     min-height: 2rem;
    // }
}

.dim-col-name {
    max-width: 100px;
}

.dim-col-name-arrow {
    position: relative;
    top: -0.05rem;
    svg {
        height: .6rem;
        width: .6rem;
        transform: rotate(90deg);
    }
}

.dim-col-value-popwrap {
    max-height: 10rem;
    overflow-y: auto;
    max-width: 12rem;
    overflow-x: hidden;

    & .col-val-item:not(:last-child) {
        border-bottom: solid 1px #f1f1f1;
    }
    .col-val-item {
        // cursor: pointer;
        // &:hover {
        //     background: #0000000e;
        // }
    }
}

.filter-calc-metric-body .metric-cols {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 7rem;
}

.phrase-limit-tag {
    background: #F7DBDB;
    color: #CB1010;
}

.submit-filter-wrap {
    position: fixed;
    bottom: 0;
    padding: 1rem;
    z-index: 10;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;

    .submit-filter-content {
        display: flex;
        align-items: center;
        gap: .8rem;
        background: #FFF;
        padding: 0.3rem .4rem 0.3rem 1.2rem;
        border: solid 1px #d9d9d9;
        box-shadow: 0px 7px 18px -6px rgba(0, 0, 0, 0.3215686275);
        border-radius: 2rem;

        .submit-filter-info {
            font-size: .9rem;
            color: #444444;
        }
    }

    .submit-filter-btn {
        display: flex;
        align-items: center;
        gap: .35rem;
        font-size: .9rem;
        color: #fff;
        background: #2C83C4;
        border: solid 1px #2c83c4;
        border-radius: 5rem;
        padding: .5rem 1rem;
        transition: .2s;

        svg {
            position: relative;
            // margin-left: 0;
            right: 0;
            height: .9rem;
            width: .9rem;
            transition: .2s;
        }

        &:disabled {
            cursor: not-allowed;
            background: #8c8c8c;
            border-color: #8c8c8c;
        }

        &:not(:disabled):hover {
            background: #275A89;
            color: #FFF;
            transition: .2s;
            svg {
                // margin-left: .25rem;
                right: -.4rem;
                transition: .2s;
            }
        }
    }
}