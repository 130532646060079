@import "../../../../../assets/scss/custom/common/variables";
@import "../../../../../assets/scss/custom/common/fonts";
@import "../../../../../assets/scss/custom/common/colors";
@import "../../../../../assets/scss/common/colors";
@import "../../../../../assets/scss/common/variables";
@import "../../../../../assets/scss/common/font";
.icon-box-desc {
    float: left;
    margin-right: 6px;
    margin-top: -3px;
    padding-top: 4px;
}

/// datadomain css

.data-domain-details-upper-section {
    margin: $margin10 0 $margin10 * 2;

    .data-domain-details-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.25rem;
    }

    .card {
        // box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.2);
        // border-radius: 0 !important;
        // padding: $padding10 + 5;
    }

    .upper-left {
        .card {
            min-height: 8.125rem;
        }
        h5 {
            margin: 0 0 0 $margin5;
            font-size: $font16;
            font-family: $inter-semibold;
        }

        i {
            font-size: 1.5625rem;
        }

        p {
            font-size: $font12;
            margin: 0 0 $margin5 0;
            font-family: $inter-medium;

            &.grey {
                font-family: $inter-semibold;
                & ~ p {
                    margin: 0;
                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        white-space: inherit;
                        word-break: break-all;
                    }
                }
            }
        }
    }

    .upper-right {
        box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0 !important;
        padding: 15px;
        background-color: #fff;
        min-height: 130px;
        .card {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: $margin10 * 2;
        }

        .col-6 {
            &:nth-child(3),
            &:nth-child(4) {
                .card {
                    margin-bottom: 0;
                }
            }
        }

        .icon-span {
            margin-top: -10px;
            position: absolute;
            margin-left: -8px;
        }
        .count-box {
            margin-left: 35px;
            margin-top: -28px;
        }
        .bottom-row {
            margin-bottom: 20px;
        }
        .icon-box {
            height: 30px;
            width: 30px;
            background-color: $lightprimary;
            text-align: center;
            line-height: 50px;
            border-radius: 50%;
            margin: 0 10px 0 0;

            i {
                font-size: ($font12 - 2) * 3;
                color: $primary;
                vertical-align: middle;
            }

            ~ div {
                width: calc(100% - 60px);

                h6 {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    overflow: hidden;
                }
            }
        }

        p,
        h6 {
            margin: 0;
        }

        p {
            font-size: $font12;
            margin: 0 0 $margin5;
            font-family: $inter-semibold;
        }

        // h6 {
        //     font-size: $font16;
        //     font-family: $inter-semibold;
        // }

        .col-6 {
            &:nth-child(3),
            &:nth-child(4) {
                h6 {
                    font-size: $font12 + 1;
                    font-family: $inter-medium;
                }
            }
        }
    }
    &.usr-manage-usr-grp {
        margin-top: 0;
    }
}

.user-grp-header {
    margin-top: $margin10;
    margin-bottom: $margin10;

    .heading-content {
        display: flex;
        align-items: center;

        i {
            font-size: $font16;
        }

        p {
            margin: 0 0 0 $margin5;
            font-size: $font14;
            font-family: $inter-semibold;
        }
    }
}

.user-grp-bottom {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -12px 0;

    .user-grp-box {
        border: 1px solid $galleryGray;
        padding: $padding10;
        margin-bottom: $margin10 * 2;
        cursor: pointer;
        transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
        background-color: $white;

        .user-grp-box-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $margin10;

            .user-grp-box-left {
                display: flex;
                align-items: center;

                i {
                    font-size: $font16 + 4;
                }

                p {
                    font-size: $font16;
                    font-family: $inter-semibold;
                    margin: 0 0 0 $margin5;
                }
            }

            .user-type {
                background-color: $greenback;
                color: $appleGreen;
                padding: $padding5 - 3 $padding10 + 5;
                border-radius: 20px;
                font-size: $font12;
            }

            .user-member {
                background-color: $lightprimary;
                color: $primary;
                padding: $padding5 - 3 $padding10 + 5;
                border-radius: 20px;
                font-size: $font12;
            }
        }

        .user-grp-desc {
            margin: 0;
            min-height: $height50 + 30;

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                white-space: inherit;
                word-break: break-all;
            }
        }

        .data-domain-bottom {
            margin-top: $margin10;
            border-top: 1px solid $galleryGray;
            padding-top: $padding10;

            p {
                margin: 0 0 $margin5 0;
                font-size: $font12 - 2;
            }

            h6 {
                font-size: $font12 - 1;
                font-family: $inter-semibold;
                margin: 0;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
            }

            .data-domain-box {
                display: flex;

                .data-domain-box-inner {
                    display: flex;

                    span ~ div {
                        width: calc(100% - 3rem);
                        margin-left: $margin5;
                    }
                }
            }
        }

        &:hover {
            border-color: $primary;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
        }
    }
}

.data-domain-edit {
    display: flex;
    min-height: calc(100vh - 110px);

    label {
        margin: 0 0 $margin5 0;
        font-weight: 400 !important;
        display: $d-block;
        font-family: $inter-regular;

        &.ant-checkbox-wrapper {
            margin: 0;
            display: inline-flex;
        }
    }

    .data-domain-edit-left-part {
        width: 27%;
        padding: 0.9375rem;
        border-right: 1px solid $galleryGray;

        .warning {
            color: #fab43a;
            font-size: 0.625rem;
        }

        textarea {
            height: 6.25rem;
        }
    }

    .data-domain-edit-right-part {
        width: 73%;
        padding: 0.9375rem;
        height: calc(100vh - 5.625rem);
        overflow: auto;
        overflow-x: hidden;

        .data-domain-edit-header {
            display: flex;
            align-items: center;
            background-color: $header-back;
            padding: $padding10;
            margin-bottom: $margin10;

            p {
                margin: 0;
                font-family: $inter-semibold;
                margin: 0;
            }

            i {
                font-size: 1.25rem;
                margin: 0 $margin5;
            }
        }
    }

    .collapse-section-row-container {
        padding: 0;

        .collapse-container {
            box-shadow: none;
        }

        .collapse-header {
            padding: $padding5 0.9375rem;
            align-items: center;
            background-color: $header-back;

            h6 {
                font-size: $font14;
                font-family: $inter-semibold;

                i {
                    font-size: $font16;
                    vertical-align: bottom;
                    margin: 0 $margin5 0 0;
                }
            }
        }

        .collapse-div {
            margin: 0;
            padding: 0;
            border: none;

            table {
                th {
                    background-color: $tableColGrey;
                    font-family: $inter-semibold;
                }

                th,
                td {
                    border: none;
                    color: $color-content;
                    padding: 0.375rem 0.5rem;
                }

                td {
                    .bxs-crown {
                        padding: 0.1875rem;
                        border: 1px solid $yellow;
                        background-color: $white;
                        border-radius: 50%;
                        font-size: 0.5rem;
                    }

                    .ant-empty-normal {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.data-domain-detail {
    .usergroupList {
        background: #ffffff;
        margin-top: 0.625rem;
        padding: 0px;
    }
    .custom-breadcrumb {
        font-weight: 600;
    }
    .detail-header {
        margin-left: 0.3125rem;
    }
}

.data-domain-details-upper-section .upper-right {
    .text-value {
        color: #828282;
    }
    .upper-right-header {
        margin-bottom: 0.75rem;
    }
}

.dataDomain-lower-part {
    // background: #FFF;
    // max-height: calc(100% - 93px);
}
.user-details-content {
    .dataDomain-lower-part {
        height: calc(100% - 6.875rem) !important;
    }
}

.dataDomain-lower-part {
    .dictionary_search_container {
        float: right;
    }
    .header-icon {
        float: right;
    }
    .lower-subheader {
        border-bottom: 1px solid #eaeaea;
    }
    .heading-content {
        width: 12.5rem;
        margin-top: 0.125rem;
    }
    .domain-icon {
        float: left;
        margin-right: 0.3125rem;

        svg {
            height: 1.25rem;
            width: 1.25rem;
        }
    }
    .sub-header-right {
        // margin-top: 10px;
    }
    &.usr-manage-usr-grp {
        .heading-content {
            width: 100%;
        }
    }
}

.usr-manage-usr-dataDomain-scrollable {
    position: relative;
    height: calc(100vh - 16.125rem);
    overflow-y: scroll;
}

.usergroup-details-lower-section {
    position: relative;
    height: calc(100vh - 18.25rem);
    overflow-y: scroll;
}

.data-domain-edit label {
    color: #828282;
    font-weight: 600;
    font-size: 0.75rem;
}

.data-domain-edit {
    .data-domain-edit-right-part .empty-domain {
        text-align: center;

        svg {
            height: 8.75rem;
            width: 10.75rem;
        }
    }
    .data-domain-edit-right-part .empty-domain .head {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .data-domain-edit-right-part .empty-domain .para {
        font-size: 0.8125rem;
        line-height: 1.125rem;
        text-align: center;
        color: #8e8e8e;
    }
}

.select-grp-types {
    display: flex;
    align-items: center;
    height: $height-full;

    p {
        margin: 0;
        color: $color-gray;
        width: $width-full - 50;
        border: 1px solid $galleryGray;
        font-size: $font12;
        padding: 0.1875rem $padding5;
        text-align: center;
        cursor: pointer;
        width: max-content;
        // width: 80px;
        display: flex;
        gap: 0.3125rem;
        align-items: center;
        & svg {
            stroke: $color-gray;
            height: 0.625rem;
            width: 0.8125rem;
        }
        &.owner-style {
            background-color: #2c83c4;
            border-color: #5f5de9;
            color: #fff;
            cursor: auto;
            width: 4.375rem;
            & svg {
                stroke: #fff !important;
            }
        }

        &.user-style {
            background-color: #2c83c4;
            border-color: #5f5de9;
            color: #fff;
            /* background-color: rgba(95, 93, 233, 0.1); */
            cursor: auto;
            width: max-content;
            // width: 80px;
            & svg {
                stroke: #fff !important;
            }
        }
        .filter {
            float: left;
        }
    }
}

.modal-input-lbl {
    font-size: 12px;
    color: #828282;
}

.Create-Domain-right {
    margin-top: $margin10;
    margin-bottom: $margin10;

    .lower-subheader {
        padding: 10px 0px;
    }

    .heading-content {
        display: flex;
        align-items: center;

        i {
            font-size: $font16;
        }

        p {
            margin: 0 0 0 $margin5;
            font-size: $font14;
            font-family: $inter-semibold;
        }
    }
}
.float-right {
    float: right;
}

.data-domain-details-upper-section .upper-right .text-value {
    font-size: 9px;
}

.border-bottom {
    border-bottom: 1px solid #f1f1f1 !important;
    padding-bottom: 5px !important;
}

.dataDomain-lower-part .sub-header {
    border-bottom: 1px solid #f1f1f1 !important;
}

.create-datadomain .sub-header {
    border-bottom: 1px solid #f1f1f1 !important;
}

.ant-table-header .ant-table-thead .type-cell {
    text-align: center;
}
.mt-20 {
    margin-top: 20px;
}

.data-connectiongridview-responsive .card {
    min-height: 165px;
}

.data-connectiongridview-responsive .card {
    display: grid;
    gap: $padding10;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    margin: 0;
    padding: $padding10;
    position: $p-relative;

    .card-tags {
        bottom: auto;
        top: 40px;
        right: 30px;
    }

    .card {
        border-radius: 3px;
        border: 1px solid $grey-three;
        cursor: pointer;
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
                0 5px 12px 4px rgba(0, 0, 0, 0.09);
        }
    }

    .card-body {
        padding: $padding10;
        position: relative;
    }

    .connectionsCard-circle {
        display: flex;
        justify-content: space-between;
        position: $p-relative;

        &::after {
            content: "";
            position: $p-absolute;
            height: 1px;
            background-color: $light-background;
            left: -10px;
            right: -10px;
            top: 50%;
            transform: translate(0, -50%);
        }

        .icon-box {
            width: $width70;
            height: $height70;
            border-radius: 50%;
            border: 1px solid $light-background;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            z-index: 1;

            img {
                height: $height50;
                width: auto;
            }
        }

        .connectionsCard-profile-list {
            display: flex;
        }
    }

    .connectionsCard-actions {
        display: flex;
    }

    .connectionsCard-header {
        display: flex;
        align-items: center;
        margin-bottom: $margin10;

        p {
            margin: 0;
            color: $primary;
        }
    }

    .connectionsCard-description {
        margin-bottom: $margin10;
        .connection-heading {
            font-family: $inter-bold;
        }

        .grey-text {
            color: $grey-three;
        }

        .connectionsCard-status {
            position: absolute;
            right: 15px;
            top: 55px;
        }
    }
}

.error {
    color: #ff565e;
}

.data-domain-edit-right-part .row {
    font-size: 0.8125rem !important;
}

.domain-usr-grp-usrlist {
    .user-list-container {
        height: 100%;
        width: 100%;
    }
    .user-list-wrapper {
        height: calc(100% - 1.25rem);
        overflow-y: auto;
        margin-inline: -1.5rem;
        padding: 1.5rem;
    }
}

// .policy-table {
//     .custom-simple-landing-dgt {
//         grid-template-columns: 298.6px 304.4px 150px 150px !important;
//     }
// }

// .usr-manage-usr-grp {
//     .usergroup-details-lower-section {
//         .custom-simple-landing-dgt {
//             grid-template-columns: 298.6px 304.4px 150px 150px !important;
//         }
//     }
// }
