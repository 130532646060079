//accordion

body {
    .landing-page-accordion {
        .custom-accordion {
            background-color: $white;
        }
    }
    .custom-accordion {
        .ant-collapse {
            .ant-collapse-item {
                border-bottom: 1px solid $black;
                // margin-bottom: $margin10;
            }

            .ant-collapse-header {
                padding: $padding10 !important;
                font-size: $font14;
                font-family: $inter-semibold;
                text-transform: $tt-uppercase;
                line-height: 24px;
                color: $black;
                border-bottom: 1px solid $grey-one;
                display: flex;
                align-items: center;
                & > svg {
                    height: 16px;
                }
                .ant-collapse-extra {
                    svg {
                        // border: 1px solid #17171A;
                        padding: 2px;
                        border-radius: 2px;
                    }
                    button {
                        svg {
                            border: none;
                            padding: 0px;
                            border-radius: 0px;
                        }
                    }
                }
                
            }

            .ant-collapse-content-box {
                padding: 0 !important;
            }
            .ant-collapse-extra {
                svg {
                        transition: .3s;
                    }
            }
            .ant-collapse-item-active {
                .ant-collapse-extra {
                    & > svg {
                        transform: rotateX(180deg);
                        // border: 1px solid #17171A;
                        padding: 2px;
                        border-radius: 2px;
                        transition: .3s;
                    }
                        .add-icon-container {
                            & > svg {
                                    transform: rotateX(180deg);
                                    border: 1px solid #17171A;
                                    padding: 2px;
                                    border-radius: 2px;
                                    transition: .3s;
                                }
                        }
                    button {
                        svg {
                            border: none;
                            padding: 0px;
                            border-radius: 0px;
                        }
                    }
                }
            }
        }

        .add-icon-container {
            display: flex;
            align-items: center;
        }
    }

    .ant-tabs {
        .ant-tabs-content-holder {
            .custom-accordion {
                padding: 0 $padding10;
                .ant-collapse-item {
                    border: none;
                }
                .ant-collapse-header {
                    border-bottom: 1px solid $light-background;
                    padding: $padding10 0 !important;
                }
                .ant-collapse-content {
                    padding: $padding10 0;
                }
            }
        }
    }
}
