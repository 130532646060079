.flows-multicol {
    .custom-vlist-dgt {
        background-color: #FFF;
        border: none !important;
        grid-template-columns: auto !important;
        .rdg-header-row {
            background-color: #FFF !important;
        }
        .rdg-cell {
            border: none !important;
            box-shadow: none !important;
        }
        .rdg-row {
            background-color: #fff;
            
            &:hover {
                background-color: #f5f5f5 !important;
            }
        }
    }
}