@font-face {
    font-family: 'InterBlack';
    src: url('../fonts/Inter-Black.woff2') format('woff2'),
        url('../fonts/Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterExtraBold';
    src: url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
        url('../fonts/Inter-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterBold';
    src: url('../fonts/Inter-Bold.woff2') format('woff2'),
        url('../fonts/Inter-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterSemibold';
    src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterMedium';
    src: url('../fonts/Inter-Medium.woff2') format('woff2'),
        url('../fonts/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterRegular';
    src: url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterLight';
    src: url('../fonts/Inter-Light.woff2') format('woff2'),
        url('../fonts/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterExtraLight';
    src: url('../fonts/Inter-ExtraLight.woff2') format('woff2'),
        url('../fonts/Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterThin';
    src: url('../fonts/Inter-Thin.woff2') format('woff2'),
        url('../fonts/Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

// inter black
$inter-black: "InterBlack";

// inter bold
$inter-bold: "InterBold";

// inter semibold
$inter-semibold: "InterSemibold";

// inter medium
$inter-medium: "InterMedium";

// inter regular
$inter-regular: "InterRegular";

//inter light
$inter-light: "InterLight";