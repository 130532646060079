@import "../../../assets/scss/common/colors";

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #17171a;
}


.new-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 150;
  // z-index: 8;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  &-open {
    opacity: 1;
    pointer-events: all;
  }
}

// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
//     background: transparent;
// }

.new-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1060;
  // z-index: 10;
  width: 14rem;
  background: $black;
  transition: all 0.6s ease;  //delay in speed of sidebar

  .new-logo {
    img {
      height: 1.5rem;
    }
  }

  .ant-menu-title-content {
    font-family: "InterRegular";
  }

  li.ant-menu-item svg {
    height: 1.125rem;
    width: 1.125rem;
  }

  &-top {
    padding: 8px 12px;
    cursor: pointer;
    border-bottom: none;
    min-height: 2.5rem;

    display: flex;
    align-items: center;
    user-select: none;
  }

  &-bottom {
    height: 100%;
  }

  &-hide {
    transform: translateX(-500%);
    width: 45px;
  }

  &-collapse {
    width: 45px;

    .new-logo {
      pointer-events: none;
      opacity: 0;
    }

    .new-navigation-title {
      opacity: 0;
      pointer-events: none;
      white-space: nowrap;
    }
  }
  .ant-menu-submenu-title {
    margin-inline: 0 !important;
  }
  .ant-menu-item:not(.ant-menu-item-disabled):focus-visible{
    outline: none !important;
  }
  .ant-menu-sub > .ant-menu-item {
    padding-left: 2.6875rem !important;
    height: 2.5rem !important;
    line-height: 2.5rem !important;
  }
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #17171a;
}

.hamburger {
  margin-right: 20px;
  cursor: pointer;

  path {
    fill: $white !important;
  }
}

.new-logo {
  pointer-events: all;
  opacity: 1;
  transition: all 0.3s ease;
}

.collapsed-navigation-icon:hover,
.active-menu {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

.new-navigation {
  &-list {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
  }

  &-item {
    cursor: pointer;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &-link {
    display: block;
    position: relative;
    padding: 0px 15px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &-link:hover &-title {
    color: $white;
  }

  &-activelink {
    background: rgba(255, 255, 255, 0.2);
  }

  &-activelink &-title {
    color: $white !important;
  }

  &-icon path {
    fill: $white !important;
  }

  &-title {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translateY(-50%);
    color: $grey-three;
    font-weight: 600;

    opacity: 1;
    pointer-events: auto;
    white-space: nowrap;

    transition: opacity 0.3s ease;
  }
}

.nav-vertical-scroll {
  height: calc(100vh - 2.8rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::-webkit-scrollbar {
    display: none;
  }
}

#lineage-sb-ic {
  #i {
    stroke: #fff;
  }

  #y {
    fill: transparent !important;
    stroke: #fff;
  }
}

.ant-menu-submenu-title {
  padding: 0 10px !important;
}

.ant-menu.ant-menu-inline-collapsed {
  width: 44px;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  width: 100% !important;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.submenu-popup .ant-menu-item {
  width: calc(100% - 8px) !important;
}

.ant-menu-vertical .ant-menu-submenu-title {
  margin-inline: 0px !important;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: rgb(23, 23, 26) !important;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub:hover {
  color: rgba(255, 255, 255, 0.65);
  background: rgb(23, 23, 26) !important;
}

.new-navigation-link {
  font-size: 13px;

  & svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
}

.ant-menu-item:active {
  background-color: rgb(23, 23, 26);
}

.ant-menu-item-selected:active {
  background-color: #454548 !important;
}

.ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: transparent !important;
}

// .ant-menu-item:active {
//     background-color: #454548;
// }
// .ant-menu-item:hover {
//     background-color: #454548;
// }
// .ant-menu-item-active {
//     background-color: #454548;
// }

.new-navigation-activelink {
  background: rgba(255, 255, 255, 0.2);
}

.new-navigation-link:hover {
  background: rgba(255, 255, 255, 0.2);
}

.ant-menu-title-content {
  font-size: 0.8125rem;
}

.home-link .ant-menu-submenu-arrow {
  display: none;
}

.home-link .ant-menu-title-content {
  padding-top: 0.375rem;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: transparent;
}

.new-sidebar {
  .ant-menu-item {
    svg {
      path {
        fill: white;
        opacity: 0.7;
      }
    }
  }

  & .ant-menu-submenu-title {
    display: grid;
    grid-template-columns: 1.875rem auto;
  }

  & .ant-menu-submenu-title {
    display: grid !important;
    grid-template-columns: 1.875rem auto;
    align-items: center !important;
    height: 2.5rem !important;
    line-height: 2.5rem!important;
  }

  & .ant-menu-vertical > li:first-child {
    margin-left: 0px !important;
  }

  & .ant-menu-root > li svg {
  // & .ant-menu-root > li:first-child svg {
    margin-left: 0.1875rem !important;
  }

  .ant-menu-root > li:nth-child(2) {
    svg {
      margin-top: 0.25rem;
    }
  }

  .ant-menu-root {
    & > li:last-child .ant-menu-submenu {
      .ant-menu-title-content {
        margin-left: 0 !important;
      }
    }
  }
}

.submenu-popup {
  svg {
    margin-right: 10px;

    path {
      fill: white;
      opacity: 1;
    }
  }
}

.new-sidebar-menu {
  & > .ant-menu-item {
    display: grid !important;
    grid-template-columns: 1.875rem auto auto;
    align-items: center;
    margin: 0px;
    height: 2.5rem !important;
    line-height: 2.5rem !important;
  }

  > .ant-menu-item {
    padding-left: 10px !important;
    .ant-menu-title-content {
      padding-left: 0.625rem;
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      svg {
        path {
          opacity: 1;
        }
      }
    }
  }

  .ant-menu-submenu {
    .ant-menu-title-content {
      margin: 0 !important;
      padding-left: 0.625rem;
    }
  }

  .ant-menu-item {
    svg {
      path {
        fill: white;
      }
    }
  }

  .ant-menu-item-selected {
    svg {
      path {
        opacity: 1;
      }
    }
  }

  .ant-menu-submenu-title {
    svg {
      margin-right: 0.625rem;
    }
  }

  svg {
    path {
      fill: white;
      opacity: 0.7;
    }
  }
}
.ant-menu-title-content {
  font-family: "InterRegular";
}

.ant-menu-item-disabled {
  display: none !important;
}

.newTab-Hyperlink{
  text-decoration: none !important;
}

.ant-menu-submenu-title {
  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
}