.pulse-logimg-wrap {
    position: relative;
    display: flex;
    height: 8rem;
    width: 22.25rem;
    max-width: 25rem;
    // width: 270px;
    align-items: center;
    justify-content: center;
    // border: solid 1px #d9d9d9;
    border-radius: 6px;
    overflow: hidden;

    img {
        // max-width: 90%;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }


    .pulse-logimg-overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0.625rem;
        z-index: 1;
        background-color: #000000a1;
        opacity: 0;
        transition: .3s;
    }

    &.en {
        &:hover {
            .pulse-logimg-overlay {
                opacity: 1;
                transition: .3s;
            }
        }
    }

    .img-act-btn {
        display: flex;
        height: 1.5rem;
        width: 1.5rem;
        align-items: center;
        justify-content: center;
        color: #ffffffc2;
        cursor: pointer;
        transition: .3s;

        svg {
            height: 1.125rem;
            width: 1.125rem;
        }

        &:hover{
            color: #FFF;
            transition: .3s;
        }
    }
}