@import "../../../../../assets/scss/common/colors";
@import "../../../../../assets/scss/common/font";
@import "../../../../../assets/scss/common/variables";

.policy-container {
  padding: 10px;
  display: flex;
  gap: 8px;
  height: calc(100vh - 75px);
  background-color: #f9f9f9;
  position: relative;

  .left-bar {

    width: 20%;
    border-right: 1px solid $light-background;
    background-color: $white;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $white;
    transition: .4s;
  }  

  .right-bar {
    width: 80%;
    position: relative;
    background-color: $white;
    overflow-y: scroll;
    background-color: #f9f9f9;
    transition: .4s;
  }
}