.policy-row-mask {
  height: 18.75rem;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.1875rem;
  margin-bottom: 0.9375rem;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;

  .policy-row-mask-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
    border-bottom: 0.0625rem solid #f1f1f1;
  }

  .policy-row-mask-title h4 {
    font-weight: 600;
    font-size: 0.8125rem;
    margin: 0;
  }

  .policy-row-mask-options {
  }

  .policy-row-mask-table {
    flex: 1;
    overflow-y: scroll;
    .filter-section{
      overflow: hidden;
      .filter-min-max-height{
        max-height: 10.625rem !important;
        min-height: 2.5rem;
      }
    }
    
  }
}
