.clst-notification-pop-wrap {
    .ant-popover-content {
        width: 21.875rem;
    }
    .ant-tabs-content-holder {
        max-height: 18.75rem;
        overflow-y: auto;
    }
}

.clst-noti-tabs {
    height: 100%;
    // max-width: 44.5rem;
    .ant-tabs-tab {
        padding: 0.375rem 0.125rem 0.375rem 0.125rem;
        border: none;
        border-radius: 0;
        font-size: 0.8125rem;
        line-height: 1;
    }

    .ant-tabs-content {
        height: 100%;

        .ant-tabs-tabpane-active {
            height: 100%;
            overflow-y: auto;
        }
    }

    // .ant-tabs-tab:hover {
    //     span {
    //         color: #2c83c4! important;
    //     }
    //     svg path {
    //         fill: #2c83c4! important;
    //     }
    // } 

    // .ant-tabs-tab.ant-tabs-tab-active span {
    //     color: #2c83c4! important;
    //     font-family: 'InterSemibold';
    // }

    .ant-tabs-tab .ant-tabs-tab-btn span {
        font-family: 'InterSemibold';
    }

    .ant-tabs-tab.ant-tabs-tab-active svg path {
        fill: #2c83c4! important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        border-bottom: solid 0.125rem #2c83c4;
    }

    .ant-tabs-nav {
        margin-bottom: 0 !important;
    }

    .ant-tabs-nav::before {
        border: none;
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 0.9375rem !important;
    }

    .ant-tabs-nav-more {
        display: block;
        width: 1.875rem;
        height: 1.875rem;
        padding: 0 !important;
        line-height: 0rem;
        font-size: 1rem;
        font-weight: bold;
        color: #333 !important;
        border-radius: 100%;
        transform: rotateZ(90deg);
        border: solid 0.0625rem #d6d6d6 !important;
        top: 0.25rem;
        left: 0.625rem;
    }

    .ant-badge-count {
        padding: 0.0625rem 0.125rem;
        font-size: 0.625rem;
        font-family: "InterSemibold";
        top: auto !important;
        background: #5f5f5f !important;
    }

    .ant-tabs-tab-btn {
        color: #5f5f5f !important;
    }

    .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: #2c83c4 !important;
        }
        .ant-badge-count {
            background: #2c83c4 !important;
        }
    }

    .ant-scroll-number {
        height: 1rem;
        min-width: 1rem;
    }

    .ant-tabs-nav {
        padding: 0 0.9375rem;
        border-bottom: solid 0.0625rem #dcdcdc;
    }

    .ant-tabs-nav-list {
        & > div:first-child {
            position: relative;
            margin-right: 0.625rem;

            &::after {
                content: '';
                display: block;
                width: 0.0625rem;
                height: 1.125rem;
                background: #dcdcdc;
                position: absolute;
                right: -0.875rem;
            }
        }
    }

    .ant-tabs-extra-content {
        margin-right: -0.3125rem;
        padding-bottom: 0.25rem;
    }
}

.clst-noti-box {
    display: grid;
    grid-template-columns: 2.625rem auto 2.25rem;
    padding: 0.625rem 0.3125rem;
    cursor: pointer;

    & > div:nth-child(2) {
        margin-left: 0.625rem;
    }

    &:hover {
        background: #f0f0f0;
    }
}

.clst-noti-title {
    font-size: 0.8125rem;
    margin-bottom: 0;
    font-family: 'InterRegular';

    > p {
        margin-bottom: 0;
    }
}

.clst-noti-mod-icon {
    text-align: center;
    // padding-top: 0.625rem;
    svg {
        height: 1.25rem;
        width: 1.25rem;
    }
}

.clst-noti-unread-dot {
    text-align: center;
    padding-top: 0.625rem;
    font-size: 0.6875rem;
}

.clst-noti-ts {
    font-size: 0.6875rem;
    color: #989898;
    margin-top: 0.1875rem;
    margin-bottom: 0.3125rem;
}

.clst-noti-pop-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-top: solid 0.0625rem #dcdcdc;
}

.cls-noti-mark-read-btn {
    text-transform: none !important;
}

.clst-noti-drawer-wrap {
    height: calc(100vh - 2.25rem);
    top: 2.25rem;
    outline: none !important;

    &.pulse-page {
        height: calc(100vh - 3rem);
        top: 3rem;
    } 
    
    .ant-drawer-mask {
        background: transparent;
    }
    .ant-drawer-content-wrapper {
        width: 28.125rem !important;
        box-shadow: 0.625rem 1rem 1.4375rem -0.1875rem #00000060;
        border-left: solid 0.0625rem #dcdcdc;

        @media (max-width: 40rem) {
            width: 100% !important;
        }
    }
    .ant-drawer-body {
        padding: 0 !important;
        height: 100%;
    }
}

.clst-notification-content-wrap {
    height: 100%;
    padding-top: 0.25rem;
}

.clst-noti-filter {
    border-bottom: solid 0.0625rem #dcdcdc;

    .nusg-dropdown-label {
        font-size: 0.6875rem;
    }

    .nusg-dropdown-ic {
        height: 1.5rem;
        width: 1.5rem;
    }

    .nusg-dropdown-selected {
        border: solid 0.0625rem #dcdcdc;
        border-radius: 0.375rem;
        padding: 0.1875rem 0.3125rem;
    }
}

.clst-noti-filter-health {
    display: grid;
    grid-template-columns: auto 19.0625rem;

    .nusg-dropdown-selected {
        width: 9.375rem !important;
    }

    .nusg-dropdown-label {
        width: 5.625rem !important;
    }
}

.health-noti-ddl {
    width: 9.375rem !important;
}

.notification-items-wrap {
    &.mod-alert {
        height: calc(100% - 10.5rem);
        // height: calc(100% - 8.25rem);
        overflow-y: auto;
        
        @media (max-width: 1280px) {
            height: calc(100% - 12rem);
        }

    }
    &.mod-health {
        height: calc(100% - 10rem);
        // height: calc(100% - 8.625rem);
        overflow-y: auto;
    }

} 

.app-noti-wrap {
    cursor: pointer;
    padding: 0.875rem 0.875rem !important;
    width: 24rem !important;

    .ant-notification-notice-close {
        top: 50% !important;
        transform: translateY(-50%);
    }

    .ant-notification-notice-icon {
        font-size: 1.5rem !important;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-message {
        margin-bottom: 0.5rem !important;
        margin-inline-start: 2.25rem !important;
    }

    .anticon-info-circle > svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}

.custom-noti-notice {
    font-family: 'InterRegular';
    margin-bottom: -0.25rem;
    padding-right: 0.875rem;
    font-size: 0.8rem;

    & > p {
        margin-bottom: 0;
        font-size: 0.8125rem;
    }
}