.user-group-selection-name {
  display: flex;
  align-items: center;
  gap: 10px;
}


.user-group-initials {
  margin-top: 5px;

  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  background-color: #ffffff;
  color: #17171a;
  border: 1px solid #17171a;
  margin-right: -5px;
  position: relative;
}

.user-group-leader {
  color: #2c83c4;
  border: 1px solid #2c83c4;
}