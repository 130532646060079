@import "../../../assets/scss/common/colors";
@import "../../../assets/scss/common/variables";
@import "../../../assets/scss/common/font";

.scheduler-gridview-responsive {
    display: grid;
    gap: 0.9375rem;
    grid-template-columns: repeat(auto-fill, minmax(19.125rem, 1fr));
    margin: 0;
    padding: $padding10;
    position: $p-relative;
    overflow-x: auto;
    overflow: hidden;
    .card-tags {
        bottom: auto;
        top: 2.5rem;
        right: 1.875rem;
    }

    .card {
        border-radius: 0.1875rem;
        border: none;
        cursor: pointer;
        transition: box-shadow 0.2s ease-in-out;
        min-height: 7.75rem;

        &:hover {
            box-shadow:
                0 1px 2px -2px rgba(0, 0, 0, 0.16),
                0 3px 6px 0 rgba(0, 0, 0, 0.12),
                0 5px 12px 4px rgba(0, 0, 0, 0.09);
        }
    }

    .card-body {
        padding: $padding10;
        position: relative;
    }

    .schedulerCard-circle {
        display: flex;
        justify-content: space-between;
        position: $p-relative;

        &::after {
            content: "";
            position: $p-absolute;
            height: 1px;
            background-color: $light-background;
            left: -10px;
            right: -10px;
            top: 50%;
            transform: translate(0, -50%);
        }

        .icon-box {
            width: $width70;
            height: $height70;
            border-radius: 50%;
            border: 1px solid $light-background;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            z-index: 1;

            img {
                height: $height50;
                width: auto;
            }

            svg {
                height: 40px;
                width: 40px;
            }
        }

        .schedulerCard-profile-list {
            display: flex;
        }
    }

    .schedulerCard-actions {
        display: flex;
    }

    .schedulerCard-header {
        display: flex;
        align-items: center;
        margin-bottom: $margin10;

        p {
            margin: 0;
            color: $primary;
        }
    }

    .schedulerCard-description {
        margin-bottom: $margin10;

        .scheduler-heading {
            font-family: $inter-bold;
            font-size: 18px;
            color: #2c83c4;
        }

        .grey-text {
            color: $grey-three;
        }

        .schedulerCard-status {
            position: absolute;
            right: 15px;
            top: 55px;
        }
    }

    .schedulerCard-profile-list {
        .schedulerCard-profile-listitem {
            display: flex;
            align-items: center;
            margin-bottom: 0;

            .label {
                margin: 0 $margin10;
            }

            span {
                &:last-child {
                    width: calc(100% - 106px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .red-border {
        border: 1px solid $error-red;
    }
}

.scheduler-list-wrap {
    .custom-simple-landing-dgt {
        height: calc(100vh - 87px);
        overflow-x: auto;
    }
}

.sch-ls-name {
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
    gap: 6px;
}

.sch-ls-ic {
    display: flex;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    background: #f1f0f0;
    border-radius: 100%;

    svg {
        path {
            fill: #2c83c4;
        }
    }
}

.sch-ls-text {
    // color: #2c83c4;
    font-weight: 700;
}

:is(.timezone-select, .timezone-select-option) .element-name {
    display: block;
}

.sch-timestamp-list {
    height: 122px;
    overflow-y: auto;
    border: solid 1px #d0d0d0;
    border-radius: 3px;
}

.sch-steps-dnd-list {
    height: calc(100vh - 210px);

    .rdg-cell {
        padding: 0 !important;
    }
}

.sch-timestamp-row {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        .sch-del-timestamp {
            display: block;
        }
    }
}

.sch-del-timestamp {
    display: none;
}

body {
    .ant-select-dropdown.react-js-cron-select-dropdown.react-js-cron-select-dropdown-period {
        min-width: auto !important;
        width: 12.5rem !important;
        max-width: 12.5rem !important;
        font-size: 0.75rem !important;
        font-family: $inter-regular !important;
    }
    .react-js-cron-select-dropdown-months,
    .react-js-cron-select-dropdown-month-days,
    .react-js-cron-select-dropdown-week-days,
    .react-js-cron-select-dropdown-hours {
        min-width: auto !important;
        width: 12.5rem !important;
        max-width: 12.5rem !important;
        font-size: 0.75rem !important;
        font-family: $inter-regular !important;
    }

    .react-js-cron-field {
        margin-bottom: 0.625rem;
    }

    .dsrc-lf-icon {
        img {
            height: 0.9375rem;
            width: 0.9375rem;
        }
    }

    // .react-js-cron-custom-select-dropdown {
    //     min-width: 0 !important;
    //     width: 200px !important;
    //     max-width: 200px;
    // }

    .cron-dropdown {
        .ant-select-selector {
            width: 12.5rem !important;
            font-size: 0.75rem !important;
        }

        .react-js-cron-field {
            font-size: 0.75rem !important;
        }
    }
}

.steps-ls-ic {
    & > svg {
        width: 1rem;
    }
}

.cursor-move-stp-ls {
    & > svg {
        cursor: grab;
    }
}

.scheduler-settings-wrapper {
    width: 99%;
}

.scheduler-toggle-disable {
    .ant-radio-button-wrapper-checked {
        & span > label {
            color: #ffffff99 !important;
        }

        & svg > path {
            fill: #ffffff99 !important;
        }
    }
}

.notification-list-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 482px);

    & .toggleAntD {
        margin-top: 3px;
        margin-left: 1px;
    }
    .scheduler-toggle-disable {
        .ant-radio-button-wrapper-checked {
            & span > label {
                color: #ffffff99 !important;
            }

            & svg > path {
                fill: #ffffff99 !important;
            }
        }
    }
}

.selected-border {
    box-shadow: 0px 1px 4px #2c83c4 !important;
}

.scheduler-sqlEdition {
    border: 1px solid #929292;
    padding: 5px;
    border-radius: 8px;

    .custom-sql-editor-note code {
        font-size: 7px;
        padding: 0px !important;
    }
}

.float-execute-btn {
    position: fixed;
    width: 2.1875rem;
    height: 2.125rem;
    border-radius: 50%;
    bottom: 0.375rem;
    cursor: pointer;
    right: 0.75rem;
    z-index: 11;
    box-shadow: 0 0 15px rgba(55, 54, 54, 0.15);

    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        height: 0.9375rem;
        width: 0.9375rem;
    }
}

.podtag-list-scheduler {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // border: 1px solid #929292;
    height: 1.5rem;
    border-radius: 0.25rem;
    width: 66%;
    margin: 0px 0.9375rem 1px 0;
    padding: 1px 2px;

    .podTag {
        background-color: $primary;
        display: flex;
        align-items: center;
        padding: 0.25rem;
        border-radius: 1px;
        font-size: 0.5rem;
        font-family: $inter-regular;
        height: 1.25rem;
        margin: 0 0.3125rem 0 0;

        span {
            color: $white;
        }
    }
}

.icon-box2 {
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    border: 3px solid #2c83c4;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    z-index: 1;

    img {
        height: 1.5625rem !important;
        width: auto;
    }

    svg {
        height: 1.25rem !important;
    }
}

.overwrite-insert-toggle .ant-radio-group {
    display: flex !important;
    width: 100%;
}

.overwrite-insert-toggle .ant-radio-group > label {
    width: 50%;
}

.black-svg-color svg :is(rect, circle, line) {
    stroke: black;
    stroke-width: 1.2;
}

.scheduler-gridview-responsive > div :is(.paragraph) {
    font-size: 0.8125rem !important;
}

.scheduler-gridview-responsive > div .label {
    font-size: 0.625rem;
}

.scheduler-gridview-responsive > div span.paragraph span {
    font-size: 0.625rem;
}

.scheduler-gridview-responsive > div .scheduler-heading + p {
    font-size: 0.6875rem !important;
}

.scheduler-settings-wrapper .label svg {
    height: 1.125rem;

    path {
        fill: #2c83c4;
    }
}

.scheduler-settings-wrapper .card-header {
    & label svg {
        height: 0.9375rem;
    }

    font-size: 0.8125rem;
}

.selected-step-border {
    background-color: #ddedff;
    border: solid 1px #2c83c4;
}

.steps-outer-card {
    .ant-checkbox-wrapper {
        margin: 0.5625rem;
    }
}

.cron-dropdown .ant-select-disabled .ant-select-selector {
    padding: 0 !important;
    background: #ffffff !important;
    color: #707070 !important;
    border: none;
    width: auto !important;
}

.cron-dropdown .ant-select-disabled .ant-select-arrow {
    display: none;
}

.cron-dropdown .ant-select-disabled .ant-select-selection-item {
    line-height: 2rem;
    padding: 0;
}

.cron-dropdown .react-js-cron-custom-select {
    min-width: 4.375rem;
    z-index: 1;
    height: 2rem;

    .ant-select-selection-overflow {
        top: -2px;
    }
}

.cron-dropdown .react-js-cron-field {
    line-height: 2rem;
}

.scheduler-icon-box :is(svg, img) {
    height: 1.875rem;
    width: 1.75rem;
}

.text-elipse-scheduler-name {
    white-space: nowrap;
    width: 14.375rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-js-cron-select-dropdown-month-days,
.react-js-cron-select-dropdown-week-days,
.react-js-cron-select-dropdown-hours,
.react-js-cron-select-dropdown-minutes {
    .rc-virtual-list .ant-select-item-option .ant-select-item-option-content {
        padding: 5px 5px !important;
        // ;

        // overflow:visible !important;

        // text-overflow:visible !important;
    }
    .rc-virtual-list-holder {
        max-height: 380px !important;
    }
    .ant-select-item {
        padding: 5px 5px !important; //reduce padding for content
    }
}

.scheduler-gridview-responsive {
    .filled-star-icon,
    .star-icon {
        & svg {
            height: 0.8125rem;
        }

        position: absolute;
        top: -0.1rem;
        right: 1.25rem;
        z-index: 1;
        width: 1.625rem;
        height: 1.625rem;
        // border: solid 1px #00000029;
        border-radius: 50%;
        // background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.react-js-cron-clear-button {
    display: block;
    position: absolute;
    top: -2.5625rem;
    height: 1.75rem;
    padding: 0.25rem 0.9375rem;
    font-size: 0.75rem;
    right: 0px;
    background: transparent !important;
    border: 1px solid red !important;
    border-radius: 0.1875rem;
    color: #333333 !important;
    span {
        display: none !important;
    }
}

.TZ-Select {
    position: absolute;
    top: -2.5625rem;
    .ant-select-selection-item {
        user-select: text !important;
    }
}
.react-js-cron-clear-button::after {
    content: "Reset";
}

.react-js-cron-select-dropdown-minutes,
.react-js-cron-select-dropdown-month-days,
.react-js-cron-select-dropdown-hours {
    .ant-select-item-option-selected,
    .ant-select-item-option-active {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .ant-select-item-option:hover,
    .ant-select-item-option:focus,
    .ant-select-item-option:focus-visible,
    .ant-select-item-option-active:hover,
    .ant-select-item-option-active:focus,
    .ant-select-item-option-active:focus-visible {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}
.scheduler-detail-page-wrapper {
    height: calc(100vh - 2.5rem);
    font-family: "InterSemibold";
    font-size: $font14;
    // font-weight: 600;
    color: #333333;

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
    }

    @media (max-width: 930px) {
        .col-4:first-child {
            -ms-flex: 0 0 20% !important;
            flex: 0 0 20% !important;
            max-width: 20% !important;
        }
        .col-4:nth-child(2) {
            -ms-flex: 0 0 40% !important;
            flex: 0 0 40% !important;
            max-width: 40% !important;
        }
        .col-4:nth-child(3) {
            -ms-flex: 0 0 40% !important;
            flex: 0 0 40% !important;
            max-width: 40% !important;
        }
    }

    .cron-dropdown {
        .react-js-cron {
            font-weight: 400;
        }

        .ant-select-selector {
            border-radius: 3px;
        }
    }

    .select-model-btn {
        height: 1.75rem !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        font-size: 0.6rem !important;

        svg {
            height: 0.5rem !important;
            width: 0.7rem !important;
        }
    }
    .parameter-btn {
        height: 1.75rem !important;
        width: 1.75rem !important;
    }
    .select-model-input {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
        border: 1px solid #e6e6e6;
        width: 100%;
        padding: 0.3125rem 0.625rem;
        outline: none !important;
        font-size: 0.6875rem;
        font-weight: 400 !important;
        height: 1.75rem;

        svg {
            height: 1.125rem;
            width: 1.125rem;
        }
    }

    .ant-radio-wrapper {
        color: #333333;
        font-size: $font12;
        font-family: $inter-regular;
    }

    .schedule-btn-outline {
        border: 1px solid #d3d3d4;
        border-radius: 0.25rem;
        background: transparent;
        font-size: 0.6875rem;
        text-transform: capitalize !important;
        font-weight: 400 !important;
    }

    .cron-dropdown .ant-select-disabled .ant-select-selector {
        padding: 4px 30px !important;
        background: #ffffff !important;
        color: #707070 !important;
        border: none;
        width: auto !important;

        .ant-select-selection-placeholder {
            padding: 0px !important;
        }
    }

    .toggleAntD {
        margin-bottom: 1px;
    }
    .toggleAntD > .ant-radio-group {
        border-radius: 2px !important;
    }
    .toggleAntD .ant-radio-button-wrapper {
        height: 26px !important;
        border-radius: 2px !important;

        .ant-radio-button-wrapper-checked {
            border-radius: 2px !important;
        }
    }

    .cron-dropdown .ant-select-disabled .ant-select-arrow {
        display: none;
    }

    .cron-dropdown .ant-select-disabled .ant-select-selection-item {
        line-height: 2rem;
        padding: 0;
    }

    .cron-dropdown .react-js-cron-field {
        line-height: 2rem;
    }

    .scheduler-sub-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .custom-border-bottom {
        border-bottom: 1px solid #d6d6d6;
    }
    .notification-section {
        // width: 39rem;
        height: 10rem;
        border-radius: 3px;
        background: #f7f7f7;
        display: grid;
        place-content: center;
        place-items: flex-start;
        grid-template-columns: 1fr;
        padding: 0.4375rem 0.8125rem;

        .custom-select-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector .ant-select-selection-item {
            height: 1rem !important;
            line-height: 1.4 !important;
        }

        .custom-select-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
            align-items: self-start;
            padding-top: 0.3rem !important;
        }

        .ant-radio-wrapper {
            color: #333333;
            font-size: $font12;
            font-family: $inter-regular;
        }
        .ant-checkbox-wrapper {
            color: #333333;
            font-size: $font12;
            font-family: $inter-regular;
            &.span {
                color: #333333;
                font-size: $font12 !important;
                font-family: $inter-regular !important;
            }
        }
    }

    .trigger-section {
        position: relative;
        // height: calc(100vh - 195px);

        .col-2-trigger {
            width: 8rem;
            padding: 0px 0.625rem 0.5rem 0.125rem;

            &.menu-list-item {
                li {
                    svg {
                        height: 0.9375rem;
                        width: 0.9375rem;
                        margin-right: 0.3rem;
                    }
                }
            }
        }
        .col-10-trigger {
            width: calc(100vw - 8rem);
        }
        .margin-top-crondashboard {
            margin-top: 1rem;
            padding: 1rem 0rem 1rem 1rem;
        }
        .menu-list-item {
            li {
                display: flex;
                align-items: center;
                gap: 0.25rem;
                padding: 0.625rem;
                height: 2.5rem;
                width: 100%;
                margin-top: 0.3125rem;
                cursor: pointer;
                border-radius: 2px;
                border: 1px solid transparent;
                transition: 0.2s;
            }
            .active {
                width: 100%;
                height: 2.5rem;
                background: #2c83c4;
                color: #ffffff;
                border-radius: 2px;
                transition: 0.2s;
            }
            li:hover {
                width: 100%;
                height: 2.5rem;
                border: 1px solid #2c83c4;

                border-radius: 2px;
                transition: 0.2s;
            }
        }
        .ant-radio-wrapper {
            color: #333333;
            font-size: $font12;
            font-family: $inter-regular;
        }
    }
    .moniter-time {
    }
}
.scheduler-flow-modal {
    @media (max-width: 900px) {
        max-width: 95% !important;
    }

    .dash-ic {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        svg {
            height: 1.125rem;
            width: 1.125rem;
        }
    }

    .flow-select-modal {
        .domain-tabs {
            display: grid;
            grid-template-columns: minmax(48.25rem, 34.375rem) auto;
            align-items: start;
        }
        .pod-list-wrap {
            height: calc(100vh - 20.75rem);
            overflow: auto;
        }
    }
    .pod-select-modal {
        .domain-tabs {
            display: grid;
            grid-template-columns: minmax(48.25rem, 34.375rem) auto;
            align-items: start;
        }
        .pod-list-wrap {
            height: calc(100vh - 20.75rem);
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

// .paramater-scheduler-popover{
//     position: relative;
//     top: 20px;
//     .ant-popover-arrow {
//         right: 24px !important;
//         top: 18px !important;
//     }
// }
.sub-header-right {
    .vertical-separator {
        height: 22px;
        margin: 0 12px;
    }
}

#Group1::before {
    content: "";
    display: block;
    width: 3px;
    height: 4%;
    background: linear-gradient(45deg, transparent, #bfbdbd, transparent);
    position: absolute;
    top: 6%;
    right: 235px;
}

#Group2::after {
    content: "";
    display: block;
    width: 3px;
    height: 4%;
    background: linear-gradient(45deg, transparent, #bfbdbd, transparent);
    position: absolute;
    top: 6%;
    right: 118px;
}

.btn-only-icon {
    svg {
        height: 20px !important;
        width: 20px !important;
    }
}

.notification-section-flex {
    .ant-input-number {
        border-radius: 2px !important;
        font-size: 11px !important;
    }
}
.paramater-scheduler-popover {
    .ant-popover-content {
        width: 610px;

        right: 14px;
    }
}

.scheduler-gridview-responsive {
    .dashboard-grid {
        min-height: 8.75rem;
    }

    .landingCard-profile-list {
        svg {
            max-height: 0.875rem;
            max-width: 1.375rem;
        }
    }
}

.Scheduler-Trigger-Section {
    .custom-btn-grp .toggleAntD .ant-radio-group {
        label {
            width: 50% !important;
        }
    }
}

li:has(.dbox-opt-itm-active) {
    background: #f3f3f3; //highlight active filter
}

li:has(.dbox-opt-itm-reset):hover {
    background: transparent !important;
}

#param-popover {
    .parameter-text-area:is(:disabled, :read-only) {
        max-height: 1.875rem;
        overflow-y: auto;
    }
    .parameter-text-area {
        height: 1.875rem;
    }
}

.parameter-offset-section {
    position: absolute;
    top: -1.75rem;
    right: 0px;
}
.parameter-offset-section:has(.ant-input-number) {
    position: absolute;
    top: -1px;
    right: 0px;
    .ant-input-number {
        padding: 1px 0.375rem !important;
        border-radius: 0.1875rem !important;
        font-size: 0.6875rem !important;
        font-family: $inter-semibold !important;
    }
    .ant-input-number-input {
        padding: 1px 0.3125rem !important;
    }
}

.paramater-scheduler-popover {
    .parm-itm {
        & > div {
            align-self: center;
        }
    }
    .ant-select-selection-item {
        .element-type {
            svg {
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }
}

.tile-view-default {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 10rem;
    width: 100%;
    border: 1px solid #d3d3d3;
    background: #fafafa;
    border-radius: 5px;
}

.flow-tile-view {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 10rem;
    width: 100%;
    border: 1px solid #d3d3d3;

    border-radius: 5px;
    .flow-image {
        height: 6.25rem;
        width: 100%;
        img {
            height: 100%;
            object-fit: cover;
            border-radius: 3px !important;
            width: 100%;
        }
    }
    .flow-image {
        .empty-flow {
            img {
                height: 7%;
                object-fit: cover;
                border-radius: 3px !important;
                width: 7%;
            }
        }
    }

    .redirect-ic {
        svg {
            height: 1.125rem;
            width: 1.125rem;
        }
    }
}

.dashboard-tile-view {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 10rem;
    padding-left: 1rem;
    width: 100%;
    border: 1px solid #d3d3d3;

    border-radius: 5px;
    .dashboard-image {
        height: 100px;
        width: 100%;
        img {
            height: 100%;
            object-fit: contain;
            border-radius: 3px !important;
            width: 100%;
        }
    }
    .paragraph {
        width: $width-full - 40;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.6875rem;
        &:nth-child(2) {
            width: 100%;
        }
    }

    .section-heading {
        svg {
            height: 1.25rem;
            width: 1.25rem;
        }
    }
}

.scheduler-basic-details {
    :is(.custom-input-field, .custom-textarea-field, .form-control):is(:disabled, :read-only) {
        border: 1px solid #eaeaea !important;
        padding: 4px 8px !important;
    }
}

.dashboard-index-page {
    .scheduler-gridview-responsive > div:not(:last-child)::after {
        content: "";
        display: block;
        width: 3px;
        height: 70%;
        background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
        position: absolute;
        top: 25%;
        right: -10px;
    }
}
.scheduler-wrapper {
    .scheduler-gridview-responsive > div:not(:last-child)::after {
        content: "";
        display: block;
        width: 3px;
        height: 70%;
        background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
        position: absolute;
        top: 25%;
        right: -10px;
    }
}

.scheduler-mod-meta {
    svg {
        height: 0.9375rem;
        width: 0.9375rem;
    }
}
