//buttons

.custom-btn.resend-email{

    svg{
        height: 2.25rem !important;
        width: 2.25rem;
        // margin: 0.75rem 0rem 0rem 0rem;
        path {
            fill:#2C83C4
        }
    }
}
body {
    .custom-btn {
        height: 1.5rem;
        border-radius: 0.1875rem;
        transition: all 0.2s ease-in-out;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        & > span {
            display: flex;
            align-items: center;
        }
       
        svg {
            height: 0.9375rem;
            width: 0.9375rem;

            path {
                transition: all 0.2s ease-in-out;
            }
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    .btn-with-text {
        font-size: 0.8125rem;
        font-family: $inter-medium;
        text-transform: $tt-capitalize;
        padding: 0.3125rem 0.5rem;
        line-height: 1.4 !important;
        svg {
            margin-right: $margin5 - 0.0625rem;
            height: 0.75rem !important;
        }
    }

    .btn-with-icon {
        padding: 0.1875rem;
        width: 1.5rem;
    }

    .custom-btn-primary {
        background-color: $primary;
        border: none;
        color: $white !important;

        svg {
            path {
                fill: $white;
            }
        }

        &:hover {
            background-color: $primary-hover;
        }

        &:disabled {
            background-color: $grey-three;
        }
        
    }
    
    .custom-btn-borderless{
        border:none!important;
    }
    .custom-btn-outline-blue{
        border: 0.0625rem solid $primary;
        color:$primary;
        background-color: $white;

        svg{
            path{
                fill:$primary
            }
        }
    }

    .custom-btn-outline {
        border: 0.0625rem solid transparent;
        color: $black;
        background-color: transparent;
        &.back-transparent {
            background-color: transparent
        }
        &:focus {
            border-color: $primary;
            color: $black;
        }

        &:hover,
        &:active {
            // border-color: $primary;
            // color: $primary;

            color: #2c83c4;
            background: #0001010f;

            svg {
                path {
                    fill: $primary;
                }
            }
        }

        &:disabled {
            // border-color: $grey-three;
            color: $grey-three;

            svg {
                path {
                    fill: $grey-three;
                }
            }

            &:hover {
                background-color: $white;
            }
        }
    }

    .fixed-button {
        position: fixed;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        bottom: 1.25rem;
        cursor: pointer;
        right: 1.25rem;
        z-index: 11;
        // z-index: 2;
        box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.15);

        svg {
            margin: 0;
            height: 1.25rem !important;
            width: 1.25rem !important;
        }
    }

    .danger-btn {
        border: 0.0625rem solid $error-red;
        background-color: $white;
        color: $error-red;

        svg {
            path {
                fill: $error-red;
            }
        }

        &:disabled {
            opacity: .6;
            border: 0.0625rem solid $error-red !important;
            &:hover {
                background-color: $white;
                color: $error-red !important;

                svg {
                    path {
                        fill: $error-red;
                    }
                }
            }
        }

        &:hover {
            background-color: $error-red;
            color: #FFF !important;
            svg {
                path {
                    fill: #FFF;
                }
            }
        }
    }

    .custom-btn-default {
        border: none;
        color: $black;
        background-color: transparent;
        padding: $padding5;

        &:hover {
            color: $primary;
        }

        &:disabled {
            color: $grey-three;
        }

        svg {
            height: $height50 - 30;
        }
    }

    .custom-btn-danger {
        background-color: $error-red;
        color: #FFF;
        border: none;
        svg {
            path {
                fill: $white;
            }
        }
        &:hover {
            background-color: $error-red-hover;
        }
    }

    .toggleAntD {

        & .ant-radio-button-wrapper > span {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & > .ant-radio-group {
            border-radius: 0.1875rem;
            outline: thin;
            outline-color: #c2c2c2;
            outline-style: solid;
        }
        .ant-radio-button-wrapper-disabled {
            opacity: .6;
        }
        .ant-radio-button-wrapper {
            border: 0.0625rem solid transparent;
            height: 1.5rem;
            width: 1.5rem;
            padding: 0 $padding5;
            line-height: 1.5rem;
            text-align: center;
            border-radius: 0.1875rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            svg {
                path {
                    fill: $grey-three;
                }
            }

            &::before {
                display: none;
            }

            &:nth-child(1) {
                // border-top-right-radius: 0;
                // border-bottom-right-radius: 0;
                border-right: none;
            }

            &:nth-child(2) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: none;
            }

            &:hover {
                border-color: $primary !important;
                svg {
                    path {
                        fill: $primary;
                    }

                    line {
                        stroke: $primary;
                    }
                }
            }
        }

        .ant-radio-button-wrapper-checked {
            background-color: $primary !important;
            border-color: $primary !important;
            box-shadow: none !important;

            &:hover {
                svg {
                    path {
                        fill: $white;
                    }

                    line {
                        stroke: $white;
                    }
                }
            }

            svg {
                path {
                    fill: $white;
                }

                line {
                    stroke: $white;
                }
            }
        }
    }
    
    .toggle-btn-with-text {
        margin-right: $margin10;

        .label {
            font-family: $inter-regular;
            margin-bottom: $margin5 !important;
            text-transform: $tt-capitalize;
        }
        .toggleAntD {
            label {
                font-size: 0.6875rem !important;
            }
            .ant-radio-group {
                display: flex;
            }
            .ant-radio-button-wrapper {
                width: auto;
                line-height: 1.75rem;
                padding: 0 $padding10 * 2;

                span {
                    ~ span {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        white-space: inherit;
                        -webkit-line-clamp: 1;
                    }
                }
            }
        }

        & ~ {
            input {
                width: $width-full - 70;
            }
        }
    }

    .triple-toggle-btn {
        width: $width-full;
        .ant-radio-group {
            display: $d-block;
        }
        .ant-radio-button-wrapper {
            width: $width-full - 66.67;

            &:nth-child(2) {
                border-radius: 0;
                border-left: 0.0625rem solid $grey-three;
            }

            &:nth-child(3) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: none;

                &.ant-radio-button-wrapper-checked {
                    background-color: $primary !important; //$error-red !important;
                    border-color: $primary !important ///$error-red !important;
                }
            }

            .ant-radio-button {
                ~ span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: $height50 - 24;
                    font-size: 0.6875rem;
                    svg {
                        margin-right: $margin5 - 2;
                    }
                }
            }
        }
    }

    .toggleAntD {
        width: auto !important;
        padding: 0 !important;
        height: auto !important;
        
        svg {
            max-height: 0.75rem !important;
            max-width: 0.75rem !important;
        }

        .ant-radio-button-wrapper {
            & > span:last-child {
                width: 100%;
                & > div {
                    width: 100%;
                }
            }
        }
    }
    .full-width-toggle {
        .ant-radio-group {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;

            & > label {
                width: 100%;
            }
        }
    }

    // .modal_section_with_left_form {
    //     .triple-toggle-btn{
    //         .ant-radio-button-wrapper {
    //             width: $width-full - 50 !important;
    //         }
    //     }
    // }
}
.custom-btn-grp button:disabled {
    border: none;
}
.data-dictionary .custom-btn-grp button:disabled{
    border: 0.0625rem solid $error-red;
    cursor:not-allowed!important;
}
.custom-btn-grp button:disabled svg {
    // height:0.8125rem;
    height:0.9375rem;
    
}