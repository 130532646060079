.schema-name-wrap {
    .ant-badge {
        position: absolute;
        top: 2px;
        right: 7px;
        cursor: pointer;
    }

    svg {
        height: 1rem;
        width: 1.25rem;
    }
}
.pod-crt-either-exp-sch {
    .custom-search-input:not(:disabled) {
        background: #fff;
    }
    .custom-pod-crt-tree .custom-simple-landing-dgt .rdg-cell {
        border: none;
    }
    .custom-pod-crt-tree {
        height: calc(100vh - 9.25rem);

        &.src-view {
            height: calc(100vh - 11.8rem);
        }
    }
}
.highlight-schlist {
    border: solid 2px #dc3545;
}
.sch-chk-ic {
    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}

.sch-cont-child {
    .data-set-icon {
        height: 1rem;
        width: 0.9375rem;
    }
}