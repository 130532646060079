.clarista-loader {
    // stlyes
    z-index: 15;
    .query-cancel {
        color: #2c83c4;
        position: relative;
        padding: 0rem 0.4375rem;
        border-radius: 0.1875rem;
        display: flex;
        align-items: center;
        font-family: "InterRegular";
        font-size: 0.875rem;
        &:hover {
            background: #eeeeee;
        }
        svg {

            height: 0.75rem;
            width: 0.75rem;

            path {
                fill: #2c83c4;
                font-weight: 0.8125rem;
            }
        }
    }
}
