.file-ic {
    position: absolute;
    display: flex;
    border: solid 1px #d6d6d6;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    background: #fff;
    align-items: center;
    justify-content: center;
    top: 0.625rem;
    left: 0.625rem;

    svg {
        height: 1.25rem;
        width: 1.25rem;
    }
}

.file-det {
    width: calc(100% - 7.125rem);
    margin: 0 0 0 3.125rem;

    .ant-progress-text .anticon {
        font-size: 0.875rem;
    }
    .ant-progress-text {
        margin-inline-start: 0.5rem;
    }
}

.file-det label {
    margin-bottom: 0;
    font-size: 0.8125rem;
    font-family: "InterSemibold";
    width: 100%;
}

.file-det small {
    font-size: 0.6875rem;
    color: #828282;
    font-family: "InterSemibold";
}

.file-act {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 1;
    height: 100%;
}

.fi-up-container {
    height: calc(100vh - 7.375rem);
    &.src-view {
        height: calc(100vh - 10.125rem);
    }
}

// .fi-up-fibox {
//     &:hover {
//         box-shadow: 0px 0px 20px 3px #c2c2c270;
//         border-radius: 3px;
//     }
// }

.drag-upload-large {
    height: 100%;
}

.fi-up-fibox {
    z-index: 1;

    &:hover {
        z-index: 2 !important;
    }
}

.upload-more {
    position: absolute;
    top: 0;
    background: transparent;
    width: 100%;
    left: 0;
    z-index: 1;
    height: 100%;
}

.on-upload-ideal {
    opacity: 0;
    border: dashed 3px transparent;
    transition: 0.3s;

    & .upload-note {
        display: none;
    }
}
:is(.on-upload-ideal, .on-upload-drag):not(.drag-upload-large) {
    display: flex;
    align-items: center;
    justify-content: center;
}
.on-upload-drag {
    opacity: 1;
    background: #2c83c452;
    border: dashed 3px #2c83c4;
    z-index: 2 !important;
    transition: 0.3s;
}
.fup-note {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
    padding-bottom: 6px;
    padding-left: 6px;

    &::before {
        content: "";
        display: block;
        height: 10px;
        width: 100%;
        background: #fff;
        position: absolute;
        top: -8px;
    }
}

.fi-up-container:hover > div > .upload-more {
    z-index: 0 !important;
}

.ant-progress-text {
    font-size: 0.75em !important;
    font-weight: 600 !important;
    color: #828282 !important;
}

.file-ic:hover {
    .file-select-checkbox {
        display: flex;
    }
}

.file-select-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: #efefef;
    // background: #ababab;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: #FFF;

    &.active {
        display: flex !important;
        background: #ffffff;
    }
}

.drop-cloud-icon {
    svg {
        height: 5.3125rem;
        width: 5.3125rem;
    }
}