.prveiw-noresult {
    margin-top: 60px;
}

.Refresh-icon {
    font-size: 18px !important;
}

.iconImgDiv {
    width: 10px;
    height: 10px;
    display: block;
    position: relative;
}

.iconImgDiv::after {
    content: "";
    background: url(../../../assets/images/json-type.png);
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 10;
}