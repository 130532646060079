@import "../../../../assets/scss/common/colors";
@import "../../../../assets/scss/common/font";
@import "../../../../assets/scss/common/variables";

$info-header-color: #acb0c7;

.summary-left-container {
  height: calc(100vh - 78px);
  overflow: auto;

  .radial-graph-section {
    .apexcharts-canvas {
      .apexcharts-text {
        display: none;
      }
    }
  }
}

.summary-left-container {
  .top-wrapper {
    margin-bottom: $margin10;
    display: flex;
  }

  .js-plotly-plot {
    svg {
      margin-top: 15px;
      height: 100%;
    }
  }
  .js-plotly-plot {
    .modebar-btn {
      svg {
        height: 100% !important;
      }
    }
   
  }

  .radial-graph-section {
    display: flex;
    gap: 10px;
    margin-bottom: $margin10;

    .box {
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: space-between;
      padding: 10px;
      width: 25%;
      background-color: $white;
      border-radius: 3px;

      p {
        margin: 0 0 5px 0;
      }

      h5 {
        margin: 0;
      }
    }
  }

  .zoomchart {
    padding: 0.375rem;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: $margin10;
    border-radius: 3px;
    background-color: $white;

    select, option {
      font-size: 0.8125rem;
    }

    .toolbar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      select {
        height: 1.875rem;
        margin-right: 0.625rem;
        border: 1px solid #eaeaea !important;
        padding: 0 0.625rem;
        cursor: pointer;

        &:focus {
          outline: none !important;
        }
      }

      .ant-picker {
        border-radius: 0 !important;
        border: 1px solid #eaeaea !important;

        .ant-picker-separator {
          display: none;
        }
      }
    }

    .apexcharts-toolbar {
      display: none !important;
    }
  }

  .text-editor-section {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    position: relative;
    min-height: 100px;
    border-radius: 3px;
    background-color: $white;

    a {
      font-weight: 500;
      color: #5f5de9 !important;

      &.rdw-dropdown-selectedtext {
        color: #495057 !important;
        font-weight: 400;
      }
    }

    .text-editor-header {
      display: block;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 0.625rem 0.625rem 0.625rem;
      border-bottom: 1px solid $light-background;

      ~div {
        max-height: 350px;
        overflow: auto;
        padding: 0 15px;
      }
      .text-editor-button-setup{
        position: absolute;
        right: 7px;
        top: 8px;
      }
    }

    .btn-grp {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
    }
  }
}

.error-box {
  background-color: transparent !important;

  .error-time {
    font-size: 11px;
  }
}

.parenterrorLogs {
  background-color: #fff6f7 !important;

  .d-flex,
  .card-header {
    background-color: transparent !important;
  }

  .error-time {
    color: #ff565e;
  }

  .error-body {
    p {
      margin: 0;
    }
  }

  .bx-play {
    transform: rotate(90deg);
  }
}

// new design css for summary page
body {
  .summary-main-container {
    display: flex;
    height: calc(100vh - 76px);

    .summary-left-container {
      width: $width-full;// - 25;
      padding: 0.125rem 0 0.3125rem 0.3125rem;
      margin: 0.1875rem 0.3125rem 0 0;

      .pod-meta-container {
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        background-color: $white;
        width: $width-full - 50;
        margin-right: $margin5;

        .pod-meta-header {
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $light-background;
        }

        .pod-meta-headings {
          display: flex;
          align-items: center;
          width: calc(100% - 65px);

          p {
            &:nth-child(1) {
              width: $width-full - 65;
            }

            &:nth-child(2) {
              width: $width-full - 35;
            }

            .summary-pod-database {
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: calc(100% - 40px);
            }

            .summary-pod-ellipsis {
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: calc(100% - 48px);
            }
          }
        }

        .pod-meta-description {
          padding: 10px;
          height: calc(100% - 50px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          textarea {
            height: calc(100% - 30px);
            padding: 5px;
          }
        }
      }

      .pod-details-container {
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
        width: $width-full - 50;
        background-color: $white;
        margin-left: $margin5;
        border-radius: 3px;

        .podDetails-header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: $padding10;
          border-bottom: 1px solid $light-background;
        }

        .pod-details-lower-section-container {
          padding: $padding10;
        }

        .pod-info-left-section {
          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 33.33%;
              margin-bottom: $margin10;

              .icon-box {
                background-color: $light-background;
                width: $width50 - 15;
                height: $height50 - 15;
                border-radius: 50%;
                text-align: center;
                line-height: 35px;

                &~div {
                  width: calc(100% - 40px);
                }
              }

              &:last-child {
                p {
                  margin-right: 10px;
                }

                .pod-info-active-user-section {
                  display: flex;
                  align-items: center;

                  span {
                    height: $height50 - 26;
                    width: $width50 - 26;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 24px;
                    background-color: $white;
                    color: $black;
                    border: 1px solid $black;
                    margin-right: -5px;
                    position: relative;

                    &:nth-child(1) {
                      z-index: 6;
                    }

                    &:nth-child(2) {
                      z-index: 5;
                    }

                    &:nth-child(3) {
                      z-index: 4;
                    }

                    &:nth-child(4) {
                      z-index: 3;
                    }

                    &:nth-child(5) {
                      z-index: 2;
                    }

                    &:nth-child(6) {
                      z-index: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .summary-right-container {
      // width: $width-full - 75;
      display: none;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
      background-color: $white;
      border-radius: 3px;
      margin: $margin5 - 3 $margin5;

      .upload-button {
        margin: 10px 10px;
      }

      .timeline {
        // height: calc(100vh - 195px);
        overflow-y: hidden;
        overflow-x: hidden;

        .timeline-header {
          display: flex;
          align-items: center;
          padding: $padding10;
          height: $height50;
          border-bottom: 1px solid $light-background;

          p {
            margin: 0 0 0 $margin5;
          }
        }

        .timeline-content {
          // height: calc(100% - 50px);
          // overflow: auto;
          // border-bottom: 1px solid $light-background;
        }

        .timeline-slots {
          padding: 0 $padding10;

          h6 {
            display: none;
          }
        }

        .timeline-card-container {
          padding: $padding5 + 3 0;
          display: flex;
          align-items: center;
          position: relative;
          border-bottom: 1px solid #f1f1f1;

          .card-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .card-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .card-box {
            span {
              height: 30px;
              width: 30px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              background-color: #2c83c4;
              border: none;
              position: relative;
            }
          }

          .card-content {
            flex: 1;
            display: flex;
            flex-direction: column;
          }

          .card-datetime {
            p {
              color: #b9bbc7;
              font-size: 12px;
            }
          }

          .dashed-border {
            position: absolute;
            bottom: -30px;
            height: 30px;
            border-left: 1px dashed #b6b8c3;
            left: 27px;
          }
        }
      }

      .upload-content-section {
        height: $height-full - 50;

        .upload-table-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: $padding10;
          border-bottom: 1px solid $light-background;
        }

        .summary-upload-table {
          //height: calc(100% - 50px);
          overflow: auto;
        }

        .summary-uploaded-no-data {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 100%;

          img {
            height: $height50 * 2 - 10;
          }
        }

        table {
          thead {
            background-color: #fcfcfc;

            th {
              padding: 8px 15px;
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid #eaeaea;

              td {
                padding: 15px;
                width: 15%;

                &:last-child {
                  text-align: center;

                  i {
                    cursor: pointer;
                  }
                }

                &:nth-child(1),
                &:nth-child(5) {
                  span {
                    width: 160px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                  }
                }
              }
            }
          }
        }

        .summary-upload-table {
          padding: 0 $padding10;
        }

        .uploaded-item {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $light-background;
          padding: $padding10 0;

          .document-body {
            display: flex;
            align-items: center;
            width: calc(100% - 40px);

            .document-data {
              width: $width-full;
            }

            svg {
              height: $height50 - 20;
            }

            p {
              margin: 0;
              font-size: $font12;
            }
          }

          .document-actions {
            display: flex;

            span {
              width: $width50 - 30;
              height: $height50 - 30;
              cursor: pointer;
            }
          }

          .document-action-delete {
            svg {
              path {
                fill: $error-red;
              }
            }
          }
        }
      }
    }
  }

  .auditGraph {
    background: #FFFF;
    height: calc(100vh - 27rem);
    overflow-y: hidden;
    overflow-x: hidden;

    // .js-plotly-plot {
    //   height: 550px !important;

    // }
  }
  
}
.summary_file_container {
  display: flex;
  flex-wrap: wrap;
  font-family: 'InterMedium';
  .card {
    max-width: 18.75rem;
    min-width: 18.75rem;
    margin-right: 0.625rem;
    .width-decider {
      width: 7.5rem;
    }
  }
}
.audit-breadcrumb {
  position: sticky;
  top: 0;
  z-index: 1;
  .pod-meta-headings {
    display: flex;
  }
}
.new-audit-left-sec-wrap {
  // height : calc(100vh - 123px);
  .new-audit-us {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
    padding: 0 5px;
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
  .new-audit-bs {
    height: 35%;
    // overflow-y: scroll;
    // overflow-x: hidden;
    .ant-table .ant-table-tbody tr td {
      font-size: 11px;
    }
    .ant-table-body {
      max-height: 100% !important;
      height: calc(100vh - 623px) !important;
    }
  }
}
.usage-success-btn {
  background: #00CC92 !important;
  border-color: #00CC92 !important;
}
.audit-usage-tab {
  &.extend-usage {
    height: calc(100vh - 160px);
    // height: calc(100vh - 182px);
  }
  @media (min-width: 1920px) {
    height: calc(100vh - 268px);
    // height: calc(100vh - 383px);
    :is(.custom-tab-with-text, .ant-tabs, .ant-tabs-content, .usage-listing, #usage-refresh-panel + div, #usage-refresh-panel + div > div.show-hand-cursor) {
      height: 100%;
    }
    .custom-simple-landing-dgt {
      height: calc(100% - 12px) !important;
    }
  }
  &.hide-usage-scroll {
    .usage-refresh-panel {
    }
      top: 5px;
      position: absolute;
      right: 0;
      width: 250px;
    }
}
.timeline-detail {
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.overview-editor-wrap {
  .rdw-editor-wrapper {
    height: 200px;
    border: solid 1px #4444;
  }
}
.timeline-refresh-btn-wrap {
  position: absolute;
  padding: 0 10px;
  top: 8px;
  right: 0;
}
.add-overview-graphics {
  width: 13.5rem;
  cursor: pointer;
  .ovr-ic{
    svg {
      height: 2.1875rem;
    }
    & + label {
      svg {
        height: 0.625rem;
        margin-right: 0.3125rem;
      }
    } 
  }
  path {
    fill: #828282;
  }
  label {
    font-size: 0.8125rem;
    color: #828282;
  }
  &:hover {
    path {
      fill: #2c83c4;
    }
    label {
      color: #2c83c4;
    }
  }

}
.rdw-editor-main {
  height: calc(100% - 3rem) !important;
}

.audit-usage-tab.extend-usage :is(.custom-tab-with-text, .ant-tabs-top, .ant-tabs-content-holder, .ant-tabs-content.ant-tabs-content-top, .usage-listing, .usage-listing > div, .usage-listing > div > div):not(.usage-refresh-panel) {
  height: 100% !important;
}
.audit-usage-tab.extend-usage .usage-listing > div > div > div {
  height: calc(100% - 2.375rem) !important;
}
.auditGraph {
  & .plot-container.plotly {
    height: 100%;
  }
}

.pod-col-pub-drw {
  height: calc(100% - 2.1875rem);
  top: 2.1875rem;
  outline: none !important;
  .ant-drawer-mask {
    background: transparent;
  }
  .ant-drawer-body {
    padding: 0px !important;
  }
}
.pod-col-pub-tbl {
  .rdg-cell {
    padding: 0.5rem 1rem !important;
  }
  .custom-content-dgt {
    overflow-x: hidden;
  }
}
.pod-col-count-tag {
  .ant-badge-count {
      padding: 1px 0.125rem;
      font-size: 0.4375rem;
      font-family: "InterSemibold";
      top: auto !important;
      background: #2c83c4 !important;
  }
}
.pod-col-tbl-wrap {
  height: calc(100vh - 17.5rem);
  padding-bottom: 0.625rem;
}