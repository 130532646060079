// modal
body {
    .custom-modal {
        .modal-header {
            padding: $padding10 !important;
            border-color: $light-background;

            .right-flow-header {
                display: flex;
            }
        }

        .modal-title {
            display: flex;
            width: $width-full;
            justify-content: space-between;
            align-items: center;
            position: $p-relative;
            font-size: $fontSizeLargeHeading;
            h2 {
                margin: 0 0 0 $margin5;
            }
        }

        .modal-body {
            padding: $padding10;
            max-height: calc(100vh - 160px);
            overflow: auto;
            overflow-x: hidden;

            .landing-page-accordion {
                padding: 0;
            }

            .dropzone {
                border: 1px dashed $grey-three;
                border-radius: 3px;
                padding: $padding10;
                text-align: center;
                cursor: pointer;
            }

            .dropzone-previews {
                .card {
                    padding: $padding10 0;
                    border: none;
                    border-bottom: 1px solid $light-background;
                }

                .progress {
                    height: 0.3125rem;
                }
            }

            .progress-list {
                display: flex;
                justify-content: space-between;

                li {
                    svg {
                        height: 1.875rem;
                        width: 1.875rem;
                    }

                    &:nth-child(2) {
                        width: calc(100% - 4.375rem);
                    }
                }
            }
        }

        .custom-filter {
            top: 30px;
            right: 35px;
        }

        .modal-footer>* {
            margin: 0;
        }

        .podtag-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .podTag {

                display: flex;
                align-items: center;
                color: #2c83c4 !important;
                background:rgba(123, 167, 254, 0.2);
               
                height: 23px;
                padding: 0 8px;
                border-radius: 30px;
                margin: 0px 5px 5px 0px;
                display: flex;
                align-items: center;
                font-size: 9px;
                font-family: "InterSemibold";

                // padding: 0px 7px;
                // border-radius: 3px;
                // font-size: 8px;
                // font-family: "InterRegular";
                // border: 1px solid;
                // height: 26px;
                // margin: 7px 5px 0px 0;

                span {
                    color: #707070;
                }

                i {
                    color: #707070;
                    cursor: pointer;
                    font-size: 13px;
                    opacity: 0.8;
                }
            }
        }

        .ant-table {
            .ant-table-tbody {
                tr {
                    &.table-selected-row {
                        td {
                            background-color: $dark-background;
                        }
                    }
                }
            }
        }

        &.delete-popup {
            .modal-title {
                h2 {
                    color: $error-red;
                }
            }
        }


        &.warning-popup {
            // .modal .delete-popup {
            max-width: 400px !important;

            // }
            .modal-title {
                h2 {
                    color: #F26D21;
                }

                i {
                    color: #F26D21;

                }

                // button{
                //     background-color: #F26D21;
                // }

            }
        }
    }

    .connection-modal {
        .custom-filter {
            &.old-custom-filter {
                top: 40px;
                right: 51px;
            }
        }
    }
}