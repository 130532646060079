.unst-chat-content-body {
    display: grid;
    grid-template-columns: auto 25%;
}

.unst-source-collapse {
    .ant-collapse-header {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        flex-direction: row-reverse;
        width: max-content;
    }
    .ant-collapse-header-text {
        font-family: 'InterSemiBold';
        color: #444;
    }
    .ant-collapse-content-box {
        padding-inline: 1rem !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .ant-collapse-expand-icon {
        padding-inline-start: .80rem !important;
        svg {
            height: .5rem;
        }
    }
}

.unst-src-itm {
    cursor: pointer;
    padding: .5rem 0;
    border-bottom: solid 2px #F1F3F5;

    &:hover {
        background: #fafafa;
    }
}

.unst-src-itm-heading {
    font-size: .8rem;
    line-height: 1.4;
}

.unst-src-itm-file {
    svg {
        height: .9rem;
        width: .9rem;
        margin-inline-end: .3rem;
    }
}

.unstruct-talkdata-container {
    .chat-user-sender-avt {
        .ant-avatar {
            height: 1.75rem !important;
            width: 1.75rem !important;
        }
    }
}