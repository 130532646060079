// tabs
body {
    .flow-lower-section {
        width: 100%;
        padding: 0;
        // padding: $margin0 $padding10;
    }

    .data-tab {
        position: $p-relative;
        z-index: 2;


        .ant-tabs,
        .ant-tabs-nav-wrap {
            overflow: visible;
        }
        .ant-tabs-nav {
            margin: 0;

            &::before {
                display: none;
            }
        }
    
        .ant-tabs-nav-list {
            margin: 0;
            .ant-tabs-tab {
                background-color: $grey-one;
                height: 1.75rem;
                padding: 0.25rem 0.625rem !important;
                border-radius: 0.1875rem 0.1875rem 0 0 !important;
                font-size: $font14;
                margin: $margin0 $margin10 $margin0 $margin0 !important;
                border: none;

                svg {
                    path {
                        fill: $black;
                    }

                    &:hover {
                        path {
                            fill: $primary;
                        }
                    }
                }

                .ant-tabs-tab-btn {
                    color: $black;
                }

                &:hover {
                    .ant-tabs-tab-btn {
                        color: $primary;
                    }
                }

                &.ant-tabs-tab-active {
                    background-color: $white;
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);

                    .ant-tabs-tab-btn {
                        color: $black;
                    }
                }
            }
        }
    }

    .custom-tab {
        overflow: visible;

        .ant-tabs-nav-wrap {
            overflow: visible !important;
        }
        .ant-tabs-nav {
            margin: 0;

            &::before {
                display: none;
            }

            .ant-tabs-tab {
                width: $width50 - 20;
                margin: 0 $margin10;
                display: flex;
                justify-content: center;
                

                &:hover {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }

            .ant-tabs-tab-active {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }

            .ant-tabs-ink-bar {
                background-color: $primary;
                bottom: -6px;
                height: $height10 - 9;
            }
        }
        .ant-tabs-tab {
            padding: 0;
        }
    }

    .custom-tab-with-text {
        .ant-tabs-top {

            .ant-tabs-tab + .ant-tabs-tab {
                margin: 0;
            }
            .ant-tabs-nav-list {
                margin: 0;
                border-bottom: 1px solid $light-background;

                .ant-tabs-tab {
                    background-color: $white;
                    padding: $padding10 $padding10;
                    border: none;

                    .tabs-title {
                        font-size: 0.8125rem;
                        color: $black;
                        transition: color 0.2s ease-in-out;
                    }

                    .tabs-icon {
                        display: flex;
                        align-items: center;
                        svg {
                            height: 1.125rem;
                            max-width: 1.125rem;
                            path {
                                fill: $black;
                                transition: fill 0.2s ease-in-out;
                            }
                        }
                    }

                    &:hover {
                        .tabs-title {
                            color: $primary;
                        }

                        .tabs-icon {
                            svg {
                                path {
                                    fill: $primary;
                                }
                            }
                        }
                    }
                }

                .ant-tabs-tab-active {
                    .tabs-title {
                        color: $primary;
                    }

                    .tabs-icon {
                        svg {
                            path {
                                fill: $primary;
                            }
                        }
                    }
                }

                .ant-tabs-ink-bar {
                    background-color: $primary;
                }
            }
        }

        .ant-tabs-nav-operations {
            display: none !important;
        }
    }
}