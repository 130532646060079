// colors
// main
$primary: #2c83c4;
$light-primary: rgba(44, 131, 196, 0.2);
$dict-dark-color: #0A3053 ;
$url-link-color:#3b7098;
$primary-hover: #275a89;
$white: #ffffff;
$black: #17171A;
$success-green: #00cc92;

$warning-yellow: #ffbb01;
$error-red: #dc3545;
$error-red-hover: #b52b38;

$main-background: #f7f7f7;

$default-tag-backgroud:#FBFCFE;

// grey shades
$grey-one: #f9f9f9;
$grey-two: #f2f2f2;
$grey-three: #828282;
$grey-four: #5C5B5B;

// accent colors
$accent-one: #00bcd4;
$accent-two: #ff5722;
$accent-three: #cddc39;
$accent-four: #4caf50;
$accent-five: #ffab00;
$accent-six: #ffe300;
$accent-seven: #ff0070;
$accent-eight: #ff45e4;
$accent-nine: #b736ff;
$accent-ten: #7a28ff;
$accent-eleven: #2d75ff;

// background colors
$light-background: #f1f1f1;
$dark-background: #e8e8e8;

// business alert and quality alert
$quality-alert: #5C0A8F;
$business-alert: #2C83C4;
// $quality-alert: #da473e;  old color code
// $business-alert: #00cc92;  old color code