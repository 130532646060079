@import "../../../../../assets/scss/custom/common/variables";
@import "../../../../../assets/scss/custom/common/fonts";
@import "../../../../../assets/scss/custom/common/colors";

.icon-box-desc {
  float: left;
  margin-right: 0.375rem;
  margin-top: -0.1875rem;
}

/// datadomain css

.data-domain-detail-page {
  background-color: #f9f9f9;
  padding: 0.625rem;
  display: flex;

  .data-domain-detail-left-side {
    margin-right: 0.625rem;
    height: calc(100vh - 5.625rem);
    overflow: auto;

    &.usr-manage-usr-grp {
      overflow: hidden;
    }

    &.data-domain-detail-usergroup {
      width: 75%;
    }

    &.data-domain-detail-data-domain {
      width: 100%;
    }
  }

  .data-domain-detail-right-side {
    width: 25%;
    box-shadow: 0.0625rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-radius: 0.1875rem;

    .user-list-container {
      .user-list-header {
        font-size: $fontSizeHeading;
        padding: 0.625rem;
        border: 0.0625rem solid #f1f1f1;
        font-weight: 600;
      }

      .user-list-wrapper {
        .user-list-item {
          padding: 0.5rem 0.625rem;
          display: flex;
          align-items: center;
          border-bottom: 0.0625rem solid #f1f1f1;

          .user-initials {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #17171a;
            background-color: #ffffff;
            border: 0.0625rem solid #17171a;
            margin-right: 0.5rem;
          }

          .user-personal-details {
            flex: 1;
            width: 3.4375rem;
            .user-personal-details-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .caption {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}
.domain-users-list{
  .data-domain-detail-right-side {
    width: 25%;
    // box-shadow: 0.0625rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    // border-radius: 0.1875rem;

    .user-list-container {
      .user-list-header {
        font-size: $fontSizeHeading;
        padding: 0.625rem;
        border: 0.0625rem solid #f1f1f1;
        font-weight: 600;
      }

      .user-list-wrapper {
        .user-list-item {
          padding: 0.5rem 0.625rem;
          display: flex;
          align-items: center;
          border-bottom: 0.0625rem solid #f1f1f1;

          .user-initials {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #17171a;
            background-color: #ffffff;
            border: 0.0625rem solid #17171a;
            margin-right: 0.5rem;
          }

          .user-personal-details {
            flex: 1;
            width: 3.4375rem;
            .user-personal-details-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .caption {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}
.mt-10 {
  margin-top: 0.625rem;
}

.data-domain-details-upper-section {
  margin-bottom: $margin10;
  display: flex;
  gap: 0.625rem;

  & > * {
    flex-basis: 100%;
  }

  .data-domain-details-insights {
    background-color: $white;
    box-shadow: 0rem 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);

    .data-domain-details-header {
      padding: 0.625rem;
      border: 0.0625rem solid #f1f1f1;
      font-weight: 600;
      font-size: $fontSizeHeading;
    }

    .data-domain-details-content {
      padding: 0.625rem;
      display: grid;
      grid-template-columns: 0.7fr 0.5fr 1fr;
      gap: 0.625rem;
      // display: flex;
      // flex-wrap: wrap;
      overflow: hidden;

      &.usr-manage-usr-data-domain {
        grid-template-columns: 7.5rem 6.25rem minmax(10rem, 12.5rem) 12.5rem;
        height: calc(100% - 2.5rem);
      }

      .data-domain-small-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.625rem;
        // width: 33.33%;
        margin-bottom: 0.625rem;

        .icon-box {
          background-color: #f1f1f1;
          width: 2.1875rem;
          height: 2.1875rem;
          border-radius: 50%;
          text-align: center;
          line-height: 2.1875rem;

          svg {
            max-width: 1.875rem;
          }
        }

        .data-domain-small-content {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.6875rem;
          p {
            margin: 0;
          }

          .label {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  .card {
    // border-radius: 0 !important;
    // padding: $padding10 + 5;
  }

  .upper-left {
    .card {
      min-height: 8.125rem;
    }
    h5 {
      margin: 0 0 0 $margin5;
      font-size: $font16;
      font-family: $inter-semibold;
    }

    i {
      font-size: 1.5625rem;
    }

    p {
      font-size: $font12;
      margin: 0 0 $margin5 0;
      font-family: $inter-medium;

      &.grey {
        font-family: $inter-semibold;
        & ~ p {
          margin: 0;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            white-space: inherit;
            word-break: break-all;
          }
        }
      }
    }
  }

  .upper-right {
    box-shadow: 0rem 0rem 0.375rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0 !important;
    padding: 0.9375rem;
    background-color: #fff;
    min-height: 8.125rem;
    .card {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $margin10 * 2;
    }

    .col-6 {
      &:nth-child(3),
      &:nth-child(4) {
        .card {
          margin-bottom: 0;
        }
      }
    }

    .icon-span {
      margin-top: -0.625rem;
      position: absolute;
      margin-left: -0.5rem;
    }
    .count-box {
      margin-left: 2.1875rem;
      margin-top: -1.75rem;
    }
    .bottom-row {
      margin-bottom: 1.25rem;
    }
    .icon-box {
      height: 1.875rem;
      width: 1.875rem;
      background-color: $lightprimary;
      text-align: center;
      line-height: 3.125rem;
      border-radius: 50%;
      margin: 0 0.625rem 0 0;

      i {
        font-size: ($font12 - 2) * 3;
        color: $primary;
        vertical-align: middle;
      }

      ~ div {
        width: calc(100% - 3.75rem);

        h6 {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }
      }
    }

    p,
    h6 {
      margin: 0;
    }

    p {
      font-size: $font12;
      margin: 0 0 $margin5;
      font-family: $inter-semibold;
    }

    // h6 {
    //     font-size: $font16;
    //     font-family: $inter-semibold;
    // }

    .col-6 {
      &:nth-child(3),
      &:nth-child(4) {
        h6 {
          font-size: $font12 + 1;
          font-family: $inter-medium;
        }
      }
    }
  }
}

.user-grp-header {
  margin-top: $margin10;
  margin-bottom: $margin10;

  .heading-content {
    display: flex;
    align-items: center;

    i {
      font-size: $font16;
    }

    p {
      margin: 0 0 0 $margin5;
      font-size: $font14;
      font-family: $inter-semibold;
    }
  }
}

.user-grp-bottom {
  display: flex;
  flex-wrap: wrap;
  margin: 0.625rem -0.75rem 0;

  .user-grp-box {
    border: 0.0625rem solid $galleryGray;
    padding: $padding10;
    margin-bottom: $margin10 * 2;
    cursor: pointer;
    transition:
      border-color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
    background-color: $white;

    .user-grp-box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin10;

      .user-grp-box-left {
        display: flex;
        align-items: center;

        i {
          font-size: $font16 + 4;
        }

        p {
          font-size: $font16;
          font-family: $inter-semibold;
          margin: 0 0 0 $margin5;
        }
      }

      .user-type {
        background-color: $greenback;
        color: $appleGreen;
        padding: $padding5 - 3 $padding10 + 5;
        border-radius: 1.25rem;
        font-size: $font12;
      }

      .user-member {
        background-color: $lightprimary;
        color: $primary;
        padding: $padding5 - 3 $padding10 + 5;
        border-radius: 1.25rem;
        font-size: $font12;
      }
    }

    .user-grp-desc {
      margin: 0;
      min-height: $height50 + 30;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: inherit;
        word-break: break-all;
      }
    }

    .data-domain-bottom {
      margin-top: $margin10;
      border-top: 0.0625rem solid $galleryGray;
      padding-top: $padding10;

      p {
        margin: 0 0 $margin5 0;
        font-size: $font12 - 2;
      }

      h6 {
        font-size: $font12 - 1;
        font-family: $inter-semibold;
        margin: 0;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }

      .data-domain-box {
        display: flex;

        .data-domain-box-inner {
          display: flex;

          span ~ div {
            width: calc(100% - 3rem);
            margin-left: $margin5;
          }
        }
      }
    }

    &:hover {
      border-color: $primary;
      box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.3);
    }
  }
}

.data-domain-edit {
  display: flex;
  min-height: calc(100vh - 6.875rem);

  label {
    margin: 0 0 $margin5 0;
    font-weight: 400 !important;
    display: $d-block;
    font-family: $inter-regular;

    &.ant-checkbox-wrapper {
      margin: 0;
      display: inline-flex;
    }
  }

  .data-domain-edit-left {
    width: 20%;
    padding: $padding10 + 5;
    border-right: 0.0625rem solid $galleryGray;

    .warning {
      color: #fab43a;
      font-size: $font12 - 2;
    }

    textarea {
      height: 12.5rem;
    }
  }

  .data-domain-edit-right {
    width: 80%;
    padding: $padding10 + 5;
    height: calc(100vh - 5.625rem);
    overflow: auto;

    .data-domain-edit-header {
      display: flex;
      align-items: center;
      background-color: $header-back;
      padding: $padding10;
      margin-bottom: $margin10;

      p {
        margin: 0;
        font-family: $inter-semibold;
        margin: 0;
      }

      i {
        font-size: $font16 + 4;
        margin: 0 $margin5;
      }
    }
  }

  .collapse-section-row-container {
    padding: 0;

    .collapse-container {
      box-shadow: none;
    }

    .collapse-header {
      padding: $padding5 $padding10 + 5;
      align-items: center;
      background-color: $header-back;

      h6 {
        font-size: $font14;
        font-family: $inter-semibold;

        i {
          font-size: $font16;
          vertical-align: bottom;
          margin: 0 $margin5 0 0;
        }
      }
    }

    .collapse-div {
      margin: 0;
      padding: 0;
      border: none;

      table {
        th {
          background-color: $tableColGrey;
          font-family: $inter-semibold;
        }

        th,
        td {
          border: none;
          color: $color-content;
          padding: $padding5 - 1 $padding10 - 2;
        }

        td {
          .bxs-crown {
            padding: $padding5 - 2;
            border: 0.0625rem solid $yellow;
            background-color: $white;
            border-radius: 50%;
            font-size: $font12 - 4;
          }

          .ant-empty-normal {
            margin: 0;
          }
        }
      }
    }
  }
}

.data-domain-detail {
  .usergroupList {
    background: #ffffff;
    margin-top: 0.625rem;
    padding: 0rem;
  }
  .custom-breadcrumb {
    font-weight: 600;
  }
}

//New STYLING
.domain-details-container {
  padding: 0 !important;
  box-shadow: 0rem 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);

  .domain-details-header {
    padding: 0.625rem;
    border-bottom: 0.0625rem solid #f1f1f1;

    & > svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    .domain-details-title {
      margin-left: 0.5rem;
      font-weight: 600;
      font-size: $fontSizeHeading;
    }
  }

  .domain-details-description {
    padding: 0.625rem;
    max-width: 42.0625rem;
    max-height: 8.125rem;

    p {
      color: #828282;
      font-size: $fontSizeParagraph;
    }
  }
}

.data-domain-details-upper-section .upper-right {
  .text-value {
    color: #828282;
  }
  .upper-right-header {
    margin-bottom: 0.75rem;
  }
}

.dataDomain-lower-part {
  background: #ffffff;
  border-radius: 0.1875rem;

  &.usr-manage-usr-dataDomain {
    height: calc(100vh - 15.75rem);
    overflow-y: scroll;
  }

  .lower-subheader {
    display: flex;
    padding: 0.625rem;
    justify-content: space-between;
    align-items: center;

    border-bottom: 0.0625rem solid #f1f1f1;

    .heading-content {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      p {
        margin: 0;
        font-weight: 600;
        font-size: $fontSizeHeading;
      }
    }
  }
  // .dictionary_search_container {
  //   float: right;
  // }
  // .header-icon {
  //   float: right;
  // }
  // .lower-subheader {
  //   border-bottom: 0.0625rem solid #eaeaea;
  // }
  // .heading-content {
  //   width: 12.5rem;
  //   margin-top: 0.125rem;
  // }
  // .domain-icon {
  //   float: left;
  //   margin-right: 0.3125rem;
  // }
  // .sub-header-right {
  //   margin-top: 0.625rem;
  // }
}

.data-domain-edit label {
  color: #828282;
  font-weight: 600;
  font-size: 0.75rem;
}

.data-domain-edit {
  .data-domain-edit-right .empty-domain {
    text-align: center;
  }
  .data-domain-edit-right .empty-domain .head {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .data-domain-edit-right .empty-domain .para {
    font-size: 0.8125rem;
    line-height: 1.125rem;
    text-align: center;
    color: #8e8e8e;
  }
}

.select-grp-types {
  display: flex;
  align-items: center;
  height: $height-full;

  p {
    margin: 0;
    color: $color-gray;
    width: $width-full - 50;
    border: 0.0625rem solid $galleryGray;
    font-size: $font12;
    padding: 0.1875rem $padding5;
    text-align: center;
    cursor: pointer;
    width: 5rem;

    &.owner-style {
      background-color: #2c83c4;
      border-color: #5f5de9;
      color: #fff;
      cursor: auto;
      width: 4.375rem;
      display: flex;
      gap: 0.3125rem;
    }

    &.user-style {
      background-color: #2c83c4;
      border-color: #5f5de9;
      color: #fff;
      /* background-color: rgba(95, 93, 233, 0.1); */
      cursor: auto;
      width: 4.375rem;
      display: flex;
      gap: 0.3125rem;
    }
    .filter {
      float: left;
    }
  }
}

.modal-input-lbl {
  font-size: 0.75rem;
  color: #828282;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;

  outline: none;
}
.Create-Domain-right {
  margin-top: $margin10;
  margin-bottom: $margin10;

  .heading-content {
    display: flex;
    align-items: center;

    i {
      font-size: $font16;
    }

    p {
      margin: 0 0 0 $margin5;
      font-size: $font14;
      font-family: $inter-semibold;
    }
  }
}
.float-right {
  float: right;
}
.data-domain-details-upper-section .upper-right .text-value {
  font-size: 0.5625rem;
}

.border-bottom {
  border-bottom: 0.0625rem solid #f1f1f1 !important;
  padding-bottom: 0.3125rem !important;
}

.dataDomain-lower-part .sub-header {
  border-bottom: 0.0625rem solid #f1f1f1 !important;
}

.create-datadomain .sub-header {
  border-bottom: 0.0625rem solid #f1f1f1 !important;
}

.ant-table-header .ant-table-thead .type-cell {
  text-align: center;
}
.mt-20 {
  margin-top: 1.25rem;
}

.data-domain-tile {
  margin-top: 0.625rem;
  .detail-header {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #17171a;
    margin-left: 0.3125rem;
  }
  .detail-value {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #828282;
    margin-left: 1.25rem !important;
  }
}
.data-domain-details-upper-section .upper-left {
  box-shadow: 0rem 0.125rem 0.25rem 0 #00000033;
}
.dataDomain-lower-part {
  .icon-box-desc {
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}
.usr-manage-usr-dataDomain {
  .col-12 {
    padding-inline: 0.9375rem;
  }
}

.dataDomain-lower-part:is(.conn-list, .pod-list, .policy-table) {
  .custom-simple-landing-dgt {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}

.dataDomain-lower-part.usr-manage-usr-grp {
  .custom-simple-landing-dgt {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

.domain-usr-grp-list.select-grp-types {
  p {
    font-size: .5rem !important;
  }
  svg {
    height: 0.5rem;
    width: 0.6rem;
  }
}

.usr-grp-acc-ic, .usr-grp-domain-ic {
  svg {
    height: 1rem;
    width: 1rem;
  }
}

.data-domain-detail-right-side.user-group-cont {
  .user-list-wrapper {
    height: calc(100vh - 8.4rem);
    overflow-y: auto;
  }
}