// .search-loader-circle-2 {
//   position: relative;
//   //   width: 70px;
//   //   height: 70px;
//   display: inline-block;
//   background-clip: padding-box;
//   background: white;
//   margin-right: 6px;
//   svg {
//     height: 20px !important;
//     width: 20px !important;
//     z-index: 2 !important;

//     padding: 3px !important;
//     background: white !important;
//     border-radius: 50% !important;
//   }
// }
// .search-loader-circle-2:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1;
//   margin: -2px;
//   border-radius: 20px;
//   background: linear-gradient(
//     90deg,
//     rgba(39, 90, 137, 1) 0%,
//     rgba(44, 131, 196, 1) 51%,
//     rgba(232, 245, 255, 1) 100%
//   );
//   animation: loader-circle-2-animation-2 5s linear infinite;
// }
// // .search-loader-circle-2:before {
// //   position: absolute;
// //   top: -4px;
// //   bottom: -4px;
// //   left: -4px;
// //   right: -4px;
// //   background: linear-gradient(red, blue);
// //   content: "";
// //   z-index: -1;
// //   border-radius: 16px;
// //   animation: loader-circle-2-animation-2 1s linear infinite;
// // }
// /* @keyframes loader-circle-2-animation {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(-360deg);
//     }
//   } */
// @keyframes loader-circle-2-animation-2 {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
.search-icon{
  .ant-input-affix-wrapper >input.ant-input{
    cursor: pointer !important;

  }
  .ant-input-affix-wrapper-disabled{
    background-color: white;
    cursor: pointer !important;
    padding: 0px 10px;
  }
}
