@import "../../../../../assets/scss/common/colors";
@import "../../../../../assets/scss/common/variables";

.landingPage {
    .sub-header {
        padding: 2px $padding10;
        .landingPage_navbar-nav {
            display: flex;
            justify-content: space-between;
            li {
                margin: 0 $margin10 * 2 0 0;
                position: relative;
                cursor: pointer;

                svg {
                    margin-right: $margin5;
                }

                &::after {
                    content: "";
                    position: $p-absolute;
                    bottom: -5px;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background-color: transparent;
                }

                &.landing_nav-item_active {
                    color: $primary;
                    &::after {
                        background-color: $primary;
                    }
                }
            }
        }
    }
    .sub-header-left {
        height: 100%;
    }
    
}
