.create-policy-secondary-header {
  .sub-header-left {
    display: flex;
    gap: 0.3125rem;
  }

  .sub-header-right {
    display: flex;
    align-items: center;
  }

  .domain-name,
  .table-name,
  .policy-name {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    font-family: "InterMedium";
    // font-weight: 600;
    font-size: 0.8125rem;
    & svg {
      height: 1rem;
      width: 1rem;
    }
  }

  .domain-name {
    text-transform: uppercase;
  }

  .table-name {
  }

  .policy-name {
    text-transform: uppercase;
  }
}
