.user-management-data-domain {
    .rotate-button-backdrop {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .add-options-list {
        position: fixed;
        right: 20px;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s ease;
        bottom: 60px;
        pointer-events: none;

        li {
            padding: 15px;
            background-color: white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;

            svg,
            path {
                fill: #2c83c4;
            }

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    .active-list {
        opacity: 1;
        bottom: 80px;
        pointer-events: all;
    }

    .custom-btn-grp {
        .custom-btn-primary {
            svg {
                transition: all 0.3s ease;
            }
        }

        .rotate-icon {
            svg {
                transform: rotate(140deg);
            }
        }
    }

    .custom-btn-active {
        color: #2c83c4;
        background: rgba(0, 1, 1, 0.0588235);
    }
}
