.custom-panel-toggler {
    position: fixed;
    z-index: 1;
    display: flex;
    width: 30px;
    height: 30px;
    background: #ffffff;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: solid 1px #d1cdcd;
    box-shadow: 1px 1px 3px 0px #333333b3;
    cursor: pointer;
    bottom: 25px;
    left: 45px;
    border-radius: 0px 15px 15px 0px;
    transition: .3s;

    &.cus-sidebar-hidden {
        left: 0;
    }

    &:hover {
        background: #bfbdbd;
        transition: .3s;
    }

    &.op {
        width: 22px;
        padding-right: 5px;
        svg {
            transform: rotateY(180deg);
            transition: .3s;
        }
        &:hover {
            width: 30px;
            padding-right: 0px;
            transition: .3s;
        }
    }
    &.cl{
        width: 20px;
        svg {
            transition: .3s;
        }
        &:hover {
            width: 30px;
        }
    }
}