@import "../../../../../assets/scss/common/colors";
@import "../../../../../assets/scss/common/variables";
@import "../../../../../assets/scss/common/font";

body {
    .modal {
        .landingPage-tag {
            border-radius: 3px;
            .card {
                border: none;
            }

            .card-header {
                background-color: $white;
                border: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: $padding10;

                .landingPage-tag-title {
                    display: flex;
                    align-items: center;

                    svg {
                        height: 1.25rem;
                        width: 1.25rem;
                    }

                    .section-heading {
                        margin-left: $margin10;
                    }
                }
            }

            .card-body {
                padding: 0;

                .subtitle {
                    background-color: $grey-two;
                    padding: $padding10;
                }

                .tag-list {
                    padding: $padding10;
                    max-height: calc(100vh - 380px);
                    overflow: auto;
                }

                .landingCard-tag-menu-list {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: $padding5 0;
                    border-bottom: 1px solid $light-background;

                    .paragraph {
                        margin-left: $margin10;
                    }

                    .landingCard-tag-menu-listItem {
                        display: flex;
                        align-items: center;
                        & .paragraph {
                            max-width: 300px;
                        }
                    }
                }

                .landingPage-tag-selectColor-palette {
                    margin-bottom: $margin10;

                    .landing-tag {
                        cursor: pointer;
                        i {
                            font-size: $font20;
                            border: 1px solid $white;
                            border-radius: 50%;
                            &:hover {
                                border: 1px solid #2C83C4;
                            }
                            
                        }
                        .active {
                            border: 1px solid #2C83C4;
                        }
                    }
                    
                }

                .landingPage-tag-label {
                    font-size: $font12;
                    margin-bottom: $margin5;
                }
            }
        }
    }
}