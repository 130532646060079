.unst-podcrt-header {
    padding: 0.3125rem 0.825rem;
    border-bottom: solid 1px #DEE2E6;
    height: 3rem;
}
.unst-podcrt-subheader {
    padding: 0.3125rem 0.825rem;
    height: 3rem;
}
.unst-podcrt-filter {
    position: relative;
    background: #f1f1f1;
    color: #333;
    display: flex;
    align-items: center;
    padding: 0.1875rem 0.5rem 0.1875rem 0.75rem;
    border-radius: 4rem;
    font-size: .8rem;
    margin-bottom: 0;
    font-family: 'InterMedium';

    .ic-arr {
        transform: rotate(90deg);
    }
}

.unst-grid-page-wrapper {
    height: calc(100% - 6rem);
}

.unst-document-page-wrapper {
    height: calc(100% - 8rem);
}

.unst-podcrt-gridview {
    padding: 0.3125rem 0.825rem;
    height: 100%;
    overflow-y: auto;

    .data-view-dbox ._src_img {
        height: 2.4rem;
        width: 2.4rem;
        color: #777777;
    }
}
.unst-podcrt-gridview-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10.5rem,1fr));
    gap: 1rem;

    & > div {
        height: 16rem;
    }

    .ant-upload-wrapper {
        .btn-with-text {
            font-size: .6rem;
        }
    }

    .ant-upload-drag {
        border-width: 2px !important;
    }

    .ant-upload-drag-icon {
        svg {
            height: 2rem;
            width: 2rem;
        }
    }
}
.unst-file-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: .5rem;
    box-shadow: 3px 1px 7px 0px #BFBFBF;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    transition: .3s all;

    .file-type {
        padding: 0.625rem;
    }
    
    .file-type span {
        font-size: .6rem;
        font-family: 'InterSemiBold';
        background: #D1E0FF;
        padding: .25rem .4rem;
        border-radius: 1rem;
        color: #267CC7;
    }
    
    .file-detail-name {
        padding: 0 0.625rem;
        margin-bottom: .5rem;
        min-height: 2rem;
        word-break: break-all;
    }

    .file-thumb {
        position: relative;
        height: 54%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        // border: solid 1px #DEE2E6;
        box-shadow: 0px 0px 0px 1px #e1e1e1;
        border-radius: 0 0 .5rem .5rem;
        transition: .3s all;

        .no-thumbnail {
            position: absolute;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            background: #fafafa;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            text-transform: uppercase;
            color: #0000001f;
            border-radius: .5rem;
        }
    }

    .file-detail {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: .5rem 0.625rem 0.5rem 0.625rem;
        height: 3rem;
        align-items: center;
        transition: .3s all;
    }

    .delete-file-btn {
        cursor: pointer;
        position: absolute;
        height: 1.8rem;
        width: 1.8rem;
        background: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0.5rem;
        right: .5rem;
        z-index: 1;
        border: solid 1px #ded7d7;
        transform: scale(0);
        transition: .3s;

        &:hover {
            background: #ffe7e7;
        }

        svg {
            height: .8rem;
            width: .8rem;
        }
    }

    &:hover {
        .delete-file-btn {
            transform: scale(1);
            transition: .3s;
        }
    }

    .hor-sep {
        margin-inline: 0.625rem;
    }

    .file-detail-actions {
        display: flex;
        align-items: center;
        gap: .25rem;
    }

    .file-detail-type svg {
        height: .9rem;
        width: .9rem;
    }

    &:hover {
        box-shadow: 3px 3px 7px 2px #BFBFBF;
        transition: .3s all;
    }

    .deleting-status {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        background: #ffe7e7;
        padding: .2rem 0;
    }
}
.unst-podcrt-listview {
    height: calc(100% - 0rem);
    // height: calc(100% - 8rem);
    overflow: hidden;
}
.unst-podcrt-listview-content {
    height: 100%;
    padding: 0.3125rem 0.5rem;
    .list-view-content {
        cursor: pointer;
        display: grid;
        grid-template-columns: 40% 15% auto 20% 10rem;
        align-items: center;
        padding: .75rem 1rem;

        &:hover {
            box-shadow: 0px 0px 6px 0px #dcdcdc;
            background: #fcfcfc;
        }
    }

    .list-view-content:not(:last-child) {
        border-bottom: solid 1px #EAEDEF;
    }

    .list-view-fileimg {
        width: 2rem;
        height: 2rem;
    }
}

.unst-podcrt-content-wrap {
    position: relative;
    height: calc(100vh - 2.625rem);
}

.unst-file-preview-drawer {
    outline: none !important;
    .ant-drawer-content-wrapper {
        box-shadow: none !important;
    }
    .ant-drawer-header {
        border-bottom-color: #DEE2E6;
        padding: 0.725rem;
    }
    .ant-drawer-body {
        padding: 0.725rem;
        overflow: hidden;
    }
}

.file-preview-drawer-body {
    display: grid;
    grid-template-columns: 40% 60%;
}

.file-preview-fields {
    display: grid;
    grid-template-columns: 8rem auto;
    align-items: center;
    row-gap: 1.5rem;
    column-gap: .8rem;
    padding: 1rem 1rem;
    overflow-y: auto;
    overflow-x: auto;
    // height: calc(100vh - 10rem);
}

.colorful-tag {
    .anticon-close {
        position: relative;
        transform: translateY(20%);
        height: 0.5rem;
        width: 0.5rem;
    }
}

.unst-pod-crt-docgridtab {
    width: 11rem;
    &.ant-tabs-top >.ant-tabs-nav::before {
        border-bottom: none !important;
    }

    .ant-tabs-nav {
        margin-bottom: 0 !important;
    }

    & .ant-tabs-tab {
        padding: 0.5rem 0 !important;
    }

    & .ant-tabs-tab-icon {
        margin-inline-end: .5rem !important;

        svg {
            height: 1rem;
            width: 1rem;
        }
    }
}

/*Grid Creation CSS*/

.grid-creation-header {
    border-bottom: solid 1px #DEE2E6;
}

.unst-pod-crt-creategrid-body {
    & > div:first-child {
        width: 25%;
        border-right: solid 1px #DEE2E6;
    }
    & > div:last-child {
        width: 75%;
    }
}

.unst-pod-crt-creategrid-body {
    height: calc(100% - 3rem);
    overflow: hidden;
}

.doc-searchbar {
    height: 2.75rem;
    padding: .5rem 1rem;
    background: #F1F1F1;
    border-bottom: solid 1px #DEE2E6;

    .custom-search input {
        background: transparent;
        border: none;
        margin-bottom: 0 !important;
    }
}

.doc-list-table {
    height: calc(100% - 2.75rem);
    
    .custom-simple-landing-dgt .rdg-cell {
        border: none !important;
    }
}

.unst-colorful-tag-arrowbtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    background: #DEE2E6;
    color: #343A40;
    border-radius: 100%;
    padding: .25rem;
    transition: .3s;

    &:hover {
        background: #c1cad2;
        transition: .3s;
    }
}

.unst-colorful-tag {
    width: 50%;
    overflow-x: auto;
    border-radius: 4rem;

    &::-webkit-scrollbar {
        display: none;
    }
}

.unst-domain-wrap {
    .nusg-dropdown-label {
        font-family: 'InterMedium';
    }
    .nusg-dropdown-ic {
        background: transparent !important;
        padding: 0;
        width: auto;
        svg {
            height: 1rem;
            width: 1rem;
        }
    }
}

.unst-unsaved-pod-overlay {
    position: fixed;
    height: calc(100% - 3rem);
    width: 100%;
    background: #000000a3;
    top: 5.3125rem;
    left: 0;
    z-index: 6;
    backdrop-filter: blur(2px);
}

.unst-file-upload-prg-wrap {
    z-index: 5;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 16rem;

    .unst-file-upload-prg-list {
        background: #fff;
        padding: .8rem 0;
        border-radius: .5rem;
        box-shadow: 0px 1px 12px 0 #9f9f9f70;
        max-height: 20rem;
        overflow-y: auto;
    }

    .unst-file-upload-list-item {
        padding: 0 .8rem;
        display: flex;
        align-items: center;
    }

    .dot {
        display: inline-flex;
        height: .2rem;
        width: .2rem;
        background: currentColor;
        border-radius: 100%;
        margin-inline: .1rem;
        margin-bottom: 1px;
        opacity: .6;
    }
}

.unst-progress-collapse {
    cursor: pointer;
    padding: .5rem .8rem;
    background: #fff;
    box-shadow: 0px 1px 12px 0 rgba(159, 159, 159, 0.4392156863);
    margin-top: .5rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background: #fafafa;
    }

    .unst-file-count {
        display: flex;
        background: #2c83c4;
        min-width: 1rem;
        height: 1rem;
        color: #fff;
        font-size: .6rem;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
    }
    .arrow {
        transform: rotate(90deg) rotateY(0deg);
        display: block;
        transition: .3s;

        svg {
            height: .7rem;
            width: .7rem;
        }


        &.open {
            transform: rotate(90deg) rotateY(180deg);
            transition: .3s;
        }
    }

}

.unst-item-remove {
    cursor: pointer;
    display: flex;
    width: 1rem;
    height: 1rem;
    align-items: center;
    justify-content: center;
    padding: .2rem;
    border-radius: 100%;
    color: #dc3545;

    &:hover {
        background: #eaedef;
    }
}

.unst-tag-opt-renderer {
    transition: .3s;
    &:hover {
        background: transparent;
        transition: .3s;
    }
}

.unst-tag-poplist {
    .ant-select-item-option-selected {
        .unst-tag-opt-renderer {
            background: transparent !important;
            transition: .3s;
        }
    }
}

// .unstructured-document-card {
//     & > div.card {
//         min-height: auto !important;
//     }
// }

.file-holder-loader {
    & > .ant-skeleton {
        height: 100% !important;
        border-radius: .5rem;
        overflow: hidden;
    }
}