.icon-toggler {
    .ant-radio-button-wrapper {
        line-height: 0 !important;
        width: 1.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 1.5rem;

        svg {
            height: 1rem;
            width: 1rem;
        }

        &.ant-radio-button-wrapper-checked {
            background: #2c83c4;
            border-color: #2c83c4 !important;
            svg path {
                fill: #FFF
            }
        }
        &.ant-radio-button-wrapper-disabled {
            opacity: .8 !important;
        }

        &::before {
            display: none;
        }
    }

    &.shadow-toggle {
        border: solid 1px #DEDEDE;
        border-radius: 1rem;

        .ant-radio-button-wrapper {
            border: none;
            border-radius: 1rem;
            outline: none !important;
            height: 1.875rem;
            width: 1.875rem;
            svg {
                height: 0.9375rem;
                width: 0.9375rem;
                top: -1px;
                position: relative;
            }

            svg path {
                fill: #a7a7a7;
            }

            &.ant-radio-button-wrapper-checked {
                background: #ffffff;
                border-color: #2c83c4 !important;
                box-shadow: 0px 0px 4px 2px #8bcdff;

                svg path {
                    fill: #2c83c4;
                }
            }

            &.ant-radio-button-wrapper:last-child::before {
                display: none
            }

            &::before {
                display: none !important;
            }
        }
    }
}