@import "../../../assets/scss/common/font";
@import "../../../assets/scss/common/variables";

.status-container {
    display: flex;
    font-family: $inter-regular;
    font-weight: $fw-700 - 100;
    font-size: $font12;
    padding: 5px 0;
    border-radius: 12px;
    align-items: center;
    span {
        white-space: nowrap;
        &:first-child {
            padding: 0 3px 0 7px;
        }
        &:last-child {
            padding: 0 7px 0 3px;
        }
    }
}