.no-data-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .no-data-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    h3 {
      font-weight: 600;
      font-size: 1rem;
    }

    p {
      font-size: 0.75rem;
      text-align: center;
      color: #8e8e8e;
      max-width: 60%;
    }
  }
}
