.custom-vlist-dgt {
    background-color: #FFF;
    border: none !important;
    .rdg-cell {
        border: none !important;
        box-shadow: none !important;
    }
    .rdg-row {
        background-color: #fff;
        
        &:hover {
            background-color: #f5f5f5 !important;
        }
    }
    &.border-bottom {
        .rdg-cell {
            border-bottom: 1px solid #dddddd4a !important;
        }
    }
}