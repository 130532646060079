@import "../../../assets/scss/common/colors";
.clusters-wrapper {
    .clusters-tables {
        height: calc(100vh - 60vh);
        .dict-title-strip {
            padding: 0.5rem 0.75rem;
            background: #f7f7f7;
        }
        .text-stop {
            color: $error-red;
        }
        .text-run {
            color: $primary;
        }
        .text-terminate {
            color: $warning-yellow;
        }
        .text-pending{
            color: #EEB524;
        }

        .custom-simple-landing-dgt {
            grid-template-columns: repeat(5, 20%) !important;
        }
    }
    .schedule-icon svg {
        height: 1.125rem;
        width: 1.125rem;
    }
    .notebook-cluster-table {
        height: calc(100vh - 40vh);

        .custom-simple-landing-dgt {
            grid-template-columns: 20% 20% 40% 20% !important;
        }
    }
    .podtag-list-scheduler {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // border: 0.0625rem solid #929292;
        height: 1.5rem;
        border-radius: 0.25rem;
        width: 66%;
        margin: 0rem 0.9375rem 0.0625rem 0.8125rem;
        padding: 0.0625rem 0.125rem;

        .podTag {
            background-color: $primary;
            display: flex;
            align-items: center;
            padding: 0.25rem;
            border-radius: 0.0625rem;
            font-size: 0.6875rem;
            height: 1.25rem;
            margin: 0 0.3125rem 0 0;

            span {
                color: $white;
            }
        }
    }

    .section-heading-dark {
        color: $primary-hover !important;
        font-size: 0.8125rem !important;
        font-family: "InterSemibold" !important;
    }
}
.credit-modal {
    .ant-drawer-body {
        overflow: hidden;
    }
}

.usage-chart {
    .echarts-for-react {
        & > div:first-child {
            // height: 18.75rem !important;
            // width: 100% !important;
            & > canvas {
                top: -0.3125rem !important;
                left: -1.875rem !important;
                // width: calc(100vw - 80%) !important;
            }
        }
    }
}
.gray-color-NA {
    color: rgba(0, 0, 0, 0.5) !important;
}

.debit {
    font-size: 0.75rem;
    font-weight: bolder !important;
    color: #333; // debid red color removed
}
.credit {
    font-size: 0.75rem;
    font-weight: 800;
    color: #31c43b;
}
.credit-card {
    height: 10.625rem;
    width: 85%;
    border-radius: 0.375rem;
    position: relative;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1.5625rem;
    background: linear-gradient(100.35deg, #2c83c4 1.27%, #275a89 97.46%);
    // background-image: linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%)
    .credit-img {
        position: absolute;
        bottom: -0.3125rem;
        height: 5.625rem;
        right: 0rem;
    }
    .total-credit {
        font-family: "InterSemiBold";
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5rem;
        text-align: left;
        color: #c0e8ff;
    }
    .edit-credit-amount {
        font-size: 0.75rem;

        text-align: left;
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
        .custom-search-input {
            background: transparent !important;
            border-bottom: none !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-radius: 0rem !important;
            width: 50% !important;
            color: #fff;
        }
        #add-credit-input::placeholder {
            color: #fff;
        }

        .custom-search-input:focus {
            border-color: #2c83c4 !important;
            background: transparent !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-radius: 0rem !important;
            width: 50% !important;
            color: #fff;
        }
        .custom-search-input:focus-visible {
            border-color: #2c83c4 !important;
            background: transparent !important;
            border-bottom: 0.0625rem solid #fff !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-radius: 0rem !important;
            width: 50% !important;
            outline: none !important;
            color: #fff;
        }
    }

    .credit-amount {
        font-family: "InterSemiBold";
        font-size: 1.5625rem;
        font-weight: 500;
        text-align: left;
        color: #fff;
        cursor: default;
    }

    .add-credit {
        font-family: "InterReguler";
        font-size: 0.9375rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: #fff;
        cursor: pointer;
        text-align: left;
        text-decoration: underline;
    }
}

.schedule-icon {
    .active {
        svg {
            path {
                stroke: $primary;
            }
        }
    }
}
.cluster-schedule-toggle{
    .custom-switch-btn.ant-switch.ant-switch-small{
        margin-top: -0.5px;
        position: relative;
        top: 1px;
    }
    .custom-switch-btn .ant-switch-inner {
        padding-inline-start: 0.375rem;
        padding-inline-end: 1.125rem;
        top: -1.5px !important;
        position: relative;
    }
    .custom-switch-btn .ant-switch-handle{
        width: 0.8rem !important;
        height: 0.8rem !important;
        top: 1.5px !important;
    }
}
.Pin {
    svg {
        fill: #333;
    }
}
.cluster-status {
    font-size: 0.8em;
    color: #fff;
    .sphere {
        height: 0.6875rem;
        width: 0.6875rem;
    }

    svg {
        margin-right: 0.125rem;
        fill: #28a745;
        stroke: #6dcc83;
        stroke-width: 0.125rem;
    }
}

.cluster-square-pill {
    // height: 1.5625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3125rem !important;
    background: #fff;
    padding: 0.1875rem 0.375rem; //0.6875rem 0.75rem 0.75rem 0.625rem;
    .cluster-sphere {
        height: 0.4375rem;
        width: 0.4375rem;
        // margin-top: 0.125rem;
        margin-right: 0.3125rem;
    }
    .cluster-status-text {
        font-size: 0.8rem;
        font-weight: bold;
    }
}

.cluster-status-danger {
    font-size: 0.8em;
    color: #fff;
    .sphere {
        height: 0.6875rem;
        width: 0.6875rem;
    }
    svg {
        margin-right: 0.125rem;
        fill: #dc3545;
        stroke: #d55562;
        stroke-width: 0.125rem;
    }
}

.fw-400 {
    font-weight: 400;
}
.fw-600 {
    font-weight: 600;
}
.fw-800 {
    font-weight: 800;
}
.fw-bold {
    font-weight: bold;
}
.fw-bolder {
    font-weight: bolder;
}

.InterBold {
    font-family: "InterExtraBold";
}

.credit-btn {
    position: relative;
    overflow: hidden;
}
.animate-btn {
    // animation: fadeIn 5s !important;
    // @include transition(0.2s ease-in-out)    ;
    transition: all 0.6s ease-in-out !important; /* Adjust duration and easing as needed */
}

.hide-button {
    transform: translateX(60vw);
    // transition: opacity 250ms 300ms;
    transition: left 1s ease; /* Adjust duration and easing as needed */
}

.table-column-title {
    font-size: 0.8125rem;
}

.history-usage-table {
    .rdg-cell {
        padding: 0rem !important;
    }
}
.remove-table-borders .rdg-cell {
    border: none !important;
}

.table-content-color {
    color: $grey-four !important;
    font-weight: 800;
}

.no-cluster-text {
    font-size: 0.75rem;
    color: $grey-three;
}

.default-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6875rem;
    background: $default-tag-backgroud;
    border: 0.0625rem solid $grey-two;
    padding: 0.3125rem;
    font-weight: 600;
    height: 1.25rem;
    color: $grey-four;
    border-radius: 0.1875rem;
    svg {
        height: 0.75rem;
        width: 0.75rem;
    }
}
.active-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6875rem;
    background: $primary-hover;
    border: 0.0625rem solid $primary-hover;
    padding: 0.3125rem;
    font-weight: 600;
    height: 1.25rem;
    color: #fff;
    border-radius: 0.1875rem;
    svg {
        height: 0.75rem;
        width: 0.75rem;
    }
}

.input-bottom-border {
    border-bottom: 0.0625rem solid $grey-three !important;
    height: 0.9375rem !important;
    font-size: 0.75rem !important;
    padding: 0rem !important;
    margin: 0rem !important;
    background: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0rem !important;
}

.input-bottom-border:focus {
    border-color: #2c83c4 !important;
}

input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    cursor: pointer;
    background: none !important; /* Ensures no background color */
    border: none !important; /* Ensures no border */
    color: transparent !important; /* Ensures no visible spin button */
    background-color: transparent !important;
}

input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    cursor: pointer;
    background: none !important; /* Ensures no background color */
    border: none !important; /* Ensures no border */
    color: transparent !important; /* Ensures no visible spin button */
    background-color: transparent !important;
}

.sub-msg {
    font-size: 0.75rem;
}

.login-user-initial-status {
    margin: 0.125rem;
    padding: 0.375rem;
    text-align: center;
    line-height: 0.4375rem;

    font-size: 0.625rem;
    font-weight: 600;
    color: #6a7183;
    z-index: 1;
    cursor: pointer;
    border-radius: 50%;
    text-transform: uppercase;
    box-shadow: 0rem 0rem 0.0625rem 0rem #333;
}
.login-user-initial-tile {
    margin: 0;
    padding: 1rem;
    text-align: center;
    line-height: initial;
    font-size: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.25rem;
    width: 1.25rem;
    font-weight: 600;
    color: #6a7183;
    z-index: 1;
    // cursor: pointer;
    border-radius: 50%;
    text-transform: uppercase;
    box-shadow: 0rem 0rem 0.0625rem 0rem #333;
}

.login-user-initial-capsule {
    // height: 1.375rem;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // border-radius: 1.625rem !important;
    // background: #fff;
    // margin-left: 0.4375rem;
    // background: #c6fffd;
    // width: 3.75rem;

    svg {
        height: 0.625rem !important;
        width: 0.625rem !important;
        stroke-width: 1.25rem;
        margin-right: 0.3125rem;
        margin-left: 0.1875rem;
    }
}
.online-user-dot {
    .ant-badge-status-dot {
        width: 0.5rem !important;
        height: 0.5rem !important;
        top: -0.125rem !important;
    }

    .ant-badge-status-processing::after {
        border-width: 0.25rem !important;
        border-style: solid;
        border-color: white !important;
    }
}

.online-user-dot-normal {
    .ant-badge-status-dot {
        width: 0.5rem !important;
        height: 0.5rem !important;
        top: -0.125rem !important;
    }
    .ant-badge-status-processing::after {
        border-width: 0.25rem !important;
        border-style: solid;
    }
}

.ant-notification {
    padding: 0rem !important;
    margin: 0rem !important;
    height: 1.875rem !important;
    line-height: normal !important;
    top: 3.75rem !important;
    right: 0.9375rem !important;
    font-size: 0.875rem;
}
.user-status-notification {
    height: 3.4375rem !important;
    min-width: 17.1875rem !important;
    max-width: fit-content !important;
    .ant-notification-notice-message {
        padding: 0rem !important;
        margin-bottom: 1.25rem !important;
        // line-height: 0 !important;
    }
    .ant-notification-notice-content {
        padding: 0rem !important;
        margin: 0rem !important;
        // line-height: 0 !important;
    }
    &.ant-notification-notice {
        padding: 0.8125rem !important;
    }
}

#online-popover {
    .ant-popover-inner {
        border-radius: 0.625rem !important;
        padding: 0.0625rem;
    }
    .ant-popover-inner-content {
        // background-color: #21252a;
        border-radius: 0.625rem !important;
    }
    .users-list {
        min-height: auto;
        max-height: 9.75rem;
        overflow-y: auto;

        .text-elipse {
            white-space: nowrap;
            width: 6.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

// #online-popover::-webkit-scrollbar-track,
// #online-popover *::-webkit-scrollbar-track {
//     background-color: #21252a !important;
//     border-radius: 0.625rem;
// }

.me-icon {
    font-size: 0.4375rem;
    padding: 0.0625rem;
    bottom: 1.5625rem;
    position: absolute;
    left: 2.0625rem;
    z-index: 1;
    font-weight: 800;
}
.msg-text-elipse {
    white-space: nowrap;
    width: 0.625rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clust-status {
    svg {
        height: 1.25rem;
        width: 1.25rem;
    }
}

.credit-clust-drawer {
    @media (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }
    }
}