body {
    .custom-accordion {
        .accordion-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $header-back;
            padding: $padding5 + 2 $padding10;
        }

        .accordion-heading {
            display: flex;
            align-items: center;

            img {
                height: $height50 - 32;
                margin-right: $margin5;
            }

            h3 {
                margin: 0;
                font-size: $font14;
                text-transform: $tt-uppercase;
                color: $primary;
                font-family: $inter-regular;
            }
        }
    }
}
