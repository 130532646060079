// media query for ipad

@media only screen and (max-width: 1100px) {
    body {
        // &.vertical-collpsed {
        //     .main-content {
        //         // margin-left: 60px !important;
        //         overflow-y: auto;

        //         .landingPage,
        //         .POD-main-container,
        //         .responsive-scroll,
        //         .user-management-data-domain,
        //         .data-domain-detail {
        //             width: $width100 * 13;
                    
        //         }
        //     }
        // }

        .landingPage {
            .landingPage_listView_data {
                .ant-table-wrapper {
                    .ant-table-container {
                        &::before,
                        &::after {
                            display: none !important;
                        }
                    }
                }
            }

            .landinggridview-responsive {
                grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            }
            .connectiongridview-responsive {
                grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            }
        }
    }
}
