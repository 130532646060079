@import "../../../assets/scss/common/colors";


.active-timeline-tab{

    color: $primary;
}
.timeline-tab{
    .ant-tabs-tab-active{
        box-shadow: none;
        border-bottom: 2px solid !important;
    }
}
.icon-primary{
    svg{
        path{
            fill:$primary;
        }
    }
}