body {
    //button
    .btn {
        font-size: $font12;
        height: 1.75rem;
        border: none;
        text-transform: $tt-capitalize;
        padding: 0 $padding10;
        font-family: $inter-semibold;
        @include transition(0.2s ease-in-out);
        @include border-radius(3px);

        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }

        i {
            font-size: $font16 - 1;
            vertical-align: text-bottom;

            &.bx-chevron-left {
                font-size: $font16 + 4;
            }

            &.bx-edit-alt,
            &.bx-trash-alt,
            &.bxs-save {
                font-size: $font12;
                margin-right: $margin5 - 1;
            }
        }

        &.btn-icon {
            padding: 0;
            width: $width50 - 22;
        }
    }

    .btn-primary {
        background-color: $primary;
        color: $white;

        &:focus {
            background-color: $primary;
        }

        &:hover,
        &:active {
            background-color: $primary !important;
        }
    }

    .btn-secondary {
        background-color: $white;
        border: 1px solid $primary;
        color: $primary !important;

        &:focus {
            background-color: $white;
            border: 1px solid $primary;
        }

        &:hover,
        &:active {
            background-color: $primary !important;
            color: $white !important;
            border-color: $primary !important;
        }
    }

    .btn-grey {
        background-color: $gainsboro;
        color: $color-content;

        &:focus {
            background-color: $gainsboro;
        }

        &:hover,
        &:active {
            background-color: $violet;
        }
    }

    .btn-black {
        background-color: $black;
        color: $white !important;

        &:hover {
            background-color: $black;
        }

        &:focus {
            background-color: $black !important;
        }
    }

    .btn-danger-outline {
        border: 1px solid $lightred;
        color: $lightred !important;
        background-color: $white;

        &:hover {
            background-color: $lightred;
            color: $white !important;
        }

        &:active {
            background-color: $lightred;
            border: 1px solid $lightred;
            color: $white !important;
        }
    }

    .btn-danger {
        background-color: $lightred;
        color: $white;

        &:hover {
            background-color: $flushMahogany;
            color: $white;
        }
    }

    .btn-success-outline {
        border: 1px solid $appleGreen;
        background-color: $white;
        color: $appleGreen;

        &:hover {
            background-color: $appleGreen;
            color: $white;
        }
    }

    .account-pages {
        .btn-primary {
            color: $white !important;
            display: inline-flex;
            place-items: center;
        }

        img {
            height: $height50 * 6;
        }
    }
}
