.tutor-help-btn {
    position: fixed;
    display: flex;
    width: 40px;
    height: 40px;
    background: linear-gradient(45deg, #57b8ff, #003988);
    // border: solid 1px #ffffff;
    align-items: center;
    justify-content: center;
    bottom: 56px;
    left: 3px;
    z-index: 999;
    border-radius: 100%;
    cursor: pointer;
    transition: .3s;

    &:hover {
        background: linear-gradient(272deg, #57b8ff, #003988);
        transition: .3s;
    }
}
.tutor-li-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 370px;
}
.tutor-heading {
    padding: 12px 6px 12px 0;
    border-radius: 0px;
    font-size: 16px;
    font-family: 'InterSemibold';
    position: sticky;
    top: 0;
    z-index: 1;

    & p {
        font-size: 14px;
    }

    &.navigator {
        background: linear-gradient(-45deg, #cce8fb, white);
        color: #2c72a7;
    }
    &.lab {
        background: linear-gradient(-45deg, #eee3fc, white);
        color: #7a28ff;
    }
    &.pulse {
        background: linear-gradient(-45deg, #ffe7dc, white);
        color: #C60C4F;
    }
}
.tutor-collapse {
    &.show {
        height: 365px;
        transition: .3s;
    }
    &.fade {
        height: 0;
        transition: .3s;
        :is(ul, .ant-list-empty-text) {
            display: none !important;
        }
    }
    & .ant-list-item-meta {
        padding-left: 46px;
    }
}