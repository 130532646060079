.usage-and-jobs-wrapper {
    .flow-sub-header {
        min-height: 2rem;
    }
    & .jobs-flow-alert {
        .custom-simple-landing-dgt {
            height: calc(100vh - 7.0625rem);
        }
    }
    .custom-simple-landing-dgt {
        height: calc(100vh - 5.25rem);
    }
    .usage-refresh-panel {
        position: absolute;
        top: 1.8125rem;
        right: 0.9375rem;
        width: 15.625rem;
    }
    #usage-refresh-panel {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    #usage-refresh-panel-jobs {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .right-flow-header {
        display: none !important;
    }
    .tab-menu {
        .subtitle {
            display: flex;
            align-items: center;
            gap: 0.25rem;
        }
    }
}

.scheduler-logs-drawer {
    #usage-refresh-panel-jobs {
        position: absolute;
        top: 0.625rem;
        right: 4.625rem;
    }
    .ant-drawer-header {
        position: relative;
    }
}
.error-details-modal {
    z-index: 1300 !important;
}

// .ant-drawer {
//     z-index: 1000;
// }

.new-usage-main-tabs {
    .ant-tabs-nav {
        margin-bottom: 0 !important;
        padding: 0.5rem 0.9375rem 0 0.9375rem;
    }

    .ant-tabs-content {
        & > div {
            height: calc(100vh - 4.5rem);
            overflow-y: auto;
        }
    }
}

.new-usage-stats-wrapper {
    padding: 0.625rem 0.9375rem;

    & > div:first-child {
        border-bottom: solid 0.0625rem #d5d5d5;
        position: relative;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 6px;
            background: #fff;
            position: absolute;
            bottom: 0px;
        }
    }

    & > div:first-child {
        height: 45%;
    }
    & > div:last-child {
        height: 55%;
    }

    .label {
        color: #495057;
        margin-left: 0.0625rem;
        margin-bottom: 0.0625rem;
    }
    @media (max-width: 768px) {
        & > div:first-child {
            height: auto;
        }
        & > div:last-child {
            height: 25rem;
        }
    }

    @media (max-width: 1100px) and (max-height: 920px) {
        & > div:first-child {
            height: auto;
        }
        & > div:last-child {
            height: 50%;
            // height: 22rem;
        }
    }
}

.n-usg-heading {
    padding-top: 0.9375rem;
    background: #fff;
    position: relative;
    z-index: 1;
}

.n-usg-stats-upper {
    display: grid;
    grid-template-columns: auto 20.625rem;
    height: calc(100% - 2.1875rem);
    padding-bottom: 0.9375rem;

    & > div:first-child {
        padding-right: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & > div:last-child {
        padding-left: 1.5rem;
        border-left: solid 0.0625rem #d5d5d5;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;

        & > div:last-child {
            border: none;
            padding-top: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.n-usg-upper-graph {
    text-align: center;
    padding: 1.125rem 0;

    @media (min-width: 1200px) and (max-width: 1400px) {
        @media (max-height: 726px) {
            .ant-progress-circle > .ant-progress-inner {
                height: 6.25rem !important;
                width: 6.25rem !important;
                font-size: 1.25rem !important;
            }
        }
    }
}

.usage-circle-progress {
    .ant-progress-circle-trail {
        stroke-width: 0.75rem;
    }
}

.n-usg-legend {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background: #b0baff;
    border-radius: 100%;
}
.n-usg-lower-graph {
    height: calc(100% - 4.25rem);
    overflow: hidden;
}

.nusg-kpi-heading {
    color: #777a85;
    margin-bottom: 0;
    @media (min-width: 1600px) {
        font-size: 1rem !important;
    }
}

.nusg-kpi-val {
    color: #2b3674;
    font-size: 1.25rem;
    margin-bottom: 0;
}

.nusg-conn-avt {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    & > .src-span {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;

        img.sources_circle {
            height: 100%;
            width: 100%;
            margin: auto;
        }
    }
}

.nusg-conn-avt-grp {
    justify-content: flex-end;
    .ant-avatar-string {
        font-size: 80% !important;
    }

    @media (max-width: 650px) {
        .ant-avatar {
            height: 3.125rem !important;
            width: 3.125rem !important;
        }
    }
}

.nusg-kpi-row {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    overflow: hidden;

    & > div {
        padding: 0.625rem 1rem;
    }

    & > div:first-child {
        padding-left: 0.625rem;
    }

    & > div:not(:first-child) {
        border-left: solid 0.0625rem #d5d5d5;
    }

    @media (min-width: 1600px) {
        & > div {
            padding: 1.125rem 1.5rem;
        }
    }

    @media (max-width: 938px) {
        & {
            grid-template-columns: repeat(2, 50%);
            gap: 1rem;
        }
        & > div:first-child {
            padding-left: 1rem;
        }
    }

    @media (max-width: 768px) {
        & {
            grid-template-columns: repeat(4, 25%);
        }
    }

    @media (max-width: 650px) {
        grid-template-columns: repeat(2, 50%);

        & > div {
            padding: 0.625rem 0.625rem;
        }

        & > div:nth-child(3) {
            border: none;
        }
    }
}

.nusg-kpi-ic {
    display: flex;
    height: 3.4375rem;
    width: 3.4375rem;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.8125rem;
    color: #4318ff;
    background: #f4f7fe;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0.875rem;
    @media (min-width: 1600px) {
        height: 4rem;
        width: 4rem;
    }
    @media (max-width: 1200px) {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0.625rem;
    }
    @media (max-width: 768px) {
        height: 2rem;
        width: 2rem;
        padding: 0.5rem;
    }
    @media (max-width: 650px) {
        height: 3.125rem;
        width: 3.125rem;
        padding: 0.75rem;
    }
}

.nusg-kpi-sep {
    margin: 1rem 0;
}

.nusg-dropdown-ic {
    margin-right: 0.5rem;
    display: flex;
    height: 1.75rem;
    width: 1.9375rem;
    align-items: center;
    padding: 0.375rem;
    background: #e3fff7;
    border-radius: 100%;
    svg {
        height: 0.875rem;
        width: 0.9375rem;
    }
}

.nusg-dropdown-label {
    min-width: 5rem;
    font-family: "InterRegular";
    font-size: 0.8125rem;
}

.nusg-dropdown-selected {
    width: 9.375rem;
}

.nusg-domain-ddl {
    width: 11.25rem;

    .custom-ddl-item {
        font-size: 0.625rem;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
    }
}

.new-usage-ativity-wrapper {
    padding: 0.625rem 0.9375rem;
}

.nusg-activity-filter-wrap {
    .vertical-separator {
        height: 1.375rem;
        margin: 0 1.25rem -1.6875rem;
    }

    .label {
        margin-left: 0.0625rem;
        color: #495057;
    }
    @media (max-width: 1000px) {
        & {
            margin-right: auto;
        }
        .vertical-separator {
            margin: 0 0.5rem;
        }
    }
}

.nusg-module-ddl {
    width: 11.25rem;

    .custom-ddl-item {
        font-size: 0.8125rem;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
    }

    .custom-ddl-item-wrap {
        max-height: none;
        overflow-y: hidden;

        svg {
            height: 0.875rem;
            width: 0.875rem;
        }
    }
}

.nusg-module-ddl-selected {
    padding: 0.1875rem 0.625rem;
    border: solid 0.0625rem #eeeeee;
    border-radius: 0.375rem;
    height: 2rem;
    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}

.nusg-activity-table-wrap {
    .custom-simple-landing-dgt {
        height: calc(100vh - 10.625rem) !important;
        margin: 0 -0.9375rem;
        padding: 0 0.5rem;

        .rdg-cell {
            border: none !important;
        }
    }

    @media (max-width: 1000px) {
        & .custom-simple-landing-dgt {
            height: calc(100vh - 12.3125rem) !important;
        }
    }
}

.nusg-highlight-color {
    color: #275a89;
}

.nusg-activity-table-wrap {
    &.col-holding-3 {
        .custom-simple-landing-dgt {
            grid-template-columns: 45% 33% auto !important;
        }
    }
    &.col-holding-4 {
        .custom-simple-landing-dgt {
            grid-template-columns: 28% 30% auto 11.25rem !important;
        }
    }
    &.col-holding-5 {
        .custom-simple-landing-dgt {
            grid-template-columns: 28% 30% 12.5rem auto 11.25rem !important;
        }
    }
    @media (max-width: 940px) {
        &.col-holding-5 {
            .custom-simple-landing-dgt {
                grid-template-columns: 25% 25% auto 8.75rem 8.25rem !important;
            }
        }
        &.col-holding-4 {
            .custom-simple-landing-dgt {
                grid-template-columns: 25% 25% 25% 25% !important;
            }
        }
        &.col-holding-3 {
            .custom-simple-landing-dgt {
                grid-template-columns: 34% 34% 32% !important;
            }
        }
    }
}

.new-usg-heading-wrap {
    @media (max-width: 1000px) {
        & {
            flex-direction: column;
            margin-top: -4px;
        }
        & > div:first-child {
            width: 100%;
            height: 1.5rem;
        }
        // .usg-act-log-heading {
        //     margin-bottom: 0.75rem !important;
        // }
    }
}

.kpi-holder {
    cursor: pointer;
    transform: scale(1);
    transition: 0.3s;
}

.kpi-holder:hover {
    transform: scale(1.1);
    transition: 0.3s;
}

#usage-filter-range-picker {
    @media (max-width: 830px) {
        width: 9.375rem !important;
    }
}

.usage-circle-progress {
    .ant-progress-inner {
        height: 7.5rem !important;
        width: 7.5rem !important;
        font-size: 1.5rem !important;
    }
}
