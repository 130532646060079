body {
    background-color: $white;

    &.vertical-collpsed {
        .main-content {
            background-color: $white;
        }
    }
}

form {
    position: $p-relative;
}

.form-check {
    input[type="radio"].form-check-input {
        top: -1px;
    }
}

.form-group {
    .error {
        font-size: $font12;
        margin: 0;
    }
}

.error{
    color: #ff565e;
}
// unordered list

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

//select

select.form-control {
    height: $height50 - 20;
    border: 1px solid $galleryGray;
    @include border-radius(0);
    font-family: $inter-regular;
    box-shadow: none !important;
    padding: 0 $padding5;

    &:focus {
        border-color: $primary;
    }
}

//input field

input.form-control {
    height: $height50 - 20;
    border: 1px solid $galleryGray;
    @include border-radius(0);
    font-family: $inter-regular;
    box-shadow: none !important;
    font-size: 0.6875rem;

    &:focus {
        border-color: $primary;
    }
}

//textarea
textarea.form-control {
    border: 1px solid $galleryGray;
    @include border-radius(0);
    font-family: $inter-regular;
    width: $width-full;
    box-shadow: none !important;
    font-size: 0.6875rem;

    &:focus {
        border-color: $primary;
    }
}

//breadcrumb

.breadcrumb {
    background-color: $white;
    padding: $padding10 * 2 0;

    li {
        font-family: $inter-light;
        font-size: $font14;
        padding-right: $padding10;

        a {
            @include transition(0.2s ease-in-out);

            &:hover {
                color: $primary;
            }
        }
    }
}

.ant-select-dropdown {
    .ant-select-item-option-content {
        font-size: $font12;
        font-family: $inter-regular;
        color: $color-content;
    }

    .rc-virtual-list {
        .ant-select-item-option-active,
        .ant-select-item-option-selected {
            background-color: $header-back !important;
        }

        .ant-select-item-option {
            .ant-select-item-option-content {
                display: block;
                // justify-content: space-between;
            justify-content: left;

                .element-name {
                    width: $width-full;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .element-type {
                    font-style: italic;
                    color: $primary;
                }
            }
        }
    }
}

body {
    .sql-header {
        font-size: $font14 - 1;
    }

    .ace_editor {
        * {
            font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace;
        }
    }
}

.section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $header-back;
    padding: $padding5 $padding10;

    .section-header-left {
        p {
            font-size: $font14;
            font-family: $inter-semibold;
            margin: 0;
        }
    }

    .section-header-right {
        display: flex;
    }
}

body {
    .text-error{
        color: #DC535C !important;
        font-size: $font14;
    }
    .ant-select {
        display: block;

        &.ant-select-focused {
            .ant-select-selector {
                border-color: $primary !important;
            }
        }
    }

    .rdw-editor-toolbar {
        padding: 6px;
        background-color: #f5f5f5;
        border: none;
        display: flex;
        align-items: center;
        border-radius: 0;

        .rdw-inline-wrapper,
        .rdw-text-align-wrapper,
        .rdw-link-wrapper,
        .rdw-block-wrapper,
        .rdw-list-wrapper {
            margin-bottom: 0;
            display: flex;
        }

        .rdw-option-wrapper,
        .rdw-dropdown-wrapper {
            min-width: $width50 - 20;
            height: $height50 - 20;
            border-radius: 3px;
            margin: 0 $margin5 0 0;
            border: none !important;
            text-align: center;
            line-height: 26px;
            padding: 0 $padding10 - 2;
            cursor: pointer;
            position: $p-relative;
            background-color: transparent !important;

            &.rdw-option-active {
                background-color: #e7e7e7 !important;
            }
        }

        .rdw-dropdown-wrapper {
            a {
                span {
                    font-size: 12px;
                }
            }
        }
    }

    .rdw-dropdown-optionwrapper {
        position: absolute;
        top: 35px;
        left: 0;
        background-color: $white;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.21);

        li {
            font-size: $font12;
            padding: $padding5 - 2 $padding10;
            font-family: $inter-regular;

            &.rdw-dropdownoption-active {
                background-color: $header-back;
            }

            &:hover {
                background-color: $header-back;
            }
        }
    }

    .rdw-link-modal {
        height: auto;
        position: absolute;
        top: 45px;
        background-color: $white;
        padding: $padding10;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.21);

        label {
            font-family: $inter-medium;
            font-size: $font12 !important;
            font-weight: 500 !important;
            margin: 0 0 $margin5 0;
            display: $d-block;
        }

        .rdw-link-modal-input {
            height: $height50 - 20;
            border: 1px solid $galleryGray;
            border-radius: 0;
            font-size: $font12;
            margin: 0 0 $margin5 * 3 0;
            outline: none !important;
        }

        input[type="checkbox"] {
            vertical-align: middle;
            margin: 0 $margin5 0 0;
        }

        .rdw-link-modal-buttonsection {
            margin: $margin5 * 3 0 0 0;
            display: $d-block;

            button {
                font-size: $font12;
                padding: $padding5 $padding5 * 3;
                border-radius: 3px;
                font-weight: 600;
                box-shadow: none !important;
                color: $white;
                background-color: $primary;
                border: 1px solid $primary;
                transition: all 0.2s ease-in-out;

                &:nth-child(2) {
                    border: 1px solid $primary;
                    color: $primary !important;
                    background-color: $white;
                    margin-left: $margin10;

                    &:hover {
                        background-color: $primary;
                        color: $white !important;
                    }
                }

                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
}

.section_with_left_form {
    display: flex;
    min-height: calc(100vh - 110px);
    padding: 0;

    .form_left {
        width: 20%;
        // padding: $padding10 + 5;
        border-right: 1px solid $galleryGray;
        height: calc(100vh - 85px);
        overflow: auto;

        label {
            margin: 0 0 $margin5 0;
            font-weight: 400 !important;
            display: $d-block;
            font-family: $inter-semibold;
        }

        .warning {
            color: #fab43a;
            font-size: $font12 - 2;
        }

        textarea:not(:disabled) {
            height: 200px;
        }

        // .ant-select {
        //     .ant-select-clear {
        //         top: 15px;
        //         right: 2px;
        //     }

        //     .ant-select-selector {
        //         border: 1px solid $galleryGray !important;
        //         background-color: $white !important;
        //         border-radius: 0 !important;
        //         min-height: $height50 - 20 !important;
        //         box-shadow: none;
        //         padding: 0 $padding5 !important;
        //         height: auto !important;

        //         .ant-select-selection-item {
        //             background-color: $primary;
        //             border-radius: 4px;
        //             font-size: $font12;

        //             .ant-select-selection-item-content {
        //                 color: $white;
        //             }

        //             .ant-select-selection-item-remove {
        //                 display: flex;
        //                 align-items: center;
        //                 color: $white;
        //             }
        //         }

        //         &:focus {
        //             outline: none !important;
        //             border-color: $primary;
        //         }

        //         .ant-select-selection-placeholder {
        //             color: $color-content;
        //             font-size: $font12;
        //         }
        //     }

        //     &.ant-select-disabled {
        //         .ant-select-selector {
        //             background-color: $tableColGrey !important;
        //             .ant-select-selection-item {
        //                 opacity: 0.5;
        //             }
        //         }
        //     }

        //     &.ant-select-focused {
        //         .ant-select-selector {
        //             box-shadow: none;
        //         }
        //     }
        // }
    }

    .section_content_right {
        width: 80%;
        padding: $padding10 + 5 $padding10 + 5 $padding5 $padding10 + 5;
        height: calc(100vh - 102px);
        overflow: auto;
        background-color: #fbfbfd;

        .query-builder-main {
            display: flex;
            margin-bottom: $margin10 * 2;
            border-bottom: 1px solid $galleryGray;

            .query-builder-container {
                width: $width-full - 30;
                padding-right: $padding10 + 5 !important;
                border-right: 1px solid $galleryGray;
            }

            .string-format {
                width: $width-full - 70;
                margin-left: $padding10 + 5;
                border: 1px solid $galleryGray;

                label {
                    font-weight: 500 !important;
                    font-size: $font12 !important;
                    font-family: $inter-medium;
                    padding: $padding5 $padding10;
                    background-color: $header-back;
                    color: $color-content;
                    display: $d-block;
                }

                p {
                    margin: 0;
                    padding: $padding10;
                    font-size: $font12;
                }
            }
        }

        .query-builder {
            margin: 0;
            font-family: $inter-regular;
            height: $height50 * 3 + 10;
            overflow: auto;

            .group-or-rule.group {
                padding: 0;
            }
            .group-or-rule {
                .group--header {
                    margin-top: 0;

                    .ant-btn {
                        font-size: $font12;
                        height: $height50 - 22 !important;
                        border: none;
                        text-transform: $tt-capitalize;
                        padding: 0 $padding10 * 2 !important;
                        font-family: $inter-semibold;
                        @include transition(0.2s ease-in-out);
                        border-radius: 0 !important;
                        width: auto !important;

                        &.ant-btn-primary {
                            background-color: $primary;
                            color: $white;
                        }
                    }
                }

                .group--conjunctions {
                    .ant-btn {
                        background-color: $white;
                        border: 1px solid $primary;
                        color: $primary;

                        &:hover {
                            background-color: $primary;
                            color: $white;
                        }
                    }
                }

                .group--actions {
                    .action--ADD-RULE {
                        background-color: $primary !important;
                    }

                    .action--ADD-GROUP {
                        background-color: $white !important;
                        border: 1px solid $primary !important;
                        color: $primary !important;

                        &:hover {
                            background-color: $primary !important;
                            color: $white !important;
                        }
                    }

                    .ant-btn-danger {
                        border: 1px solid $lightred;
                        color: $lightred !important;
                        background-color: $white !important;
                        padding: 0 !important;
                        width: $width50 - 20 !important;

                        &:hover {
                            background-color: $lightred !important;
                            color: $white !important;
                        }
                    }
                }
            }

            .group--children {
                .rule.group-or-rule {
                    @include border-radius(0);
                    background-color: $header-back;
                }
                .rule--body {
                    .rule--field,
                    .rule--operator {
                        width: $width-full - 66.6666;

                        .ant-select {
                            width: $width-full !important;
                        }
                    }

                    .rule--field {
                        .ant-select-selector {
                            border: 1px solid $galleryGray !important;
                            background-color: $white !important;
                            border-radius: 0 !important;
                            height: $height50 - 20 !important;
                        }
                    }

                    .rule--value {
                        width: $width-full - 66.6666;

                        .rule--widget {
                            display: flex;
                            align-items: center;
                            font-size: $font12;
                        }

                        .widget--valuesrc {
                            display: none;
                        }

                        .widget--widget {
                            width: $width-full;

                            .ant-col,
                            .ant-input-number {
                                height: auto !important;
                                padding: 0 !important;
                                border: none !important;
                                box-shadow: none;
                            }

                            input[type="text"],
                            input[role="spinbutton"],
                            .ant-picker {
                                border: 1px solid $galleryGray !important;
                                background-color: $white !important;
                                border-radius: 0 !important;
                                height: $height50 - 20 !important;

                                &::placeholder {
                                    font-size: $font12;
                                }
                            }
                        }
                    }

                    .rule--operator {
                        display: $d-inline-block;
                        margin: 0;
                        padding-left: $padding10;
                    }
                }

                .rule--header {
                    .ant-btn-danger {
                        border: 1px solid $lightred;
                        color: $lightred !important;
                        background-color: $white !important;
                        padding: 0 !important;
                        width: $width50 - 25 !important;
                        height: $height50 - 25 !important;
                        border-radius: 0 !important;

                        &:hover {
                            background-color: $lightred !important;
                            color: $white !important;
                        }
                    }
                }
                .ant-select {
                    .ant-select-selection-item,
                    .ant-select-selection-placeholder {
                        background-color: $white;
                        border-radius: 0;
                        line-height: 1.3;
                        font-size: $font12 + 1;
                    }
                }
            }
        }

        .custom-accordion {
            .accordion-heading {
                h3 {
                    text-transform: $tt-capitalize;
                }
            }
        }
    }
}
