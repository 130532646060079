@import "../../../../../assets/scss/common/colors";

.catalogue-subheader {
  // position: sticky;
  // background: #FFF;
  // top: 2.1875rem;
  // z-index: 1;

  .svg-dark {
    svg {
      path {
        fill: $black;
      }
    }
  }
  .catalogue-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: unset;
  }

  .catalogue-list {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
  }

  .catalogue-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.1875rem 0rem;
    border-bottom: 0.125rem solid transparent;

    &.tab-dis {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &:last-child {
      svg {
        width: 0.875rem !important;
        // margin-right: 0.375rem;
      }
    }
    .catalogue-icon {
      margin-right: 0.5rem;
      svg {
        height: 1rem;
        width: 1rem;
        top: -0.0625rem;
        position: relative;
      }

      svg,
      path {
        fill: $black;
      }
    }

    .catalogue-title {
      font-weight: 600;
      font-size: 0.75rem;
      color: #17171a;
    }

    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  }

  .active {
    border-bottom: 0.125rem solid $primary;

    .catalogue-icon {
      svg,
      path {
        fill: $primary !important;
      }
    }

    .catalogue-title {
      color: $primary;
    }
  }
}
