
.data-type-list {
  .ant-list {
    box-sizing: border-box;
    margin: 0;
    overflow: auto;
    height: 220px;
    width: 180px;
    padding: 7px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
  }

  .ant-list-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 2px;
    color: rgba(0, 0, 0, 0.85);
  }

  li:hover {
    background-color: #e5e5e5;
  }
}

.crt-pod-schm-wrap {
  &.default-fit {
    .custom-vlist-dgt {
      grid-template-columns: 30% minmax(200px, auto) minmax(180px, 200px) 180px !important;
      // grid-template-columns: 25% minmax(200px, auto) minmax(200px, auto) minmax(200px, auto) 180px !important;


      // grid-template-columns: 25% minmax(200px, auto) minmax(200px, auto) minmax(200px, auto) 150px 180px !important;
      // grid-template-columns: 25% auto 200px 200px 150px 150px !important;
    }
  }
  &.default-fit-5 {
    .custom-vlist-dgt {
      grid-template-columns: 25% minmax(200px, auto) minmax(200px, auto) minmax(200px, auto) 180px !important;
    }
  }
  &.default-fit-6 {
    .custom-vlist-dgt {
      grid-template-columns: 25% minmax(200px, auto) minmax(200px, auto) minmax(200px, auto) 180px !important;
    }
  }
  &.default-fit-7 {
    .custom-vlist-dgt {
      grid-template-columns: minmax(300px, 400px) minmax(200px, 400px) minmax(150px, auto) minmax(150px, auto) 110px 180px 180px !important;
    }
  }
  &.default-fit-8 {
    .custom-vlist-dgt {
      grid-template-columns: minmax(300px, 400px) minmax(200px, 400px) minmax(150px, auto) minmax(150px, auto) 110px 180px 180px 180px !important;
    }
  }

  .tag-list {
    display: flex;
    align-items: center;
    justify-content: left;

    border: 1px solid #e5e5e5;
    height: 1.9375rem;
    border-radius: 4px;
    width: calc(100% - 3.75rem);
    flex-wrap: wrap;
    max-width: calc(100% - 3.75rem);
    // margin: 0px 15px 1px 13px;

    // .categoryTag-danger {
    //   background-color: #FF565E !important;
    //   color: white !important;
    // }

    &.clsf {
      flex-wrap: nowrap !important;
    }

    .categoryTag {
      max-width: 86px;
      & .text-with-ellipsis {
        max-width: 100% !important;
      }
      & .custom-btn-category {
        &:hover {
          background: #0000001a;
        }
        font-size: 13px !important;
        position: relative;
        right: -12px;
        background: #7a797917;
        border-radius: 0 24px 24px 0px !important;
        padding: 10px;
        width: 24px !important;
        height: 26px !important;
      }
    }

    // .classification {
    //   background-color: #ffebc5;
    //   color: #707070;
    //   display: flex;
    //   align-items: center;
    //   padding: 4px 12px 4px 12px;
    //   font-size: 8px;
    //   font-family: "InterRegular";
    //   height: 26px;
    //   width: auto;
    //   font-weight: 600;
    //   border-radius: 50px;
    //   position: relative;

    //   // span {
    //   //   color: $white;
    //   // }


    // }

    // .businesstermTag {
    //   background-color: #DCFAE9;
    //   color: #707070;
    //   border: none;
    //   display: flex;
    //   align-items: center;
    //   padding: 10px;
    //   outline: none;
    //   border-radius: 1px;
    //   font-size: 8px;
    //   font-weight: 600;
    //   font-family: "InterRegular";
    //   height: 24px;
    //   width: 100%;

    //   border-radius: 3px;

    //   .text-elipse {
    //     white-space: nowrap;
    //     width: 100px;
    //     overflow: hidden;
    //     text-overflow: ellipsis;

    //   }


    // }

    // .warning_businessTerm {

    //   background-color: #fcf4b0;
    //   color: #707070;

    //   border: none;
    //   display: flex;
    //   align-items: center;
    //   padding: 10px;
    //   font-weight: 600;
    //   outline: none;
    //   border-radius: 1px;
    //   font-size: 8px;
    //   font-family: "InterRegular";
    //   height: 24px;
    //   width: 100%;

    //   border-radius: 3px;
    // }

    .text-elipse {
      white-space: nowrap;
      max-width: 86px;
      overflow: hidden;
      text-overflow: ellipsis;

    }

  }
}


.custom-btn-category {
  background-color: transparent;
  color: #707070;
  padding: 4px;
  border-radius: 100% !important;
  font-size: 10px;
  font-weight: 600;
  font-family: "InterRegular";
  width: 14px !important;
  line-height: 1.4 !important;
  border: none;
  &.select {
    &:hover {
      background-color: #ebebeb;
    }
    background-color: #ffffff;
    padding: 4px 10px 4px 4px;
    border-radius: 150px !important;
    border: dashed 1px #2c83c4a1;
    width: auto !important;
  }

  & svg {
    margin: 0 5px;
    height: 8px !important;
    width: 8px !important;
    opacity: .8 !important;
  }
}
:is(.apall-btn, .edt-btn) {
  width: 24px !important;
  border-radius: 100% !important;
}


.custom-btn-classification {
  background-color: transparent;
  color: #707070;
  padding: 0.25rem;
  border-radius: 100% !important;
  font-size: 0.625rem;
  font-weight: 600;
  font-family: "InterRegular";
  width: 0.875rem !important;
  line-height: 1.4 !important;
  border: none;
  &.select {
    &:hover {
      background-color: #ebebeb;
    }
    background-color: #ffffff;
    padding: 0.25rem 0.625rem 0.25rem 0.25rem;
    border-radius: 9.375rem !important;
    border: dashed 1px #edb138;
    width: auto !important;
  }

  // span {
  //   color: $white;
  // }

  & svg {
    margin: 0 0.3125rem;
    height: 0.5rem !important;
    width: 0.5rem !important;
    opacity: .8 !important;
  }
}

.custom-btn-businessterm {
  background-color: transparent;
  color: #03dc62;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 600;
  border-radius: 1px;
  font-size: 10px;
  font-family: "InterRegular";
  height: 1.5rem;
  border: none;

  & svg {
    margin: 0 0.3125rem;
    height: 0.875rem !important;
    width: 0.875rem !important;
    opacity: .8 !important;
  }
}

.custom-btn-warning-businessterm {

  background-color: transparent;
  color: #c1ae03;

  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 1px;
  font-weight: 600;

  font-size: 0.625rem;
  font-family: "InterRegular";
  height: 1.5rem;
  border: none;

  & svg {
    margin: 0 0.3125rem;
    height: 0.875rem !important;
    width: 0.875rem !important;
    opacity: .8 !important;
  }
}

.ant-avatar {
  box-sizing: border-box;
  margin: 0.25rem;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.625rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.5625rem;
  border-radius: 50%;
}

.ant-avatar-group .ant-avatar {
  border: 2px solid #fff;
}

// }

.ant-popover-inner-content .ant-avatar {
  box-sizing: border-box;
  margin: 0.1875rem;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.625rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  border-radius: 50%;
}



.helplinktext {
  // background-color: #FFFF;
  color: black;
  border: none;
  display: block;
  text-wrap: wrap;
  line-height: 1.4;
  text-align: left !important;
  padding: 6px 0px;
  outline: none;
  border-radius: 1px;
  font-size: 10px;
  font-weight: 400;
  font-family: "InterRegular";

  div strong {
    -webkit-user-select: text !important;
    /* Safari */
    -ms-user-select: text !important;
    /* IE 10 and IE 11 */
    user-select: text !important
      /* Standard syntax */
  }

  -webkit-user-select: text !important;
  /* Safari */
  -ms-user-select: text !important;
  /* IE 10 and IE 11 */
  user-select: text !important;

  .text-elipse {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;

  }


}

.schma-hover-dtype-list {
  .ant-list-item-meta {
    align-items: center !important;
  }
}

.apall-btn {
  display: none !important;
}

.dic-apall-wrap {
  &:hover {
    .apall-btn {
      display: flex !important;
    }
  }
}

.crt-pod-schm-wrap .tag-list.dic-apall-wrap:hover .classificationTag.cont {
  // width: 44%;
}

.crt-pod-schm-wrap .tag-list.dic-apall-wrap:hover .categoryTag.cont {
  // width: 44%;
}

.new-schemalist-dnd-wrap .custom-vlist-dgt {
  overflow-x: scroll;

  .text-black-50.small, .text-dark.small {
    font-size: 0.6875rem;
  }

  .dtype-ic-bx {
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
.row-unchoosed {
  opacity: .6;
}
.sch-opt-field {
  &::placeholder {
    color: #333;
  }
  &:-ms-input-placeholder {
      color: #333;
  }
  &::-ms-input-placeholder {
      color: #333;
  }
}
.pod-crt-prev-tbl {
  padding-bottom: 0 !important;
  padding-top: 18px !important;
  & > div {
    height: 100% !important;
  }
}
.pod-crt-sch-splitpane {
  overflow: hidden;
  &.hide-sch-table {
    .react-split__sash {
      display: none !important;
    }
  }
}
.auto-class-wrap {
  padding: 4px 5px;
  border: solid 1px #b2efef;
  border-radius: 30px;
  background: #f0ffff;
}
.auto-classified-inp {
  position: relative;
  // input {
  //   outline: dashed 1px #2c83c4;
  // }
}
.has-match-cell {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 2px;
    height: 100%;
    left: -8px;
    top: 0;
    // background: #2c83c4;
  }
}

.status-wrap:hover {
  .apall-btn {
    display: flex !important;
  }
}

.dtype-li-wrap {
  max-height: 146px;
  overflow-y: auto;
  & > .dtype-li-itm {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    margin-bottom: 5px;
    
    &.active {
      background: #bde3ff;
    }
    
    &:hover {
      background: #ededed;
    }
  }
}

.dtype-ic-bx {
  position: relative;
  top: -1px;
}

.arch-indicator {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 6px;
  font-size: 8px;
  color: #333333;
  line-height: 1.4;
  border-radius: 0px 0px 24px 0px;
  top: 0;
  left: 0;
  background: #dedede;

  svg {
    position: relative;
    top: -2px;
    left: -2px;
  }
}

.archived-biz {
  border-radius: 3px;
  position: absolute;
  top: 15px;
  right: 43px;
  display: flex;
  font-size: 10px;
  color: #333333;
  background: #dedede;
  line-height: 1.4;
  padding: 2px 6px;
}

.new-schma-head {
  .reset-all-biz-attr {
    transition: 0.3s;
    display: flex;
    font-size: 0.875rem;
  }

  .bx-help-circle.cursor-pointer {
    font-size: 0.875rem;
  }
}