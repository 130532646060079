.show-hand-cursor {
    .rdg-row {
        cursor: pointer;
    }
}

.custom-simple-landing-dgt {
    background-color: #fff;
    border: none !important;
    overflow-x: auto;
    // height: inherit;

    .rdg-header-row {
        font-size: 0.6875rem !important;
        color: #17171A !important;
        background: #ffffff !important;
    }

    .rdg-cell {
        border-right: none !important;
        border-bottom-color: #f2f2f2 !important;
        box-shadow: none !important;
        font-family: 'InterRegular';
        padding: 0 .5rem;
    }

    .rdg-row {
        background-color: #fff;

        &:hover {
            background-color: #f5f5f5;
        }

        font-size: 0.6875rem !important;
        color: #17171A !important;
    }

    .table-row-selected {
        background-color:#ddedff;
        border: solid 0.0625rem #2c83c4;
    }
}


/* .rdg-header-sort-cell {
    position: relative;
}
.rdg-header-sort-cell::before {
    content: '';
    display: block;
    position: absolute;
    width: 0.4375rem;
    height: 0.4375rem;
    background: linear-gradient(45deg, #cfcfcf 50%, transparent 50%);
    right: 0.625rem;
    top: 51%;
    transform: translateY(-58%) rotate(135deg);
}
.rdg-header-sort-cell::after {
    content: '';
    display: block;
    position: absolute;
    width: 0.4375rem;
    height: 0.4375rem;
    background: linear-gradient(45deg, #cfcfcf 50%, transparent 50%);
    right: 0.625rem;
    top: 54%;
    transform: translateY(-45%) rotate(-45deg);
} */





/*For Sort Icon*/
.rdg-header-sort-cell {
    display: inline-flex !important;
    align-items: center;
}

:is(.rdg-cell[aria-sort="ascending"], .rdg-cell[aria-sort="descending"]) .rdg-header-sort-cell>span:last-child {
    display: flex !important;
}

.rdg-cell .rdg-header-sort-cell>span:last-child::after {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23C4C4C4'/%3E%3Cpath d='M3 10L5.59808 5.5H0.401924L3 10Z' fill='%23C4C4C4'/%3E%3C/svg%3E%0A");
}

.rdg-cell[aria-sort="ascending"] .rdg-header-sort-cell>span:last-child::after {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%232C83C4'/%3E%3Cpath d='M3 10L5.59808 5.5H0.401924L3 10Z' fill='%23C4C4C4'/%3E%3C/svg%3E%0A");
}

.rdg-cell[aria-sort="descending"] .rdg-header-sort-cell>span:last-child::after {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 10L5.59808 5.5H0.401924L3 10Z' fill='%232C83C4'/%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23C4C4C4'/%3E%3C/svg%3E");
}

.rdg-header-sort-cell>span:last-child {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
}

.rdg-header-sort-cell>span:last-child>svg {
    display: none !important;
}

.rdg-header-sort-cell>span:last-child::after {
    content: '';
    position: relative;
    display: block;
    width: 0.8125rem;
    height: 0.8125rem;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23C4C4C4'/%3E%3Cpath d='M3 10L5.59808 5.5H0.401924L3 10Z' fill='%23C4C4C4'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}