.dndflow {
  flex-direction: column;
  display: flex;
  height: 100%;
}
.react-flow__panel.bottom {
  bottom: 20px !important;
}

.react-flow__panel.left {
  right: 0px !important;
  left: auto;
}

.dndflow aside {
  border-right: 1px solid #eee;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 0px !important;
  border: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  padding: 0px !important;
}

.dndflow .react-flow__node-default.selected,
.react-flow__node-default.selected:hover {
  box-shadow: none;
}
.dndflow .selectedNode {
  box-shadow: none !important;
  border: 3px solid #2c83c4;
}
.dndflow .react-flow__node-default {
  width: 264px;
  border: none;
  padding: 0px;
}
.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.react-flow__controls {
  left: auto;
  right: 5px;
  bottom: 5px;
  border-radius: 3px;

  button {
      padding: 5px;
      line-height: 24px;
      height: 25px;
      width: 25px;
      border-radius: 0;

      &:nth-child(1) {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
      }

      &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
      }
  }
}