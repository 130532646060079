@import "../../common/colors";

// * {
//     font-family: $inter-regularInterSemibold InterMedium";
// }
body {
    overflow: hidden;
    cursor: default;
    letter-spacing: 0.3px;
}
body > iframe {
    display: none;
}

// :not(#html-content *, #html-content *::before, #html-content *::after)
*,
*::before,
*::after {
    box-sizing: border-box !important;
}

#root {
    font-family: "InterRegular" !important;
}

.dict-color-dark {
    color: #0a3053 !important;
    font-family: "InterSemibold" !important;
}

.Toastify {
    font-family: "InterRegular" !important;
    font-size: 0.8125rem;

    .Toastify__toast-body {
        display: flex;
        align-items: center;
        .short-toast-msg {
            word-break: break-word;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            /* truncate to 4 lines */
            -webkit-line-clamp: 2;
        }
    }
}

body {
    padding: 0 !important;
    font-family: $inter-regular;

    ::placeholder {
        color: #828282;
        opacity: 0.8;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #828282;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #828282;
    }

    .main-content {
        // margin-left: 2.8125rem;

        font-family: $inter-regular !important;

        .ant-tree {
            span {
                // color: #262626;
                font-family: $inter-regular !important;
            }
        }

        .search-tree .ant-tree-title {
            color: #262626;
        }

        .ant-popover {
            font-family: "Inter";
        }

        .ant-select-selection-item {
            font-family: $inter-medium !important;
        }
    }

    .suggestion-box .ant-list-item {
        font-family: $inter-medium !important;
        padding: 0rem !important;
    }

    .ant-collapse-content-box {
        font-family: $inter-regular !important;
    }

    .data-tab .ant-tabs {
        font-family: $inter-regular !important;
        font-size: 0.875rem;
    }
    .ant-tabs .ant-tabs-tab-btn:focus-visible {
        outline: none !important;
        outline-offset: 0.0625rem;
        transition: none !important;
        svg {
            outline: none !important;
        }
    }
    .ant-carousel {
        font-family: $inter-regular !important;
    }

    .search-land-rest-wrap {
        font-family: $inter-regular !important;
    }

    .landingPage_listView_data {
        font-family: $inter-regular !important;
    }

    .ant-select-multiple {
        font-family: $inter-regular !important;
        font-size: $font12;
        .ant-select-selection-item {
            margin-bottom: 0.125rem !important; //align the tag with dropdown
            border-radius: 0.1875rem !important;
        }
    }
    .split-sash-content-active {
        height: 0.0625rem !important;
    }

    .react-split__pane {
        overflow: visible;
    }
    .react-split__sash--horizontal {
        .split-sash-content-active {
            background-color: $primary !important;
            border-top: 0.0625rem solid $primary;

            &::before {
                content: "•••";
                position: absolute;
                color: $primary !important;
                font-size: smaller;

                left: 50%;
                top: 0;
                height: 0.0625rem;
            }
        }
    }

    .react-split__sash--horizontal {
        .split-sash-content {
            width: 100%;
            height: 100%;
            padding-top: 0.0625rem;
            border-top: 0.0625rem solid #e8e8e8;
        }
    }

    .react-split__sash--horizontal {
        .split-sash-content {
            &::before {
                content: "•••";
                position: absolute;
                color: #d3d3d3;
                font-size: smaller;

                left: 50%;
                top: 0;
                height: 0.0625rem;
            }
        }
    }

    .react-split__sash--vertical {
        .split-sash-content {
            width: 100%;
            height: 100%;
            padding-left: 0.0625rem;
            border-left: 0.125rem solid #e8e8e8;
        }

        .split-sash-content-active {
            &::before {
                content: "•••";
                position: absolute;
                color: $primary !important;
                font-size: smaller;

                left: 50%;
                top: 50%;
                height: 0.0625rem;
                rotate: 90deg;
            }
        }
    }

    .react-split__sash--vertical:hover {
        border-left: 0.125rem solid $primary !important;
        padding-left: 0.0625rem !important;
    }

    .react-split__sash--vertical .split-sash-content::before {
        content: "•••";
        position: absolute;
        color: #d3d3d3;
        font-size: smaller;
        left: 61%;
        top: 50%;
        height: 0.0625rem;
        rotate: 180;
        rotate: 90deg !important;
    }

    .common-page-table .ant-table .ant-table-tbody tr td span:first-child {
        // display: inline !important;
        // display: flex !important;
        display: grid !important;
        grid-template-columns: repeat(500, auto);
        align-items: center !important;
        word-break: unset;

        span {
            display: -webkit-box !important;
        }

        mark {
            background-color: $primary !important;
            color: #ffff;
        }
    }

    mark {
        background-color: $primary !important;
        color: #ffff;
        padding: 0.25rem 0rem;
    }

    // .custom-simple-landing-dgt {
    //     grid-template-columns: none !important;
    // }

    .custom-simple-landing-dgt {
        .rdg-cell {
            .flexible-search-wrap {
                & > span {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(800, auto);
                    align-items: center;
                    word-break: unset;
                    height: 100%;

                    & > span {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        // max-width: 12.5rem;
                        line-height: 1.4;
                        // padding: 0.25rem 0.125rem;
                    }

                    & > mark {
                        line-height: 1.4;
                    }
                }
            }
        }
    }

    #usage-filter-range-picker .usage-filter-range-picker {
        font-size: $font12;
    }

    .ant-picker .ant-picker-input > input {
        font-size: $font12;
    }

    .ant-input {
        font-size: $font12;
        line-height: 1;
    }
    .ant-picker {
        height: 1.625rem !important;
    }

    iframe {
        z-index: -1 !important;
    }

    #my_iframe {
        z-index: 3 !important;
    }

    .ant-tooltip {
        &.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
            left: 0.75rem;
        }
        .ant-tooltip-arrow {
            height: 1rem;
            width: 1rem;

            &::before {
                width: 1rem;
                height: 0.5rem;
            }
            &::after {
                width: 0.5625rem;
                height: 0.5625rem;
            }
        }
        .ant-tooltip-inner {
            font-size: $font12;
            font-family: $inter-regular;
            min-width: 2rem;
            min-height: 2rem;
            padding: 0.375rem 0.5rem;
            border-radius: 0.375rem;
        }
    }

    .ant-popover,
    .ant-checkbox,
    .ant-checkbox-wrapper,
    .ant-switch {
        font-size: 0.875rem;
    }

    .ant-checkbox-inner {
        height: 1rem !important;
        width: 1rem !important;

        &::after {
            width: 0.3571rem !important;
            height: 0.5714rem !important;
        }
    }

    .ant-switch:not(.ant-switch-small) {
        line-height: 20px;
        min-width: 2.75rem;
        height: 1.375rem;

        .ant-switch-handle {
            position: absolute;
            top: 0.125rem;
            inset-inline-start: 0.125rem;
            width: 1.125rem;
            height: 1.125rem;
            transition: all 0.2s ease-in-out;
        }
        &.ant-switch-checked {
            .ant-switch-handle {
                inset-inline-start: calc(100% - 1.25rem);
            }
        }

        &.ant-switch-checked {
            .ant-switch-inner {
                padding-inline-start: 0.5625rem;
                padding-inline-end: 1.5rem;
            }
        }
        .ant-switch-inner {
            padding-inline-start: 1.5rem;
            padding-inline-end: 0.5625rem;

            position: relative;
            top: -0.125rem;

            @media (max-width: 768px) {
                top: -0.3rem;
            }

            @media (min-width: 1281px) {
                top: -0.025rem;
            }

            &.ant-switch-inner-unchecked {
                margin-top: -1.25rem;
            }
        }
        // .ant-switch-inner .ant-switch-inner-checked {
        // margin-inline-start: calc(-100% + 1.375rem - 3rem);
        // margin-inline-end: calc(100% - 1.375rem + 3rem);
        // }
        .ant-switch-inner .ant-switch-inner-checked,
        .ant-switch-inner .ant-switch-inner-unchecked {
            font-size: 0.75rem;
        }
    }

    .ant-switch.ant-switch-small .ant-switch-handle {
        width: 0.75rem !important;
        height: 0.75rem !important;
        top: 0.125rem;
    }

    .ant-switch.ant-switch-small {
        min-width: 1.75rem !important;
        height: 1rem !important;
    }

    :not(.custom-switch-btn).ant-switch.ant-switch-small {
        line-height: 1rem !important;
    }

    .custom-switch-btn.ant-switch.ant-switch-small {
        line-height: 1.4;
        .ant-switch-inner {
            padding-inline-start: 1.125rem;
            padding-inline-end: 0.375rem;

            span {
                line-height: 1.4;
            }
        }

        .ant-switch-inner .ant-switch-inner-unchecked,
        .ant-switch-inner .ant-switch-inner-checked {
            font-size: 0.75rem;
        }

        .ant-switch-inner-checked {
            margin-inline-start: calc(-100% + 1rem - 36px);
            margin-inline-end: calc(100% - 1rem + 36px);
        }

        .ant-switch-inner .ant-switch-inner-unchecked {
            margin-top: 0;
            position: relative;
            top: -1rem;
        }
        .ant-switch-inner .ant-switch-inner-checked {
            margin-top: 0;
            position: relative;
            top: 1px;
        }
    }

    .ant-switch.ant-switch-small.ant-switch-checked .ant-switch-handle {
        inset-inline-start: calc(100% - 0.875rem);
    }

    .ant-switch.ant-switch-small.ant-switch-checked .ant-switch-inner,
    .ant-switch.ant-switch-small .ant-switch-inner {
        padding-inline-start: 0.375rem;
        padding-inline-end: 1.125rem;
        top: -0.062rem;
        position: relative;

        @media (max-width: 768px) {
            top: -0.1rem;
        }
    }

    .ant-tabs .ant-tabs-tab {
        padding: 0.75rem 0;
        font-size: 0.875rem;
    }

    .ant-list-sm .ant-list-item {
        padding: 0.5rem 1rem;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        box-shadow: 0 0 0 1.875rem $white inset !important;
    }

    // div,
    // h1,
    // h2,
    // h3,
    // h4,
    // h5,
    // h6,
    // p,
    // span {
    //     font-family:$inter-regular;
    // }

    a:focus {
        border: none !important;
    }

    .dropdown-border {
        border: 0.0625rem solid $galleryGray !important;
    }

    .ant-steps {
        font-family: $inter-regular;
    }

    .noresultmsg {
        font-family: $inter-regular;
        font-size: 1rem;
        color: #828282;
        font-weight: 700;
    }

    .full-screen-active {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100%;
        z-index: 1060;
        background: white;
    }

    .no-data-div {
        width: 31.25rem;
        padding: 0.9375rem 1.875rem;
        margin: 1.25rem + 0.3125rem auto;
        color: $primary;
        font-size: $font12;
        border: 0.0625rem solid #9f9ef2;
        border-radius: 0.375rem;

        p {
            margin: 0;
            text-align: center;
        }
    }

    .y-scroll {
        height: calc(100vh - 4.25rem);
        overflow: auto;
        background-color: $new-backgrey;

        &.usr-manage-usr-dataDomain {
            overflow: hidden;
        }
    }

    .create-pod-subheader ~ div {
        .y-scroll {
            height: calc(100vh - 8.125rem);
        }
    }

    .ant-tabs-dropdown {
        .ant-tabs-dropdown-menu-item {
            font-size: $font12;
            margin: 0.625rem;
            font-family: "InterRegular";
        }
    }

    .ace-github.ace_focus .ace_marker-layer .ace_active-line {
        background: $white !important;
    }

    &.vertical-collpsed {
        .vertical-menu {
            width: $width50 + 0.625rem !important;
            background-color: $white;
            border-right: 0.0625rem solid #ededed;
            z-index: 10;

            .metismenu {
                li {
                    position: $p-relative;

                    a {
                        width: $width50 + 0.625rem;
                        height: $height50;
                        padding: 0;
                        min-height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.2s ease-in-out;

                        .sidebar-icon {
                            width: $width50 - 0.625rem;
                            height: $height50 - 0.625rem;
                            border-radius: 0.1875rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all 0.2s ease-in-out;
                        }

                        i {
                            color: $menu-inactive;
                            font-size: $font20 + 0.3125rem;
                            padding: 0;
                            transition: all 0.2s ease-in-out;

                            &.bx-filter,
                            &.bx-list-check {
                                font-size: $font20 + 0.5rem;
                            }
                        }

                        span {
                            color: $menu-inactive;
                            opacity: 0;
                            visibility: hidden;
                            position: absolute;
                            top: 0.3125rem;
                            right: -10.0625rem;
                            height: 2.5rem;
                            min-width: 10.6875rem;
                            padding-left: 1.625rem;
                            line-height: 2.5rem;
                            transition: all 0.2s ease-in-out;
                        }
                    }

                    &.mm-active {
                        .sidebar-icon {
                            background-color: #eaeaff;

                            i {
                                color: $primary;
                            }
                        }
                    }

                    &:hover {
                        a {
                            .sidebar-icon {
                                background-color: #eaeaff;

                                i {
                                    color: $primary;
                                }
                            }

                            span {
                                color: $primary;
                                background-color: #eaeaff;
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        .login-user-initial {
                            color: $primary;
                        }

                        .sub-menu {
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    .sub-menu {
                        left: 3.75rem;
                        width: 10rem;
                        background-color: $white;
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        top: 2.8125rem;
                        display: block;
                        box-shadow: 0rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.2);
                        transition: all 0.2s ease-in-out;

                        li {
                            a {
                                color: $color-content !important;
                                width: $width-full;
                                padding: $padding10 $padding10 + 0.3125rem;
                                height: auto;
                                justify-content: flex-start;
                            }

                            &:hover {
                                a {
                                    color: $primary !important;
                                }
                            }

                            &.mm-active {
                                a {
                                    color: $primary !important;
                                }
                            }
                        }
                    }

                    &:last-child {
                        a {
                            img {
                                height: $height50 - 1.625rem;
                            }
                        }
                    }

                    .login-user-initial {
                        margin: 0;
                        position: absolute;
                        top: 48%;
                        left: 0;
                        right: 0;
                        text-align: center;
                        line-height: initial;
                        transform: translate(0, -50%);
                        font-size: $font12 - 0.125rem;
                        font-weight: 600;
                        color: $menu-inactive;
                        z-index: 1;
                        cursor: pointer;
                        text-transform: $tt-uppercase;
                    }
                }
            }
        }

        .main-content {
            margin-left: 2.8125rem;
        }

        .expand-content {
            margin-left: 0 !important;
        }
    }

    .main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.625rem;
        border-bottom: 0.0625rem solid #2c72a7;
        min-height: 1.875rem;
        background-color: #155e94;
        // position: sticky;
        // top: 0;
        // z-index: 2;

        // & + :is(.flow-sub-header, .conn-mod-page-subheader) {
        //     position: sticky;
        //     top: 2.1875rem;
        //     z-index: 2;
        //     background: #FFF;
        // }

        .header-setting-btn {
            &.open {
                background: #0000001f;
            }
            &:not(.light) svg path {
                fill: #FFF;
            }
            &:not(.light):hover svg path {
                fill: #FFFFFF70;
            }
        }

        .custom-header-nav {
            position: relative;
            display: flex;
            align-items: center;
            gap: 1rem;
            color: #FFF;

            &.light {
                color: #828282;

                & .custom-nav-item {

                    &.active {
                        padding: 0.7125rem 0;
                        color: #2c83c4;
                        &::after {
                            background: #2c83c4;
                        }
                    }
                }
            }
        }

        a.custom-nav-item {
            color: unset;
        }
        
        .custom-header-nav .custom-nav-item {
            position: relative;
            font-size: .8rem;
            // font-family: 'InterMedium';
            line-height: 1.5rem;
            cursor: pointer;
            padding: 0.3125rem 0;
            text-decoration: none;

            &.active {
                font-family: 'InterSemibold';
            }

            &:not(.active):hover{
                opacity: .6;
            }

            &.active::after {
                content: "";
                display: block;
                width: 100%;
                height: 0.1875rem;
                background: #fff;
                position: absolute;
                bottom: -1px;
            }
        }

        .hamburger {
            & svg {
                height: 1.125rem;
            }

            display: flex;
            margin-right: 0.6875rem;
            cursor: pointer;

            line {
                stroke: #fff !important;
            }
        }

        .left {
            display: flex;
            align-items: center;

            img {
                margin-right: 0.625rem;
            }
        }

        h3 {
            margin: 0;
            font-size: 0.875rem;
            color: $black;
            display: flex;
            align-items: center;

            a {
                display: inline-flex;
                width: $width50 - 1.875rem;
                text-align: left;
                justify-content: flex-end;

                &:hover {
                    color: $primary;
                }
            }

            i {
                vertical-align: middle;
                font-size: $font20 + 0.3125rem;
            }
        }

        .user-menu {
            position: $p-relative;
            cursor: pointer;

            p {
                margin-bottom: 0;
                margin-left: auto;
                height: 1.5rem;
                width: 1.5rem;
                border-radius: 50%;
                background-color: #fff;
                border: solid 0.0625rem #00000030;
                color: #256fa7;
                text-transform: uppercase;
                font-size: 0.75rem;
                display: flex;
                justify-content: center;
                // line-height: 1;
                align-items: center;
            }

            &-backdrop {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                z-index: 50;
            }

            .user-sub-menu {
                position: $p-absolute;
                top: 100%;
                right: 0;
                opacity: 0;
                visibility: hidden;
                display: block;
                min-width: 11.875rem;
                z-index: 100;
                background-color: $white;
                box-shadow: 0rem 0.0625rem 0.25rem 0rem rgba(0, 0, 0, 0.2);
                transition: all 0.2s ease-in-out;

                svg {
                    height: 1.5rem;
                    width: 1.5rem;
                }

                &-active {
                    opacity: 1;
                    visibility: visible;
                    color: $grey-three;
                }

                li {
                    a {
                        padding: $padding5 $padding10;
                        display: block;
                        text-decoration: none;
                        color: $grey-three;
                    }
                }
            }

            // &:hover {
            //     .user-sub-menu {
            //         opacity: 1;
            //         visibility: visible;
            //     }
            // }
        }
    }

    //create dash subheader
    .create-dash-subheader {
        min-height: $height50 - 1.25rem;

        h3 {
            margin: 0;
            font-size: $font16;
            font-family: $inter-bold;
            color: $color-content;
            display: flex;
            align-items: center;

            a {
                display: inline-flex;
                width: $width50 - 1.875rem;
                text-align: left;
                justify-content: flex-end;

                &:hover {
                    color: $primary;
                }
            }

            i {
                vertical-align: middle;
                font-size: $font20 + 0.3125rem;
            }
        }
    }

    a.setting-item-nav {
        color: unset;
    }
    .setting-item-nav {
        cursor: pointer;
        text-decoration: unset;

        &.active {
            background: #2c83c42b;
            color: #275a89 !important;
        }

        &:not(.active):hover {
            background: #0000001f;
        }
    }
    //splitpane background color

    // no result section
    .no-result,
    .noDataFound-body {
        height: $height-full;
        width: $width-full;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            height: 9.375rem;
            width: 9.375rem;
        }

        h3,
        p {
            color: $color-content;
        }

        h3 {
            font-family: $inter-bold;
            font-size: 1.375rem;
            margin: $margin10 0;
        }

        p {
            margin: 0;
            font-size: $font14;
            font-family: $inter-regular;
        }
    }

    ////////////user detials page lower section
    .dataDomain-lower-part {
        .no-result {
            background-color: #ffff;
        }
    }

    // no result section for connection page
    .connection-list {
        .no-result {
            height: calc(100vh - 5.3125rem);
        }
    }

    // for access management page
    .access-mgmt-table {
        .no-result {
            height: calc(100% - 2.5rem);

            img {
                height: 9.375rem;
            }
        }
    }

    // user management page
    .user-grp-bottom {
        .no-result {
            height: calc(100vh - 22.5rem);

            img {
                height: 9.375rem;
            }
        }
    }

    // user management data domain page
    .data-domain-edit {
        .data-domain-edit-right {
            .collapse-section-row-container {
                .no-result {
                    margin-top: 1.875rem;

                    img {
                        height: 9.375rem;
                    }
                }
            }
        }
    }

    // user management data domain page right section
    .data-domain-detail {
        .table-responsive {
            .no-result {
                height: calc(100vh - 9.0625rem);

                img {
                    height: 9.375rem;
                }
            }
        }
    }

    // height given on dictionary page to adjust "no result found" section
    // .pod-page-view {
    //     .dictionary_table {
    //         height: calc(100vh - 8.125rem);
    //     }
    // }

    .datarule-header ~ .no-result,
    .datarule-header ~ .noDataFound-body {
        height: calc(100vh - 6.25rem);
    }

    .sub-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $padding5 $padding10 + 0.3125rem;
        border-bottom: 0.0625rem solid $galleryGray;
        min-height: $height50 - 1.25rem;
        position: $p-relative;
        background-color: $white;

        p {
            margin: 0;
            color: $color-content;
            font-size: $fontSizeHeading;

            span {
                color: $primary;
            }
        }

        .sub-header-left {
            .ant-progress {
                .ant-progress-circle-path {
                    stroke: $primary;
                }

                .ant-progress-text {
                    font-weight: 600;
                    color: $primary;
                }

                & ~ p {
                    display: $d-inline-block;
                    margin-left: $margin10;
                    font-family: $inter-semibold;
                    color: $primary;
                    font-size: $font14;
                }
            }
        }

        .sub-header-right {
            display: flex;  //this will make the all right side close and edit button in secodary header will align in one line
            align-items: center;
        }

        .landingPage_navbar-nav-right {
            display: flex;
            align-items: center;
        }
    }

    .tag-popover-body {
        width: 16.25rem;

        & .paragraph {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 10.5rem;
        }
    }

    .ant-popover-inner {
        :is(.ant-popover-title, .ant-popover-inner-content) {
            padding: 0;
        }
    }

    .ant-popover {
        z-index: 2022 !important;
    }

    .ace_editor.ace_autocomplete {
        width: 37.5rem;
    }

    .ant-select-dropdown {
        z-index: 3000 !important;
    }

    .ant-tooltip {
        z-index: 3001 !important;
    }

    .ant-skeleton-element {
        display: flex !important;
    }

    .ant-message-custom-content {
        display: flex;
        align-items: center;
    }

    .custom-card {
        border-radius: 0.1875rem;
        box-shadow: 0.0625rem 0.125rem 0.25rem #00000040;
        padding: 0.625rem;
    }

    .timeline-no-found {
        svg path {
            fill: #a1a1a1;
        }
    }

    .hide-overflow-x {
        overflow-x: hidden !important;
    }

    .color-primary-dark {
        color: #275a89;
    }
    .color-primary {
        color: #2c83c4 !important;

        svg path {
            fill: #2c83c4 !important;
        }
    }

    .text-with-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }

    .ant-picker-dropdown {
        z-index: 3000 !important;
    }

    .rdg-filter-active:not(:disabled) {
        background-color: #b4dbf8 !important;
        border-color: #b4dbf8 !important;

        svg {
            path {
                fill: #2c83c4 !important;
            }
        }
    }

    .tags-acc-ls-wrap {
        max-height: 8.4375rem;
        height: 100%;
        overflow-y: auto;
        font-size: 0.75rem;

        &:is(.filter-li-itm-data-domain, .filter-li-itm-sources) {
            & > li .content-label {
                text-transform: uppercase !important;
            }
        }
    }

    .cursor-not-allowed {
        cursor: not-allowed !important;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }
    .cursor-arrow {
        cursor: default !important;
    }

    .connection-header-wrap {
        .old-custom-filter {
            top: 2.625rem !important;
            right: 1.875rem !important;
        }
    }

    .tab-menu {
        & > :is(p, .custom-tab-menu-wrapper) {
            margin: 0 $margin10 0 0;
            position: $p-relative;
            cursor: pointer;

            &:after {
                content: "";
                position: $p-absolute;
                bottom: -0.4375rem;
                left: 0;
                right: 0;
                height: $height10 - 0.5625rem;
                background-color: transparent;
            }

            &.active {
                color: $primary;

                svg {
                    path {
                        fill: $primary;
                    }
                }

                &::after {
                    background-color: $primary;
                }
            }
        }
    }

    .conn-tab-menu {
        display: flex;
        align-items: center;
        height: 1.78rem;

        & > .subtitle {
            display: flex;
            align-items: center;
            margin-right: 0.625rem;
        }

        :is(p, .custom-tab-menu-wrapper):after {
            bottom: -0.6875rem !important;
        }
    }

    .no-ob-conn {
        svg {
            height: 4rem;
            width: 4rem;
            opacity: 0.4;
        }
    }

    .input-readyonly {
        opacity: 1;
        // opacity: .6;
        border: none;
        background-color: #eaeaea !important;
        cursor: not-allowed;
        height: 1.75rem;
        padding: 0.625rem;
        border-radius: 0.1875rem;
        display: block;
        font-size: 0.8125rem;
        width: 100%;
    }
}

.new-drag-node-list {
    display: grid;
    grid-template-columns: 1.5rem auto;
    align-items: center;
    cursor: move;
}

.nd-list-content {
    display: flex;
    justify-content: space-between;
    gap: 0.625rem;
    align-items: center;
    border: solid 0.0625rem #e6e6e6;
    padding: 0rem 0.5rem;
    border-radius: 0.1875rem;
    height: 1.875rem;
}

.nd-list-content label {
    color: #17171a !important;
    line-height: 1.6;
    max-width: 12.5rem;
}

.nd-list-content > div {
    height: inherit;
}

.new-drag-node-list.new-drag-node-disable {
    opacity: 0.4;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.fix-ic-black {
    path {
        fill: #17171a;
    }

    &:hover {
        path {
            fill: #2c83c4;
        }
    }
}

.custom-badge {
    display: inline-flex;
    padding: 0.3125rem 0.5rem;
    border-radius: 0.1875rem;
    font-weight: 700;
    font-size: 0.625rem;
    background-color: #f1f1f1;
    color: #000;

    &.primary {
        background-color: #00bcd433;
        color: #00bcd4;
    }

    &.success {
        background-color: #4caf5033;
        color: #4caf50;
    }

    &.warning {
        background-color: #ffab0033;
        color: #ffab00;
    }
}

.custom-sql-editor-note {
    // background: #e8e8e8;
    padding: 0rem 0.5rem;

    code {
        background: white;
        color: #998;
        font-size: 0.8125rem;
        text-transform: uppercase;
    }
}

.white-gradient-overlay {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #ffffff 0%, transparent 74%);
        left: 0rem;
        z-index: 1;
    }
}

.inbound-no-data {
    & > svg {
        height: 5.625rem;
        width: 5.625rem;
        opacity: 0.3;
    }
}

.custom-notify {
    &.custom-notify-success {
        background-color: #51a351;
        color: #fff;
    }

    &.custom-notify-info {
        background-color: #2f96b4;
        color: #fff;
    }

    &.custom-notify-warning {
        background-color: #f89406;
        color: #fff;
    }

    &.custom-notify-error {
        background-color: #bd362f;
        color: #fff;
    }

    & .ant-notification-notice-message {
        color: #fff;
        text-transform: capitalize;
        font-weight: 700;
        opacity: 0.8;
    }

    border-radius: 0.1875rem;
}

.text-underline {
    text-decoration: underline;
}

.conn-desc-min-hght {
    min-height: 1.3125rem;
}

.row-margin-correction {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
}

.custom-select-dropdown {
    span.ant-select-selection-item-content .option-select-customized label {
        line-height: 1.3125rem !important;
    }

    .option-select-customized > i {
        display: none !important;
    }

    & > .ant-select-disabled {
        // opacity: .8 !important;
        & .ant-select-selector {
            cursor: auto !important;
        }

        & .ant-select-arrow {
            display: none;
        }

        & .ant-select-selection-item {
            color: #17171a !important;
            opacity: 1;
            padding: 0.0625rem 0.125rem 0.125rem 0.4375rem;
        }
    }
}

.option-select-customized {
    & > i {
        display: none;
        font-size: 1.125rem;
        color: #2c83c4;
        width: 1.25rem;
        height: 1.25rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.1875rem;
        cursor: pointer;
        transition: 0.4s;
    }

    &:hover i {
        &:hover {
            background: #0000000d;
        }

        opacity: 1;
        display: flex;
        transition: 0.4s;
    }
}

.selected-border {
    box-shadow: 0rem 0.0625rem 0.25rem #2c83c4 !important;
}

.filter-svg {
    path {
        fill: transparent !important;
    }
}

.fs-10 {
    font-size: 0.625rem !important;
}

:is(.custom-vlist-dgt, .custom-simple-landing-dgt) {
    & .rdg-cell-resizable::after {
        content: "";
        display: block;
        width: 0.75rem;
        height: 100%;
        background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 8L1.5 6L4 4V8ZM10.5 6L8 8V4L10.5 6ZM5.5 1.5H6.5V3H5.5V1.5ZM5.5 4H6.5V5.5H5.5V4ZM5.5 6.5H6.5V8H5.5V6.5ZM5.5 9H6.5V10.5H5.5V9Z' fill='%23828282'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        right: 0rem;
        z-index: 100;
    }
}

.businessterm-modal {
    & > div {
        height: 100%;

        & > .data-dictionary-wrapper {
            height: 100%;
        }

        .dict-main-wrap {
            height: 100% !important;
        }
    }
}

.custom-filter-li {
    display: flex;
    align-items: center;

    .content-label {
        max-width: 15rem;
        display: block;
    }
}

// input:disabled {
//     border: none !important;
//     outline: none !important;
//     background-color: transparent !important;
// }

.crt-pod-schm-wrap .custom-vlist-dgt div[role="columnheader"] {
    & > div {
        background-color: transparent !important;
    }
}

// .crt-pod-schm-wrap .custom-vlist-dgt div[role="columnheader"]::after {
//     content: '';
//     display: block;
//     width: 0.125rem;
//     height: 70%;
//     background: radial-gradient(#444444 -200%, transparent);
//     top: 14%;
//     right: 0.1875rem;
//     position: absolute;
// }

// .crt-pod-schm-wrap {
//     &.default-fit {
//         .custom-vlist-dgt  {
//             grid-template-columns: minmax(17.5rem, 15%) minmax(12.5rem, 10%) minmax(6.25rem, 10%) minmax(17.5rem, 10%) minmax(6.25rem, auto) auto !important;
//         }
//     }
// }

.crt-pod-schm-wrap .custom-vlist-dgt > .rdg-header-row {
    background-color: #ffffff !important;
}

.crt-pod-schm-wrap .rdg-cell {
    border-bottom: solid 0.0625rem #ececec !important;
    border-bottom-color: #ececec !important;
}

.crt-pod-schm-wrap
    :is(input[type="text"], input[type="date"], input[type="number"]):not(
        :is(.businesstermTag, .warning_businessTerm)
    ) {
    &:focus:not(:read-only) {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        transition: 0.3s;
    }

    &:read-only {
        outline: none !important;
        box-shadow: none !important;
    }

    border: none !important;
    background: transparent !important;
    padding-left: 0;
    padding-right: 0;
    font-size: 0.6875rem;
    transition: 0.3s;
}

.crt-pod-schm-wrap div[role="gridcell"].rdg-cell .paragraph {
    font-size: 0.6875rem !important;
}

.categoryTag-danger {
    background-color: #ff565e !important;
    color: white !important;
}

label.categoryTag {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 6.25rem;
    display: block !important;
    line-height: 1.125rem !important;
}

.categoryTag {
    background: rgba(76, 175, 80, 0.1);
    border: none;
    color: #4caf50;
    // background-color: #c4f0f5;
    // color: #707070;
    display: inline-flex !important;
    align-items: center;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.5625rem !important;
    font-family: "InterRegular";
    height: 1.5rem;
    width: auto;
    font-weight: 600;
    border-radius: 0.1875rem;
    position: relative;

    & span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        line-height: 1.4;
    }

    & :is(span, .text-with-ellipsis) {
        max-width: 6.25rem;
    }

    // & .custom-btn-category {
    //     &:hover {
    //     background: #0000001a;
    //     }
    //     font-size: 0.8125rem !important;
    //     position: relative;
    //     right: -0.75rem;
    //     background: #7a797917;
    //     border-radius: 0 1.5rem 1.5rem 0rem !important;
    //     padding: 0.625rem;
    //     width: 1.5rem !important;
    //     height: 1.625rem !important;
    // }
}

label.classificationTag {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 6.25rem;
    display: block !important;
    line-height: 1.125rem !important;
}

.classificationTag {
    background: rgba(122, 40, 255, 0.1);
    border: none;
    color: #7a28ff;
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.5625rem !important;
    font-family: "InterRegular";
    height: 1.5rem;
    width: auto;
    font-weight: 600;
    border-radius: 0.1875rem;
    position: relative;

    // span {
    //   color: $white;
    // }
    & span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        max-width: 6.25rem;
        line-height: 1.4;
    }
}

.businesstermTag {
    background-color: #fff;
    color: #707070;
    border: solid 0.0625rem #dedede;
    display: inline-flex;
    align-items: center;
    padding: 0.625rem;
    outline: none;
    border-radius: 0.0625rem;
    font-size: 0.5625rem !important;
    font-weight: 600;
    font-family: $inter-regular;
    height: 1.5rem;
    width: 100%;

    border-radius: 0.1875rem;

    &:read-only {
        font-size: 0.6875rem !important;
    }

    .text-elipse {
        white-space: nowrap;
        width: 6.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.warning_businessTerm {
    background-color: #fcf4b0 !important;
    color: #707070;

    border: none;
    display: inline-flex;
    align-items: center;
    padding: 0.625rem;
    font-weight: 600;
    outline: none;
    border-radius: 0.0625rem;
    font-size: 0.5625rem !important;
    font-family: $inter-regular;
    height: 1.5rem;
    width: 100%;

    border-radius: 0.1875rem;
    &:read-only {
        font-size: 0.6875rem !important;
    }
}

.sm-cr-hw {
    height: 1.5rem !important;
    width: 1.5rem !important;
}

:is(.custom-input-field, .custom-textarea-field, .form-control, .ant-input):is(:disabled, :read-only) {
    border: none !important;
    padding: 0.25rem 0 !important;
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    font-size: 0.75rem;
    background: transparent !important;
    height: auto !important;
    color: #000;
    opacity: 1;
    cursor: default;
}

.custom-textarea-field:disabled {
    display: inline-table !important;
}

.ant-input-number {
    font-size: 0.875rem;
}

.ant-input-number.ant-input-number-disabled .ant-input-number-input-wrap input {
    padding-left: 0.625rem;
    padding-right: 0;
}

.bg-theme-primary {
    background: #2c83c4;
}

.bg-theme-primary-dark {
    background: #155e94;
}

.create-pod-table-container {
    & .ant-radio-group {
        width: 100%;
    }
}

.custom-tree-badge .ant-badge-count {
    background-color: #2c83c4 !important;
    color: #fff !important;
    width: auto;
    font-size: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 0.375rem;
    font-family: "InterRegular" !important;
}

.create-pod-tree-schema .ant-badge {
    position: absolute;
    top: 0.125rem;
    right: 0.3125rem;
}

.custom-dgt-tree .custom-simple-landing-dgt .rdg-cell {
    border: none;
    width: 18.5rem;
}

.guide-editor {
    border: 0.0625rem solid #d7d7d7;
    border-radius: 0.1875rem;
    background: #f7f7f7;
}

.manual-creation-div-wrap {
    & .custom-vlist-dgt {
        overflow: scroll;
    }
}

.older-version {
    :is(.custom-btn):not(.ver-show),
    .new-sidebar,
    .sql-editor-toggle,
    .hamburger,
    .ver-hide,
    .summary-right-container {
        display: none !important;
    }

    .main-content {
        margin-left: 0 !important;
    }

    .new-audit-left-sec-wrap {
        width: 100% !important;
    }
}

.custom-timeline-div {
    & .no-dat-in-tmln {
        .no-mod-dat-found {
            & > div {
                height: auto !important;
            }
        }
    }

    & .no-dat-in-tmln > #timeline-list {
        overflow: hidden !important;
    }
}

.modal.fade {
    backdrop-filter: blur(0.3125rem);
}

.modal-content {
    border-radius: 1rem;
}

.dict-stats-grid {
    display: grid;
    grid-template-columns: 100%;
    // grid-template-columns: minmax(3.75rem, 12%) minmax(3.75rem, 12%) 38% 38%;
    gap: 0.625rem;
    align-items: center;
}

.cap-pile {
    &.cat {
        background: rgba(76, 175, 80, 0.1);
        border: 0.0625rem solid #4caf50;
        color: #4caf50;
        text-transform: uppercase;
    }

    &.cls {
        background: rgba(122, 40, 255, 0.1);
        border: 0.0625rem solid #7a28ff;
        color: #7a28ff;
        text-transform: uppercase;
    }

    padding: 0.25rem 0.625rem;
    border-radius: 1.25rem;
    font-size: 0.5625rem;
    font-weight: 600;
    line-height: 0.8125rem;
}

.sphere {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;

    &.tech-term {
        background-color: #00bcd4;
    }

    &.category {
        background-color: #4caf50;
    }

    &.classifier {
        background-color: #7a28ff;
    }
}

.data-dict-identity-pane {
    display: flex;
    font-size: $fontSizeHeading;

    & > div {
        display: flex;
        align-items: center;
        padding: 0 0.9375rem;
        height: 1.625rem;
        gap: 0.625rem;
    }

    & > div:not(:last-child) {
        border-right: solid 0.0625rem #dedede;
    }
}

.advanced-dictionary-table {
    height: 100%;

    // .custom-simple-landing-dgt {
    //     overflow-x: scroll !important;
    // }
}

.dict-filter-tabs {
    & > .ant-tabs-nav {
        margin-bottom: 0 !important;
    }

    & .ant-list-item {
        display: flex;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        border: none;
        text-transform: uppercase;
        font-size: 0.6875rem !important;
        font-family: "InterMedium" !important;
        justify-content: flex-start;
        color: #444 !important;
    }

    .ant-tabs-tab {
        padding: 0.3125rem 0.625rem !important;
    }

    .filter-tab-ic {
        svg {
            height: 1.125rem !important;
            width: 0.75rem !important;
        }
    }
}

.classifier-flt-li,
.category-flt-li,
.comm-flt-li {
    max-height: 11.875rem;
    overflow-y: auto;
}

.advanced-dictionary-tbl-wrap {
    .custom-simple-landing-dgt {
        overflow: auto;
    }
    .lan-sim-tbl {
        min-height: 6.25rem !important;
    }
}

.data-view-dbox {
    position: relative;

    & ._src_img {
        position: absolute;
        top: 0;
        left: 0;
    }

    & .desc-box {
        width: calc(100% - 2.875rem);
        margin-left: auto;

        & .title-txt {
            margin-bottom: 0.5rem !important;
        }

        & .desc-txt {
            min-height: 2.187rem;
            font-size: 0.75rem !important;
            color: #444 !important;
        }
    }

    & .dbox-opts {
        cursor: pointer;
        position: absolute;
        top: 0rem;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        color: #3f3f3f;
        border-radius: 0.1875rem;
        font-size: 1.25rem;
        transition: 0.3s;

        &:hover,
        &.active {
            color: #2c83c4;
            background: #efefef;
            transition: 0.3s;
        }
    }

    @media screen and (min-width: 118.75rem) {
        & .desc-box {
            width: calc(100% - 3.5rem);
        }
    }
}
.disable-execute {
    display: none !important;
}
.dbox-opt-itm {
    display: flex;
    align-items: center;

    &.danger {
        & svg path {
            fill: #dc3545 !important;
        }

        color: #dc3545 !important;
    }

    & svg {
        height: 0.9375rem;
        width: 0.9375rem;
        margin-right: 0.375rem;
    }

    &.disabled {
        opacity: 0.6;
    }
}

.new-box-style {
    & > div:not(:last-child) .card:not(.no-separator)::after {
        // vertical
        content: "";
        display: block;
        width: 0.125rem;
        height: 60%;
        background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
        position: absolute;
        top: 20%;
        right: -0.375rem;
    }
    & > div .card:not(.no-separator)::before {
        // horizontal
        content: "";
        display: block;
        width: 60%;
        height: 0.125rem;
        background: linear-gradient(45deg, transparent, #e3e3e3, transparent);
        position: absolute;
        bottom: -0.4375rem;
        left: 50%;
        transform: translateX(-50%);
    }
}

.custom-dgt-tree {
    .custom-search-input:not(:disabled) {
        background: #fff;
    }
}

#html-content {
    // position: relative;
    #overview.section-header > p.text-body-secondary.text-end {
        display: none;
    }

    #tab-pane-overview-reproduction tr:is(:nth-of-type(4), :nth-of-type(5)) {
        display: none;
    }

    footer {
        display: none;
    }

    overflow: auto;
    height: 100%;
    // height: calc(100vh - 7.875rem);
    background: #fff;

    // .row {
    //     display: block;
    // }

    // .container-fluid {
    //     display: block;
    // }

    // .nav {
    //     display: block;
    // }

    .navbar-fixed-top {
        z-index: 1;
        position: sticky;
        top: 0;
    }

    h1 {
        font-size: 1.75rem !important;
        font-weight: 700;
    }

    .row.header {
        padding-left: 0 !important;
    }

    select {
        padding: 0.25rem 0.375rem;
        border: solid 0.0625rem #dcdcdc;
        border-radius: 0.1875rem;
    }

    .section-items #variables-dropdown {
        margin-top: 0.625rem;
        position: relative;
        margin-bottom: 0.625rem;
    }

    .section-items .row.spacing {
        border-top: solid 0.0625rem #e1e1e8;
        padding: 0.9375rem 0.9375rem;
    }

    .variable .h4 {
        font-size: 0.9375rem;
    }

    .variable .h4 a {
        font-weight: 800;
        text-transform: uppercase;
    }

    .variable code {
        font-weight: 700;
    }

    // .tab-content > .active {
    //     margin: 0 -0.75rem;
    // }

    .navbar.navbar-expand-lg {
        z-index: 99 !important;
    }

    .page-header {
        margin-top: 1.1875rem;
        margin-bottom: 0.625rem;
    }

    .btn-default {
        background: #377eb8;
        border-radius: 0.1875rem;
        color: white;
        text-shadow: none;
    }

    .btn-default:hover {
        background: #1d5787;
    }

    .stats :is(th, td) {
        font-size: 0.8125rem;
    }

    .nav-pills > li.active > a,
    .nav-pills > li.active > a:focus,
    .nav-pills > li.active > a:hover {
        border-bottom: solid 0.125rem #337ab7;
        color: #337ab7;
        font-weight: 700;
        background: transparent;
    }

    .nav > li > a {
        padding: 0.375rem 0.375rem;
        border-radius: 0;
        margin-right: 0.625rem;
        color: #000;
        display: flex;
        align-items: center;
    }

    .tab-pane p.h4 {
        font-weight: 700;
    }

    span.badge {
        font-size: 0.5625rem;
        margin-left: 0.375rem;
    }

    .list-group-item.active > .badge,
    .nav-pills > .active > a > .badge {
        background: #337ab7;
        color: #fff;
    }

    div#navbar .nav {
        padding: 0.625rem 0;
    }

    .navbar-brand.anchor {
        color: #155e94;
        font-weight: 700;

        svg {
            margin-top: -0.3125rem;
        }
    }

    .col-sm-12 {
        svg.img-responsive.center-img {
            height: 12.5rem;
        }
    }
}

.data-view-dbox {
    .sources_circle {
        height: 2.1875rem;
        width: 2.1875rem;
    }
}

.col_stat_health {
    .custom-prog {
        height: 0.5rem;
    }

    .perc-txt {
        color: #343a40 !important;
        font-size: 0.6875rem !important;
        font-weight: 700;
        margin-left: 0.625rem;
        margin-bottom: 0;
    }

    & > div {
        display: inline-flex;
        width: 100%;
        justify-content: flex-start;

        & > div {
            width: calc(100% - 3.125rem) !important;
        }
    }
}

.col_stat_distinct {
    .ant-progress-inner {
        border-radius: 6.25rem !important;
    }
}

.noti-alert-prps-ic {
    &.quality {
        path {
            fill: $quality-alert;
        }
    }

    &.business {
        path {
            fill: $business-alert;
        }
    }
}

.alert-priority-icon {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    font-size: 0.625rem;
    font-weight: 700;
    color: #fff;
    background: #155e94;

    &.low {
        color: #444;
        background: #f7d356;
    }

    &.medium {
        background: #eca061;
    }

    &.high {
        background: #812b11;
    }
}

.COMMON_MONACO_CODEEDITOR {
    .mtk6 {
        color: #2c83c4;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.anchor-eff {
    &:hover {
        text-decoration: underline;
    }
}

.stats-manual-srt-btn {
    position: fixed;
    display: block;
    width: 100%;
    max-width: 6.25rem;
    height: 10%;
    top: 0rem;
    z-index: 5;
}

.react-flow__attribution {
    display: none !important;
}

.ant-popover-inner {
    padding: 0 !important;
}

.ant-popover-title {
    border-bottom: solid 0.0625rem #f1f1f1;
    margin-bottom: 0 !important;
}

.rdg-cell {
    outline: none !important;
}

button {
    outline: none !important;
}

.toast {
    font-size: 0.8125rem !important;
}

.prev-filter-comp span.ant-radio-button.ant-radio-button-checked + span > svg > rect {
    fill: #fff;
}

.dgt-filter-pop {
    .filter-section {
        width: 56.25rem;
    }
}
.prev-filter-comp {
    .ant-radio-group {
        height: 1.5rem;
        & > label:last-child {
            top: 0rem;
        }
    }
}

.catalogue-subheader {
    border-bottom: solid 0.0625rem #eaeaea;
}

.fontSizeLargeHeading {
    font-size: $fontSizeLargeHeading;

    // @media screen and (max-width: 1280px) {
    //     font-size: 0.875rem !important;
    // }
}

.fontSizeHeading {
    font-size: $fontSizeHeading;

    // @media screen and (max-width: 1280px) {
    //     font-size: 0.6875rem !important;
    // }
}

.fontSizeParagraph {
    font-size: $fontSizeParagraph;

    // @media screen and (max-width: 1280px) {
    //     font-size: 0.625rem !important;
    // }
}

.fontSizeLabel {
    font-size: $fontSizeLabel;

    // @media screen and (max-width: 1280px) {
    //     font-size: 0.625rem !important;
    // }
}

.fontSizeExtraSmall {
    font-size: $fontSizeExtraSmall;
}

:is(
        .ant-tabs-tab-btn,
        .ant-list-item,
        .ant-input,
        .ant-popover,
        .ant-btn,
        .ant-progress,
        .ant-select-selection-item,
        .ant-drawer-content-wrapper,
        .ant-dropdown-menu-title-content,
        .ant-select-selection-search-input,
        .custom-ellipsis,
        .ant-select-selection-placeholder
    ) {
    font-family: "InterRegular" !important;
}

.dashboard-index-page {
    .left-flow-header {
        width: 75% !important;
        .ant-tabs-nav .ant-tabs-nav-wrap::after {
            box-shadow: none !important;
        }
        .ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
            margin: 0.625rem !important;
        }
        .ant-tabs {
            width: 50% !important;
            box-sizing: border-box;
            margin: 0;
            padding-left: 0.8125rem;
            color: rgba(0, 0, 0, 0.88);
            font-size: 0.75rem;
            line-height: 1.571429;
            list-style: none;
            font-family: "InterReguler";
            display: flex;

            .ant-tabs-tab + .ant-tabs-tab {
                margin: 0 0 0 1.875rem !important;
            }
            .ant-tabs-nav {
                margin: 0rem;
            }
            .ant-tabs-tab {
                font-size: 0.6875rem;
                color: "#828282";
                font-weight: 400 !important;
            }

            .ant-tabs-tab-active {
                svg > path {
                    fill: #2c83c4 !important;
                    stroke: #2c83c4;
                }
            }
            // .ant-tabs-tab-active:active {
            //     font-size: 0.6875rem;
            //     background-color: #2c83c4;
            //     color: white;
            //     border-radius: 0.1875rem;
            //     // padding: 0.25rem 0.5rem;
            //     height: 1.5rem;
            //     padding: 0.25rem 0.3125rem 0.3125rem 0.3125rem;
            // }

            .ant-tabs-nav-more {
                transform: rotate(90deg);
                margin-left: 0.9375rem;
            }
            .ant-tabs-nav-operations {
                width: 1.25rem;
            }
            .ant-tabs-nav-more:hover {
                border-radius: 50%;
                height: 1.875rem;
                width: 1.875rem;
                margin-top: 0.3125rem;
                display: flex;
                background-color: #e1ecf5;
                justify-content: center;
                align-items: center;
                margin-left: 1.25rem;
            }
        }
    }
    .flow-sub-header {
        padding: 0rem 0.375rem 0rem 0rem;
    }
}

.homepage-left-flow-header {
    width: 75% !important;
    .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: none !important;
    }
    .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: none !important;
    }
    .ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
        margin: 0.625rem !important;
    }
    .ant-tabs {
        width: 50% !important;
        box-sizing: border-box;
        margin: 0;
        padding-left: 0.8125rem;
        color: rgba(0, 0, 0, 0.88);
        font-size: 0.75rem;
        line-height: 1.571429;
        list-style: none;
        font-family: "InterReguler";
        display: flex;

        .ant-tabs-tab + .ant-tabs-tab {
            margin: 0 0 0 1.875rem !important;
        }
        .ant-tabs-nav {
            margin: 0rem;
        }
        .ant-tabs-tab {
            font-size: 0.6875rem;
            color: "#828282";
            font-weight: 400 !important;
        }

        .ant-tabs-tab-active {
            svg > path {
                fill: #2c83c4 !important;
                stroke: #2c83c4;
            }
        }
        // .ant-tabs-tab-active:active {
        //     font-size: 0.6875rem;
        //     background-color: #2c83c4;
        //     color: white;
        //     border-radius: 0.1875rem;
        //     // padding: 0.25rem 0.5rem;
        //     height: 1.5rem;
        //     padding: 0.25rem 0.3125rem 0.3125rem 0.3125rem;
        // }

        .ant-tabs-nav-more {
            transform: rotate(90deg);
            margin-left: 0.9375rem;
        }
        .ant-tabs-nav-operations {
            width: 1.25rem;
        }
        .ant-tabs-nav-more:hover {
            border-radius: 50%;
            height: 1.875rem;
            width: 1.875rem;
            margin-top: 0.3125rem;
            display: flex;
            background-color: #e1ecf5;
            justify-content: center;
            align-items: center;
            margin-left: 1.25rem;
        }
    }
}
// .flow-sub-header {
//     padding: 0rem 0.375rem 0rem 0rem;
// }

.comm-subheader {
    min-height: 2rem;
}

// .category-select-modal {
//     .modal-body {
//         .custom-simple-landing-dgt {
//             grid-template-columns: 150.0313rem 210.3766rem 170.4234rem 170.4234rem !important;
//         }
//     }
// }

.ant-picker-dropdown {
    font-size: 0.6875rem !important;
    font-family: $inter-regular !important;
}

.fontInterSemiBold {
    font-family: "InterSemibold" !important;
}

.fontInterMedium {
    font-family: "InterMedium" !important;
}

.underline-button {
    display: inline-flex;
    align-items: center;
    gap: 0.125rem;
    font-size: 0.8125rem;
    color: #2c83c4;
    border-bottom: solid 0.0625rem #2c83c4;
    cursor: pointer;
    svg {
        height: 0.75rem;
        width: 0.75rem;
    }
    svg path {
        fill: #2c83c4;
    }
    &:hover {
        opacity: 0.8;
    }
    &.only-text {
        border: none;
        span {
            border-bottom: solid 0.0625rem #2c83c4;
        }
    }

    &.white {
        color: #fff;
        border-bottom: solid 0.0625rem #fff;
        svg path {
            fill: #fff;
        }
        &.only-text {
            border: none;
            span {
                border-bottom: solid 0.0625rem #fff;
            }
        }
    }
}

.new-custom-tabs {
    .ant-tabs-tab-btn {
        color: #2c83c4 !important;
        font-size: $fontSizeHeading !important;
        font-family: "InterSemibold" !important;
    }
    .ant-tabs-tab {
        padding: 0.5rem 0 !important;
    }
    .ant-tabs-ink-bar {
        background: #2c83c4 !important;
    }
}

.ant-tree {
    border-radius: 0 !important;
}

.custom-btn-outline.active {
    svg path {
        fill: #2c83c4;
    }
    color: #2c83c4;
    background: rgba(0, 1, 1, 0.0588235294);
    border: solid 0.0625rem #2c83c4 !important;
}
.notice-highlighted-text {
    background: #ffdf7f70;
    padding: 0.25rem 0.5rem;
    border-radius: 0.1875rem;
    font-size: 0.6875rem;
}
.add-circle-btn {
    display: inline-flex;
    height: 1.375rem;
    width: 1.375rem;
    justify-content: center;
    align-items: center;
    background: #d5d5d510;
    border: solid 0.0625rem #5c5c5c;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background: #addcff;
        border-color: #155e94;
        transition: 0.3s;
        & svg path {
            fill: #155e94;
        }
    }

    & svg {
        height: 0.625rem;
        width: 0.625rem;
    }
    & svg path {
        fill: #5c5c5c;
    }
}

.custom-switch-btn {
    &.ant-switch:not(.ant-switch-small) {
        .ant-switch-inner .ant-switch-inner-unchecked {
            margin-top: -20px;
        }
    }
    &.ant-switch {
        background: #fff !important;
        outline: solid 0.0625rem #959595 !important;
        .ant-switch-handle::before {
            background-color: #959595;
        }
        .ant-switch-inner {
            span {
                color: #959595;
                font-family: "InterSemibold" !important;
            }
        }
        &.ant-switch-checked {
            outline: solid 0.0625rem #275a89 !important;

            .ant-switch-handle::before {
                background-color: #275a89;
            }

            .ant-switch-inner {
                span {
                    color: #275a89;
                }
            }
        }
    }
    &.danger {
        &.ant-switch-checked {
            outline: solid 0.0625rem #991210 !important;

            .ant-switch-handle::before {
                background-color: #991210;
            }

            .ant-switch-inner {
                span {
                    color: #991210;
                }
            }
        }
    }
}

.w-90 {
    width: 90% !important;
}

.vertical-separator {
    display: block;
    width: 0.0625rem;
    height: auto;
    background: #dee2e6;
    margin: 0 0.625rem;
}

.Toastify__toast-body {
    font-family: "InterSemibold" !important;
}
.flows-section {
    .ant-tooltip-disabled-compatible-wrapper {
        display: flex !important;
    }
}

.bold-h-line {
    display: flex;
    height: 0.125rem;
    background: #275a89;
    width: 2.5rem;
}

.tile-meta-info {
    display: grid;
    grid-template-columns: 1.25rem 5.25rem auto;
    row-gap: 0.3125rem;
    & > div .paragraph {
        font-size: 0.625rem;
    }
}

.ant-tag {
    font-family: "InterMedium";
    box-sizing: border-box;
    margin: 0;
    padding: 0.125rem;

    font-size: 0.625rem !important;
    line-height: 2 !important;
    list-style: none;

    display: inline-block;
    height: auto;
    margin-inline-end: 0.5rem;
    padding-inline: 0.4375rem;
    white-space: nowrap;

    border: 0.0625rem solid #d9d9d9;
    border-radius: 0.625rem !important;
    opacity: 1;
    transition: all 0.2s;
    text-align: start;
    position: relative;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #1d1d1e;
    background-color: rgba(0, 0, 0, 0.06);
}

.ant-tag-checkable:not(.ant-tag-checkable-checked) {
    color: #1d1d1e;
    background-color: rgba(0, 0, 0, 0.06);
}
.ant-tag-checkable-checked {
    background-color: $primary;
}

.btn-large {
    width: 1.75rem !important;
    height: 1.75rem !important;

    svg {
        height: 1.25rem !important;
        width: 1.25rem !important;
    }
    &.btn-with-text {
        width: auto !important;
        font-size: 0.875rem !important;
        svg {
            height: 1.125rem !important;
            width: 1.125rem !important;
            margin-right: 0.375rem;
        }
    }
}

.tech-t-dict {
    position: absolute;
    line-height: 1.4;
    bottom: 0;
    left: 0.1875rem;
}

.dict-tbl-filter-btn.disabled {
    cursor: not-allowed;
    &:hover {
        background: transparent !important;
    }
}

.dict-tbl-filter-btn.disabled svg path {
    fill: #909090 !important;
}

.custom-tag {
    display: flex;
}

.custom-tag .ant-badge-count {
    padding: 0rem 0.1875rem;
    background-color: #2c83c4 !important;
    font-size: 0.6875rem;
    top: 0 !important;
}

.common-seperator {
    display: block;
    width: 60%;
    height: 0.125rem;
    background: radial-gradient(#cecece, transparent);
    margin: 0 auto;
}
.publish-tag-pod {
    box-sizing: border-box;
    margin: 0;
    padding: 0.125rem;
    color: #ffff;

    font-size: 0.625rem !important;
    line-height: 1.0625rem !important;
    list-style: none;

    display: inline-block;
    height: auto;
    margin-inline-end: 0.5rem;
    padding-inline: 0.4375rem;
    white-space: nowrap;
    background-color: #2c83c4;
    border: 0.0625rem solid #d9d9d9;
    border-radius: 0.625rem !important;
    opacity: 1;
    transition: all 0.2s;
    text-align: start;
    position: relative;
}
.manual-creation-div-wrap {
    .custom-vlist-dgt .rdg-cell {
        background: #fff !important;
    }
}

.new-hide-field-search {
    & > input {
        height: 2rem;
        background: #efefef;
        border-color: #efefef !important;
        border-radius: 0.375rem;
    }
}

.new-hide-fields-popover-wrap {
    width: 16.125rem;
}
.hide-field-toggle-btn {
    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}

.new-hide-show-hide-btn-prev {
    cursor: pointer;
    // background: transparent;
    // border-radius: 0.1875rem;
    color: #333333;
    transition: 0.3s;

    &:hover {
        // background: #ececec;
        color: #2c83c4;
        transition: 0.3s;
    }

    &.disabled {
        opacity: 0.8;
        cursor: not-allowed;
    }
}

.new-hidefield-dgt-hide-cols-item {
    svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
    }
}

.new-hide-field-control-btn {
    cursor: pointer;
    color: #333333;
    transition: 0.3s;

    &:hover {
        color: #2c83c4;
        transition: 0.3s;
    }
}
.round-tags-container.nav-landing {
    & > .round-tags-item {
        padding: 0.125rem 0.875rem;
        height: auto;
        font-family: "InterSemibold";
        display: flex;
        gap: 0.4375rem;
        align-items: center;
        // box-shadow: 0.125rem 0.125rem 0.5rem -0.25rem #00000073;
        border: solid 0.125rem #0000000f;
        text-transform: uppercase;
        svg {
            height: 0.9375rem;
            width: 0.9375rem;
            top: 0;
        }
    }
}

.ant-tabs-dropdown {
    z-index: 2024 !important;
}

.catalog-grid-collapse {
    .ant-collapse-header {
        align-items: center !important;
        width: fit-content !important;
    }
    .ant-collapse-expand-icon {
        position: relative;
        top: -0.125rem;
    }
}

.dot-flashing {
    position: relative;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.3125rem;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-flashing::before {
    left: -0.9375rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.3125rem;
    background-color: #000;
    color: #000;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}
.dot-flashing::after {
    left: 0.9375rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.3125rem;
    background-color: #000;
    color: #000;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
        background-color: #000;
    }
    50%,
    100% {
        background-color: rgba(152, 128, 255, 0.2);
    }
}

.float-talkdata-present {
    .fixed-button {
        right: 1.25rem !important;
        bottom: 5.25rem !important;
    }

    .add-options-list.active-list {
        bottom: 9.25rem !important;
        right: 1.375rem !important;
        // z-index: 15;
    }
}

.outbound-conn-wrap {
    .custom-simple-landing-dgt {
        grid-template-columns: 15.375rem auto 9.375rem 9.375rem 15rem 11.25rem !important;
    }
}

.merge-row-dragger {
    margin-left: 0.625rem;
}

.merge-direction-ic {
    display: flex;
    margin-left: 0.625rem;

    svg {
        height: 1.25rem;
    }
}

.dark-detail-tab {
    .ant-tabs-nav-list .ant-tabs-tab-btn svg {
        height: 1rem;
        width: 0.875rem;
        margin-right: 0.3125rem;
    }
    .ant-tabs-tab {
        padding: 0rem 0rem 0.625rem 0rem !important;
        .ant-tabs-tab-btn {
            font-family: "InterSemiBold" !important;
            color: #333333cf !important;
            font-size: 0.6875rem !important;
        }
        svg path {
            fill: #333333cf !important;
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: #0a3053 !important;
        }
        svg path {
            fill: #0a3053 !important;
        }
    }
    .ant-tabs-ink-bar {
        height: 0.1875rem !important;
        background: #2c83c4 !important;
    }
    &.dict-settings {
        .ant-tabs-content {
            & > div {
                height: calc(100vh - 5.5rem);
                overflow-y: auto;
            }
        }
        .ant-tabs-nav {
            margin-bottom: 0;
        }
        .ant-tabs-tab {
            padding: 0rem 0rem 0.625rem 0rem;
            .ant-tabs-tab-btn {
                font-family: "InterSemiBold" !important;
                color: #333333 !important;
                font-size: 0.6875rem !important;
            }
            svg path {
                fill: #333333 !important;
            }
        }
    }
}

.custom-ddl-item {
    font-size: 0.6875rem;
    font-family: "Inter";
    padding: 0.3125rem 0.625rem;
    cursor: pointer;

    &:hover,
    &.active {
        background: #f0f0f0;
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background: transparent;
    }
}

.custom-ddl-bottom {
    .custom-search-input {
        border: none;
    }
    .custom-ddl-search-wrap {
        border-bottom: solid 0.0625rem #f0f0f0;
    }
}

.custom-ddl-item-wrap {
    max-height: 10.25rem;
    overflow-y: auto;
}

.w-fit-content {
    width: fit-content !important;
    width: -moz-fit-content !important;
}

.pod-det-domain-lnk {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.flow-lower-section .usage-listing .lan-sim-tbl {
    .custom-simple-landing-dgt {
        grid-template-columns: repeat(5, 1fr) !important;
        overflow-x: hidden;
    }
}

.agg-grp-key-tbl-inp {
    .custom-select-dropdown {
        position: relative;
        bottom: -0.125rem;
    }
}

.custom-anchor {
    color: inherit !important;
    text-decoration: unset !important;
}

.ant-select-selection-search {
    inset-inline-start: 6px !important; // cursor will blink at 1st position of select box
}

.contains-pii,
.non-pii-icon {
    svg {
        height: 0.875rem;
        width: 0.875rem;
    }
}

.upload-doc-pod-det {
    svg {
        height: 1.875rem;
        width: 1.875rem;
    }
}

.ant-badge .ant-badge-count {
    min-width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
}

.reloadble-err-comp {
    svg {
        height: 3.75rem;
        width: 3.75rem;
    }
}

.ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner {
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem;
}

.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    width: 0.5rem;
    height: 0.5rem;
}

.ant-tree .ant-tree-checkbox:not(.ant-tree-checkbox-indeterminate) .ant-tree-checkbox-inner:after {
    width: 0.4rem !important;
    height: 0.6rem !important;
}

.ant-tree .ant-tree-switcher .ant-tree-switcher-icon svg {
    height: 0.625rem;
    width: 0.625rem;
}

.ant-table-wrapper .ant-table-column-sorter-down,
.ant-table-wrapper .ant-table-column-sorter-up {
    font-size: 0.75rem;
}

.ant-picker {
    font-size: 0.875rem;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0.75rem 1rem;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
    height: 1.375rem;
    padding-inline-end: 0.75rem;
}

.ant-collapse {
    font-size: 0.875rem;
}

.ant-select-single .ant-select-selector {
    font-size: 0.875rem;
    height: 2rem;
}

.ant-select .ant-select-arrow {
    inset-inline-end: 0.6875rem;
    height: 0.75rem;
    margin-top: -0.375rem;
    font-size: 0.75rem;
}

.ant-select-multiple {
    .ant-select-selection-item {
        height: 1.5rem;
        margin-top: 0.125rem;
        margin-bottom: 0.125rem;
        line-height: 1.375rem;
        border-radius: 0.25rem;
        margin-inline-end: 0.25rem;
        padding-inline-start: 0.5rem;
        padding-inline-end: 0.25rem;
    }
    .ant-select-selection-item-remove {
        font-size: 0.625rem;
    }
}

.sel-cls-modal {
    svg {
        height: 1.25rem;
        width: 1.25rem;
    }
}

.ant-select-single {
    font-size: 0.875rem;
    height: 2rem;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 1.875rem;
}

.ant-radio-wrapper .ant-radio {
    font-size: 0.875rem;

    .ant-radio-inner {
        height: 1rem;
        width: 1rem;

        &::after {
            width: 1rem;
            height: 1rem;
            margin-block-start: -0.5rem;
            margin-inline-start: -0.5rem;
        }
    }
}

.ant-radio-wrapper span.ant-radio + * {
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
}

.ant-badge .ant-scroll-number .ant-scroll-number-only {
    height: 1.25rem;
}

.ant-select-dropdown .ant-select-item {
    padding: 0.3125rem 0.75rem;
}

.ant-popover .ant-popover-title {
    min-width: 80px;
}

.ant-empty-normal .ant-empty-image {
    height: 2.5rem;
}

.ant-empty {
    margin-inline: 0.5rem;
    font-size: 0.875rem;
}

.ant-drawer .ant-drawer-body {
    padding: 1.5rem;
    font-size: 0.875rem;
}

.ant-drawer .ant-drawer-header {
    padding: 1rem 1.5rem;
    font-size: 1rem;
}

.ant-list .ant-list-item {
    padding: 0.75rem 0;
    font-size: 0.875rem;

    .ant-list-item-meta .ant-list-item-meta-title {
        font-size: 0.875rem;
    }

    .ant-list-item-meta .ant-list-item-meta-avatar {
        margin-inline-end: 1rem;
    }

    .ant-list-item-action > li {
        font-size: 0.875rem;
    }
}

.custom-multi-select {
    .ant-select-selector {
        align-items: flex-start;
        padding-top: 0.09rem;

        .ant-select-selection-overflow {
            height: 100%;
        }
    }
}

.ant-typography-h2,
.ant-typography-h2 > textarea,
.ant-typography h2 {
    font-size: 1.875rem;
}
.ant-typography-h4,
.ant-typography-h4 > textarea,
.ant-typography h4 {
    font-size: 1.25rem;
}
.ant-typography {
    font-size: 0.875rem;
}
.domain-indicator-strip {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.65rem;
    box-shadow: 1px 5px 6px 0px hsl(0deg 0% 0% / 30%);
    padding: 0.1rem 0.9rem 0.16rem 0.9rem;
    background: #fff;
    color: #275a89;
    top: 0;
    border-radius: 0 0 0.9rem 0.9rem;
    font-family: "InterSemibold";
    text-transform: uppercase;
    min-width: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.15rem;
    transition: 0.6s;

    &.hide {
        top: -100px;
        transition: 0.6s;
    }

    .expand-arrow {
        position: relative;
        top: -1px;
        svg {
            height: 0.6rem;
            width: 0.6rem;
            transform: rotate(90deg);
            transition: 0.3s;
        }
    }

    &.expand {
        .expand-arrow svg {
            transform: rotate(-90deg);
            transition: 0.3s;
        }
    }

    @media screen and (max-width: 460px) {
        min-width: auto;
    }

    &.dark {
        background: #275a89;
        color: #fff;
    }
}

.domain-strip-pop {
    width: 34rem;
}

.domain-strip-list-wrap {
    max-height: 14rem;
    overflow-y: auto;
    overflow-x: hidden;

    .domain-strip-dm-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 1rem;

        & > div {
            cursor: pointer;
            padding: 0.5rem 0.8rem;
            border-radius: 0.2rem;

            &:hover,
            &.active {
                background: #eff6ff;
            }
        }
    }
}

.text-grey-color {
    color: #868e96;
}

.custom-selection-badge {
    display: flex;
    align-items: center;
    padding: 0.0625rem 0.3125rem;
    background: #2c83c4;
    color: #fff;
    font-size: 0.6rem;
    border-radius: 20px;
    justify-content: center;
    height: 0.9rem;
    min-width: 0.9rem;
}

.common-tile-gridview {
    display: grid;
    grid-gap: 0.9375rem 0.625rem;
    grid-template-columns: repeat(auto-fill, minmax(19.5rem, 1fr));
    margin: 0;
    padding: 0.625rem;
    position: relative;
    > div span.paragraph span {
        font-size: 0.625rem;
    }
    > div span.paragraph span {
        font-size: 0.625rem;
    }

    .tile-meta-info {
        svg {
            height: 0.875rem;
            width: 0.875rem;
        }
    }

    .card {
        min-height: 8.3125rem;
        border-radius: 3px;
        cursor: pointer;
        transition: box-shadow 0.2s ease-in-out;
        &:hover {
            box-shadow:
                0 1px 2px -2px rgba(0, 0, 0, 0.16),
                0 3px 6px 0 rgba(0, 0, 0, 0.12),
                0 5px 12px 4px rgba(0, 0, 0, 0.09);
        }
    }
}

.md-citation {
    border: solid 1px #2c83c438;
    display: inline-flex;
    padding: 0.5rem 0.25rem;
    background: #2c83c438;
    border-radius: 0.2rem;
    height: 1rem;
    min-width: auto;
    vertical-align: baseline;
    top: -0.1rem !important;
    margin-inline: 0.2rem;

    & > a {
        text-decoration: none !important;
    }
}

.md-citation-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    line-height: 1.5em;
}

.custom-editor-word-highlight {
    background: #275a89;
    color: #fff !important;
    padding-inline: .2rem;
}