.pulse-landing-wrap {
    height: calc(100vh - 3rem);
    padding: 1.125rem 1.875rem;
    overflow-y: auto;
    position: relative;
}
.pulse-legend {
    h2 {
        font-size: 1.375rem;
        color: #333333;
    }
    p {
        color: #333333;
    }
}
.pulse-hero-sec {
    display: grid;
    grid-template-columns: 68% auto;
    // grid-template-columns: 54rem auto;
    grid-column-gap: 0.9375rem;
}
.pulse-land-tabs {

    .ant-tabs-nav {
        margin-bottom: 0;
    }

    .custom-search-icon {
        left: auto !important;
        right: 0.5rem;
    }
    
    .custom-search {
        position: relative;
    }
    
    .custom-search-input {
        height: 1.875rem;
        border-radius: 0.375rem;
        padding: 0.3125rem 2.8125rem 0.3125rem 0.75rem !important;
    }
    
    .custom-close-icon {
        right: 1.875rem !important;
    }

    .ant-tabs-tab-btn svg {
        height: 1.125rem !important;
        width: 1.125rem !important;
    }
}

.pulse-input-opt-wrap {
    display: grid;
    grid-template-columns: minmax(8rem, max-content) auto;
    // gap: 1rem;
    padding: .5rem .8rem;
    background: #fff;
    border-radius: 0.875rem;

    .pulse-search-container {
        height: 100%;
    }

    .pulse-search-comp {
        position: relative;
        border: none;
        align-items: start;
        height: 100%;
        top: 0;
        padding: 0rem 0rem;

        form {
            height: 100%;
        }

        .pulse-act-btn {
            position: relative;
            // top: .25rem;
        }
    }

    .pulse-search-comp.show-pulse-exp-eff {
        box-shadow: none !important;
    }

    .pulse-search-comp :is(.pls-input, .ant-input).pulse-autogrow-inp {
        min-height: 100%;
        height: 5.3125rem;
        // height: 4.3125rem;
    }
}

.pod-doc-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    // gap: .25rem;
    // border-right: solid 1px #dcdcdc;
    & > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: .6rem .8rem;
        border-right: solid 3px #dcdcdca1;
        
        &.active {
            background: #EFF6FF;
            border-color: #2C83C4;
            font-family: 'InterMedium';
        }
    }
}

.pulse-input-outerwrap {
    background: #F1F3F5;
    background: linear-gradient(90deg, rgb(239, 142, 250) 0%, rgb(231, 251, 255) 100%), rgba(241, 243, 245, 0.8);
    // background: linear-gradient(90deg, rgba(251, 218, 255, 1) 0%, rgba(231, 251, 255, 1) 100%), rgba(241, 243, 245, 0.8);
    background-blend-mode: color-burn;
    padding: .8rem;
    border-radius: 1.5rem;
}

.pulse-input-foot {
    padding: 0.7rem 0.5rem 0rem 0.5rem;
}

.source-pop-wrap {
    width: 30rem;
    padding: 0;

    .source-pop-head {
        padding-inline: .8rem;
    }
}

.source-pop-head {
    border-bottom: solid 1px #d9d9d9;
}

.source-pop-body {
    max-height: 18.75rem;
    overflow-y: auto;
    overflow-x: hidden;

    .source-content-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        row-gap: .4rem;
        padding-inline: .4rem;

        & > div {
            cursor: pointer;
            padding: .5rem .5rem;

            &:hover {
                background: #F1F3F5;
            }
            &.active {
                background: #EFF6FF !important;
            }
        }
    }
}

.new-pulse-content-wrap {
    display: grid;
    grid-template-columns: minmax(50%, 68%) minmax(15rem, 22rem);
    justify-content: space-between;
    height: 100%;
}

.top-quest-wrap {
    height: 100%;
    max-height: calc(100vh - 22rem);
    background-color: #FAFAFA;
    border-radius: 0.75rem;
    border: solid 1px #EAEDEF;
    padding: .5rem;
    margin-left: 1rem;

    .top-quest-head {
        padding: .5rem .8rem;
        border-bottom: solid 1px #CFD4DA;
    }

    .top-quest-body {
        display: flex;
        flex-direction: column;
        gap: 0rem;
        padding: .6rem .3rem;
        height: calc(100% - 3rem);
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar-thumb {
            background-color: #ffffff00;
        }
        &:hover::-webkit-scrollbar-thumb {
            background-color: #757272;
        }

        & > div {
            cursor: pointer;
            transition: .2s;
            padding: 0.5rem .5rem;
        }
        & > div:hover {
            background: #dddddd80;
            border-radius: .5rem;
            transition: .2s;
        }
    }
}

.new-pulse-lower-wrap {
    height: calc(100% - 15.5rem);
    position: relative;
    padding-top: 2rem;
}

.source-preview-btn {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
}

.source-tile-wrap {
    .source-preview-btn {
        transform: scale(0);
        transition: .3s;
    }

    &:hover {
        .source-preview-btn {
            transform: scale(1);
            transition: .3s;
        }
    }
}

.pulse-quick-preview-wrap {
    .data-grid-shrink {
        height: calc(100% - 2rem) !important;
    }
}

.new-pulse-source-file-viewer {
    display: grid;
    grid-template-columns: 12rem auto;
}

.file-prev-itm {
    cursor: pointer;
    &:hover:not(.active, .disabled) {
        background: #fafafa;
    }
    &.active {
        cursor: default;
        background: #BFDBFE;
    }
    &.disabled {
        cursor: not-allowed !important;
        opacity: 0.6 !important;
    }
}

.new-pulse-contcard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;

    & > div {
        cursor: pointer;
        padding: 1.5rem 1.5rem 2.5rem 1.5rem;
        background: #fff;
        border-radius: .5rem;
        box-shadow: 0px 0px 2px 0px #89898980;
        transition: .3s;
        
        &:hover {
            box-shadow: 0px 2px 8px 0px #89898980;
            transition: .3s;
        }
    }
}

.pulse-specific-page-heading {
    span {
        display: flex;
        align-items: center;
    }
    svg {
        height: 1.2rem;
        width: 1.2rem;
        margin-right: .5rem;
    }
}