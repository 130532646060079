.custom-content-dgt {
    & > .rdg-header-row {
        display: none;
    }
    & > .rdg-row {
        background-color: transparent !important;
    }
    & > .rdg-header-row > .rdg-cell, 
    & > .rdg-row > .rdg-cell {
        line-height: 0;
        box-shadow: none;
        background: #FFF !important;
        border: none;
        padding: 0.625rem;
        overflow: auto;
    }
}