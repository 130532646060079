.autogrow-input-field {
    cursor: text;
    display: block;
    width: 100%;
    height: 28px;
    min-height: 28px;
    max-height: 94px;
    font-size: 16px !important;
    background: #fff;
    overflow-y: hidden;
    outline: none !important;
    padding: 4px 11px !important;
    border-radius: 6px;
    line-height: 1.3;
    word-break: break-all;

    &:empty:before {
        content: attr(data-placeholder);
        color: #b7b7b7;
    }

    &.focus {
        transition: 0.3s;
        height: auto;
        overflow-y: auto;
    }

    &.disabled {
        cursor: not-allowed;
        box-shadow: none !important;
        height: 28px !important;
        overflow: hidden !important;
    }
}