.custom-prog {
    display: inline-flex;
    width: 100%;
    height: 13px;
    background: #d9d9d9;
    // background: #e7e7e7;
    border-radius: 100px;
    overflow: hidden;
    cursor: pointer;
    transition: .6s;
    & > span {
        display: inline-block;
        height: 100%;
        transition: .6s;
    }
    & > span:first-child {
        background: #2c83c4;
    }
    & > span:nth-child(2) {
        background: #F9BE48;
    }
    & > span:last-child {
        border-radius: 0 30px 30px 0;
    }
    & > span:hover {
        box-shadow: inset 0px 0px 5px -1px #00000085;
        transition: .4s;
    }
}