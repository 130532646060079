@import "../../../assets/scss/custom/common/variables";
@import "../../../assets/scss/custom/common/fonts";
@import "../../../assets/scss/custom/common/colors";
@import "../../../assets/scss/common/colors";




.user-mngm-landingPage {
  .landing_nav-item_active {
    & svg path {
      fill: #2c83c4;
    }
  }

  .sub-header {
    padding: 0.1875rem $padding10;
    height: 2.70rem;

    .landingPage_navbar-nav {
      display: flex;
      justify-content: space-between;

      li {
        margin: 0 1.25rem 0 0;
        position: relative;
        cursor: pointer;

        svg {
          margin-right: $margin5;
        }

        &::after {
          content: "";
          position: $p-absolute;
          bottom: -0.7625rem;
          left: 0;
          right: 0;
          height: 1px;
          background-color: transparent;
        }

        &.landing_nav-item_active {
          color: $primary;

          &::after {
            background-color: $primary;
          }
        }
      }
    }
  }

  .sub-header-left {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .sub-header-right {
    min-height: 1.81rem;
  }
}

.data-domain-gridview {


  display: grid;
  gap: $padding10;
  grid-template-columns: repeat(auto-fill, minmax(18.125rem, 1fr));
  margin: 0;
  padding: $padding10;
  position: $p-relative;



  // &.usr-manage-usr-grp {
  //   height: calc(100vh - 370px);
  //   overflow-y: scroll;
  // }

  // &.usr-manage-user {
  //   max-height: calc(100% - 8px);
  //   overflow-y: scroll;
  // }

  // &.usr-manage-usr-home {
  //   height: 95%;
  //   overflow-y: scroll;
  // }

  .card-tags {
    bottom: auto;
    top: 2.5rem;
    right: 1.875rem;
  }

  .card {
    border-radius: 0.1875rem;
    border: none;
    // border: 1px solid #82828236;
    cursor: pointer;
    min-height: 10.375rem;
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
        0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
  }

  .card-body {
    padding: $padding10;
  }

  .landingCard-circle {
    display: flex;
    justify-content: space-between;
    position: $p-relative;

    &::after {
      content: "";
      position: $p-absolute;
      height: 1px;
      background-color: $light-background;
      left: -10px;
      right: -10px;
      top: 50%;
      transform: translate(0, -50%);
    }

    .icon-box {
      width: $width70 - 10;
      height: $height70 - 10;
      border-radius: 50%;
      border: 1px solid $light-background;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      z-index: 1;

      svg {
        height: $height50 - 20;
        width: auto;
      }

      img {
        height: 44px;
        width: auto;
      }
    }

    .landingCard-profile-list {
      display: flex;
    }
  }

  .landingCard-actions {
    display: flex;
  }

  .landingCard-header {
    display: flex;
    align-items: center;
    margin-bottom: $margin10;
    margin-top: $margin10;
    color: $primary;
    font-weight: 800;

    p {
      margin: 0;
    }
  }

  .landingCard-description {
    margin-bottom: $margin10;
  }

  .landingCard-profile-list {
    .landingCard-profile-listitem {
      display: flex;
      align-items: center;
      margin-bottom: $margin10;

      .label {
        margin: 0 $margin10;
      }

      span {
        &:last-child {
          width: calc(100% - 6.625rem);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}


.data-domain-gridview .landingCard-circle .landingCard-actions .edit {
  margin-right: 5px;
}

.dataDomain-lower-part .no-result {
  height: 85% !important;
}

.data-domain-gridview {
  & .list-section:not(:last-child) {
    margin-bottom: 2px;
  }

  .list-section {
    max-width: 740px;
    margin: 0px auto;
    display: flex;
    align-items: center;
  }

  .list-column {
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 0;
    width: 100px;

    .paragraph {
      & > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        width: calc(100% - 0.3125rem);
      }
    }
  }

  .list-column-first {
    width: 1.25rem;
    // width: 1.875rem;
  }

  .list-column-second {
    width: 5rem;
  }

  .smallUser {
    margin-left: 0px;
  }
}

.status-tab {
  padding: 0.25rem 1.25rem;
  border-radius: 2.75rem;
  font-weight: 600;
  font-size: 0.75rem;

  &.status-tab-success {
    color: #00cc92;
    background: rgba(0, 204, 146, 0.2);

    &.with-dot &::before {
      content: "";
      width: 0.25rem;
      height: 0.25rem;
      background: #00cc92;
    }
  }

  &.status-tab-error {
    color: #ff565e;
    background: rgba(255, 86, 94, 0.2);
    position: relative;

    &.with-dot {
      &::before {
        position: absolute;
        top: 50%;
        left: 0.5rem;
        transform: translateY(-50%);

        content: "";
        width: 0.375rem;
        height: 0.375rem;
        background: #ff565e;
        border-radius: 50%;
      }
    }
  }

  &.status-tab-warn {
    color: #ffbb01;
    background: rgba(255, 187, 1, 0.2);

    &.with-dot &::before {
      content: "";
      width: 0.25rem;
      height: 0.25rem;
      background: #ffbb01;
    }
  }
}

:is(.data-domain-gridview, .user-gridview) .landingCard-header {
  font-size: 0.8125rem;
}

:is(.data-domain-gridview, .user-gridview) .paragraph {
  font-size: 0.625rem;
}

:is(.data-domain-gridview, .user-gridview) .label {
  font-size: 0.625rem;
}

:is(.data-domain-gridview, .user-gridview) .list-column-first svg {
  height: 0.875rem;
  width: 0.875rem;
}

:is(.data-domain-gridview) .landingCard-description {
  & p>span {
    font-size: 0.6875rem !important;
  }

  min-height: 1.125rem;
}

.usr-icon-box {
  margin-top: 0.125rem;

  svg {
    height: 1.375rem;
    width: 1.375rem;
  }
}

.data-domain-listview {
  &.usr-manage-user {
    height: calc(100vh - 15.375rem);
  }

  .custom-simple-landing-dgt {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

.sub-header{
  .sub-header-right {
    display: flex;
    align-items: center;
  }
}
