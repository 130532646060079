.policy-left-bar {
  .policy-details {
    padding: 0 0.625rem;
  }

  .policy-details-header {
    padding: 0.625rem;
    border-bottom: 0.0625rem solid #f9f9f9;
    font-size: 0.8125rem;
    font-weight: 600;
  }
}
