@import "../../../../assets/scss/common/colors";

.usage-drawer {
    .ant-drawer-body {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}
.usage-refresh-btn {
    position: absolute;
    top: -30px;
    right: 16px;
}
.usage-filter-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 6px;
    transition: 0.4s;
    &.active {
        grid-template-columns: auto 30px;
        transition: 0.4s;
    }
}
#usage-filter-range-picker {
    .usage-filter-range-picker {
        border-radius: 3px !important;
        color: #17171a !important;
    }
}

#pod-usage {
    height: calc(100vh - 225px) !important;
    // height: 65vh !important;
}
.transformerIcon {
    svg {
        height: 1.25rem !important;
        width: 1.25rem !important;
        path {
            fill: #2c83c4 !important;
        }
    }
}

.ant-list-item-meta-avatar {
    margin-right: 5px;
}
.-name {
    text-transform: uppercase;
    color: $url-link-color;
    font-family: "InterSemiBold";
    cursor: pointer;
}

// .usage-and-jobs-wrapper .custom-simple-landing-dgt {
//     height: calc(100vh - 84px);
//     grid-template-columns: 80px 93.9px 111.287px 177px 139.9px 3414.5px 80px 80px !important;
// }
